import dayjs from './dayjsext';

export function getTimestampFromDuration(
  dur,
  { start, past = true } = {},
): number {
  if (!dur) {
    return dayjs(start).valueOf();
  }
  if (past) {
    return dayjs(start).subtract(dayjs.duration(dur)).valueOf();
  }
  return dayjs(start).add(dayjs.duration(dur)).valueOf();
}

/*
 * condition:
 * ts should be ordered,
 * and [0] is same (most time is timestamp)
 * */
export function mergeTs(ts1, ts2) {
  return ts1.map((d, i) => {
    if (d[0] !== ts2[i][0]) {
      throw new Error("can't merge ts data are not consist");
    }
    return [...d, ts2[i][1]];
  });
}
