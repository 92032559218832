import { css, html, LitElement } from 'lit';

import { customElement } from 'lit/decorators.js';
import nls from '../../locales/index';
import { i18nMixin } from '../../decorators';
import { Mix } from '../../common';

// @ts-expect-error
@customElement('ki-accordion')
export default class KiAccordion extends Mix(LitElement, [i18nMixin, { nls }]) {
  // language=CSS
  static styles = css`
    :host {
      display: block;
    }
  `;

  constructor() {
    super();
    this.content = 'default content';
  }

  static get properties() {
    return {
      multiple: { type: Boolean },
    };
  }

  render() {
    // language=html
    return html``;
  }
}
