/* eslint-disable max-classes-per-file */
import { css, html, LitElement } from 'lit';
import { customElement, query, property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { last, first, omitBy, values } from 'lodash-es';
import {
  LoaderMixin,
  Mix,
  template,
  dayjs,
  downloadPdf,
  formatNumber,
} from '../../common';
import { getRouteOptionsAndParams } from '../ki-app';
import { i18nMixin, responsiveMixin } from '../../decorators';
import nls from '../../locales/index';

import { getCurrentApi } from '../../api';
import '@ui5/webcomponents/dist/Carousel.js';

@customElement('wwp-station-info')
export default class WwpStationInfo extends Mix(
  LitElement,
  LoaderMixin,
  responsiveMixin,
  [i18nMixin, { nls }],
) {
  static styles = css`
    :host {
      height: 100%;
      width: 100%;
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
    }

    .content {
      margin: 15pt;
      height: auto;
      flex: 1;
    }

    :host(.sm-screen) .content,
    :host(.md-screen) .content {
      width: 100%;
      margin: 0px;
      margin-right: 20px;
      display: table;
    }

    :host(.sm-screen) .content:first-of-type {
      margin-top: 50px;
    }

    :host(.md-screen) .content:first-of-type {
      margin-top: 25px;
    }

    ui5-carousel {
    }

    .images {
      margin: 15pt;
      height: 100%;
      max-height: 95%;
      flex: 1;
      min-width: 200px;
    }

    .images img {
      display: block;
      margin: auto;
      max-width: 90%;
      max-height: 90%;
    }
    .download-btn {
      margin: 10px;
    }

    .row {
      display: table-row;
      line-height: 1.5em;
    }

    .footer {
      display: table-row;
      line-height: 2em;
    }

    .row {
      width: 100%;
      height: 15px;
    }

    .cell {
      display: table-cell;
      padding: 5px 10px;
    }

    :host(.sm-screen) .cell {
      height: 34px;
    }

    .label {
      color: gray;
      padding-left: 30px;
    }

    .hidden {
      display: none;
    }

    .value {
      overflow-wrap: break-word;
    }

    .url {
      text-decoration: none;
    }

    :host(.sm-screen) .row {
      height: unset;
    }
  `;

  @query('#gallery')
  gallery;

  @property({ type: Boolean })
  showDownload = false;

  // eslint-disable-next-line class-methods-use-this
  get api() {
    return getCurrentApi();
  }

  static get properties() {
    return {
      station: {
        type: Object,
        initial: () => {},
      },
      fields: {
        type: Array,
        initial: () => ['station_no', 'station_name'],
      },
    };
  }

  disconnectedCallback() {
    super.connectedCallback();
    clearInterval(this.interval);
  }

  /**
   * Metadata info label
   * @returns Label if defined
   * @returns Translation of key if no label is defined
   * @returns Key if no translation for key is available
   * @remark Non-standard translation, use the `label` tag
   * @remark Generic translation, define it in the `@kisters/wiski-web/locales`
   */
  getLabel(field): string {
    if (field.label) {
      return field.label;
    }

    if (!this.i18n.exists(field.field))
      console.warn('# Translation missing for ', field.field);
    return this.i18n.t(field.field);
  }

  getValue(field) {
    let ret;
    let _skipSubst = false; // Skip string modification after parsing
    const val = this.station ? this.station[field.field] : undefined;
    if (!val) {
      ret = '-';
    } else if (field.type === 'number') {
      ret = formatNumber(
        parseFloat(val),
        field.numberFormat || this.numberFormat || {},
      );
    } else if (field.type === 'textnumber') {
      ret = formatNumber(
        parseFloat(val.replace(',', '.')),
        field.numberFormat || this.numberFormat || {},
      );
    } else if (field.type === 'datetime') {
      if (field.subst) {
        ret = val;
        field.subst.forEach(s => {
          ret = ret.replace(s.token, s.with);
        });
        _skipSubst = false;
      }
      const _dt = dayjs(ret, field.dateFormat?.inputPattern);
      ret = _dt.format(field.dateFormat?.outputFormat);
    } else if (field.type === 'html') {
      ret = unsafeHTML(this.station[field.field].replaceAll('\r\n', '<br>'));
    } else if (field.type === 'url') {
      // Check if URL is provided (either hardcoded or dynamic as station parameter)
      if (
        (field.urlIsStationParameter && this.station[field.url]) ||
        (!field.urlIsStationParameter && field.url)
      ) {
        let _url = '<a class="url" href="';
        if (field.urlIsStationParameter) _url += this.station[field.url];
        else _url += field.url;
        _url += '"/>';
        _url += val;
        _url += '</a>';
        ret = unsafeHTML(_url);
      } else ret = unsafeHTML(val);
    } else {
      ret = this.station[field.field];
    }

    if (ret !== '-' && field.translate) {
      ret = this.i18n.t(ret);
    }

    if (ret !== '-' && field.subst && _skipSubst) {
      if (Array.isArray(field.subst)) {
        let tmpret = val;
        field.subst.forEach(item => {
          tmpret = tmpret.replace(item.token, item.with);
        });
        ret = tmpret;
      } else {
        ret = val.replace(field.subst.token, field.subst.with);
      }
    }
    if (ret !== '-' && field.suffix) {
      ret = template(`${ret}${field.suffix}`, this.station);
    }
    return ret;
  }

  getSlider() {
    // eslint-disable-next-line lit-a11y/alt-text
    return html`<ui5-carousel id="gallery" arrows-placement="Navigation" cyclic>
      ${this.images?.map(i => html`<img alt="station" src="${i}" />`)}
    </ui5-carousel>`;
  }

  getTable(fields) {
    return fields.map(field => {
      const val = this.getValue(field);
      return val !== '-'
        ? html`<div class="row">
            <div class="cell label">${this.getLabel(field)}</div>
            <div
              style="${field.style || ''}"
              class="cell value ${field.type || ''}"
            >
              ${val}
            </div>
          </div>`
        : html``;
    });
  }

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, ['stationId']);
    this.stationId = params.stationId;
    this.station = await this.api.getStation(this.stationId);
    Object.assign(this, params.options);
    if (this.tsData) {
      this.fetchTsData();
    }
    if (this.showImages) {
      this.fetchImages();
      let count = 0;
      this.interval = setInterval(() => {
        count += 1;
        this.gallery.navigateTo(count % this.images.length);
      }, 10000000);
    }
  }

  render() {
    return html`
      ${this.tables?.map(
        table =>
          html`<div class="content">
            ${this.getTable(table.items)}
            ${table.footer
              ? html`<div class="footer">
                  <div class="label">
                    ${table.footer &&
                    this.tableHasValues(this.getTable(table.items))
                      ? unsafeHTML(table.footer)
                      : ''}
                  </div>
                </div>`
              : ``}
          </div>`,
      )}

      <div class="images ${this.images && this.images.length ? '' : 'hidden'}">
        ${this.images && this.getSlider()}
      </div>
      ${this.tables && this.showDownload
        ? html` <ki-icon-btn
            class="ripple download-btn"
            id="download-button"
            title="${this.i18n.t('download')}"
            icon="ki ki-download"
            @click="${this.download}"
          ></ki-icon-btn>`
        : ``}
    `;
  }

  download() {
    const tableData = this.tables?.map(table =>
      table.items.map(field => {
        const obj = {};
        obj[this.getLabel(field)] = this.getValue(field);
        return obj;
      }),
    );

    const dataTobeDownloaded = tableData?.map(table =>
      omitBy(table, obj => values(obj)[0] === '-'),
    ); // filter out empty values
    downloadPdf(
      {
        stationInfo: dataTobeDownloaded,
        images: this.images,
      },
      { name: `${this.station?.station_no ?? 'stationInfo'}` },
    );
  }

  // TODO: Dirty, LANUV-167
  // eslint-disable-next-line class-methods-use-this
  tableHasValues(templateResultList) {
    let anyValueFound = false;
    templateResultList.forEach(tr => {
      if (tr.values?.length) anyValueFound = true;
    });
    return anyValueFound;
  }

  async fetchImages() {
    this.images = await this.api.getImage(this.station?.station_no);
    this.requestUpdate();
    return this.images;
  }

  async fetchTsData() {
    await Promise.all(
      this.station?.timeseries
        .filter(ts => this.tsData.includes(last(ts.href.split('/'))))
        .map(async ts => {
          const tsdata = await this.api.getTsData(ts.href);
          tsdata.forEach(item => {
            this.station[item.ts_shortname] = last(last(item.data));
            const from = first(first(item.data));
            const to = first(last(item.data));
            if (from && to) {
              this.station[`${item.ts_shortname}_from`] = from;
              this.station[`${item.ts_shortname}_to`] = to;
              this.station[`${item.ts_shortname}_coverage`] = `${dayjs(
                from,
              ).format('L LT')} - ${dayjs(to).format('L LT')}`;
            }
          });
        }),
    );
  }
}
