import { css } from 'lit';

export default css`
  :host {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  ui5-button {
    cursor: pointer;
    margin: 0 0 5px 10px;
  }

  #nav-node {
    overflow: auto;
    padding: 5px 10px 0 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid gray;
  }

  #nav-node.hidden {
    display: none;
  }

  .hidden {
    display: none;
  }

  #nav-node .label {
    font-size: 1.5em;
  }

  ki-wwp-switcher-button {
    border-radius: 0;
    box-shadow: none;
    align-self: normal;
  }

  .actions {
    display: flex;
  }

  ki-icon-btn {
    margin: 5px;
    box-shadow: none;
  }

  .hideButton {
    display: none;
  }

  #chart-node {
    display: table-cell;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .contentnode {
    display: flex;
    flex: 1;
  }

  #table-wrapper {
    position: relative;
    overflow: auto;
    flex: 0 0 330px;
  }

  #table-wrapper.hidden {
    position: relative;
    overflow: auto;
    flex: 0 0 0;
  }

  ki-wwp-ts-table {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  #chart-wrapper {
    position: relative;
    flex: 1;
  }

  :host(.sm-screen) .label {
    display: none;
  }

  :host(.sm-screen) ki-wwp-switcher-button {
    margin-left: 35pt;
  }

  :host(.sm-screen) .clipboard-btn {
    display: none;
  }

  :host(.sm-screen) #table-wrapper {
    flex: 1 1 100%;
    background: white;
  }

  :host(.sm-screen) #table-wrapper.hidden {
    position: relative;
    overflow: auto;
    flex: 0 0 0;
  }

  .highcharts-legend-checkbox {
    transform: translateY(-2px);
  }

  ui5-select {
    background: #eee;
    position: absolute;
    left: 50px;
    top: 10px;
    z-index: 1;
  }
`;
