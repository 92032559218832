import { html, css, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '@ui5/webcomponents/dist/Select';
import '@ui5/webcomponents/dist/Option';
import { getRouteOptionsAndParams } from '../ki-app';
import { getCurrentApi } from '../../api';

/**
 * Component to display PDF reports using the browser's native viewer.
 * @extends LitElement
 */
@customElement('ww-pdf-reports')
export default class WwPdfReports extends LitElement {
  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      height: calc(100% - 30px);
    }
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
    .left-panel {
      text-align: center;
      flex: 0;
    }
    .right-panel {
      flex: 1;
    }

    ui5-select {
      width: 350px;
    }
  `;

  /**
   * API handler object.
   */
  private api = getCurrentApi();

  /**
   * Data array to store document information.
   */
  @property({ type: Array })
  data: { label: string; value: string }[] = [];

  /**
   * Path to the directory containing the documents.
   */
  @property({ type: String })
  path = '/documents';

  /**
   * URL of the selected document.
   */
  @property({ type: String })
  selectedDoc = '';

  /**
   * Handler for the route parameters.
   * @param location Current router location
   */
  async onAfterEnter(location: any): Promise<void> {
    const params = getRouteOptionsAndParams(location, ['content']);
    console.debug(params.content);
    this.path = params.options?.path ?? this.path;
    await this.fetchData();
  }

  /**
   * Constructs the full path to a document.
   * @param subpath Relative path to the document
   * @returns Full URL as a string
   */
  private _getFullPath(subpath: string): string {
    return new URL(
      `${this.api.dataPath}${this.path}/${subpath}`,
      window.location.origin,
    ).href;
  }

  /**
   * Fetches the document index and initializes the document selection.
   */
  async fetchData(): Promise<void> {
    const res = await this.api._requestJson(`/${this.path}/index.json`);
    this.data = res.data
      .sort(
        (a, b) =>
          new Date(b.lastModified).getTime() -
          new Date(a.lastModified).getTime(),
      )
      .map(doc => ({ label: doc.name.replace('.pdf', ''), value: doc.name }));

    if (this.data.length) {
      this.selectedDoc = this._getFullPath(this.data[0].value);
    }
  }

  /**
   * Renders the component HTML.
   * @returns Lit HTML TemplateResult
   */
  render(): ReturnType<typeof html> {
    return this.data && this.selectedDoc
      ? html` <div class="left-panel">
            <ui5-select
              @change="${(e: any) => {
                this.selectedDoc = this._getFullPath(
                  e.target.selectedOption.value,
                );
              }}"
            >
              ${this.data.map(
                item =>
                  html`<ui5-option value="${item.value}"
                    >${item.label}</ui5-option
                  >`,
              )}
            </ui5-select>
          </div>
          <div class="right-panel">
            <iframe
              title="content"
              id="contentframe"
              src="${this.selectedDoc}"
            ></iframe>
          </div>`
      : html`<div>Loading...</div>`;
  }
}
