/* eslint-disable no-plusplus */
import { html, css, LitElement } from 'lit';

import { customElement, property, query } from 'lit/decorators.js';
import '../ki-progress-bar/ki-player';
import '@ui5/webcomponents/dist/Popover';
import '@ui5/webcomponents/dist/Button';
import '@ui5/webcomponents/dist/List';
import '@ui5/webcomponents/dist/StandardListItem';
import '@ui5/webcomponents/dist/GroupHeaderListItem';
import '@ui5/webcomponents-icons/dist/cancel.js';
import ImageLayer from 'ol/layer/Image';
import ImageSource from 'ol/source/Image';
import Static from 'ol/source/ImageStatic';
import { sortBy, keyBy } from 'lodash-es';
import dayjs from '../../common/dayjsext';
import { getCurrentApi } from '../../api';
import {
  legendType,
  LegendEntry,
} from '../ki-progress-bar/ki-progress-bar-legend';
import KiStationMap from '../ki-station-map/ki-station-map';
import './ww-raster-legend';

declare type LegendEntry_ = typeof LegendEntry;
declare type KiStationMap_ = typeof KiStationMap;

interface ImageItem {
  timestamp: string;
  time: number;
  value: string;
}

interface RasterItem {
  id: string;
  label: string;
  group: string;
  images: Array<ImageItem>;
  meta: {
    attributes: {
      classification: string;
      parameterKey: string;
    };
    valueDistance: string;
  };
}

const getLegendEntry = (
  lastTimestamp: any,
  untilTimestamp: any,
  fromUnixMilliseconds: number,
  untilPercentage: number,
  childrenTimestamps: Array<any>,
) => {
  const label = `${lastTimestamp.format('ddd DD.MM')}`;
  const toTime = untilTimestamp.valueOf();
  const timeDifference = toTime - lastTimestamp.valueOf();

  const children = childrenTimestamps.map(timestamp => ({
    label: timestamp.format('LLL'),
    untilPercentage:
      ((timestamp.valueOf() + timeDifference - fromUnixMilliseconds) /
        (toTime - fromUnixMilliseconds)) *
      100,
  }));

  return {
    label,
    untilPercentage,
    children,
  };
};

const fillInMissingImages = (
  rasterItem: RasterItem,
  minTime: number,
  maxTime: number,
  stepDuration: number,
): RasterItem => {
  if (rasterItem.images.length === 0) {
    return rasterItem;
  }

  const newImages: Array<ImageItem> = [];

  let rasterImageIndex = 0;

  for (let i = minTime; i <= maxTime; i += stepDuration) {
    let image = '';
    const rasterImage = rasterItem.images[rasterImageIndex];

    if (rasterImage.time > i) {
      if (rasterImageIndex > 0) {
        image = rasterItem.images[rasterImageIndex - 1].value;
      }
    } else {
      image = rasterImage.value;
      if (rasterImageIndex < rasterItem.images.length - 1) {
        rasterImageIndex += 1;
      }
    }

    if (rasterImage.time === i) {
      newImages.push(rasterImage);
      continue;
    }

    newImages.push({
      value: image,
      timestamp: dayjs(i).format('YYYY-MM-DDTHH:mm:ssZ'),
      time: i,
    });
  }

  const returnValue = {
    ...rasterItem,
  };
  returnValue.images = newImages;

  return returnValue;
};

@customElement('ww-raster-player')
export default class WwRasterPlayer extends LitElement {
  static styles = css`
    :host {
      height: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;
    }

    ui5-popover {
      max-width: calc(100% - 100px);
      overflow-y: hidden;
      overflow-x: hidden;
    }

    ww-raster-legend {
      position: fixed;
      right: 60px;
      bottom: 90px;
    }

    ki-player {
      margin-right: 10px;
    }

    ui5-popover slot[name='header'] {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid darkgray;
      align-items: center;
    }

    .popupover-header {
      font-size: 1.3em;
      color: #32363a;
    }

    ui5-li::part(additional-text) {
      padding-left: 40px;
      max-width: initial;
    }

    .selection {
      display: flex;
      flex-direction: column;
      width: 300px;
    }

    .tsLabel {
      position: absolute;
      width: 100%;
      left: 50%;
      top: 15px;
      color: dimgray;
      font-size: 1.3em;
      transform: translateX(-50%);
      text-align: center;
    }

    .popover-content {
      display: flex;
      flex-direction: column;
    }

    ki-icon-btn {
      font-size: 20px;
      margin-top: 6px;
      margin-left: auto;
    }

    ki-icon-btn:hover,
    .icon-btn:hover {
      fill: #4a4a49;
    }

    .list-item-content-wrapper {
      line-height: 25px;
      display: flex;
      flex-direction: row;
    }

    .left-right-container {
      padding-left: 16px;
      display: flex;
      flex-direction: row;
    }

    .selection-button-left {
      border-right: 1px solid black; -->
    }

    .selection-button {
      margin-top: -5px;
    }

    .selection-item {
      width: var(--_ui5_radio_button_height);
      height: var(--_ui5_radio_button_height);
      line-height: var(--_ui5_radio_button_height);
      text-align: center;
    }

    ui5-li::part(description) {
      padding-left: 90px;
    }
    ki-icon-btn[active] {
      background-color: var(--theme-color-primary, #0056a0);
      fill: white;
    }
  `;

  static get properties() {
    return {
      rasterlist: { type: Array },
      imageList: { type: Array },
    };
  }

  @property({ type: Object, attribute: true })
  comparemap;

  @property({ type: Array, attribute: false })
  imageLists: Array<RasterItem> = [];

  @property({ type: Object, attribute: true })
  map: Promise<KiStationMap_ | undefined> = Promise.resolve(undefined);

  @property({ type: Object, attribute: true })
  _map: KiStationMap_ | undefined;

  @property({ type: Object, attribute: false })
  primarySelection: RasterItem | undefined | null;

  @property({ type: Boolean })
  disabled = false;

  @property({ type: Object, attribute: false })
  rasterIndex: {
    [id: string]: RasterItem;
  } = {};

  @property({ type: String, attribute: false })
  rlayerPrimary: ImageLayer<ImageSource> | undefined;

  @property({ type: String, attribute: false })
  rlayerSecondary: ImageLayer<ImageSource> | undefined;

  @property({ type: String, attribute: false })
  secondSelected;

  @property({ type: Object, attribute: false })
  secondarySelection: RasterItem | undefined | null;

  @query('#rasterSelect')
  _rasterSelect;

  @property({ type: Boolean })
  showSecondaryRadioButton = false;

  api = getCurrentApi();

  rasterlist: Array<{
    label: string;
    id: string;
    alias: string;
    group: string;
    href: string;
    description: string;
    meta: {
      attributes: {
        classification: string;
        parameterKey: string;
        display_group: string;
        display_description: string;
        display_order: number;
      };
      parameterKey: string;
    };
    order: Number;
    groupOrder: Number;
  }>;

  constructor() {
    super();
    this.rasterlist = [];
  }

  async updated(changedProperties) {
    if (changedProperties.has('secondarySelection')) {
      this.createRasterLayer();
      this._renderNext();
    }
  }

  async firstUpdated(): Promise<void> {
    const rasterlist = await this.api.getRasterList();
    this.rasterlist = rasterlist.filter(item => item.type === 'resource');
    this._map = await this.map;
    // this._comparemap = await this.comparemap;

    this.rasterlist = sortBy(this.rasterlist, [
      'meta.attributes.display_order',
    ]);

    // @ts-expect-error
    this.imageLists = await Promise.all(
      this.rasterlist.map(async raster => {
        let images: Array<ImageItem> = [];
        try {
          images = await this.api.getRessource(`/${raster.href}`);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn(`error loading raster images for: ${raster.href}`);
        }

        images = images
          .map(image => ({
            ...image,
            time: new Date(image.timestamp).getTime(),
          }))
          .sort((a, b) => a.time - b.time);

        return {
          ...raster,
          images,
        };
      }),
    );

    this.rasterIndex = keyBy(this.imageLists, 'id');
  }

  createRasterLayer() {
    if (this.rlayerPrimary) {
      this._map.map.removeLayer(this.rlayerPrimary);
    }

    if (this.rlayerSecondary && this.comparemap) {
      this.comparemap.map.removeLayer(this.rlayerSecondary);
    }

    this.rlayerPrimary = new ImageLayer({
      opacity: 0.7,
      zIndex: 22,
      // @ts-expect-error
      displayInLayerControl: false,
      group: 'Layers',
      name: 'Radar',
      visible: true,
    });
    this.rlayerSecondary = new ImageLayer({
      opacity: 0.7,
      zIndex: 22,
      // @ts-expect-error
      displayInLayerControl: false,
      group: 'Layers',
      name: 'Radar',
      visible: true,
    });

    if (this._map) {
      this._map.map.addLayer(this.rlayerPrimary);
    }

    if (this.comparemap) {
      this.comparemap.map.addLayer(this.rlayerSecondary);
    }
    this.rlayerPrimary.setVisible(true);
    this.rlayerSecondary.setVisible(true);
  }

  _createPlayer() {
    if (!this.primarySelection) {
      this.dispatchEvent(
        new CustomEvent('playeropen', {
          detail: { value: false },
          bubbles: false,
          composed: true,
        }),
      );
      return html``;
    }
    const legendEntries: Array<LegendEntry_> = [];

    const _type = legendType.legendEntries;

    let oldTimestamp: any;
    let childrenArray: Array<any> = [];

    let fromTime = this._minTime;

    const timeSpan = this._maxTime - this._minTime;

    const steps = timeSpan / this._stepDuration;

    for (
      let time = this._minTime;
      time <= this._maxTime;
      time += this._stepDuration
    ) {
      const newTimestamp = dayjs(time);

      if (
        oldTimestamp !== undefined &&
        !newTimestamp.isSame(oldTimestamp, 'day')
      ) {
        const dayUntil = oldTimestamp
          .hour(23)
          .minute(59)
          .second(59)
          .millisecond(999);
        const untilPercentage = (100 * (dayUntil - this._minTime)) / timeSpan;

        legendEntries.push(
          getLegendEntry(
            oldTimestamp,
            newTimestamp,
            fromTime,
            untilPercentage,
            childrenArray,
          ),
        );

        fromTime = newTimestamp.valueOf();
        childrenArray = [];
      }

      childrenArray.push(newTimestamp);

      oldTimestamp = newTimestamp;
    }

    if (oldTimestamp) {
      const newTimestamp = oldTimestamp.add(1, 'hour');
      legendEntries.push(
        getLegendEntry(
          oldTimestamp,
          newTimestamp,
          fromTime,
          100,
          childrenArray,
        ),
      );
    }

    this.dispatchEvent(
      new CustomEvent('playeropen', {
        detail: { value: true },
        bubbles: false,
        composed: true,
      }),
    );

    return html`<ki-player
      @progressChangePlayer="${this._renderNext}"
      .legendType="${_type}"
      .playSteps="${steps}"
      .keyDownSteps="${steps}"
      .legendEntries="${legendEntries}"
      popupWidth="200"
    ></ki-player>`;
  }

  _renderNext(
    e:
      | {
          detail:
            | {
                newTicks: number;
              }
            | undefined;
        }
      | undefined = undefined,
  ) {
    const imageNumber = e?.detail?.newTicks ?? 0;

    if (this.rlayerPrimary && this.primarySelection?.images?.length) {
      const image = this.primarySelection?.images[imageNumber].value;
      this.rlayerPrimary.setSource(
        this._getSource(image, this.primarySelection),
      );
    }
    if (this.rlayerSecondary && this.secondarySelection?.images?.length) {
      const secImage = this.secondarySelection?.images[imageNumber].value;
      if (secImage && secImage !== '') {
        this.rlayerSecondary.setSource(
          this._getSource(secImage, this.secondarySelection),
        );
      } else {
        this.rlayerSecondary.setSource(null);
      }
    }
  }

  _getSource(image, rasterProduct) {
    const selectedLayer = rasterProduct;
    const bbox = selectedLayer.meta.boundingBox;
    const projection =
      selectedLayer.meta.projectionProj4 ===
      '+proj=tmerc +lon_0=-8 +lat_0=0 +lon_ts=0 +lat_ts=53.5 +a=6377560.00 +b=6356260.00'
        ? 'EPSG:123457'
        : 'EPSG:123456';
    return new Static({
      imageSmoothing: false,
      url: `${this.api.dataPath}/${selectedLayer.href.replace(
        'index.json',
        image,
      )}`,
      projection: selectedLayer.meta.projectionIdentifier
        ? selectedLayer.meta.projectionIdentifier.toUpperCase()
        : projection,
      imageExtent: [bbox.minX, bbox.minY, bbox.maxX, bbox.maxY],
      // @ts-expect-error
      zIndex: 15,
    });
  }

  _stepDuration;

  _minTime;

  _maxTime;

  async _changeSelection(selected, primary = true) {
    this._rasterSelect.close();
    if (selected) {
      try {
        if (primary) {
          this.primarySelection = this.rasterIndex[selected];
          (this.renderRoot.querySelector('ki-player') as any)?.updatePercentage(
            0,
          );
        } else {
          this.secondarySelection = this.rasterIndex[selected];
        }
        let primaryDuration = 3600000;
        let secondaryDuration: number | undefined;
        if (this.primarySelection?.meta.valueDistance) {
          primaryDuration = dayjs
            .duration(this.primarySelection?.meta.valueDistance)
            .asMilliseconds();
        }
        if (this.secondarySelection?.meta.valueDistance) {
          secondaryDuration = dayjs
            .duration(this.secondarySelection?.meta.valueDistance)
            .asMilliseconds();
        }
        this._stepDuration =
          secondaryDuration && secondaryDuration < primaryDuration
            ? secondaryDuration
            : primaryDuration;

        let minTime = 0;
        let maxTime = 0;
        if (this.primarySelection && this.primarySelection.images.length > 0) {
          minTime = this.primarySelection.images[0].time;
          maxTime =
            this.primarySelection.images[
              this.primarySelection.images.length - 1
            ].time;
        }

        this._minTime = minTime;
        this._maxTime = maxTime;

        if (
          this.secondarySelection &&
          this.secondarySelection.images.length > 0
        ) {
          const secondMin = this.secondarySelection.images[0].time;
          const secondMax =
            this.secondarySelection.images[
              this.secondarySelection.images.length - 1
            ].time;
          if (secondMin < minTime) {
            minTime = secondMin;
          }
          if (secondMax > maxTime) {
            maxTime = secondMax;
          }
        }

        if (this.primarySelection) {
          this.primarySelection = fillInMissingImages(
            this.primarySelection,
            minTime,
            maxTime,
            this._stepDuration,
          );
        }
        if (this.secondarySelection) {
          this.secondarySelection = fillInMissingImages(
            this.secondarySelection,
            minTime,
            maxTime,
            this._stepDuration,
          );
        }

        this._renderNext();
      } catch (e) {
        this._clearSelection();
        // eslint-disable-next-line no-console
        console.error('could not load raster product', selected);
      }
    }
    this.requestUpdate();
  }

  // eslint-disable-next-line class-methods-use-this
  _getCoverage(tsitem) {
    const spacing = tsitem.meta?.valueDistance;
    const dateformat = spacing.includes('PT') ? 'L LT z' : 'L z';

    const t0String = tsitem.meta?.selection?.t0;
    let coverage = '-';
    if (t0String) {
      coverage = `t0: ${dayjs(t0String).format(dateformat)}`;
    } else if (tsitem.meta?.coverage?.from && tsitem.meta?.coverage?.until) {
      coverage = `${dayjs(tsitem.meta?.coverage?.from).format(
        dateformat,
      )} - ${dayjs(tsitem.meta?.coverage?.until).format(dateformat)}`;
    }
    return coverage;
  }

  _getTsTitle() {
    return this.primarySelection
      ? `${this.primarySelection.group} - ${this.primarySelection.label}${
          this.secondarySelection
            ? ` / ${this.secondarySelection.group} - ${this.secondarySelection.label}`
            : ''
        }`
      : '';
  }

  _clearSelection() {
    if (this.rlayerPrimary) {
      this._map.map.removeLayer(this.rlayerPrimary);
    }
    if (this.rlayerSecondary && this.comparemap) {
      this.comparemap.map.removeLayer(this.rlayerSecondary);
    }
    this.dispatchEvent(
      new CustomEvent('playeropen', {
        detail: { value: false },
        bubbles: false,
        composed: true,
      }),
    );
    this.primarySelection = null;
    this.secondarySelection = null;
    this.primarySelection = null;
    this._rasterSelect.close();
    this.dispatchEvent(new CustomEvent('clearselection'));
  }

  _getClassification(id) {
    return id ? this.rasterIndex[id]?.meta.attributes.classification : {};
  }

  render() {
    let group;
    return html`
      <div class="tsLabel">${this._getTsTitle()}</div>

      ${this.primarySelection ? this._createPlayer() : ''}
      <ww-raster-legend
        style="${this.secondarySelection ? 'left:10px;right: initial;' : ''}"
        .classification="${this._getClassification(this.primarySelection?.id)}"
      ></ww-raster-legend>
      <ww-raster-legend
        .classification="${this._getClassification(
          this.secondarySelection?.id,
        )}"
      ></ww-raster-legend>
      <ki-icon-btn
        class="${this.disabled ? 'disabled' : ''}"
        title="Show Raster Data"
        ?active=${this.primarySelection?.id}
        icon="ki ki-longitude"
        @click=${e => {
          this._rasterSelect.showAt(e.target);
        }}
      ></ki-icon-btn>

      <ui5-popover id="rasterSelect">
        <ui5-list
          separators="None"
          mode="None"
          @selection-change="${this._changeSelection}"
        >
          <slot name="header" class="">
            <div
              class="left-right-container"
              style="display: ${this.showSecondaryRadioButton
                ? 'flex'
                : 'none'};"
            >
              <div class="selection-item selection-button-left">Left</div>
              <div class="selection-item">Right</div>
            </div>
            <div class="popupover-header">Raster products</div>
            <div>
              <ui5-button
                design="Transparent"
                icon="cancel"
                @click="${this._clearSelection}"
                >Deselect</ui5-button
              >
              <ui5-button
                design="Transparent"
                @click="${() => this._rasterSelect.close()}"
                >X</ui5-button
              >
            </div>
          </slot>
          ${this.rasterlist.map(item => {
            const listitem = html`<ui5-li
              ?selected="${this.primarySelection?.id === item.id}"
              data-tsid="${item.id}"
              description="${`${
                item?.meta?.attributes?.display_description ?? ''
              } - ${this._getCoverage(item)}`}"
              @click="${e => {
                this.selectItem(e, item.id, item.group);
              }}"
              style=""
            >
              <div class="list-item-content-wrapper">
                <ui5-radio-button
                  group="primary"
                  class="selection-button ${this.showSecondaryRadioButton
                    ? 'selection-button-left'
                    : ''}"
                  ?checked="${this.primarySelection?.id === item.id}"
                ></ui5-radio-button>

                <ui5-radio-button
                  group="secondary"
                  ?disabled="${this.primarySelection === null ||
                  this.primarySelection?.group !== item.group ||
                  this.primarySelection?.id === item.id}"
                  ?checked="${this.secondarySelection?.id === item.id}"
                  ?hidden="${!this.showSecondaryRadioButton}"
                  class="selection-button"
                ></ui5-radio-button>
                ${item.label}
              </div>
            </ui5-li>`;
            if (group !== item.group) {
              group = item.group;
              return html`<ui5-li-groupheader>${item.group}</ui5-li-groupheader
                >${listitem}`;
            }
            return listitem;
          })}
        </ui5-list>
      </ui5-popover>
    `;
  }

  selectItem(e: any, itemId: string, group: string): void {
    this.createRasterLayer();
    if (e.target.getAttribute('group') !== 'secondary') {
      if (this.secondarySelection?.group !== group) {
        this.secondarySelection = null;
        this.dispatchEvent(new CustomEvent('clearselection'));
      }
      this.primarySelection = this.imageLists.find(item => item.id === itemId);
      this._changeSelection(itemId);
    } else if (this.primarySelection !== null) {
      this.dispatchEvent(new CustomEvent('opensecondary'));
      this.secondarySelection = this.imageLists.find(
        item => item.id === itemId,
      );
      this._changeSelection(itemId, false);
    }
  }
}
