/* eslint-disable lit-a11y/click-events-have-key-events */
import { html, css, LitElement } from 'lit';

import { customElement, property } from 'lit/decorators.js';

import '../ki-icon/ki-icon-btn';
import { legendType } from './ki-progress-bar-legend';
import './ki-player-speed-chooser';

const playAccuracy = 0.001;

@customElement('ki-player')
export default class KiPlayer extends LitElement {
  static styles = css`
    :host {
      width: 100%;
      display: block;
    }
    .player-container {
      width: 85%;
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;
    }
    .control-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 5px;
    }
    .control-spacer {
      flex: 1;
    }
    .current-status {
      margin-left: 10px;
      height: fit-content;
      border-radius: 5px;
      background-color: white;
      padding: 5px;
      box-shadow:
        0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2);
    }
    .button-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .no-left-radius {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .no-right-radius {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .left-button {
      margin-left: 2px;
    }
    .speed-chooser {
      margin-left: 10px;
    }
    .button-element:hover {
      background-color: lightgray;
    }
    ki-progress-bar-legend {
      flex: 1;
    }
  `;

  @property({ type: Boolean, attribute: false })
  playing: boolean = false;

  @property({ type: Number, attribute: false })
  playInterval: number | undefined;

  /**
   * Percentage steps on right/left arrow key.
   *
   * @type {number}
   */
  @property({ type: Number, attribute: true })
  keyDownSteps: number = 5;

  /**
   * How many playing steps there are.
   *
   * @type {number}
   */
  @property({ type: Number, attribute: true })
  public set playSteps(value: number) {
    this._playSteps = value;
    this.updateStatus();
  }

  public get playSteps(): number {
    return this._playSteps;
  }

  private _playSteps: number = 100;

  get tickSize(): number {
    return 100 / this.playSteps;
  }

  /**
   * Time in ms every tick should take.
   *
   * @type {number}
   */
  @property({ type: Number, attribute: true })
  playTickTime: number = 1000;

  /**
   * Width of the legend popup.
   *
   * @type {number}
   */
  @property({ type: Number, attribute: true })
  popupWidth: number = 140;

  /**
   * Current status.
   *
   * @type {number}
   */
  @property({ type: Number, attribute: true })
  statusPercentage: number = 0;

  /**
   * Playback speed.
   *
   * @type {number}
   */
  @property({ type: Number, attribute: true })
  speed: number = 1;

  /**
   * Current tick.
   *
   * @type {number}
   */
  @property({ type: Number, attribute: true })
  public set tickStatus(value: number) {
    this._tickStatus = value;
    this.updateStatus();
  }

  public get tickStatus(): number {
    return this._tickStatus;
  }

  private _tickStatus: number = 0;

  /**
   * Set if you want to manually inject the current status.
   *
   * @type {string}
   */
  @property({ type: Boolean, attribute: true })
  injectStatusLabel: boolean = false;

  /**
   * Current status.
   *
   * @type {string}
   */
  @property({ type: String, attribute: true })
  currentStatusLabel: String = '0';

  /**
   * Max status. Leave empty if you don't want to show anything.
   *
   * @type {string}
   */
  @property({ type: String, attribute: true })
  maxStatusLabel: String | undefined;

  /**
   * The legend entries. Each entry has a label and a max percentage.
   * Children are the entries shown on hover. The `untilPercentage` of each child is relative to its parent.
   *
   * @type {Array<{ label: string; untilPercentage: number, children: Array<{ label: string; untilPercentage: number}> }>}
   */
  @property({ type: Array, attribute: true })
  legendEntries: Array<{
    label: string;
    untilPercentage: number;
    children: Array<{ label: string; untilPercentage: number }>;
  }> = [];

  /**
   * The type of legend. (legendEntries, minMax)
   *
   * @type {number}
   */
  @property({ type: Number, attribute: true })
  legendType: legendType = legendType.legendEntries;

  /**
   * The configuration for the legend. Consists of label for min and max value.
   * Children are the entries shown on hover. The `untilPercentage` of each child is relative to the whole bar.
   *
   * @type {{ minLabel: string; maxLabel: string, children: Array<{ label: string; untilPercentage: number}> }}
   */
  @property({ type: Object, attribute: true })
  minMaxConfig:
    | {
        minLabel: string;
        maxLabel: string;
        children: Array<{ label: string; untilPercentage: number }>;
      }
    | undefined;

  disconnectedCallback(): void {
    // eslint-disable-next-line wc/guard-super-call
    super.disconnectedCallback();
    if (this.playInterval) {
      clearInterval(this.playInterval);
      this.playInterval = undefined;
    }
  }

  updateStatus() {
    if (!this.injectStatusLabel) {
      if (this.maxStatusLabel !== `${this.playSteps}`) {
        this.maxStatusLabel = this.playSteps;
      }
      this.currentStatusLabel = this.tickStatus;
    }
  }

  firstUpdated() {
    this.updateStatus();
  }

  dispatchProgressChange(newPercentage: number, newTicks: number): void {
    this.dispatchEvent(
      new CustomEvent('progressChangePlayer', {
        detail: { newPercentage, newTicks },
        bubbles: true,
        composed: true,
      }),
    );
  }

  emitProgressChange(e): void {
    this.updatePercentage(e.detail.newPercentage);
  }

  updatePercentage(percentage) {
    let newPercentage = percentage;
    if (newPercentage < 0) {
      newPercentage = 0;
    }
    if (newPercentage > 100) {
      newPercentage = 100;
    }
    this.statusPercentage = newPercentage;

    let newTicks = newPercentage / this.tickSize;

    newTicks = Math.floor(newTicks);
    this.tickStatus = newTicks;

    this.dispatchProgressChange(newPercentage, newTicks);
  }

  updateTicks(ticks) {
    let newTicks = ticks;
    if (newTicks < 0) {
      newTicks = 0;
    }
    if (newTicks > this.playSteps) {
      newTicks = this.playSteps;
    }
    this.tickStatus = newTicks;

    this.statusPercentage = this.tickSize * newTicks;

    this.dispatchProgressChange(this.statusPercentage, newTicks);
  }

  speedChanged(e) {
    const { newSpeed } = e.detail;
    this.speed = newSpeed;
    if (this.playing) {
      this.togglePlay();
      this.togglePlay();
    }
  }

  togglePlay() {
    this.playing = !this.playing;
    if (this.playing && !this.playInterval) {
      this.playInterval = window.setInterval(
        this.play,
        this.playTickTime / this.speed,
      );
    } else if (this.playInterval) {
      clearInterval(this.playInterval);
      this.playInterval = undefined;
    }
  }

  skipInfront() {
    const newTicks =
      this.tickStatus - 1 < 0
        ? this.playSteps + playAccuracy
        : this.tickStatus - 1;
    this.updateTicks(newTicks);
    this.requestUpdate();
  }

  skipAhead() {
    this.play();
  }

  play = () => {
    const newTicks =
      this.tickStatus + 1 > this.playSteps + playAccuracy
        ? 0
        : this.tickStatus + 1;
    this.updateTicks(newTicks);
    this.requestUpdate();
  };

  render() {
    return html`
      <div class="player-container">
        <ki-progress-bar-legend
          .forcePopup="${false}"
          .statusPercentage="${this.statusPercentage}"
          .keyDownSteps="${this.keyDownSteps}"
          .legendEntries="${this.legendEntries}"
          .legendType="${this.legendType}"
          .minMaxConfig="${this.minMaxConfig}"
          .popupWidth="${this.popupWidth}"
          @progressChangeLegend="${this.emitProgressChange}"
        ></ki-progress-bar-legend>
        <div class="control-container">
          <div class="button-container">
            <ki-icon-btn
              icon="ki ki-backward"
              class="toggle-btn no-right-radius left-button button-element"
              @click="${this.skipInfront}"
            ></ki-icon-btn>
            <ki-icon-btn
              icon="ki ${this.playing ? 'ki-pause' : 'ki-play'}"
              class="toggle-btn no-right-radius no-left-radius button-element"
              @click="${this.togglePlay}"
            ></ki-icon-btn>
            <ki-icon-btn
              icon="ki ki-forward"
              class="toggle-btn no-left-radius button-element"
              @click="${this.skipAhead}"
            ></ki-icon-btn>
            <ki-player-speed-chooser
              class="button-element speed-chooser"
              .speed="${this.speed}"
              @speedChange="${this.speedChanged}"
            ></ki-player-speed-chooser>
            <span class="current-status">
              ${this.currentStatusLabel}
              ${this.maxStatusLabel ? ` / ${this.maxStatusLabel}` : ''}
            </span>
          </div>
          <div class="control-spacer"></div>
          <slot></slot>
        </div>
      </div>
    `;
  }
}
