/* eslint-disable import/extensions */
/* eslint-disable max-classes-per-file */
import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { setLanguage } from '@ui5/webcomponents-base/dist/config/Language.js';
import '@ui5/webcomponents/dist/Assets.js';

import { dayjs, Mix, setDayjsTz } from '../../common';
import {
  KiApp,
  navigateTo,
  getSearchParamString,
  getCurrentPath,
  getNavItems,
  getLogoOptions,
  getConfig,
  getRouteOptionsAndParams,
} from '../ki-app';
import { SM, ViewPort, responsiveMixin, i18nMixin } from '../../decorators';
import style from './ki-app-wwp.css?inline';
import nls from '../../locales/index';
import '../ki-menu/ki-menu';

import '../wwp-station-ts-viewer/wwp-station-ts-viewer';
import '../wwp-overview/wwp-overview';
import '../wwp-station-detail/wwp-station-detail';
import '../wwp-station-detail/wwp-station-download';
import '../wwp-html/wwp-html';
import '../ww-report/ww-report';
import '../ww-report/ww-documents';

import { getCurrentApi } from '../../api';
import { AppConfigDefinition } from '../../defs/AppConfigDefinition';
import { ILocation } from '../../defs/routing';
import { WiskiWebInfoService } from '../../services/WiskiWebInfoService/WiskiWebInfoService';

@customElement('ki-app-wwp')
export default class KiAppWWP extends Mix(KiApp, responsiveMixin, [
  i18nMixin,
  { nls },
]) {
  static styles = [KiApp.styles, style];

  constructor() {
    super();

    setLanguage(this.i18n.language);
    dayjs.locale(this.i18n.language.toLowerCase());
    setDayjsTz(this._config.timeZone);
    this.basePath = './public';
    this.nls = `${this.basePath}/nls/route.json`;
    this.configSubPath = 'config';
    console.log(new WiskiWebInfoService(this.i18n).getInfoAsString());
  }

  @property({ type: String })
  basePath = '';

  @property({ type: String })
  configSubPath = '';

  @property({ type: Object })
  _config: AppConfigDefinition = getConfig() || {};

  // eslint-disable-next-line class-methods-use-this
  get api() {
    return getCurrentApi();
  }

  // eslint-disable-next-line class-methods-use-this
  getNavigationItems() {
    return getNavItems();
  }

  async onAfterEnter(location: ILocation) {
    const params = getRouteOptionsAndParams(location, []);
    Object.assign(this, params.options);
  }

  _renderMenu() {
    const menuMode = ViewPort.size === SM ? 'vertical' : 'horizontal';
    // language=html
    return html`
      <ki-menu
        slot="nav"
        .direction="${menuMode}"
        @selected="${e => {
          const currentQuery = getSearchParamString() ?? '';
          const currentViewType = getCurrentPath().replace(/[^/]+$/, '');
          const newViewType = e.detail.value.replace(/[^/]+$/, '');
          let newUrl = e.detail.value;
          if (currentViewType === newViewType) {
            newUrl = `${newUrl}${
              currentQuery.charAt(0) === '?' ? '' : '?'
            }${currentQuery}`;
          }
          navigateTo(newUrl);
        }}"
        .items="${this.getNavigationItems()}"
      ></ki-menu>
    `;
  }

  _renderDrawer() {
    return html`<div
      class="drawer-container ${this._sideBarVisible ? 'visible' : ''}"
      @click="${this.toggleSideBar}"
    >
      <div class="overlay"></div>
      <div class="drawer" style="max-width: ${this.drawerWidth}">
        ${this._renderMenu()}
      </div>
    </div>`;
  }

  /* TODO make logo as slot ? */
  _renderHeader() {
    const logoOptions = getLogoOptions() || {};
    return html`
      <slot name="header"></slot>
      <div class="header">
        <ki-icon-btn
          icon="ki ki-bars"
          class="toggle-btn"
          @click="${this.toggleSideBar}"
        ></ki-icon-btn>
        <div class="logo-left">
          <a href="${logoOptions?.left?.href || '#'}">
            <img
              id="logo-left-img"
              style="${logoOptions?.left?.style || ''}"
              src="${logoOptions?.left?.image || 'images/logo_left.png'}"
              alt="${logoOptions?.left?.alt || ''}"
          /></a>
        </div>
        <div class="nav">${ViewPort.size === SM ? '' : this._renderMenu()}</div>
        ${logoOptions?.languageSwitch
          ? this._renderLanguageSwitch(logoOptions?.languageSwitch)
          : ''}
        <div
          class="logo-right"
          style="${logoOptions?.right?.container?.style || ''}"
        >
          <a href="${logoOptions?.right?.href || '#'}">
            <img
              id="logo-right-img"
              style="${logoOptions?.right?.style || ''}"
              src="${logoOptions?.right?.image || 'images/logo_right.png'}"
              alt="${logoOptions?.right?.alt || ''}"
          /></a>
        </div>
      </div>
    `;
  }

  /* eslint-disable class-methods-use-this */
  _renderLanguageSwitch(languageSwitch) {
    if (!languageSwitch) return html``;
    const currentlang = sessionStorage.getItem('i18nextLng') || 'en';
    const languageEntries = Object.entries(languageSwitch);

    const inner = languageEntries.map(([name, code]) => {
      const active = currentlang === code;
      return html`<div
        class="${active ? 'activelang' : 'noneactivelang'}"
        @click="${() => {
          if (active) return;
          window.sessionStorage.setItem('i18nextLng', code);
          window.location.reload();
        }}"
      >
        ${name}
      </div>`;
    });

    return html`<div class="language-switch">${inner}</div>`;
  }
}
