import { ReactiveElement } from 'lit';
import { property } from 'lit/decorators.js';
import { Mix, PropertyDefaultValue } from '../../common';

export default class _AddOn extends Mix(ReactiveElement, PropertyDefaultValue) {
  @property({ type: Boolean })
  disabled = false;

  connectedCallback() {
    if (super.connectedCallback) super.connectedCallback();
    if (this.zIndex === undefined) {
      this.zIndex = Array.prototype.indexOf.call(
        this.parentElement.children,
        this,
      );
    }
    if (this.parentElement && this.parentElement.map) {
      this.map = this.parentElement.map;
      this._afterMapCreated();
    } else {
      this.mapReadyEvent = () => {
        this.map = this.parentElement.map;
        this._afterMapCreated();
        this.parentElement.removeEventListener(
          'kistationmapready',
          this.mapReadyEvent,
        );
      };
      this.parentElement.addEventListener(
        'kistationmapready',
        this.mapReadyEvent,
      );
    }
  }

  _afterMapCreated() {
    if (!this.disabled) {
      this._create();
    }
  }

  shouldUpdate(_changedProperties) {
    if (_changedProperties.has('disabled') && this.disabled) {
      this.map && this._tear();
    }
    return !this.disabled;
  }

  disconnectedCallback() {
    if (super.disconnectedCallback) super.disconnectedCallback();
    this.map && this._tear();
  }

  // eslint-disable-next-line class-methods-use-this
  _create() {
    throw new Error('need implement in sub class');
  }

  // eslint-disable-next-line class-methods-use-this
  _tear() {
    throw new Error('need implement in sub class');
  }
}
