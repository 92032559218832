import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { getCurrentApi } from '../../api';
import { Mix, PropertyDefaultValue, dayjs } from '../../common';
import { i18nMixin, loaderMixin } from '../../decorators';
import { getConfig, getRouteOptionsAndParams } from '../ki-app';
import nls from '../../locales/index';
import '../ki-ts-viewer/ki-ts-viewer';
import '../ki-ts-viewer/ki-ts-list-select';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import '../ts-graph/ts-graph-viewer';
import { WiskiTsTransformation } from '../../util/WiskiTsTransformation';
import style from './ww-graph-multi.css';
import { KiwisGraphService, DataProvider } from '../../services';

/**
 * @class WwGraphMulti../
 * Reimplementation of dynamic graph using Echarts
 *  */

interface TsPreset {
  tsFilter: string[];
  mainTs: string[];
  alarmTs: string[];
  presetTs: string[];
  filterOriginal: boolean;
}

@customElement('ww-graph-multi')
export default class WwGraphMulti extends Mix(
  LitElement,
  PropertyDefaultValue,
  loaderMixin,
  [i18nMixin, { nls }],
) {
  tsOptions: TsPreset = {};

  /** Defines the mapping of the `ca_ts` attributes from WISKI */
  tsInfoOptions = {
    tsInfo: { status: 'AdminStatus', remark: 'AdminBemerkung' },
  };

  static styles = [style, css``];

  @property({ type: String })
  stationId: string = '';

  @property({ type: String })
  parameterNo: string = '';

  @property({ type: Array })
  tsList = [];

  api = getCurrentApi();

  onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, [
      'layerName',
      'stationId',
      'stationLabel',
      'remarkAttr',
    ]);
    this.kiwisConfig = getConfig().kiwis;
    this.stationId = params.stationId;
    this.parameterNo = params.options?.parameterNo;
    this.tsOptions = params.options;
    this.dynMenu = params.options?.dynMenu;
    this.dataProvider = new DataProvider(
      this.stationId,
      this.parameterNo,
      this.kiwisConfig,
      this.tsOptions,
    );
    const appconfig = getConfig();
    this.service = new KiwisGraphService(
      `${appconfig.kiwis.basePath}?datasource=${
        appconfig.kiwis.datasource || 0
      }`,
    );
    this.fetchData();
  }

  async fetchData() {
    this.tsList = await this.dataProvider.fetchTsList();
  }

  render() {
    // language=html
    return this.tsList?.length
      ? html`<div id="graphWrapper" class="full">
          <ts-graph-viewer
            id="multiGraph"
            class="full"
            .service="${this.service}"
            .noactions="${false}"
            .tsList="${WiskiTsTransformation.normalizeTsArray(
              this.tsList,
              this.tsInfoOptions,
            )}"
            .tsInfoOptions="${this.tsInfoOptions}"
            .tsOptions="${this.tsOptions}"
            .tsExplorer="${true}"
            .from="${dayjs().subtract(1, 'week').toDate()}"
            .periods="${[
              [
                'last24hour',
                'last7day',
                'last1month',
                'last6month',
                'last1year',
                'last2year',
                'last3year',
              ],
            ]}"
            .customFilter="${function (list) {
              return list;
            }}"
          ></ts-graph-viewer>
        </div> `
      : html``;
  }
}
