/* eslint-disable wc/no-constructor-attributes */
/* eslint-disable wc/no-self-class */
/* eslint-disable max-classes-per-file */
import { html, css } from 'lit';

import { customElement, property } from 'lit/decorators.js';
import KiListItem from './ki-list-item';

@customElement('ki-list-item-group')
export default class KiListItemGroup extends KiListItem {
  // language=CSS
  static styles = css`
    ki-list-item-group {
      display: block;
      position: relative;
    }
    ki-list-item-group .label {
      padding: 12px 0;
    }

    ki-list-item-group .label:hover,
    ki-list-item-group .label:focus {
      color: var(--theme-color, #0056a0);
      background-color: #eeeeee;
    }

    ki-list-item-group .children {
      display: block;
      overflow: hidden;
      height: auto;
    }

    ki-list-item-group.folded .children {
      max-height: 0px;
    }
  `;

  @property({ type: Boolean })
  folded: boolean = false;

  // @ts-expect-error
  get itemNode() {
    return this.querySelector('.label'); // TODO
  }

  constructor() {
    super();
    this.setAttribute('tabindex', '-1');
  }

  _updateSelectedState() {
    this.classList.toggle('folded', !!this.folded);
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html` <slot></slot>`;
  }
}
