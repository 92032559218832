import { css, html, LitElement } from 'lit';

import { customElement, property } from 'lit/decorators.js';
import '../ki-popup/ki-popup';
import { getRouteOptionsAndParams } from '../ki-app';

// TODO collapse, popup with change log.
@customElement('ki-wcp-version-tag')
export default class KiWcpVersionTag extends LitElement {
  // language=CSS
  static styles = css`
    .version {
      display: block;
      padding: 20px;
      cursor: pointer;
      color: var(--theme-color);
    }

    .dependencies {
      color: grey;
      padding: 5px 30px;
    }
  `;

  @property({ type: Object })
  pkg: any = {};

  @property({ type: Boolean })
  showPopup: boolean = false;

  @property({ type: Boolean })
  showDependencies: boolean = false;

  @property({ type: Boolean })
  showBuildDate: boolean = false;

  @property({ type: String })
  dependencies: string = '';

  @property({ type: String })
  changelog: string = 'No changelog provided';

  _packageJsonPath = './package.json';

  @property({ type: String })
  get packageJsonPath(): string {
    return this._packageJsonPath;
  }

  set packageJsonPath(val: string) {
    this._packageJsonPath = val;
    if (val) {
      fetch(val)
        .then(res => res.json())
        .then(pkg => {
          this.pkg = pkg;
        });
    }
  }

  constructor() {
    super();
    this.packageJsonPath = './package.json';
  }

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, []);
    console.debug(params);
    Object.assign(this, params.options);
  }

  render() {
    // language=html
    return html`
      <div
        class="version"
        @click="${() => {
          this.showPopup = !this.showPopup;
        }}"
      >
        ${this.pkg.name}#${this.pkg.version}
      </div>
      <div class="dependencies">${this.dependencies}</div>
      <ki-popup .visible="${this.showPopup}">${this.changelog}</ki-popup>
    `;
  }
}
