import { css, html, LitElement } from 'lit';

import { customElement, property } from 'lit/decorators.js';
import { getQueryObject } from '../../decorators/query';
import {
  initRouter,
  setRoutes,
  attachRouter,
  unregisterRouter,
} from './routing/ki-router';

const _initDarkMode = () => {
  import('./services/ki-theme-service').then(themeService => {
    themeService.init();
  });
};

@customElement('ki-app-index')
export default class KiAppBootstrapper extends LitElement {
  get _mainElement() {
    return getQueryObject(this, '.app-container');
  }

  // language=CSS
  static styles = css`
    :host {
      height: 100%;
      width: 100%;
    }

    .app-container {
      height: 100%;
      width: 100%;
    }
  `;

  @property({ type: String })
  baseUrl: string = '/';

  @property({ type: Object })
  route: any = {};

  @property({ type: Boolean })
  themable: boolean = false;

  render() {
    // language=html
    return html` <div class="app-container"></div> `;
  }

  firstUpdated() {
    if (this.themable) {
      _initDarkMode();
    }
    if (this.route && this._mainElement) {
      initRouter(this.baseUrl);
      // register routes in vaadin router
      setRoutes(this.route);
      attachRouter(this._mainElement);
    } else {
      console.error(
        `couldn't initialize router! baseUrl: ${this.baseUrl} route: ${this.route} mainElement: ${this._mainElement}`,
      );
    }
  }

  disconnectedCallback() {
    // @ts-expect-error
    if (super.disconnectedCallback) super.disconnectedCallback();
    unregisterRouter();
  }
}
