/* eslint-disable import/extensions */
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { i18nMixin } from '../../decorators';
import nls from '../../locales/index';
import { Mix, formatNumber, dayjs } from '../../common';
import type { WiskiExternalStation } from '../../defs';

@customElement('ki-station-info')
export default class KiStationInfo extends Mix(LitElement, [
  i18nMixin,
  { nls },
]) {
  static styles = css`
    :host {
      display: block;
    }
    .station-name {
      padding-right: 5px;
      padding-left: 10px;
      padding-top: 10px;
      font-size: 1.2em;
    }

    hr {
      margin: 5px 0;
    }
    .table {
      display: table;
      line-height: 1.5em;
    }
    .row {
      display: table-row;
    }
    .cell {
      display: table-cell;
      padding: 0 10px;
      min-width: 50px;
      color: gray;
    }
    .label {
      color: #4a4a49;
    }
  `;

  static get properties() {
    return {
      stations: { type: Array }, // can be one or more stations
    };
  }

  constructor() {
    super();
    this.stations = [];
    this.valFormatter = {
      format(val) {
        return val === 0
          ? val
          : formatNumber(val, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
      },
    };
  }

  /* eslint-disable-next-line class-methods-use-this */
  _stationExternal(station: WiskiExternalStation) {
    return html`<div class="station-name">
        ${station.station_no} | ${station.station_name} | ${station.river_name}
      </div>
      <hr />
      <div class="table">
        <div class="row">
          <div class="cell">${unsafeHTML(station.description)}</div>
        </div>
      </div>`;
  }

  _stationTmpl(station) {
    const parameter =
      station.stationparameter_longname || station.stationparameter_name;
    return html`<div class="station-name">
        ${station.station_no} | ${station.station_name} | ${station.river_name}
      </div>
      <hr />
      <div class="table">
        <div class="row">
          <div class="cell label">${this.i18n.t(parameter)}</div>
          <div class="cell">
            ${station.ts_value != null
              ? `${this.valFormatter.format(station.ts_value)} ${
                  station.ts_unitsymbol
                }`
              : '-'}
          </div>
        </div>
        <div class="row">
          <div class="cell label">${this.i18n.t('last_measure')}</div>
          <div class="cell">
            ${(station.timestamp &&
              dayjs(station.timestamp).tz().format('L LT')) ||
            '-'}
          </div>
        </div>
      </div>`;
  }

  render() {
    return html`${this.stations.map(station =>
      station.external
        ? this._stationExternal(station)
        : this._stationTmpl(station),
    )}`;
  }
}
