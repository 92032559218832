import { filter, first } from 'lodash-es';
import dayjs from '../../common/dayjsext';

export const transformForecast = (
  data: Array<[string, number]>,
): Array<[string, number]> => data.map(i => [dayjs(i[0]).valueOf(), i[1]]);

/** Check if ranges contains at least one matching element */
export const rangeObjectContains = (
  files: Object,
  filename: string,
): boolean => {
  if (files[filename] || (Object.keys(files), k => k.includes(filename)))
    return true;
  return false;
};

/** Return a matching range element
 * @remark Always the returns the first matching element
 * Clarifiy the filename as much as possible, e.g. "level/year.json" instead of "year.json" to avoid unwanted matches
 * TODO: Schön machen + Unit tests <3
 */
export const getFile = (files: Object, rangeConfig: Object): Array<Object> => {
  let result =
    files[rangeConfig.data] ||
    files[first(filter(Object.keys(files), k => k.includes(rangeConfig.data)))];
  if (!result) {
    console.warn(`${rangeConfig.data} not found`);
    return [];
  }
  /** Filter for provided setting in rangeConfig */
  if (rangeConfig.ts_shortname) {
    result = filter(result, ts =>
      rangeConfig.ts_shortname.includes(ts.ts_shortname),
    );
  }

  /** TODO: Find t0 (?) - Please comment */
  if ((result.length ?? 0) > 0 && result[0].t0) {
    result = result.map(res => ({
      ...res,
      t0Time: dayjs(res.t0).valueOf(),
    }));
    result = result.sort((a, b) => a.t0Time < b.t0Time);
  }

  /** Assert, that only arrays are returned (WW-) */
  if (Array.isArray(result)) return result;
  return [result];
};

/** Transform inputString to lower case and replace special chars with alternatives
 * @remark Should handle german, french and icelandic chars
 */
export const normStr = (inputStr: string | undefined): string | undefined => {
  // Return empty string or undefined
  if (!inputStr) return inputStr;

  const replacements: { [key: string]: string } = {
    ü: 'ue',
    ö: 'oe',
    ä: 'ae',
    ß: 'ss',
    à: 'a',
    â: 'a',
    ç: 'c',
    é: 'e',
    è: 'e',
    ê: 'e',
    ë: 'e',
    î: 'i',
    ï: 'i',
    ô: 'o',
    ù: 'u',
    û: 'u',
    ý: 'y',
    þ: 'th',
    ð: 'd',
    æ: 'ae',
    œ: 'oe',
    // Add more replacements as needed
  };
  let result = inputStr.toLowerCase();
  // Replace special characters
  result = result.replace(
    new RegExp(Object.keys(replacements).join('|'), 'g'),
    match => replacements[match],
  );

  return result;
};
