import { css } from 'lit';
// language=CSS
export default css`
  :host {
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
  }

  .content {
    margin: 15pt;
    height: auto;
  }

  :host(.sm-screen) .content,
  :host(.md-screen) .content {
    width: 100%;
    margin: 15pt 0;
    display: table;
  }

  .cellheader {
    hyphens: auto;
    text-align: center;
  }

  .cell.iconcell {
    text-align: center;
    white-space: nowrap;
  }

  .content {
    padding: 2%;
  }

  .dltable {
    display: table;
    width: 100%;
    table-layout: fixed;
    max-width: 800px;
  }
  .header {
    padding: 10px;
  }
  .row {
    display: table-row;
  }
  .cell {
    display: table-cell;
    padding: 10px 2%;
    vertical-align: middle;
    hyphens: auto;
    overflow-x: hidden;
    word-wrap: break-word;
  }
  .innerCell {
    display: inline;
    font-size: 1.5em;
  }

  .label,
  ki-icon {
    fill: gray;
    padding: 5px;
  }
  ki-icon:hover {
    cursor: pointer;
  }
  .descr {
    font-weight: 900;
    padding: 30px 10px;
  }

  .hidden {
    display: none;
  }
`;
