import { filter, some } from 'lodash-es';
import { TimeseriesSelectionItem } from './TimeseriesSelectionItem';

export class DynamicSelectionUtil {
  /**
   * Stage new timeseries without duplicates
   * @param arr1 Existing array of staged timeseries
   * @param arr2 Objects you want to add
   * @returns Combined array of staged timeseries without duplicates
   */
  public static addToStagedTimeseries(
    arr1: Array<TimeseriesSelectionItem>,
    arr2: Array<TimeseriesSelectionItem>,
  ) {
    const result = arr1;
    const _a1 = arr1.map(i => i.text);
    arr2.forEach(i => {
      if (!_a1.includes(i.text)) result.push(i);
    });
    return result;
  }

  /**
   *
   * @param input String to search for in available items
   * @param arr Input array with items to check
   * @param attributes Properties of the item which should be checked
   * @returns All items with at least one match
   */
  public static match(
    input: String,
    arr: Array<TimeseriesSelectionItem>,
    attributes: Array<string> = ['text'],
  ): Array<TimeseriesSelectionItem> {
    const res = filter(arr, i =>
      some(attributes.map(attr => i[attr].toLowerCase().includes(input))),
    );
    return res;
  }

  /**
   *
   * @param input String to search for in available items
   * @param arr Input array with items to check
   * @param attributes Properties of the item which should be checked
   * @returns All items with at least one exact match with input
   */
  public static matchExact(
    input: String,
    arr: Array<TimeseriesSelectionItem>,
    attributes: Array<string> = ['text'],
  ): Array<TimeseriesSelectionItem> {
    const res = filter(arr, i =>
      some(attributes.map(attr => i[attr].toLowerCase() === input)),
    );
    return res;
  }

  /**
   * Resolve Wilcard in tsName
   * @param str contains exactly one '*' as a wildcard
   * @param tsList available timeseries of the station
   * @returns timeseries matching the wildcard pattern
   */
  public static resolveWildcard(
    str: string,
    tsList: Array<TimeseriesSelectionItem>,
  ): Array<TimeseriesSelectionItem> {
    const result: Array<TimeseriesSelectionItem> = [];
    throw Error(`Not implemented${str} ${tsList} ${result}`);
  }

  /**
   * @returns UI5 Value State string
   * @remarks See "additional-text-value" or "Value State": https://sap.github.io/ui5-webcomponents/playground/components/List/ or https://sap.github.io/ui5-webcomponents/playground/components/Input/
   * Used to colorize AdditionalTextValue
   */
  public static getValueState(
    item: TimeseriesSelectionItem,
  ): 'Information' | 'Success' | 'Warning' {
    if (item.isThreshold) return 'Warning';
    // if (item.isDistinct) return 'Success'; //TODO: Meta data injection
    return ''; // Information
  }

  /**
   * @returns UI5 Icon name
   * @remarks Icon Explorer https://sapui5.hana.ondemand.com/sdk/test-resources/sap/m/demokit/iconExplorer/webapp/index.html
   */
  public static getSuggestionItemIcon(item: TimeseriesSelectionItem): string {
    if (item.isThreshold) return 'message-warning';
    if (item.isDistinct) return 'line-chart-time-axis';
    return 'multiple-line-chart';
  }

  /**
   * @param input Usually ts_path
   * @returns True if timeseries is threshold/warning level
   */
  public static getIsThreshold(
    input: string,
    patternInTsPath: string,
  ): boolean {
    return input.includes(patternInTsPath);
  }

  /**
   * @param input Usually ts_path
   * @returns True if timeseries is not a group (contains no * in string)
   */
  public static getIsDistinct(input: string): boolean {
    return !input.includes('*');
  }

  /** Sample Data for Debugging/Testing
   * @returns Mocked SuggestionItems
   */
  public static getTestDataSuggestionItems(): Array<TimeseriesSelectionItem> {
    return [
      {
        text: 'Day.Min.B.Inter.W',
        additionalText: 'Wasserstand [S]',
        description: 'Wasserstand.TagMin',
        isDistinct: true,
        isThreshold: false,
        ts_path: '100/39899002/S/Day.Min.B.Inter.W',
      },
      {
        text: 'Day.Max.B.Inter.W',
        additionalText: 'Wasserstand [S]',
        description: 'Wasserstand.TagMax',
        isDistinct: true,
        isThreshold: false,
        ts_path: '100/39899002/S/Day.Max.B.Inter.W',
      },
      {
        text: 'Day.Mean.B.Inter.W',
        additionalText: 'Wasserstand [S]',
        description: 'Wasserstand.TagMean',
        isDistinct: true,
        isThreshold: false,
        ts_path: '100/39899002/S/Day.Mean.B.Inter.W',
      },
      {
        text: 'Day.*',
        additionalText: 'Wasserstand [S]',
        description: 'Mehrere Zeitreihen',
        isDistinct: false,
        isThreshold: false,
      },
      {
        text: '*.W.*',
        additionalText: 'Wasserstand [S]',
        description: 'Mehrere Schwellwerte',
        isDistinct: false,
        isThreshold: true,
      },
    ] as Array<TimeseriesSelectionItem>;
  }
}
