/* eslint-disable wc/no-self-class */
import { html, css, LitElement } from 'lit';

import { customElement } from 'lit/decorators.js';
import '../ki-icon/ki-icon';
import PropertyDefaultValue from '../../common/PropertyDefaultValue';
import Mix from '../../common/Mix';

// @ts-expect-error
@customElement('ki-nav')
export default class KiNav extends Mix(LitElement, PropertyDefaultValue) {
  // language=css
  static styles = css`
    :host(.vertical) {
      display: flex;
      flex-direction: column;
      padding: 5px 0px;
      height: 100%;
    }

    :host(.horizontal) ki-list {
      display: flex;
      color: var(--theme-text-color);
      fill: var(--theme-text-color);
    }
    :host(.horizontal) ki-list > ki-list-item,
    :host(.horizontal) ki-list > ki-list-item-group {
      display: inline-block;
    }
    :host(.horizontal) ki-list > ki-list-item-group .children {
      position: absolute;
      top: 100%;
      right: 0;
      background: white;
      color: var(--theme-color);
      fill: var(--theme-color);
      padding-top: 5px;
      box-shadow:
        0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2);
    }

    :host(.horizontal) .nav-item {
      padding: 8px;
    }

    ki-list-item-group .children.folded {
      display: none;
    }

    .nav-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      padding: 4px;
    }
    .nav-icon {
      min-width: 30px;
      text-align: center;
    }

    .nav-label {
      padding: 0px 15px 0px 10px;
      flex: 1;
    }
  `;

  static get properties() {
    return {
      direction: { type: String, default: 'vertical' }, // vertical, horizontal
      items: { type: Array, initial: () => [] },
      value: { type: String },
      selectedItem: { type: Object },
    };
  }

  // TODO move to global object
  // eslint-disable-next-line class-methods-use-this
  get _selectedView() {
    return `#${window.location.hash.slice(1).split('?')[0] || ''}`;
  }

  // eslint-disable-next-line class-methods-use-this
  set _selectedView(link) {
    window.location.hash = link;
  }

  renderItem(item) {
    if (item.items?.length > 0) {
      // language=html
      return html` <ki-list-item-group>
        <div
          class="label"
          @click="${() => {
            item.folded = !item.folded;
            this.requestUpdate();
          }}"
        >
          <div class="nav-item">
            <div class="nav-icon"><ki-icon icon="${item.icon}"></ki-icon></div>
            <div class="nav-label">${item.label}</div>
            <div class="expand-icon">
              <ki-icon
                icon="ki ${item.folded ? 'ki-chevron-up' : 'ki-chevron-down'}"
              ></ki-icon>
            </div>
          </div>
        </div>
        <div class="children ${item.folded ? 'folded' : ''}">
          ${item.items.map(t => this.renderItem(t))}
        </div>
      </ki-list-item-group>`;
    }
    // language=html
    return html`<ki-list-item
      .selected="${item.link === this._selectedView}"
      @click="${() => this.handleClick(item)}"
    >
      <div class="nav-item">
        <div class="nav-icon"><ki-icon icon="${item.icon}"></ki-icon></div>
        <div class="nav-label">${item.label}</div>
      </div>
    </ki-list-item>`;
  }

  render() {
    this.classList.toggle('vertical', this.direction === 'vertical');
    this.classList.toggle('horizontal', this.direction === 'horizontal');
    // language=html
    return html`<ki-list>
      ${this.items.map(item => this.renderItem(item))}
    </ki-list>`;
  }

  handleClick(item) {
    if (this.selectedItem === item) {
      return;
    }
    this.dispatchEvent(
      new CustomEvent('selected', {
        detail: {
          item,
        },
      }),
    );
    this._selectedView = item.link;
  }
}
