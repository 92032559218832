/* eslint-disable no-console */
import { groupBy } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import parquetjs from '@dsnp/parquetjs/dist/browser/parquet.esm';
import { JsonAPI } from './JsonAPI';

export class ParquetAPI extends JsonAPI {
  _fullData: Promise<any[]>;

  constructor(args) {
    super(args);
    // preload data
    this._fullData = this._requestFullData();
  }

  // eslint-disable-next-line default-param-last
  async _requestJson(path, base = this.dataPath, nocache?) {
    console.debug('request', path, base, nocache);
    if (path.endsWith('.parquet')) {
      const records = await this._loadData(`${base}${path}`);
      return records;
    }
    // /data/internet/layers/index.json
    // /data/internet/stations/stations.json
    if (path === '/data/internet/stations/stations.json') {
      return this._fullData;
    }
    // /data/internet/layers/ts_cl/index.json
    const layerMatch = /\/data\/internet\/layers\/([^\\/]*)\/index.json/.exec(
      path,
    );
    if (layerMatch) {
      console.debug('layerMatch', layerMatch);
      const layerName = layerMatch[1];
      return this._loadData(`/data/internet/layers/${layerName}/index.parquet`);
    }

    // station index
    // /data/internet/stations/16/Shelburne72GREENWOOD/index.json
    const stationMatch =
      /\/data\/internet\/stations\/([^\\/]*)\/([^\\/]*)\/index.json/.exec(path);
    if (stationMatch) {
      console.debug('stationMatch', stationMatch);
      const stationNo = stationMatch[2];
      const records = await this._fullData;
      const stationData = records.filter(
        (r: any) => r.station_no === stationNo,
      );
      const station = stationData[0];
      const parameters = groupBy(stationData, 'stationparameter_name');
      const links = Object.keys(parameters).map(p => ({
        type: 'resource',
        station_parameter: p,
        mime_type: 'application/json',
        href: `internet/stations/${station.site_no}/${station.station_no}/${parameters[p]?.[0]?.stationparameter_shortname}/wq.json`,
        purpose: ['station'],
      }));
      if (station) {
        return {
          ...station,
          _links: links,
        };
      }
    }

    // Ts Data
    // /data/internet/stations/16/Shelburne72GREENWOOD/ManganeseMn/wq.json
    const tsMatch =
      /\/data\/internet\/stations\/([^\\/]*)\/([^\\/]*)\/([^\\/]*)\/wq.json/.exec(
        path,
      );
    if (tsMatch) {
      const siteId = tsMatch[1];
      const stationNo = tsMatch[2];
      const paramName = tsMatch[3];
      let records = await this._fullData;
      records = records.filter(
        (r: any) =>
          r.station_no === stationNo &&
          r.stationparameter_shortname === paramName &&
          r.site_no === siteId,
      );
      if (records[0]) {
        // @ts-ignore
        const data = { ...records[0] };
        data.data = records.map((r: any) => [
          r.ts_timestamp,
          parseFloat(r.ts_value),
        ]);
        return data;
      }
    }

    return super._requestJson(path, base, nocache);
  }

  // eslint-disable-next-line class-methods-use-this
  async _loadData(file) {
    // TODO performance
    // eslint-disable-next-line no-undef
    // @ts-ignore
    const reader = await parquetjs.ParquetReader.openUrl(file);
    const cursor = reader.getCursor();

    const records = [];
    let record;
    // eslint-disable-next-line no-await-in-loop,no-cond-assign
    while ((record = await cursor.next())) {
      // @ts-ignore
      records.push(record);
    }
    await reader.close();
    return records;
  }

  // eslint-disable-next-line class-methods-use-this
  async _requestFullData() {
    return this._loadData('/data/internet/index.parquet');
  }
}
