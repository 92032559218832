/* eslint-disable default-param-last */
/* eslint-disable class-methods-use-this */
import { has, get, find, filter, merge, keyBy, groupBy } from 'lodash-es';
import { dayjs, Layer, TsDataResponse } from '../common';
import {
  getLastTimeseriesData,
  getLayerMetadata,
  getLayerStations,
  getParameterMetadata,
  getStationMetadata,
  getStationMetadataById,
  getTimeseriesMetadata,
  StationWithSiteMetadata,
} from './ZarrAPIHelper';
import { AllParameterMetadata } from './Zarr';
import { Dictionary } from '../defs/common';
// api for file generator

export interface TsValue {
  interpolation_type: number;
  is_forecast: boolean;
  timestamp: string;
  ts_decimals: number;
  ts_name: string;
  ts_resolution: string;
  ts_shortname: string;
  ts_value: number;
  param_longname: string | null;
  param_name: string;
  param_shortname: string;
  parameter_key: string | null;
  parameter_type: string | null;
  units: string;
}

export interface TsValues {
  [key: string]: TsValue;
}

export interface LayerIndexData {
  mainParameter: string;
  site_no: string;
  site_name: string;
  station_id: string;
  station_no: string | number;
  station_name: string;
  station_latitude: number;
  station_longitude: number;
  owner: string;
  river: string;
  basin: string;
  subbasin: string;
  state: string;
  district: string;
  subsystem: number;
  fcst_file_prefix: string;
  division: string;
  type: string;
  wl: number;
  dl: number;
  hfl_value: number;
  hfl_date: string;
  ts_values: TsValues;
  parameter: AllParameterMetadata;
}

const defaultLayer = {
  tablePopup: true,
  popupControl: true,
  popup: 'ww-map-popup-dynamic',
  filter: [],
  classification: {
    default: {
      marker: {
        backgroundColor: 'rgb(7, 97, 199)',
        shape: 'circle',
        color: 'rgba(255,255,255, 1)',
        icon: '',
        fontSize: 0.8,
        radius: 8,
        border: {
          color: 'rgba(29,29,27, 1)',
          width: 1,
        },
      },
    },
    tags: [
      {
        name: 'wert',
        label: 'aktuelle Werte',
        filter: {
          timestamp: {
            duration: 'PT48H',
          },
        },
        marker: {
          backgroundColor: 'rgb(7, 97, 199)',
          shape: 'circle',
        },
      },
      {
        name: 'keinwert',
        priority: 1,
        label: 'keine aktuellen Werte',
        filter: {
          timestamp: {
            not: {
              duration: 'PT48H',
            },
          },
        },
        marker: {
          backgroundColor: 'rgba(255, 255, 255, 1)',
        },
      },
    ],
  },
  sortedBy: [{ field: 'station_longname', ascending: true }],
  columns: [
    {
      css: 'width:95px;',
      field: '__tag',
      label: 'Status',
      format: 'status',
      sortable: true,
    },
    {
      field: 'station_name',
      label: 'Station',
      sortable: true,
    },

    {
      field: 'timestamp',
      label: 'Zeitpunkt [MEZ]',
      format: 'dateTime',
      sortable: true,
      hideAble: true,
    },
    {
      field: 'ts_value',
      label: 'Wert',
      sortable: true,
      hideAble: true,
      format: {
        type: 'number',
        options: {
          minimumFractionDigits: 2,
        },
      },
    },
  ],
};

export class ZarrAPI {
  reportsPath: string;

  basePath: string;

  configSubPath: string;

  configPath: string;

  staticConfigPath: string;

  dataPath: string;

  reportList: any;

  _cache: any;

  _requestcache: any;

  imagesList: any;

  constructor({
    basePath,
    dataPath,
    configSubPath,
    reportsPath,
    staticConfigPath,
  }) {
    this.reportsPath = reportsPath || null;
    this.basePath = basePath || '.';
    this.configSubPath = configSubPath || 'config';
    this.staticConfigPath = staticConfigPath || 'static_config';
    this.configPath = `${this.basePath}/${this.configSubPath}`;
    this.dataPath = dataPath || './data';
    this._cache = new Map();
    this._requestcache = new Map();
    this.reportList = null;
    this.imagesList = null;
  }

  async getClassification(name) {
    const classes = await this.getClassifications();
    return find(classes, { name });
  }

  async getClassifications() {
    return this._requestJson('/internet/raster/classifications.json');
  }

  async getMapConfig() {
    return this._requestJson('/map.json', this.configPath);
  }

  async getSites() {
    return this._requestJson('/stations/sites.json');
  }

  async getStations(layerName: string) {
    const config = await this._requestJson('/layercfg.json', this.configPath);

    const layerMetadata = await getLayerMetadata();
    if (layerMetadata && config) {
      const foundLayerConfig = config.find(conf => conf.alias === layerName);

      if (!foundLayerConfig) {
        return [];
      }

      const isArray = Array.isArray(foundLayerConfig.id);

      const foundLayers = layerMetadata.layers.filter(layer =>
        isArray
          ? foundLayerConfig.id.includes(layer.alias)
          : layer.alias === foundLayerConfig.id,
      );
      if (foundLayers) {
        const temp = await Promise.all(
          foundLayers.map(async foundLayer =>
            Promise.all(
              (await getLayerStations(foundLayer.id)).map(async stationId =>
                getStationMetadata(stationId),
              ),
            ),
          ),
        );

        return temp.length > 0
          ? temp.reduce((prev, current) => [...prev, ...current])
          : temp;
      }
    }
    return [];
  }

  async getRessource(ahref, base) {
    return this._requestJson(ahref, base);
  }

  async getStationByNo(stationNo) {
    return getStationMetadata(stationNo);
  }

  async getStation(
    stationId: string,
  ): Promise<StationWithSiteMetadata | undefined> {
    return getStationMetadataById(stationId);
  }

  getLink(ahref: string): string {
    return this.dataPath + ahref;
  }

  async getLayers(): Promise<Dictionary<Layer>> {
    const layerMetadata = await getLayerMetadata();

    const config = await this._requestJson('/layercfg.json', this.configPath);

    if (!config || !layerMetadata) {
      throw Error('error loading config or data');
    }

    layerMetadata.layers.push({
      alias: 'stations',
      id: 'stations',
      layer_type: 'STATION_LAYER',
      arrayNames: [],
    });

    const layers = layerMetadata.layers.map(layer => ({
      ...layer,
      creationDateInMillis: layerMetadata.creationDateInMillis,
    }));

    return merge(keyBy(layers, 'alias'), keyBy(config, 'alias'));
  }

  async getLayer(alias): Promise<TsDataResponse> {
    const l = filter(await this.getLayers(), { alias })[0];
    if (!l.config) {
      l.config = { ...defaultLayer };
      l.config.id = l.id;
      l.config.alias = l.alias;
      l.config.classification.label = l.alias;
      l.config.columns = l.config.columns.map(item => {
        if (item.field === 'ts_value') {
          item.label = `${l.label}`;
        }
        return item;
      });
    }

    if (l?.id) {
      let data = await this.getLayerData(l);

      if (l.config.parameterFilterAttribute) {
        try {
          data.forEach(station => {
            if (station[l.config.parameterFilterAttribute]) {
              const parFilter = JSON.parse(
                station[l.config.parameterFilterAttribute],
              );
              if (Array.isArray(parFilter) && parFilter.length !== 0) {
                Object.keys(station.ts_values).forEach(par => {
                  if (!parFilter.includes(par)) {
                    delete station.ts_values[par];
                  }
                });
              }
            }
          });
          data = data.filter(item => Object.keys(item.ts_values).length);
        } catch (e) {
          console.error('invalid parameterFilterAttribute');
        }
      }

      if (l.config.stationsFilter) {
        data = data.filter(station =>
          Object.keys(l.config.stationsFilter).every(key => {
            const condition = l.config.stationsFilter[key];
            if (condition === '*') {
              return has(station, key);
            }
            if (condition.startsWith('!')) {
              return (
                get(station, key) !== l.config.stationsFilter[key].substr(1)
              );
            }
            return get(station, key) === l.config.stationsFilter[key];
          }),
        );
      }
      return {
        data,
        config: l.config,
        creationDateInMillis: l.creationDateInMillis,
      } as TsDataResponse;
    }

    throw new Error(`layer ${alias} does not exist`);
  }

  async getLayerData(l: Layer): Promise<LayerIndexData[]> {
    const ids = typeof l.id === 'string' ? [l.id] : l.id;
    const paramMetadata = new Map<
      string,
      | {
          param_name: string;
          param_shortname: string;
          units: string;
        }
      | undefined
    >();
    let stationNos: string[] = [];

    await Promise.all(
      ids.map(async id => {
        const parameterMetadata = await getParameterMetadata(id);
        const stationNumbers = await getLayerStations(id);
        paramMetadata.set(id, parameterMetadata);
        stationNos = stationNos.concat(stationNumbers);
      }),
    );

    stationNos = stationNos.filter(
      (entry, index) => stationNos.indexOf(entry) === index,
    );

    return Promise.all(
      (
        await Promise.all(
          stationNos.map(async stationId => {
            const metaData = await getStationMetadata(stationId);
            return { stationId, metaData };
          }),
        )
      )
        .filter(dat => dat.metaData)
        .map(async ({ stationId, metaData }) => {
          const siteNo = metaData!.site_no;
          const ts_values: TsValues = {};

          await Promise.all(
            ids.map(async id => {
              const parameterMetadata = paramMetadata.get(id);
              const allTimeseries = metaData?.parameter[id]?.timeseries;

              if (siteNo && allTimeseries) {
                const timeseriesList = Object.keys(allTimeseries);
                if (timeseriesList.length > 0) {
                  if (timeseriesList.length > 1) {
                    console.warn('TODO: multiple ts', metaData);
                  }
                  const ts = timeseriesList[0];
                  const timeseriesData =
                    (
                      await getLastTimeseriesData(siteNo, stationId, id, ts)
                    )?.data.filter(dat => dat.value !== undefined) ?? [];
                  const timeseriesMetadata = await getTimeseriesMetadata(ts);

                  const tsValueObject: any = {
                    ...timeseriesMetadata,
                    ...parameterMetadata,
                  };

                  if (timeseriesData.length > 0) {
                    const lastItem = timeseriesData[timeseriesData.length - 1];

                    tsValueObject.timestamp = dayjs(lastItem.timestamp).format(
                      'YYYY-MM-DDTHH:mm:ssZ',
                    );
                    tsValueObject.ts_value = lastItem.value;
                  }

                  ts_values[id] = tsValueObject;
                }
              }
            }),
          );

          return {
            ...metaData!,
            mainParameter: l.id,
            ts_values,
          };
        }),
    );
  }

  async getImages() {
    if (this.imagesList) {
      return this.imagesList;
    }

    const list = await this._requestJson('/images/index.json');
    this.imagesList =
      list && list.all_listing
        ? list.all_listing.filter(st => st.mimeType.includes('image/'))
        : [];
    return this.imagesList;
  }

  async getReport(stationNo) {
    const list = await this.getReports();
    return list[stationNo];
  }

  async getReports() {
    if (!this.reportsPath) {
      return [];
    }
    if (this.reportList) {
      return this.reportList;
    }
    const list = await this._requestJson(`/${this.reportsPath}/index.json`);
    this.reportList =
      list && list.all_listing
        ? groupBy(
            list.all_listing.map(st => {
              const [, stationNo, reportGroup, fileName] = st.name.split('/');
              return {
                path: st.name,
                stationNo,
                reportGroup,
                fileName,
                mimeType: st.mimeType,
              };
            }),
            'stationNo',
          )
        : {};
    return this.reportList;
  }

  async getImage(stationNo) {
    const list = await this.getImages();
    const images = list.filter(st => st.name.includes(`/${stationNo}/`));
    if (images.length) {
      return images.map(img => `${this.dataPath}/${img.name}`);
    }
    return [];
  }

  async getReportInfo() {
    // TODO need better file structure.
    return this._requestJson(`/documents/index.json`, undefined, true); // todo
  }

  async requestText(path, base = this.dataPath) {
    return fetch(`${base}${path}`).then(x => x.text());
  }

  /* eslint-disable class-methods-use-this */
  /* eslint-disable no-unused-vars */

  /*
   * cache all request;
   * */
  // eslint-disable-next-line default-param-last
  async _requestJson(path, base = this.dataPath, nocache?) {
    let data;
    if (!nocache && this._requestcache.get(path)) {
      const cachedrequest = await this._requestcache.get(path);
      const cachedrequestdata = await cachedrequest.clone().json();
      return cachedrequestdata;
    }

    if (!nocache && this._cache.get(path)) {
      return this._cache.get(path);
    }
    try {
      const doFetch = fetch(`${base}${path}`);
      this._requestcache.set(path, doFetch);
      const resp = await doFetch;
      data = await resp.clone().json();
      this._cache.set(path, data);
      this._requestcache.delete(path);
    } catch (err) {
      // catches errors both in fetch and response.json
      throw path;
    }

    return data;
  }
}
