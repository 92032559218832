import { keys, forIn } from 'lodash-es';

// const station = {
//      "station_no": ["nlsLabel", "1430"],
//     ...
//      "data": [["2021-03-16T00:00:00.000+01:00", 185], ["2021-03-16T00:15:00.000+01:00", 184]]
//  };

/**
 * Download station data as excel
 *
 * @param {object} station - The station data to be download
 * @param {object} [options] - Options to customize the excel content
 *          {
 *              name: "excelfilename",
 *              sheetName: "sheetname",
 *              meta: ['station_name', 'station_no', 'parametertype_name', 'ts_unitsymbol',...]
 *          }
 */
export async function downloadExcel(station = {}, options = {}) {
  const XLSX = await import('xlsx');
  const wb = XLSX.utils.book_new();
  if (station) {
    const ws = XLSX.utils.aoa_to_sheet(station.metadata);
    // Add ts data
    XLSX.utils.sheet_add_aoa(ws, station.data, { origin: -1 });
    ws['!cols'] = [{ wch: 30 }];
    XLSX.utils.book_append_sheet(wb, ws, options.sheetName || 'Sheet1');
  }
  XLSX.writeFile(wb, `${options.name || 'download'}.xlsx`);
}

/**
 * Download station data as csv
 * @param {object} station - The station data to be download
 * @param {object} [options] - Options to customize the excel content
 *          {
 *              name: "excelfilename",
 *              sheetName: "sheetname",
 *              meta: ['station_name', 'station_no', 'parametertype_name', 'ts_unitsymbol',...]
 *          }
 */
export async function downloadCSV(station = {}, options = {}) {
  const XLSX = await import('xlsx');
  const wb = XLSX.utils.book_new();
  if (station) {
    const ws = XLSX.utils.aoa_to_sheet(station.metadata);
    // Add ts data
    XLSX.utils.sheet_add_aoa(ws, station.data, { origin: -1 });
    XLSX.utils.book_append_sheet(wb, ws, options.sheetName || 'Sheet1');
  }
  XLSX.writeFile(wb, `${options.name || 'download'}.csv`, {
    bookType: 'csv',
    FS: ',',
  });
}

/**
 * Get data uri from image url
 * @param url
 */
function getDataUri(url) {
  return new Promise(resolve => {
    const image = new Image();
    image.setAttribute('crossOrigin', 'anonymous'); // getting images from external domain

    image.onload = async function () {
      console.debug('Image loaded');
      const canvas = document.createElement('canvas');
      canvas.width = this.naturalWidth;
      canvas.height = this.naturalHeight;

      // next three lines for white background in case png has a transparent background
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = '#fff'; /// set white fill style
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      canvas.getContext('2d').drawImage(this, 0, 0);

      resolve(canvas.toDataURL('image/jpeg'));
    };

    image.onerror = function () {
      console.error(`Cannot load image:${url}`);
    };

    image.src = url;
  });
}

function getLongestLabel(data = {}) {
  let longestLabel = '';
  forIn(data, value => {
    const key = keys(value)[0];
    if (key.length > longestLabel.length) {
      longestLabel = key;
    }
  });
  return longestLabel;
}

/**
 * Download Station info and images to pdf.
 *
 * @param content= {stationInfo:[[{"stationno":"aaa"},{"stationname":"bbb"}...],[{"stationno":"aaa"},{"stationname":"bbb"}...]],images:["url1","url2"]}
 * @param options = {name: "pdfname"}
 */

export async function downloadPdf(
  content = { stationInfo: [], images: [] },
  options = {},
) {
  const { jsPDF } = await import('jspdf');
  // eslint-disable-next-line new-cap
  const doc = new jsPDF({
    orientation: 'p',
    unit: 'mm',
    format: 'a4',
    hotfixes: ['px_scaling'],
  });
  const pageHeight = doc.internal.pageSize.height;
  const paddingLeft = 10;
  let paddingTop = 10; // page content height
  const GAP = 10;
  const imageSize = 150;
  for (const key of keys(content)) {
    const value = content[key];
    if (!value) continue;
    if (key === 'images') {
      for (let index = 0; index < value.length; index += 1) {
        if (paddingTop + imageSize > pageHeight) {
          doc.addPage();
          paddingTop = GAP;
        }
        // eslint-disable-next-line no-await-in-loop
        const dataUri = await getDataUri(value[index]);
        // @ts-ignore
        doc.addImage(
          dataUri,
          'JPEG',
          paddingLeft,
          paddingTop,
          imageSize,
          imageSize,
        ); // add image with 150*150
        paddingTop += imageSize;
      }
    } else if (key === 'stationInfo') {
      for (let i = 0; i < value.length; i += 1) {
        const tableData = value[i];
        const text = getLongestLabel(tableData);
        const labelDim = doc.getTextDimensions(text);
        const valuePaddingLeft = Math.ceil(labelDim.w) + paddingLeft + GAP;
        // eslint-disable-next-line guard-for-in
        for (const k of keys(tableData)) {
          const item = tableData[k];
          if (paddingTop > pageHeight) {
            doc.addPage();
            paddingTop = GAP;
          }
          const kk = keys(item)[0];
          doc.setTextColor(128, 128, 128);
          doc.text(kk, paddingLeft, paddingTop, {}, {});
          doc.setTextColor(29, 29, 28);
          doc.text(item[kk], valuePaddingLeft, paddingTop, {}, {});
          paddingTop += GAP;
        }
        paddingTop += GAP; // add distance if multiple tables
      }
    }
  }

  doc.save(`${options?.name || 'download'}.pdf`);
}
