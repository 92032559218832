/* eslint-disable max-classes-per-file */
import { html, LitElement, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { concat, orderBy } from 'lodash-es';
import { Mix, PropertyDefaultValue } from '../../common';
import { responsiveMixin, SM, ViewPort, i18nMixin } from '../../decorators';
// import './components'; // TODO: Why?
import nls from '../../locales/index';
import '@ui5/webcomponents/dist/Popover';
import '@ui5/webcomponents/dist/List';
import '@ui5/webcomponents/dist/StandardListItem';

@customElement('ki-ts-list-select')
export default class KiTsListSelect extends Mix(
  LitElement,
  PropertyDefaultValue,
  responsiveMixin,
  [i18nMixin, { nls }],
) {
  static styles = css`
    :host {
      position: relative;
    }

    ui5-list {
      width: 350px;
    }

    ki-icon-btn {
      margin: 5px;
      box-shadow: none;
    }

    ki-icon-btn:hover {
      fill: white;
      background: var(--theme-color-secondary, #0056a0);
    }

    .hidden {
      display: none;
    }
    .no-ts {
      padding: 8px;
      width: 400px;
      text-align: center;
    }

    ki-searchbox {
      box-shadow: unset;
    }
  `;

  _open;

  @property({ type: Array })
  tsList = [];

  @property({ type: String })
  mainTs = '';

  @property({ type: Array })
  presetTs = [];

  @property({ type: Array })
  filteredOut = [];

  @property({ type: Array })
  selectedTs = [];

  render() {
    return html`
      <ki-icon-btn
        id="ts-list-selector-btn"
        tabindex="0"
        role="button"
        title=${this.i18n.t('timeseriesSelector')}
        icon="ki ki-chart-area"
        @click="${this._openTsList}"
      >
      </ki-icon-btn>
      ${ViewPort.size === SM ? this._renderMobile() : this._renderMenu()}
    `;
  }

  _renderMenu() {
    return html`
      <ui5-popover
        placement-type="Bottom"
        id="ts-list"
        header-text="${this.i18n.t('timeseriesList')}"
      >
        <ui5-list
          id="myList"
          class="full-width"
          mode="MultiSelect"
          @selection-change="${this._updateTs}"
        >
          ${this.tsList?.length > 0
            ? this._renderList()
            : html`<div class="no-ts">${this.i18n.t('noTimeseriesList')}</div>`}
        </ui5-list>
      </ui5-popover>
    `;
  }

  _renderMobile() {
    return html` <ki-modal
      closeable
      id="ts-list-modal"
      label=${this.i18n.t('timeseriesList')}
    >
      <ui5-list id="myList" class="full-width" mode="MultiSelect">
        ${this.tsList?.length > 0
          ? this._renderList()
          : html`<div class="no-ts">${this.i18n.t('noTimeseriesList')}</div>`}
      </ui5-list>
    </ki-modal>`;
  }

  _renderList() {
    return html` <ki-searchbox
        id="searchbox"
        class="ts-list__searchbox"
        @search="${this._handleSearch}"
      ></ki-searchbox>
      ${orderBy(this.tsList, ['ts_name'], ['asc']).map(
        item =>
          html`<ui5-li
            class="${this.filteredOut?.includes(item.ts_path) ? 'hidden' : ''}"
            title="${item.ts_path}"
            ?selected=${this.selectedTs?.includes(item.ts_path)}
            description="${item.ts_shortname}"
            graphic="icon"
            >${item.ts_name}</ui5-li
          >`,
      )}`;
  }

  firstUpdated() {
    /** Select mainTs and presets if defined */
    this.selectedTs = this.presetTs?.length
      ? concat(
          [this.mainTs],
          this.presetTs.map(ts => ts.ts_path),
        )
      : [this.mainTs];

    // use to render default ts in graph
    this.dispatchEvent(
      new CustomEvent('change', {
        detail: { value: this.selectedTs },
      }),
    );
  }

  _handleSearch(evt) {
    const searchExp = evt.detail.value;
    if (!searchExp) {
      this.filteredOut = [];
    } else {
      const regx = new RegExp(searchExp, 'i');
      this.filteredOut = this.tsList
        .filter(ts => !regx.test(ts.ts_shortname) && !regx.test(ts.ts_name))
        .map(item => item.ts_path);
    }
  }

  _openTsList() {
    if (ViewPort.size === SM) {
      this.renderRoot.querySelector('#ts-list-modal')?.show();
    } else {
      if (this._open) this.renderRoot.querySelector('#ts-list').close();
      else
        this.renderRoot
          .querySelector('#ts-list')
          .showAt(this.renderRoot.querySelector('#ts-list-selector-btn'));

      this._open = !this._open;
    }
  }

  _updateTs(evt) {
    this.selectedTs = [
      ...new Set(evt.detail.selectedItems.map(ts => ts.title)),
    ];
    this.dispatchEvent(
      new CustomEvent('change', {
        detail: { value: this.selectedTs },
      }),
    );
  }
}
