import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { until } from 'lit/directives/until.js';
import { getCurrentApi } from '../../api';

@customElement('ww-raster-legend')
export class WwRasterLegend extends LitElement {
  static styles = css`
    :host {
      padding: 10px;
    }
    .legend {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .legend-item {
      flex: 1;
      color: white;
      padding: 0 5px;
      text-align: center;
    }
  `;

  api = getCurrentApi();

  @property() classification;

  @property() classiObj = { color: [] };

  async renderClass() {
    if (!this.classification) {
      return html``;
    }
    const classiObj = await this.api.getClassification(this.classification);
    if (classiObj) {
      return html`<div class="desc">${classiObj.description}</div>
        <div class="legend">
          ${classiObj.color.map(
            item =>
              html`<div
                class="legend-item"
                style="background-color:#${this.getRgbString(item.rgb)}"
                title="${item.description}"
              >
                ${item.description}
              </div>`,
          )}
        </div>`;
    }
    return html``;
  }

  // eslint-disable-next-line class-methods-use-this
  getRgbString(rgbNumber: number) {
    let rgbString = rgbNumber.toString(16);
    while (rgbString.length < 6) {
      rgbString = `0${rgbString}`;
    }
    return rgbString;
  }

  render() {
    return html` ${until(this.renderClass(), html`<span>...</span>`)} `;
  }
}
