/* eslint-disable no-bitwise */
import tinycolor from 'tinycolor2';
import { merge } from 'lodash-es';
import { Series, TsData, Meta } from '../../../types';
import { ConfigLoader } from '../../../services';

import dayjs from '../../../common/dayjsext';

export class TsGraphHelper {
  /** Dayjs format */

  public static formatTimestamp(
    unix: number,
    dateformat: string = 'DD.MM.YYYY',
  ): string {
    return dayjs(unix).format(dateformat);
  }

  // eslint-disable-next-line class-methods-use-this
  public static getAxisPosition(index: number): 'left' | 'right' {
    return index % 2 ? 'right' : 'left';
  }

  /** Multiple y-Axis label Offset to not collide with yAxis
   * @param index y-Axis index, to gradually increase offset
   * @returns Label offset in px
   */
  public static calculateOffset(index: number): number {
    if (index % 2 === 1) {
      // If index is odd
      return (index - 1) * 40;
    }
    // If index is even
    return index * 40;
  }

  /**
   * Filter null values to avoid ghosting points in graph
   * @param data Array with TsData
   * @remark Data array in series is not affected and can be explored e.g. in ts-value-table
   * @returns Filtered data array
   */
  public static removeNullValues(data: TsData[]): TsData[] {
    return data.filter(d => d[1] !== null);
  }

  /**
   * Add Style, Label, Type info to meta data
   * @returns Modified tsList with added metaInformation
   * */
  public static applyMetaData(
    tsList: Array<Series>,
    configLoader: ConfigLoader,
  ): Array<Series> {
    for (let i = 0; i < tsList.length; i += 1) {
      const s = tsList[i];
      const meta = configLoader.getTsTemplates(tsList[i].shortName);
      this.injectColorValues(s, configLoader);
      if (meta) {
        s.meta = merge(s.meta, meta);
      }
      if (!s.meta.type) s.meta = this.guessSeriesType(s);
    }
    return tsList;
  }

  /** COnvert any TimeseriesName to a numeric values, which will later get used to define a determnistic shade of a basecolor */
  public static djb2Hash(str: string): number {
    let hash = 5381;
    for (let i = 0; i < str.length; i += 1) {
      const char = str.charCodeAt(i);
      hash = (hash * 33) ^ char;
    }
    return hash >>> 0; // Use bitwise operation to get an unsigned integer
  }

  // Function to reduce a hash to a numeric value between 0 and 100
  public static reduceHashTo0To100(hash: number): number {
    return (hash % 81) - 40; // Get remainder when divided by 101, resulting in a value between 0 and 100
  }

  /**
   * Add Style, Label, Type info to meta data
   * */
  public static injectColorValues(
    series: Series,
    configLoader: ConfigLoader,
  ): Series {
    const tsTemplate = configLoader.getTsTemplates(series.shortName);
    const baseColor = configLoader.getParameterBaseColor(
      series.parameter.shortName,
    );
    if (!tsTemplate || !tsTemplate.style || !tsTemplate.style.color) {
      // if color not given, apply base color
      const shadeNumber = this.reduceHashTo0To100(this.djb2Hash(series.name));
      const _color: string = tinycolor(baseColor)
        .lighten(shadeNumber)
        .toRgbString();

      if (!series.meta) series.meta = { style: { color: _color } };
      if (!series.meta.style) series.meta.style = { color: _color };
      if (!series.meta.style.color) series.meta.style.color = _color;
    }

    return series;
  }

  /** Simple Guesser Method to catch undefined types   *
   * @param series Series with undefined type
   * @returns Series with guessed type or with type: "undefined"
   * @remark *Currently only identifies "min" and "max" timeseries, following the LANUV convention - feel free to expand/overwrite this function
   * @remark Type "Undefined" is visualized as line series
   */
  public static guessSeriesType(series: Series): Meta {
    const keywords = {
      min: ['.min.'],
      max: ['.max.'],
    };

    const input = series.shortName.toLowerCase();

    // Check for keywords in the input string
    for (const category of Object.keys(keywords)) {
      const categoryKeywords = keywords[category];
      for (const keyword of categoryKeywords) {
        if (input.includes(keyword)) {
          console.debug('Matched', input, 'with type', category);
          series.meta.typeGuessed = true;
          series.meta.type = category; // Return the category with a match
          break; // Assuming you want to stop after the first match
        }
      }
      if (series.meta.typeGuessed) break; // Break the outer loop if a match was found
    }

    return series.meta;
  }
}
