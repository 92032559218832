import { customElement } from 'lit/decorators.js';
import { css } from 'lit';
import { i18nMixin } from '../../decorators';
import {
  BaseTable,
  Export,
  Selection,
  ColumnFormatter,
  SortAble,
  LazyRendering,
} from '../ki-table';
import { Mix } from '../../common';
import nls from '../../locales/index';

const Parent = Mix(
  BaseTable,
  Selection,
  ColumnFormatter,
  LazyRendering,
  SortAble,
  Export,
);

@customElement('ki-ts-viewer-table')
export default class KiTsViewerTable extends Mix(Parent, [i18nMixin, { nls }]) {
  static styles = [
    ...Parent.styles,
    css`
      :host {
        height: 100%;
      }
      .col-value {
        text-align: right;
      }
      .header .header-content {
        padding: 10px 5px;
        hyphens: auto;
        text-align: left;
      }
      .table {
        font-size: 14px;
      }
      .table .body .cell {
        padding: 5px 5px;
      }
      .selection-col {
        display: none;
      }
      .row {
        cursor: default;
      }
    `,
  ];
}
