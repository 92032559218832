import { html } from 'lit';

// eslint-disable-next-line default-param-last
export function StatusIcon(image, label = '', col) {
  return html` <div
    class=" cell   col-__tag"
    style=" ${col.css || ''}text-align: center"
  >
    <img src=" ${image}" alt="${label}" title="${label}" />
  </div>`;
}
