/* eslint-disable class-methods-use-this */
/* eslint-disable no-return-assign */
import { html, css, LitElement, TemplateResult } from 'lit';
import { html as staticHtml, unsafeStatic } from 'lit/static-html.js';
import '@ui5/webcomponents/dist/Icon';
import '@ui5/webcomponents/dist/Button';

import '@ui5/webcomponents/dist/SegmentedButton';
import '@ui5/webcomponents/dist/SegmentedButtonItem';

import { customElement, property } from 'lit/decorators.js';
import '../ki-icon/ki-icon-btn';
import { getConfig } from '@kisters/wiski-web/components';
import { responsiveMixin } from '../../decorators';
import { Mix } from '../../common';

@customElement('ww-simple-dashboard')
export default class WwSimpleDashboard extends Mix(
  LitElement,
  responsiveMixin,
) {
  static styles = css`
    :host {
      --primary-color: #0080ff;
      --secondary-color: #0000ff;
      --font-size-base: 1rem;
      --font-size-large: 1.5rem;
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;
    }

    .dashboard-container {
      height: 100%;
      min-height: 250px;
      padding: 0px;
      display: flex;
      z-index: 1;
    }

    .graph-container {
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-content: center;
      gap: 0px;
      position: relative;
    }

    .dashboard-popup {
      width: 400px;
      height: 300px;
    }

    .pagination {
      display: flex;
      align-self: center;
      align-items: center;
      width: fit-content;
      padding: 0.3rem 0.3rem;
      margin-top: 0.4rem;
    }

    .count:hover {
      font-size: 1.1rem;
    }

    .count.selected {
      font-weight: bold;
      font-size: 1.1rem;
      border: solid 0.1rem;
    }

    .pagination-mobile-btn {
      display: none;
    }
    .prev-btn,
    .next-btn {
      align-self: center;
      cursor: pointer;
      z-index: 1000;
    }

    .prev-btn {
      margin-left: 10px;
    }
    .next-btn {
      margin-right: 10px;
    }

    .pagination-btn {
      width: 4rem;
      height: 4rem;
    }

    .pagination-icon {
      width: 3rem;
      height: 3rem;
    }

    .pagination-mobile {
      display: none;
    }

    @media only screen and (max-width: 800px) {
      .graph-container {
        display: flex;
      }
      .graph-container > * {
        width: 100%;
      }

      .prev-btn,
      .next-btn {
        display: none;
      }

      .pagination-mobile {
        display: flex;
        flex-wrap: wrap;
        align-self: center;
        z-index: 1000;
        margin-bottom: 0.5rem;
      }

      .pagination {
        display: none;
      }
    }
  `;

  @property({ type: Array, attribute: false })
  stations = [];

  @property({ type: String, attribute: false })
  popup = '';

  @property({ type: String, attribute: false })
  layerAlias = '';

  @property({ type: Number })
  selectedStep = 1;

  @property({ type: Number })
  totalSteps = 0;

  @property({ type: Boolean })
  isMobile = false;

  @property({ type: Boolean })
  shouldHideScreenArrows = false;

  @property({ type: Boolean })
  shouldHidePaginationArrows = true;

  @property({ type: Number })
  graphsPerPage = 0;

  popupX = 400;

  popupY = 300;

  firstUpdated() {
    this.updateTotalSteps();
    this.checkMaxWidth();
  }

  constructor() {
    super();
    const config = getConfig()?.pagination;
    this.shouldHidePaginationArrows = config?.shouldHidePaginationArrows;
    this.shouldHideScreenArrows = config?.shouldHideScreenArrows;
  }

  checkMaxWidth() {
    const handleMaxWidthChange = () => {
      this.isMobile = false;
      this.selectedStep = 1;

      const mediaQueryMobile = window.matchMedia('(max-width: 600px)');
      const mediaQueryMaxHeight = window.matchMedia('(max-height: 515px)');

      this.graphsPerPage =
        this.stationsGridXCount() * this.stationsGridYCount();

      if (mediaQueryMobile.matches) {
        this.isMobile = true;
        this.graphsPerPage = 1;
      } else {
        this.isMobile = false;
      }

      if (mediaQueryMaxHeight.matches) {
        this.graphsPerPage = 0;
      }
      this.updateTotalSteps();
    };

    window.addEventListener('resize', handleMaxWidthChange);
    handleMaxWidthChange(); // Call initially
  }

  /** Num of station in X-dimension (left -> right) */
  stationsGridXCount(): number {
    const bigBtns = this.shadowRoot.querySelectorAll('.big-btn');
    let offsetBtn = 20; // margin sum of both buttons
    Array.from(bigBtns).forEach(btn => {
      const btnsWidth = btn as HTMLElement;
      offsetBtn += btnsWidth.clientWidth + btnsWidth.offsetWidth;
    });
    const xCount = Math.floor((this.clientWidth - offsetBtn) / this.popupX);
    return xCount || 0;
  }

  /** Num of station in Y-dimension (top -> bottom) */
  stationsGridYCount(): number {
    const offsetPagination =
      this.shadowRoot?.querySelector('.pagination')?.offsetHeight ?? 0;
    const yCount = Math.floor(
      (this.clientHeight - offsetPagination) / this.popupY,
    );
    return yCount || 0;
  }

  updateTotalSteps() {
    this.graphsPerPage > 0
      ? (this.totalSteps = Math.ceil(this.stations.length / this.graphsPerPage))
      : (this.totalSteps = Math.ceil(this.stations.length / 1));
  }

  update(changedProperties: Map<string, any>) {
    if (
      changedProperties.has('stations') &&
      changedProperties.get('stations')
    ) {
      this.selectedStep = 1;
      this.updateTotalSteps();
    }
    super.update();
  }

  _getNavItems() {
    const steps: TemplateResult[] = [];
    const totalPages = Math.ceil(this.stations.length / this.graphsPerPage);

    if (totalPages <= 8) {
      // If there are 8 or fewer pages, simply display all pages
      for (let i = 1; i <= totalPages; i += 1) {
        steps.push(this._createNavItem(i));
      }
    } else {
      const { showFirst, showLast, showMiddle } = this._calculateShowCounts();

      for (let i = 1; i <= showFirst; i += 1) {
        steps.push(this._createNavItem(i));
      }

      if (this.selectedStep > showFirst + 1) {
        steps.push(this._createEllipsisItem());
      }

      const start = Math.max(showFirst + 1, this.selectedStep - showMiddle);
      const end = Math.min(
        totalPages - showLast,
        this.selectedStep + showMiddle,
      );

      for (let i = start; i <= end; i += 1) {
        steps.push(this._createNavItem(i));
      }

      if (this.selectedStep < totalPages - showLast) {
        steps.push(this._createEllipsisItem());
      }

      for (let i = totalPages - showLast + 1; i <= totalPages; i += 1) {
        steps.push(this._createNavItem(i));
      }
    }

    return steps;
  }

  _createNavItem(index) {
    return html`
      <ui5-segmented-button-item
        @click="${() => {
          this.selectedStep = index;
        }}"
        class="count ${this.selectedStep === index ? 'selected' : ''}"
      >
        ${index}
      </ui5-segmented-button-item>
    `;
  }

  _createEllipsisItem() {
    return html`
      <ui5-segmented-button-item disabled>...</ui5-segmented-button-item>
    `;
  }

  _calculateShowCounts() {
    const showFirst = 2;
    const showLast = 2;
    const showMiddle = 2;
    return { showFirst, showLast, showMiddle };
  }

  _getPagination() {
    if (this.totalSteps) {
      if (this.shouldHidePaginationArrows && !this.isMobile) {
        return html`
          <ui5-segmented-button> ${this._getNavItems()} </ui5-segmented-button>
        `;
      }
      return html`
        <ui5-segmented-button>
          <ui5-segmented-button-item @click="${this.handlePrevPage}">
            <ui5-icon name="navigation-left-arrow"></ui5-icon>
          </ui5-segmented-button-item>

          ${this._getNavItems()}

          <ui5-segmented-button-item @click="${this.handleNextPage}">
            <ui5-icon name="navigation-right-arrow"></ui5-icon>
          </ui5-segmented-button-item>
        </ui5-segmented-button>
      `;
    }
    return html``;
  }

  handlePrevPage() {
    if (this.selectedStep > 1) {
      this.selectedStep -= 1;
      this.requestUpdate();
    }
  }

  handleNextPage() {
    if (this.selectedStep < this.totalSteps) {
      this.selectedStep += 1;
      this.requestUpdate();
    }
  }

  render() {
    if (this.graphsPerPage > 0) {
      const tag = unsafeStatic(this.popup);
      return html`
        <div class="pagination">${this._getPagination()}</div>

        <div class="dashboard-container" id="dashboard-container">
          ${!this.shouldHideScreenArrows
            ? html`
                <div class="prev-btn big-btn">
                  <ui5-segmented-button-item
                    class="pagination-btn"
                    @click="${this.handlePrevPage}"
                  >
                    <ui5-icon
                      class="pagination-icon"
                      name="navigation-left-arrow"
                    ></ui5-icon>
                  </ui5-segmented-button-item>
                </div>
              `
            : ''}
          <div class="graph-container">
            ${this.stations
              .slice(
                this.selectedStep * this.graphsPerPage - this.graphsPerPage,
                this.selectedStep * this.graphsPerPage,
              )
              .map(
                station =>
                  staticHtml`<${tag} class="dashboard-popup" flexSize .layerAlias="${
                    this.layerAlias
                  }" .stations="${[station]}"></${tag}>`,
              )}
          </div>
          ${!this.shouldHideScreenArrows
            ? html`
                <div class="next-btn big-btn">
                  <ui5-segmented-button-item
                    class="pagination-btn"
                    @click="${this.handleNextPage}"
                  >
                    <ui5-icon
                      class="pagination-icon"
                      name="navigation-right-arrow"
                    ></ui5-icon>
                  </ui5-segmented-button-item>
                </div>
              `
            : ''}
        </div>

        <div class="pagination-mobile">${this._getPagination()}</div>
      `;
    }
    return html``;
  }
}
