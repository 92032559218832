import Control from 'ol/control/Control';
// import './components'; // TODO: Why?
import { fromLonLat } from 'ol/proj';

if (!navigator.geolocation) {
  console.warn('Geolocation is not supported for this Browser/OS.');
}

export default class LocationControl extends Control {
  constructor(options = {}) {
    options.element = document.createElement('ki-icon-btn');
    options.element.icon = 'ki ki-crosshairs';
    options.element.classList.add('location-btn');
    super(options);
  }

  gotoLocation() {
    // TODO timeout
    // need https
    const p = new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        pos => {
          this.getMap()
            .getView()
            .animate({
              center: fromLonLat(
                [pos.coords.longitude, pos.coords.latitude],
                this.getMap().getView().getProjection(),
              ),
              duration: 500,
            });
          resolve();
        },
        e => {
          console.error(e);
          alert("Can't get your location");
          reject(e);
        },
        {
          timeout: 2000,
        },
      );
    });

    // TODO show loading?
    console.debug(p);
  }
}
