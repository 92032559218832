import {
  ImageArcGISRest,
  ImageWMS,
  OSM,
  TileArcGISRest,
  TileImage,
  TileWMS,
  Vector as VectorSource,
  WMTS,
  XYZ,
  ImageStatic,
  VectorTile as VectorTileSource,
} from 'ol/source';
import {
  Image as ImageLayer,
  Tile as TileLayer,
  Vector as VectorLayer,
} from 'ol/layer';
import VectorTileLayer from 'ol/layer/VectorTile';
import { optionsFromCapabilities } from 'ol/source/WMTS';
import { GeoJSON, WMTSCapabilities, TopoJSON, MVT } from 'ol/format';
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';
import TileGrid from 'ol/tilegrid/TileGrid';

import { applyStyle } from 'ol-mapbox-style';

import FontIcon from '../components/ki-station-map/FontIcon';

const parserWMTS = new WMTSCapabilities();

const styleFontIconDefaults = {
  backgroundColor: 'rgba(197, 213, 234, 1)',
  icon: '',
  fontSize: 0.8,
  radius: 9,
  color: 'rgba(29,29,27, 1)',
  border: {
    color: 'rgba(29,29,27, 1)',
    width: 1,
  },
};

const styleDefaults = {
  stroke: {
    color: 'rgba(255, 255, 255, 1.0)',
    width: 1,
  },
  fill: {
    color: 'rgba(155, 155, 155, 0.5)',
  },
};

const textStyleDefaultPoint = {
  textAlign: 'left',
  textBaseline: 'middle',
  font: '14px -apple-system, BlinkMacSystemFont, sans-serif, Calibri',
  offsetX: 7,
  fill: { color: 'black' },
  stroke: { color: 'black', width: 0.5 },
};

const textStyleDefault = {
  textAlign: 'center',
  textBaseline: 'middle',
  font: '24px -apple-system, BlinkMacSystemFont, sans-serif,Calibri',
  fill: new Fill({ color: 'white' }),
  stroke: new Stroke({ color: 'black', width: 1 }),
};

function getTextStyle(type, textStyle) {
  let style = { ...textStyleDefault, ...textStyle };
  switch (type) {
    case 'Point':
      style = { ...textStyleDefaultPoint, ...textStyle };
      break;
    default:
  }
  style.fill = new Fill(style.fill);
  style.zIndex = 1;
  style.stroke = new Stroke(style.stroke);
  style.backgroundFill = style.backgroundFill
    ? new Fill(style.backgroundFill)
    : null;
  style.backgroundStroke = style.backgroundStroke
    ? new Fill(style.backgroundStroke)
    : null;
  return new Text(style);
}

export function getStyle(type, style = {}, textStyle = {}) {
  let retStyle;
  const { stroke, fill } = style;
  switch (type) {
    case 'Point':
      retStyle = new Style({
        text: getTextStyle(type, textStyle),
        image: new FontIcon({ ...styleFontIconDefaults, ...style }),
      });
      break;
    case 'LineString':
    case 'MultiLineString':
      retStyle = new Style({
        text: getTextStyle(type, textStyle),
        stroke: new Stroke(stroke || styleDefaults.stroke),
      });
      break;
    case 'Polygon':
    case 'MultiPolygon':
    case 'Circle':
      retStyle = new Style({
        text: getTextStyle(type, textStyle),
        stroke: new Stroke(stroke || styleDefaults.stroke),
        fill: new Fill(fill || styleDefaults.fill),
      });
      break;
    case 'GeometryCollection':
      retStyle = new Style({
        text: getTextStyle(type, textStyle),
        stroke: new Stroke(stroke || styleDefaults.stroke),
        fill: new Fill(fill || styleDefaults.fill),
        image: new CircleStyle({
          radius: 10,
          fill: null,
          stroke: new Stroke({
            color: 'magenta',
          }),
        }),
      });
      break;
    default:
      break;
  }
  return retStyle;
}

function getVectorTileLayer({
  viewFilter,
  label,
  zIndex,
  maxZoom,
  minZoom,
  extent,
  projection,
  url,
  attribution,
  style = {},
}) {
  const _style = new Style({
    stroke: new Stroke(
      style.stroke ?? {
        color: 'gray',
        width: 1,
      },
    ),
    fill: new Fill(
      style.fill ?? {
        color: 'rgba(20,20,20,0.9)',
      },
    ),
  });
  return new VectorTileLayer({
    maxZoom,
    viewFilter,
    minZoom,
    zIndex,
    name: label,
    extent,
    style: () => _style,
    source: new VectorTileSource({
      format: new MVT(),
      projection,
      attributions: attribution,
      url,
      zIndex,
    }),
  });
}

function getWTMSLayer({
  extent,
  viewFilter,
  attributions,
  maxZoom,
  minZoom,
  label,
  url,
  layer,
  matrixSet,
  opacity = 1,
}) {
  const tileLayer = new TileLayer({
    opacity,
    maxZoom,
    minZoom,
    extent,
    viewFilter,
    name: label,
  });
  fetch(url)
    .then(response => response.text())
    .then(text => {
      const options = optionsFromCapabilities(parserWMTS.read(text), {
        layer,
        matrixSet,
      });
      options.crossOrigin = 'Anonymous';
      tileLayer.set('name', label || options.title);
      options.attributions = attributions;
      tileLayer.setSource(new WMTS(options));
    });
  return tileLayer;
}

function getWMSLayer({
  viewFilter,
  attributions,
  projection,
  maxZoom,
  zIndex,
  minZoom,
  extent,
  ratio = 1,
  params = {},
  label,
  url,
  opacity = 1,
  tiled = false,
}) {
  return tiled
    ? new TileLayer({
        opacity,
        maxZoom,
        minZoom,
        viewFilter,
        zIndex,
        extent,
        name: label,
        source: new TileWMS({
          crossOrigin: 'Anonymous',
          projection,
          url,
          params,
          transition: 0,
          attributions,
        }),
      })
    : new ImageLayer({
        opacity: opacity || 1,
        name: label,
        maxZoom,
        minZoom,
        zIndex,
        viewFilter,
        extent,
        source: new ImageWMS({
          serverType: 'mapserver',
          ratio,
          projection,
          crossOrigin: 'Anonymous',
          url,
          params,
          attributions,
        }),
      });
}

function getMapBoxStyleLayer({
  viewFilter,
  label,
  zIndex,
  maxZoom,
  minZoom,
  extent,
  url,
}) {
  const newLayer = new VectorTileLayer({
    maxZoom,
    viewFilter,
    minZoom,
    zIndex,
    extent,
    name: label,
    declutter: true,
  });

  applyStyle(newLayer, url);
  // applyBackground(newLayer, url);

  return newLayer;
}

function getOSMLayer({ viewFilter, label, zIndex, maxZoom, minZoom, extent }) {
  return new TileLayer({
    maxZoom,
    viewFilter,
    minZoom,
    zIndex,
    extent,
    name: label,
    source: new OSM(),
  });
}

function getXYZLayer({
  viewFilter,
  projection,
  gridParams,
  zIndex,
  maxZoom,
  minZoom,
  label,
  url,
  attribution,
  params,
  extent,
}) {
  return new TileLayer({
    name: label,
    maxZoom,
    minZoom,
    extent,
    viewFilter,
    source: new XYZ({
      projection,
      attributions: attribution,
      url,
      zIndex,
      crossOrigin: 'Anonymous',
      params,
      tileGrid: gridParams ? new TileGrid(gridParams) : undefined,
    }),
  });
}

function getCustomLayer({
  viewFilter,
  maxZoom,
  zIndex,
  minZoom,
  label,
  url,
  gridParams,
  projection,
}) {
  const urlTemplate = `${url}_alllayers/L{z}/R{y}/C{x}.png`;
  return new TileLayer({
    name: label,
    zIndex,
    maxZoom,
    viewFilter,
    minZoom,
    source: new TileImage({
      tileUrlFunction(tileCoord) {
        const z = tileCoord[0];
        const x = tileCoord[1];
        const y = tileCoord[2];
        const _z = `00${z.toString()}`.substr(-2);
        const _r = `00000000${y.toString(16)}`.substr(-8);
        const _c = `00000000${x.toString(16)}`.substr(-8);
        return urlTemplate
          .replace('{z}', _z)
          .replace('{y}', _r)
          .replace('{x}', _c);
      },
      projection,
      tileGrid: new TileGrid(gridParams),
    }),
  });
}

function getTileArcGISRestLayer({
  viewFilter,
  maxZoom,
  zIndex,
  minZoom,
  label,
  url,
  attribution,
  format = 'PNG',
}) {
  return new TileLayer({
    name: label,
    opacity: 1.0,
    maxZoom,
    minZoom,
    viewFilter,
    zIndex,
    source: new TileArcGISRest({
      attributions: attribution,
      url,
      crossOrigin: 'Anonymous',
      params: { FORMAT: format },
    }),
  });
}

function getStaticImageLayer({
  viewFilter,
  opacity = 1.0,
  imageSmoothing = true,
  maxZoom,
  zIndex,
  minZoom,
  label,
  url,
  projection,
  extent,
}) {
  return new ImageLayer({
    name: label,
    opacity,
    maxZoom,
    minZoom,
    viewFilter,
    zIndex,
    source: new ImageStatic({
      imageSmoothing,
      url,
      projection,
      imageExtent: extent,
    }),
  });
}

function getImageArcGISRestLayer({
  viewFilter,
  maxZoom,
  zIndex,
  minZoom,
  label,
  url,
  attribution,
  params,
  projection,
}) {
  return new ImageLayer({
    name: label,
    opacity: 1.0,
    maxZoom,
    minZoom,
    viewFilter,
    zIndex,
    source: new ImageArcGISRest({
      attributions: attribution,
      url,
      projection,
      ratio: 1,
      crossOrigin: 'Anonymous',
      params,
    }),
  });
}

function getJsonLayer({
  featureProjection,
  attribution,
  opacity = 1,
  highlightedStyle,
  idProperty,
  declutter = false,
  type,
  extent,
  label,
  url,
  dataProjection,
  labelProperty,
  style,
  styleProperty,
  zIndex = null,
  maxZoom,
  minZoom,
  hoverStyle,
  textStyle,
  viewFilter,
  haslinks,
}) {
  const vectorSource = new VectorSource({ attributions: attribution });
  const FeatureParser = type === 'topojson' ? TopoJSON : GeoJSON;
  const layer = new VectorLayer({
    updateWhileInteracting: true,
    updateWhileAnimating: true,
    name: label,
    source: vectorSource,
    type,
    declutter,
    extent,
    maxZoom,
    interactive: !!hoverStyle,
    haslinks: !!haslinks,
    opacity,
    viewFilter,
    minZoom,
    zIndex,
    style(feature) {
      return feature._style;
    },
  });
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url);
  xhr.onload = () => {
    if (xhr.status === 200) {
      const data = JSON.parse(xhr.responseText);
      if (data.bbox) {
        layer.setExtent(data.bbox);
      }
      const features = new FeatureParser({
        dataProjection: dataProjection || 'EPSG:3857',
      }).readFeatures(data, { featureProjection });
      features.forEach(feat => {
        if (idProperty && feat.get(idProperty)) {
          feat.setId(feat.get(idProperty));
        }
        feat._style = getStyle(
          feat.getGeometry().getType(),
          style[feat.get(styleProperty)] || style,
          textStyle,
        );

        if (highlightedStyle) {
          feat._highlightedStyle = getStyle(
            feat.getGeometry().getType(),
            highlightedStyle.style[feat.get(styleProperty)] ||
              highlightedStyle.style,
            highlightedStyle.textStyle,
          );
        }

        if (hoverStyle) {
          if (hoverStyle.style) {
            feat._hoverStyle = getStyle(
              feat.getGeometry().getType(),
              hoverStyle.style[feat.get(styleProperty)] || hoverStyle.style,
              hoverStyle.textStyle,
            );
            if (hoverStyle.labelProperty) {
              feat._hoverStyle
                .getText()
                .setText(feat.get(hoverStyle.labelProperty));
            }
          }
          if (hoverStyle.tooltipProperty) {
            feat._toolTip = feat.get(hoverStyle.tooltipProperty);
          }
        }
        if (labelProperty) {
          feat._style.getText().setText(feat.get(labelProperty));
        }
      });
      vectorSource.addFeatures(features);
      vectorSource.dispatchEvent('done');
    } else {
      /* eslint-disable-next-line no-throw-literal */
      throw `generate layer ${label} failed`;
    }
  };
  xhr.send();
  return layer;
}

function _getLayer(args) {
  const {
    group = 'Backgrounds',
    selectable = true,
    visible = false,
    type = 'none',
    label = 'none',
    legendColor = null,
  } = args;
  let bglayer;
  switch (type.toLowerCase()) {
    case 'vectortiles':
      bglayer = getVectorTileLayer(args);
      break;
    case 'staticimage':
      bglayer = getStaticImageLayer(args);
      break;
    case 'mapbox':
      bglayer = getMapBoxStyleLayer(args);
      break;
    case 'osm':
      bglayer = getOSMLayer(args);
      break;
    case 'wmts':
      bglayer = getWTMSLayer(args);
      break;
    case 'wms':
      bglayer = getWMSLayer(args);
      break;
    case 'xyz':
      bglayer = getXYZLayer(args);
      break;
    case 'custom':
      bglayer = getCustomLayer(args);
      break;
    case 'tilearcgisrest':
      bglayer = getTileArcGISRestLayer(args);
      break;
    case 'imagearcgisrest':
      bglayer = getImageArcGISRestLayer(args);
      break;
    case 'geojson':
      bglayer = getJsonLayer(args);
      break;
    case 'topojson':
      bglayer = getJsonLayer(args);
      break;
    default:
      bglayer = new VectorLayer({
        name: label,
        vectorSource: new VectorSource({}),
      });
  }
  bglayer.setProperties({
    legendColor,
    displayInLayerControl: selectable,
    group,
  });
  bglayer.setVisible(visible);
  return bglayer;
}

export function getLayer(args) {
  const { type, items, visible, label, group, legendColor, attribution } = args;
  if (type === 'layergroup') {
    const ret = items.map((layer, i) => {
      layer.selectable = i === 0;
      layer.group = group;
      layer.label = label;
      layer.visible = visible;
      layer.legendColor = legendColor;
      layer.attribution = attribution;
      return _getLayer(layer);
    });
    ret.forEach(layer => {
      layer.linkedlayers = ret;
    });
    return ret;
  }
  return _getLayer(args);
}
