import { html, css, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import '../ki-echart/ki-echart';

export const widgetType = {
  default: 1,
  addNew: 2,
  empty: 3,
};

@customElement('ki-widget')
export default class KiWidget extends LitElement {
  static styles = css`
    :host {
      height: 100%;
      width: 100%;
      display: block;
      overflow: hidden;
    }
    .widget-container {
      height: 100%;
      width: 100%;
    }
  `;

  type: number = widgetType.empty;

  configuration: string = '';

  static get properties() {
    return {
      configuration: { type: String },
      type: { type: Number },
    };
  }

  renderWidgetContent() {
    switch (this.type) {
      case widgetType.addNew:
        return '+';
      case widgetType.empty:
        return '';
      case widgetType.default:
      case undefined:
      default: {
        const config = JSON.parse(this.configuration ?? '{}');
        if (config) {
          return html`<ki-echart .options="${config}"></ki-echart>`;
        }
        return config.text ?? '';
      }
    }
  }

  render() {
    return html`
      <div class="widget-container">${this.renderWidgetContent()}</div>
    `;
  }
}
