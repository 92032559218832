/* eslint-disable no-console */
import { html, LitElement } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { customElement, property } from 'lit/decorators.js';
import { first, keyBy, last } from 'lodash-es';
import {
  template,
  deUmlaut,
  Mix,
  downloadFile,
  copyTextToClipboard,
  dayjs,
} from '../../common';
import { formatNumber } from '../../common/NumberFormatter';
import { responsiveMixin, i18nMixin } from '../../decorators';
import { getCurrentApi } from '../../api';
import { getRouteOptionsAndParams, getCsvDelimiter } from '../ki-app';
import nls from '../../locales/index';

import '@ui5/webcomponents/dist/Toast';

import { downloadExcel } from '../../common/download';
import DataTransformation from '../../common/timeseries/DataTransformation';
import style from './wwp-station-download.css?inline';
import type {
  FilegenMetaFile,
  FilegenMetaResource,
  FilegenMetaStation,
  FilegenMimeType,
  FilegenTsData,
} from '../../defs/FilegenMetaTypes';

@customElement('wwp-station-download')
export default class WwpStationDownload extends Mix(
  LitElement,
  responsiveMixin,
  [i18nMixin, { nls }],
) {
  static styles = style;

  /** Central data object; gets set via Router, see onAfterEnter */
  @property({ type: Object })
  station: FilegenMetaStation | undefined;

  _showUTCDates = false;

  dataColumn = ['Timestamp', 'Value', 'Quality Code Name'];

  // Zeitpunkt'), 'Messwert', 'Status
  get dataColumnLabels() {
    return [
      this.i18n.t('DOWNLOADS.timestamp'),
      this.i18n.t('DOWNLOADS.value'),
      this.i18n.t('DOWNLOADS.status'),
    ];
  }

  get dataColumnLabelsAggr() {
    return [
      this.i18n.t('DOWNLOADS.day'),
      this.i18n.t('DOWNLOADS.dayMin'),
      this.i18n.t('DOWNLOADS.dayAvg'),
      this.i18n.t('DOWNLOADS.dayMax'),
      this.i18n.t('DOWNLOADS.status'),
    ];
  }

  api = getCurrentApi();

  connectedCallback() {
    if (super.connectedCallback) super.connectedCallback();
  }

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, ['stationId']);
    this.station = await this.api.getStation(params.stationId);
    Object.assign(this, params.options);
  }

  /**
   * Looks through timeseries index of station to find matching files
   * @param filename e.g. `week.json`. ỳear.json` or `complete.json`
   * @param param stationparameter_longname
   * @param mimeType
   * @returns
   */
  getFile(
    filename: string,
    param: string,
    mimeType: FilegenMimeType = 'application/json',
  ): FilegenMetaResource[] {
    return this.station
      ? this.station.timeseries.filter(
          item =>
            item.station_parameter === param &&
            item.href.includes(filename) &&
            item.mime_type === mimeType,
        )
      : [];
  }

  getParameter(param) {
    return this.parameterMapping[param] || param;
  }

  /**
   * Generate human readable description of dataset
   * @remark Uses localization
   */
  getPeriod(period, resolution = null) {
    if (!period) {
      return resolution;
    }
    let ret = dayjs.duration(period).humanize();
    if (period === 'PoR') {
      ret = this.i18n.t('complete');
    }
    if (resolution) {
      ret += ` ${this.i18n.t('as')} ${resolution}`;
    }
    // Return with Capital letter
    return ret.charAt(0).toUpperCase() + ret.slice(1);
  }

  render() {
    return this.station
      ? html`<div class="content">
            <div class="descr">${unsafeHTML(this.descr)}</div>
            <div class="dltable">
              ${this._renderHeader()}
              ${this.periods.map(per => html`${this._renderRow(per)}`)}
            </div>
            ${this.additionalDownloads()}
          </div>
          <ui5-toast id="wcToastShort"
            >${this.i18n.t('copytoclipboard')}</ui5-toast
          >`
      : html`<div>Loading...</div>`;
  }

  // eslint-disable-next-line class-methods-use-this
  additionalDownloads() {
    return html``;
  }

  _renderHeader() {
    return html` <div class="row header">
      <div class="cell cellheader label"></div>
      ${this.params
        .filter(item =>
          this.station.timeseries.some(
            ts => ts.station_parameter === item.parameter,
          ),
        )
        .map(
          field =>
            html`<div class="cell cellheader label">${field.label}</div>`,
        )}
    </div>`;
  }

  /** Decide if icon/dataset should be visible in download matrix */
  _checkVisibility(per, parameter) {
    const list =
      per.type === 'file' ? this.station.zips : this.station.timeseries;
    const token = per.file[parameter] || per.file;

    if (Array.isArray(token)) {
      return list.some(
        ts =>
          ts.station_parameter === parameter &&
          token.some(t => ts.href.endsWith(t)),
      );
    }
    return list.some(
      ts => ts.station_parameter === parameter && ts.href.endsWith(token),
    );
  }

  _getCSVDownloadIcon(per, field) {
    const format = 'csv';
    if (per.type !== 'file') {
      return html`<div class="innerCell">
        <ki-icon
          style="fill:var(--theme-color-primary);display:${this._checkVisibility(
            per,
            field.parameter,
          )
            ? 'inital'
            : 'none'}"
          title="${this.i18n.t('downloadFile')}"
          icon="ki ki-file-csv"
          .fileformat="${'csv'}"
          .value="${field}"
          .period="${per}"
          @click="${this.download}"
        ></ki-icon>
      </div>`;
    }
    const filehref = this.getDownloadFileHref(
      per.file,
      field.parameter,
      format,
    );
    if (!filehref) {
      return html``;
    }
    return html`<div class="innerCell">
        <a href="${filehref}">
          <ki-icon
            title="${this.i18n.t('downloadFile')}"
            style="fill:var(--theme-color-primary);"
            icon="ki ki-file-csv"
            .fileformat="${'csv'}"
            .value="${field}"
            .period="${per}"
          ></ki-icon
        ></a>
      </div>
      <div class="innerCell">
        <ki-icon
          style="fill:var(--theme-color-primary);display:${this._checkVisibility(
            per,
            field.parameter,
          )
            ? 'inital'
            : 'none'}"
          icon="ki ki-link"
          title="Copy link to clipboard"
          .fileformat="${'csv'}"
          .value="${field}"
          .period="${per}"
          @click="${() => {
            const link = document.createElement('a');
            link.href = filehref;
            copyTextToClipboard(
              `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`,
            );
            this.renderRoot.querySelector('#wcToastShort').show();
          }}"
        ></ki-icon>
      </div>`;
  }

  _renderRow(per) {
    return html` <div class="row">
      <div class="cell">${this.getPeriod(per.period, per.resolution)}</div>
      ${this.params
        .filter(item =>
          this.station.timeseries.some(
            ts => ts.station_parameter === item.parameter,
          ),
        )
        .map(
          field =>
            html`<div class="cell iconcell">
              ${this._getCSVDownloadIcon(per, field)}
              <div class="innerCell">
                <ki-icon
                  title="${this.i18n.t('downloadFile')}"
                  style="fill:green;display:${per.type !== 'file' &&
                  this._checkVisibility(per, field.parameter)
                    ? 'inital'
                    : 'none'}"
                  icon="ki ki-file-excel"
                  .fileformat="${'xls'}"
                  .value="${field}"
                  .period="${per}"
                  @click="${this.download}"
                ></ki-icon>
              </div>
            </div>`,
        )}
    </div>`;
  }

  _getAggrData(files, minDate) {
    /** Normalize to keep backward-compatibility */
    const data = DataTransformation.normalizeFiles(files);
    let minTs;
    let meanTs;
    let maxTs;
    data.forEach(ts => {
      if (ts.ts_shortname.includes('Min')) {
        minTs = keyBy(ts.data, o => o[0]);
      } else if (ts.ts_shortname.includes('Max')) {
        maxTs = keyBy(ts.data, o => o[0]);
      } else if (ts.ts_shortname.includes('Mean')) {
        meanTs = keyBy(ts.data, o => o[0]);
      }
    });

    /** Extract cols from first data object */
    const cols = data[0].columns.split(',');
    const releaseStatusIndex = cols.indexOf('Release State');
    const qualityCodeNameIndex = cols.indexOf('Quality Code');

    const rows = [];
    Object.keys(meanTs).forEach(k => {
      if (new Date(k).getTime() > minDate) {
        const row = [
          this._showUTCDates
            ? dayjs(k).utc().format('L')
            : dayjs(k).format('L'),
          minTs[k] ? formatNumber(minTs[k][1], this.numberFormat || {}) : '',
          meanTs[k] ? formatNumber(meanTs[k][1], this.numberFormat || {}) : '',
          maxTs[k] ? formatNumber(maxTs[k][1], this.numberFormat || {}) : '',
        ];

        if (releaseStatusIndex !== -1) {
          const releaseState =
            this.releaseStates[meanTs[k][releaseStatusIndex]] ||
            this.releaseStates.default;
          row.push(releaseState);
        }

        if (qualityCodeNameIndex !== -1) {
          row.push(meanTs[k][qualityCodeNameIndex]);
        }

        rows.push(row);
      }
    });
    return rows;
  }

  _getData(files, minDate) {
    /** Pick first data item; normalize (wrap in array) to keep backward-compatibility */
    const dataFile: FilegenMetaStation =
      DataTransformation.normalizeFiles(files)[0];
    const cols = dataFile.columns.split(',');

    /** Map Col index to data rows */
    const releaseStatusIndex = cols.indexOf('Release State');
    const qualityCodeNameIndex = cols.indexOf('Quality Code');

    const rows = [];
    console.time('StartDownloadParsing');
    dataFile.data.forEach(item => {
      const dateInMs = new Date(item[0]).getTime();
      if (dateInMs > minDate) {
        const row = [
          this._showUTCDates
            ? dayjs(dateInMs).utc().format('L LT')
            : dayjs(dateInMs).tz().format('L LT'),
          item[1] === null
            ? ''
            : formatNumber(item[1], this.numberFormat || {}),
        ];

        if (releaseStatusIndex !== -1) {
          const releaseState =
            this.releaseStates[item[releaseStatusIndex]] ||
            this.releaseStates.default;
          row.push(releaseState);
        }

        if (qualityCodeNameIndex !== -1) {
          row.push(item[qualityCodeNameIndex]);
        }
        rows.push(row);
      }
    });
    console.timeEnd('StartDownloadParsing');
    return rows;
  }

  getDownloadFileHref(
    file: string | Array<string> | Object,
    parameter: string,
    format: 'csv' | 'xlsx' | 'xls',
  ): string {
    let dlfiles: Array<{
      href: string;
      mime_type: string;
      period_alias: string;
      purpose: Array<string>;
      station_parameter: string;
      type: string;
    }> = this.station.zips.filter(item => {
      if (item.station_parameter !== parameter) {
        return false;
      }
      const fileParam = file[parameter];
      if (fileParam) {
        return item.href.endsWith(file[parameter]);
      }
      if (Array.isArray(file)) {
        return file
          .map(f => item.href.endsWith(f))
          .reduce((prev, curr) => prev || curr);
      }
      return item.href.endsWith(file);
    });

    if (dlfiles.length > 1) {
      dlfiles = dlfiles.filter(file =>
        format === 'xls'
          ? file.href.endsWith('xls') || file.href.endsWith('xlsx')
          : file.href.endsWith(format),
      );
    }

    if (dlfiles.length < 1) {
      return null;
    }

    return this.api.getLink(`/${dlfiles[0].href}`);
  }

  async download(evt) {
    const format = evt.target.fileformat;
    const { period, file, resolution } = evt.target.period;
    const { parameter, label } = evt.target.value;
    const type = typeof file;
    console.debug('type', type);
    const tsShortname =
      evt.target.period.ts_shortname || evt.target.value.ts_shortname;
    if (type.match('file')) {
      const dlfile = this.getDownloadFileHref(file, parameter, format);
      if (dlfile) {
        window.open(dlfile);
      }
    }
    const datafile: FilegenMetaFile = first(
      type.match('object')
        ? this.getFile(file[parameter], parameter)
        : this.getFile(file, parameter),
    );
    console.debug('Download', datafile);
    if (datafile && !type.match('file')) {
      console.debug(datafile);
      const tsdata: FilegenTsData | FilegenTsData[] = await this.api.getTsData(
        datafile.href,
      );
      console.debug(tsdata);
      let _data = DataTransformation.normalizeFiles(tsdata);
      const dataList = DataTransformation.normalizeFiles(tsdata);
      if (tsShortname && type.match('agg')) {
        _data = tsdata.data.filter(item => item.ts_shortname === tsShortname);
      }
      if (_data && _data[0].data) {
        /** Merge MetaData and first data object into single object */
        const mdList = { ...this.station, ..._data[0] };
        mdList.creation = dayjs().tz().format('L LT');
        mdList.resolution = resolution;
        const minDate =
          period === 'PoR'
            ? -Infinity
            : dayjs().tz().subtract(dayjs.duration(period)).valueOf();
        const data =
          type.match('agg') && dataList.length > 1
            ? this._getAggrData(dataList, minDate)
            : this._getData(dataList, minDate);
        // eslint-disable-next-line prefer-destructuring
        mdList.from = data[0]?.[0];
        mdList.to = last(data)?.[0];
        const filename = this.filename
          ? `${template(this.filename, mdList)}_${this.getPeriod(period)}`
          : `${dayjs().tz().format('L')}_${
              this.station.station_longname
            }_${label}_${this.getPeriod(period)}`;
        const mdfields = this.mdfields.map(item => [
          this.mdlabels[item] || this.i18n.t(item),
          this.mdValues[item] || mdList[item],
        ]);
        const header =
          type.match('agg') && dataList.length > 1
            ? [this.dataColumnLabelsAggr]
            : [this.dataColumnLabels];
        if (format === 'xls') {
          downloadExcel(
            {
              metadata: mdfields.concat(header),
              data,
            },
            {
              sheetName:
                type.match('agg') && dataList.length > 1
                  ? this.i18n.t('dailydata')
                  : this.i18n.t('complete'),
              name: deUmlaut(filename),
            },
          );
        } else {
          let csvResult = mdfields
            .map(item => `#${item.join(': ')}`)
            .join('\r\n');
          csvResult += `\r\n${header
            .map(item => `${item.join(getCsvDelimiter())}`)
            .join('\r\n')}`;
          csvResult += `\r\n${data
            .map(item => `${item.join(getCsvDelimiter())}`)
            .join('\r\n')}`;
          downloadFile(csvResult, deUmlaut(filename));
        }
      }
    }
  }
}
