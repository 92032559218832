import dayjs from '../../common/dayjsext';

export interface IPeriod {
  from: number;
  to: number;
}

export interface IColumnOption {
  label: string;
  dates: IPeriod;
}

export class PeriodsList {
  allowtime;

  constraints;

  _periods;

  _periodsWithoutTime;

  periodListOptions;

  value;

  constructor(classification) {
    this.constraints =
      classification && classification.constraints
        ? classification.constraints
        : {};

    const options =
      classification && classification.periods
        ? classification.periods
        : [
            [
              'today',
              'thisWeek',
              'thisMonth',
              'thisYear',
              'theDaySoFar',
              'last15day',
              'last30day',
              'last60day',
              'last6month',
              'last1year',
              'last2year',
              'last3year',
            ],
          ];
    this.allowtime === true
      ? (this.periods = options)
      : (this.periodsWithoutTime = options);
    this.periodListOptions =
      classification && classification.periodListOptions
        ? classification.periodListOptions
        : null;
    this.value = 'today';
  }

  set periods(val) {
    if (!val) {
      return;
    }
    this._periods = val;
  }

  get periods() {
    return this._periodss;
  }

  set periodsWithoutTime(val) {
    if (!val) {
      return;
    }
    this._periodsWithoutTime = val;
  }

  get periodsWithoutTime() {
    return this._periodsWithoutTime;
  }

  static getDurationBySrt(period: string): IPeriod | null {
    let matched;
    let m;
    let unit;
    switch (period) {
      case 'custom':
        return {
          from: dayjs().valueOf(),
          to: dayjs().valueOf(),
        };
      case 'today':
        return {
          from: dayjs().startOf('day').valueOf(),
          to: dayjs().add(1, 'days').startOf('day').valueOf(),
        };
      case 'thisWeek':
        return {
          from: dayjs().startOf('week').valueOf(),
          to: dayjs().add(1, 'weeks').startOf('week').valueOf(),
        };
      case 'thisMonth':
        return {
          from: dayjs().startOf('month').valueOf(),
          to: dayjs().add(1, 'months').startOf('month').valueOf(),
        };
      case 'lastMonth':
        return {
          from: dayjs().add(-1, 'months').valueOf(),
          to: dayjs().valueOf(),
        };
      case 'thisYear':
        return {
          from: dayjs().startOf('year').valueOf(),
          to: dayjs().add(1, 'years').startOf('year').valueOf(),
        };
      case 'lastYear':
        return {
          from: dayjs().add(-1, 'years').valueOf(),
          to: dayjs().valueOf(),
        };
      case 'theDaySoFar':
        return {
          from: dayjs().startOf('day').valueOf(),
          to: dayjs().valueOf(),
        };
      case 'WTD':
        return {
          from: dayjs().startOf('week').valueOf(),
          to: dayjs().startOf('day').valueOf(),
        };
      case 'MTD':
        return {
          from: dayjs().startOf('month').valueOf(),
          to: dayjs().startOf('day').valueOf(),
        };
      case 'YTD':
        return {
          from: dayjs().startOf('year').valueOf(),
          to: dayjs().startOf('day').valueOf(),
        };
      case 'yesterday':
        m = dayjs().subtract(1, 'days');
        return {
          from: m.clone().startOf('day').valueOf(),
          to: m.add(1, 'days').startOf('day').valueOf(),
        };
      case 'dayBeforeYesterday':
        m = dayjs().subtract(2, 'days');
        return {
          from: m.clone().startOf('day').valueOf(),
          to: m.add(1, 'days').startOf('day').valueOf(),
        };
      case 'dayLastWeek':
        m = dayjs().subtract(1, 'weeks');
        return {
          from: m.clone().startOf('day').valueOf(),
          to: m.add(1, 'days').startOf('day').valueOf(),
        };
      case 'preWeek':
        m = dayjs().subtract(1, 'weeks');
        return {
          from: m.clone().startOf('week').valueOf(),
          to: m.add(1, 'weeks').startOf('week').valueOf(),
        };
      case 'preMonth':
        m = dayjs().subtract(1, 'months');
        return {
          from: m.clone().startOf('month').valueOf(),
          to: m.add(1, 'months').startOf('month').valueOf(),
        };
      case 'preYear':
        m = dayjs().subtract(1, 'years');
        return {
          from: m.clone().startOf('year').valueOf(),
          to: m.add(1, 'years').startOf('year').valueOf(),
        };
      case 'periodOfRecord':
        return {
          from: dayjs().startOf('year').valueOf(),
          to: dayjs().startOf('day').valueOf(),
        };
      default:
        matched = period.match(/^last(\d+)(min|hour|day|month|year)/i);
        if (matched) {
          m = dayjs();
          unit = matched[2].toLowerCase();
          if (unit === 'min') {
            unit = 'minute';
          }
          return {
            from: m.clone().subtract(matched[1], `${unit}s`).valueOf(),
            to: m.valueOf(),
          };
        }
        return null;
    }
  }

  getPeriodsList() {
    const returnOptions: Array<Array<IColumnOption>> = [];
    const options =
      this.allowtime === true ? this.periods : this.periodsWithoutTime;
    options.forEach(col => {
      const returnCol: Array<IColumnOption> = [];
      col.forEach(opt => {
        const periodValue = PeriodsList.getDurationBySrt(opt);

        if (periodValue) {
          returnCol.push({
            label: opt,
            dates: periodValue,
          });
        }
      });
      returnOptions.push(returnCol);
    });
    return returnOptions;
  }
}
