/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-classes-per-file */
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import { getRouteOptionsAndParams } from '../ki-app/routing/ki-router';

import './ts-graph-viewer';
import { Series, TsData } from '../../defs/GraphTypes';
import {
  getEmptyValues,
  getStationMetadataById,
} from '../../api/ZarrAPIHelper';
import { ZarrGraphService } from '../../services/ZarrGraphService/zarr-graph-service';
import { ConfigLoader } from '../../services/ConfigLoader/ConfigLoader';
import { IService } from '../../services/IService';

@customElement('ww-ts-graph')
export class WwTsGraph extends LitElement {
  static styles = [
    css`
      .ts-graph {
        height: 100%;
      }
    `,
  ];

  @property({ type: String })
  customFontFamily: String | undefined;

  @property({ type: Object })
  service: IService | null = null;

  @property({ type: Object })
  paramOptions = {};

  @property({ type: String })
  stationId: string | undefined;

  @property({ type: Boolean })
  yStart0 = true;

  @property({ type: Boolean })
  yAxisBold = true;

  @property({ type: Object })
  series: Array<Series> = [];

  @property({ type: Array })
  selectedSeries: Array<string> = [];

  @property({ type: Array })
  data: Array<[number, number, string?, string?]> = [];

  @property({ type: Object })
  numberFormat: Intl.NumberFormatOptions | undefined;

  @property({ type: Object, attribute: true })
  parameterTranslations: Map<string, string> = new Map();

  configLoader: ConfigLoader;

  constructor() {
    super();
    this.configLoader = new ConfigLoader();
  }

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, [
      'stationId',
      'stationLabel',
    ]);
    this.paramOptions = params.options;
    this.stationId = params.stationId;
    if (params.options.yStart0 !== undefined) {
      this.yStart0 = params.options.yStart0;
    }
    if (params.options.yAxisBold !== undefined) {
      this.yAxisBold = params.options.yAxisBold;
    }
    if (params.options.numberFormat) {
      this.numberFormat = params.options.numberFormat;
    }

    // TODO: for now. Expect a full translation list later. Maybe loaded by i18n
    if (params.options.parameter_label) {
      const newTranslationMap = new Map();
      newTranslationMap.set(
        params.options.station_parameter,
        params.options.parameter_label,
      );

      this.parameterTranslations = newTranslationMap;
    }

    this.customFontFamily = params.options?.customFontFamily;

    this.service = new ZarrGraphService();
  }

  updated(changedProperties) {
    if (changedProperties.has('stationId')) {
      this.setTsList();
    }
  }

  async setTsList() {
    const param = this.paramOptions.station_parameter;

    if (this.stationId !== undefined && param !== undefined) {
      // const layerMeta = await getLayerMetadata();
      // const layer = layerMeta?.layers.find(l => l.alias === this.layerName);

      const color = this.configLoader.getParameterBaseColor(param);

      const stationMeta = await getStationMetadataById(this.stationId);

      if (!stationMeta) {
        return;
      }

      const timeseriesMetadata = stationMeta.parameter[param]?.timeseries;

      if (!timeseriesMetadata) {
        return;
      }

      const timeseries = Object.keys(timeseriesMetadata);

      if (timeseries.length === 0) {
        return;
      }

      const tsData = await Promise.allSettled(
        timeseries.map(async (ts: string) => getEmptyValues(param, ts)),
      );

      const selectedIds: Array<string> = [];

      const series = tsData.map(tsDat => {
        if (tsDat.status === 'fulfilled' && tsDat.value !== undefined) {
          const newSeriesId = `${tsDat.value!.param_shortname}/${
            tsDat.value!.ts_shortname
          }`;
          selectedIds.push(newSeriesId);
          // TOOD move to TSGraphHelper applyMetaData
          const tsTemplate = this.configLoader.getTsTemplates(newSeriesId);
          return {
            name:
              tsTemplate?.label ??
              `${tsDat.value!.param_shortname} - ${tsDat.value!.ts_shortname}`,
            shortName: tsDat.value!.param_shortname,
            timeseries: tsDat.value.ts_shortname,
            id: newSeriesId,
            animation: false,
            parameter: {
              name: tsDat.value!.param_name,
              shortName: tsDat.value!.param_shortname,
              id: tsDat.value!.param_shortname,
              unit: tsDat.value!.units,
            },
            from: undefined,
            until: undefined,
            station: {
              name: stationMeta.station_name,
              shortName: stationMeta.station_name,
              id: stationMeta.station_id,
              lat: stationMeta.station_latitude,
              lon: stationMeta.station_longitude,
              site: {
                name: stationMeta.site_name,
                id: stationMeta.site_no,
                wkt: undefined,
              },
            },
            data: tsDat.value!.data.map(dat => [
              dat.timestamp,
              dat.value,
            ]) as Array<TsData>,
            meta: {
              type: 'line',
              style: {
                color: tsTemplate?.style?.color ?? color,
              },
            },
          };
        }

        return undefined;
      });

      const newSeries: Array<Series> = series.filter(
        dat => dat !== undefined,
      ) as Array<Series>;

      if ((newSeries?.length ?? 0) < 1) {
        return;
      }

      this.series = newSeries;

      this.selectedSeries = selectedIds;
    }
  }

  render() {
    return html`<ts-graph-viewer
      class="ts-graph"
      .layers=${false}
      .noactions=${false}
      .tsList=${this.series}
      .service=${this.service}
      .numberFormat=${this.numberFormat}
      .selectedIds=${this.selectedSeries}
      .yStart0=${this.yStart0}
      .yAxisBold=${this.yAxisBold}
      .customFontFamily=${this.customFontFamily}
      .parameterTranslations=${this.parameterTranslations}
    ></ts-graph-viewer>`;
  }
}
