// TODO move to i18n

import { mapValues, get } from 'lodash-es/object';

export function translate(obj, i18n) {
  if (obj) {
    if (typeof obj === 'string') {
      return obj.replace(/\$i18n{([\s\S]+?)}/g, (_match, p1) => i18n.t(p1));
    }

    if (Array.isArray(obj)) {
      return obj.map(s => translate(s, i18n));
    }

    if (typeof obj === 'object') {
      return mapValues(obj, s => translate(s, i18n));
    }
  }
  return obj;
}

export function readCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function template(t, data) {
  if (typeof t === 'string') {
    return t.replace(/\${([\s\S]+?)}/g, (_match, p1) => get(data, p1));
  }
  return t;
}

export function RGBAToHex(rgba) {
  const inParts = rgba.substring(rgba.indexOf('(')).split(',');
  const r = Number(inParts[0].substring(1).trim());
  const g = Number(inParts[1].trim());
  const b = Number(inParts[2].trim());
  // const a = parseFloat(inParts[3].substring(0, inParts[3].length - 1).trim()).toFixed(2);
  const outParts = [r.toString(16), g.toString(16), b.toString(16)];

  // Pad single-digit output values
  outParts.forEach((part, i) => {
    if (part.length === 1) {
      outParts[i] = `0${part}`;
    }
  });

  return `#${outParts.join('')}`;
}

function padZero(str, len = 2) {
  const zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

export function contrastColor(colorVal, bw) {
  let hex = colorVal;
  if (colorVal.includes('rgba')) {
    hex = RGBAToHex(hex);
  }
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  }
  // invert color components
  const rStr = (255 - r).toString(16);
  const gStr = (255 - g).toString(16);
  const bStr = (255 - b).toString(16);
  // pad each with zeros and return
  return `#${padZero(rStr)}${padZero(gStr)}${padZero(bStr)}`;
}

function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    // eslint-disable-next-line no-console
    console.info(`Fallback: Copying text command was ${msg}`);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    () => {
      // eslint-disable-next-line no-console
      console.info('Async: Copying to clipboard was successful!');
    },
    err => {
      // eslint-disable-next-line no-console
      console.error('Async: Could not copy text: ', err);
    },
  );
}

export function addSwipeEvents(target, handle) {
  let touchstartX = 0;
  let touchstartY = 0;
  let touchendX = 0;
  let touchendY = 0;
  let timerstart = new Date().getTime();
  target.addEventListener(
    'touchstart',
    event => {
      timerstart = new Date().getTime();
      touchstartX = event.changedTouches[0].screenX;
      touchstartY = event.changedTouches[0].screenY;
    },
    false,
  );

  target.addEventListener(
    'touchend',
    event => {
      if (new Date().getTime() - timerstart < 250) {
        touchendX = event.changedTouches[0].screenX;
        touchendY = event.changedTouches[0].screenY;
        handle(touchstartX, touchendX, touchstartY, touchendY);
      }
    },
    false,
  );
}

export function delay(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

export function setWithExpiry(key, value, ttl, session = false) {
  if (session && !sessionStorage) {
    console.error('Session Storage not available');
    return;
  }
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value,
    expiry: now.getTime() + ttl * 1000,
  };
  const storage = session ? sessionStorage : localStorage;
  storage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key, session) {
  if (session && !sessionStorage) {
    console.error('Session Storage not available');
    return null;
  }
  const storage = session ? sessionStorage : localStorage;
  const itemStr = storage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export function deUmlaut(value) {
  /* eslint-disable no-param-reassign */
  value = value.toLowerCase();
  value = value.replace(/ä/g, 'ae');
  value = value.replace(/ö/g, 'oe');
  value = value.replace(/ü/g, 'ue');
  value = value.replace(/ß/g, 'ss');
  value = value.replace(/ /g, '-');
  value = value.replace(/\./g, '');
  value = value.replace(/,/g, '');
  value = value.replace(/\(/g, '');
  value = value.replace(/\)/g, '');
  /* eslint-enable no-param-reassign */
  return value;
}

/** Download method for csv files */
export function downloadFile(string, filename) {
  // Add BOM to the beginning of the CSV
  const bom = '\uFEFF';
  const csvWithBom = bom + string;

  const link = document.createElement('a');
  link.style.display = 'none';
  link.setAttribute('target', '_blank');
  link.setAttribute(
    'href',
    `data:text/csv;charset=utf-8,${encodeURIComponent(csvWithBom)}`,
  );
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}

export function downloadTableAsCsv(
  table,
  seperator = ',',
  filename = 'data.csv',
  header = '',
) {
  const csv: Array<string> = [];
  for (const row of table.rows) {
    const tmpCell: Array<string> = [];
    for (const cell of row.cells) {
      if (cell.colSpan === 1) {
        const field = cell.innerText
          .replace(/(\r\n|\n|\r)/gm, '')
          .replace(/(\s\s)/gm, ' ')
          .replace(/"/g, '""');
        tmpCell.push(`"${field}"`);
      }
    }
    csv.push(tmpCell.join(seperator));
  }
  downloadFile(`${header}${csv.join('\n')}`, filename);
}
