import { customElement, property } from 'lit/decorators.js';
import _AddOn from './_AddOn';
import LayersControl from './LayersControl';
import './ki-layers-control-element';

@customElement('ki-layers-control')
export default class KiLayersControl extends _AddOn {
  // TODO i18n?

  constructor() {
    super();
    this.cachePrefix = 'none';
  }

  @property({ type: Array })
  groups = [];

  @property({ type: Object })
  i18n = {};

  @property({ type: String })
  style = '';

  @property({ type: String })
  context = '';

  @property({ type: String })
  cachePrefix = '';

  @property({ type: Boolean })
  toggleAll = false;

  @property({ type: Boolean })
  treeList = false;

  _create() {
    this.controller = new LayersControl({
      toggleAll: this.toggleAll,
      treeList: this.treeList,
      i18n: this.i18n,
      groups: this.groups,
      style: this.style,
      context: this.context,
      cachePrefix: this.cachePrefix,
    });
    this.map.addControl(this.controller);
  }

  _tear() {
    this.map.removeControl(this.controller);
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);
    // // TODO how to update. for simple component can just recreate.
    if (_changedProperties.size > 0 && this.map) {
      this._tear();
      this._create();
    }
  }
}
