/* eslint-disable lit-a11y/click-events-have-key-events */
/* eslint-disable max-classes-per-file */
import { html, css, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '..'; /** TODO: WTF?  */
import { getLastParamString } from '../ki-app';
import { PropertyDefaultValue, Mix } from '../../common';
import { responsiveMixin } from '../../decorators';
import { NavItem } from './NavItem';

@customElement('ki-menu')
export default class KiMenu extends Mix(
  LitElement,
  PropertyDefaultValue,
  responsiveMixin,
) {
  static styles = css`
    :host {
      display: flex;
      height: 100%;
      overflow: auto;
    }

    :host(.vertical) {
      flex-direction: column;
    }

    .menu-item {
      display: flex;
      flex-direction: row;
      padding: 10px 5px;
      background: white;
      fill: var(--theme-text-menu-color, --theme-text-color);
      color: var(--theme-text-menu-color, --theme-text-color);
      background: var(
        --theme-background-menu-color,
        var(--theme-color-primary, #1d1d1b)
      );
      border: 1px solid
        var(--theme-background-menu-color, var(--theme-color-primary, #1d1d1b));
      align-items: center;
      flex: 1;
      position: relative;
      justify-content: start;
      transition: all 0.3s ease;
      min-width: 0%;
      max-height: 100px;
      max-width: 320px;
    }

    :host(.sm-screen) .menu-item {
      max-width: initial;
    }

    .menu-item.selected {
      background: var(--theme-color-secondary, #1d1d1b);
      color: var(
        --theme-text-menu-color-selected,
        --theme-text-menu-color,
        --theme-color-primary,
        white
      );
      fill: var(
        --theme-text-menu-color-selected,
        --theme-text-menu-color,
        --theme-color-primary,
        white
      );
    }
    .menu-item:hover,
    .menu-item:focus {
      cursor: pointer;
      background: var(--theme-color-secondary, #1d1d1b);
      color: var(--theme-text-menu-color-selected, white);
    }
    .menu-icon {
      min-width: 30px;
      text-align: center;
    }

    .hidden {
      display: none;
    }

    .menu-label {
      padding: 0px 5px 0px 5px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .expand-icon {
      margin-left: auto;
      margin-right: 5px;
    }
    .expand-icon.hidden {
      visibility: hidden;
    }

    .sub-menu-wrapper {
      display: flex;
      flex-direction: column;
    }

    .sub-menu-wrapper.horizontal {
      position: absolute;
    }
    .sub-menu-item {
      display: flex;
      flex-direction: row;
      padding: 10px;
      fill: var(--theme-text-menu-color, --theme-text-color);
      background: var(
        --theme-background-menu-color,
        var(--theme-color-primary, #1d1d1b)
      );
      color: var(--theme-text-menu-color, --theme-text-color);
      align-items: center;
      padding-left: 20px;
      transition: all 0.3s ease;
    }
    .sub-menu-item:hover,
    .sub-menu-item.focused,
    .sub-menu-item:focus {
      cursor: pointer;
      background: var(--theme-color-secondary, #1d1d1b);
      color: var(--theme-color-primary, white);
    }
  `;

  connectedCallback() {
    if (super.connectedCallback) super.connectedCallback();
    // Close menu on clicking in the application
    window.addEventListener('click', () => this.deselectItem());
  }

  disconnectedCallback() {
    if (super.disconnectedCallback) super.disconnectedCallback();
    window.removeEventListener('click', () => this.deselectItem());
  }

  deselectItem() {
    this.value = '';
    this.requestUpdate();
  }

  @property({ type: Array })
  items: NavItem[] = [];

  @property({ type: String })
  value: string = '';

  @property({ type: String })
  direction: 'vertical' | 'horizontal' = 'vertical';

  /**
   * Determines if a menu item should be displayed as selected
   * @remark One item (e.g. parameter) should be always selected, therefor perform matching with route
   */
  private isSelected(item: NavItem): boolean {
    return this.value
      ? item === this.value
      : item.link?.endsWith(getLastParamString());
  }

  render() {
    // eslint-disable-next-line wc/no-self-class
    this.classList.toggle('vertical', this.direction === 'vertical');
    return html`${this.items.map((item, index) => {
      const renderSubMenu = this.value === item;
      const hasSubMenu = item.items?.length > 0;
      return html`<div
          class="menu-item ${this.isSelected(item) ? 'selected' : ''}"
          part="menu-item ${this.isSelected(item) ? 'selected' : ''}"
          @click="${this.handleClick}"
          .value="${item}"
          id="menu-item-${index}"
        >
          <div class="menu-icon ${item.icon ? '' : 'hidden'}">
            <ki-icon part="menu-icon" icon="${item.icon}"></ki-icon>
          </div>
          <div part="menu-label" class="menu-label">${item.label}</div>
          <div class="expand-icon ${hasSubMenu ? '' : 'hidden'}">
            <ki-icon
              part="expand-icon"
              class="icons"
              icon="ki ${this.value && this.value === item
                ? 'ki-chevron-up'
                : 'ki-chevron-down'}"
            ></ki-icon>
          </div>
        </div>
        ${renderSubMenu ? this._renderSubMenu() : ''} `;
    })}`;
  }

  _renderSubMenu() {
    // 如果menuitem position relative， 那么absolute的子菜单无法显示。所以使用如下计算
    const currentMenuItem = this.renderRoot.getElementById(
      `menu-item-${this.items.indexOf(this.value)}`,
    );
    const { width, height, top, left } =
      currentMenuItem.getBoundingClientRect(); // sub menu left position
    return html`<div
      class="sub-menu-wrapper ${this.direction === 'vertical'
        ? ''
        : 'horizontal'}"
      style="min-width:${width}px;width:auto;left:${left}px;top:${top +
      height}px"
    >
      ${this.value?.items?.map(
        subItem =>
          html`<div
            part="sub-menu-item"
            class="sub-menu-item"
            @click="${this.handleClick}"
            .value="${subItem}"
          >
            <div class="menu-icon ${subItem.icon ? '' : 'hidden'}">
              <ki-icon part="menu-icon" icon="${subItem.icon}"></ki-icon>
            </div>
            <div part="menu-label" class="menu-label">${subItem.label}</div>
          </div>`,
      )}
    </div>`;
  }

  handleClick(event) {
    event.stopPropagation();
    if (
      event.currentTarget.value.link &&
      event.currentTarget.value.link.includes('://')
    ) {
      window.open(event.currentTarget.value.link);
    } else if (event.currentTarget.value?.items) {
      if (this.value === event.currentTarget.value) {
        this.value = null;
      } else {
        this.value = event.currentTarget.value;
      }
      this.requestUpdate();
    } else {
      this.value = event.currentTarget.value;

      // https://lit-element.polymer-project.org/guide/events#working-with-events-and-shadow-dom
      // Bubbles and Composed must be true to buble through shadow DOM
      const selectedEvent = new CustomEvent('selected', {
        detail: {
          value: event.currentTarget.value.link,
        },
        bubbles: true,
        composed: true,
      });
      this.dispatchEvent(selectedEvent);
    }
  }

  updated() {
    const b = this.renderRoot.querySelector('.sub-menu-wrapper');
    if (b) {
      b.scrollIntoView();
    }
  }
}
