import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { last, merge, find, orderBy } from 'lodash-es';
import './ki-wwp-switcher-button';
import './ki-wwp-ts-table.js';

import Highcharts from 'highcharts/highstock';
import exporting from 'highcharts/modules/exporting';
import patternFill from 'highcharts/modules/pattern-fill';
import offlineexporting from 'highcharts/modules/offline-exporting';
import nodata from 'highcharts/modules/no-data-to-display';
import {
  getRouteOptionsAndParams,
  getCsvDelimiter,
  getConfig,
} from '../ki-app';
import {
  LoaderMixin,
  template,
  copyTextToClipboard,
  Mix,
  dayjs,
  formatNumber,
  hclocales,
  DataTransformation,
} from '../../common';
import {
  i18nMixin,
  responsiveMixin,
  ViewPort,
  SM,
  queryParamMixin,
  localStorageMixin,
} from '../../decorators';

import { getCurrentApi } from '../../api';

import nls from '../../locales/index';
import style from './ki-wwp-graph-precip.css?inline';

import '@ui5/webcomponents/dist/Button';
import '@ui5/webcomponents/dist/Select';
import '@ui5/webcomponents/dist/Option';
import { PopupUtil } from './ki-wwp-graph-helper';
import { AppConfigDefinition } from '../../defs/AppConfigDefinition';

exporting(Highcharts);
patternFill(Highcharts);
offlineexporting(Highcharts);
nodata(Highcharts);

@customElement('ki-wwp-graph-precip')
export default class KiWwpGraphPrecip extends Mix(
  LitElement,
  LoaderMixin,
  responsiveMixin,
  [i18nMixin, { nls }],
  [
    localStorageMixin,
    { targetProperty: '__favouriteStationIds', typeParam: Array },
  ],
  [
    queryParamMixin,
    {
      targetProperty: '_selectedPeriod',
      selector: 'period',
      defaultValue: '',
    },
  ],
) {
  highcharts = Highcharts;

  config: AppConfigDefinition = getConfig();

  timeZone: string = this.config.timeZone || dayjs.tz.guess();

  miniGraph = false;

  imageShareAutoOpen = false;

  filterAccuracy = 66;

  ignoreAccuracy = false;

  sharedTooltip = true;

  zoomType = 'x';

  // eslint-disable-next-line class-methods-use-this
  get localStorageId() {
    return `wwp-favorites-ids`;
  }

  ___favouriteStationIds = [];

  get __favouriteStationIds() {
    return this.___favouriteStationIds;
  }

  set __favouriteStationIds(value) {
    this.___favouriteStationIds = value;
    this.requestUpdate();
  }

  __selectedPeriod;

  get _selectedPeriod() {
    return this.__selectedPeriod;
  }

  set _selectedPeriod(value) {
    this.__selectedPeriod = value;
    this.requestUpdate();
  }

  favButton = false;

  static styles = style;

  firstUpdated() {
    if (!this.miniGraph) {
      this.refreshInterval = setInterval(() => {
        this.fetchData();
      }, 300000);
    }
    Object.assign(this, this.options);
    if (
      this.renderRoot.host.classList.contains('sm-screen') ||
      this.miniGraph
    ) {
      this.tableOpen = false;
    }
    this.api = getCurrentApi();
    this.fetchData();
  }

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, [
      'layerName',
      'stationId',
      'stationLabel',
    ]);

    Object.assign(this, JSON.parse(JSON.stringify(params.options)));
    this.layerName = params.layerName;
    this.apppath = location.route;
    this.stationId = params.stationId;
  }

  isFavorite() {
    return this.__favouriteStationIds.indexOf(this.station?.station_id) >= 0;
  }

  _toggleFavourite() {
    if (this.__favouriteStationIds.indexOf(this.station.station_id) === -1) {
      this.__favouriteStationIds = [
        ...this.__favouriteStationIds,
        this.station.station_id,
      ];
    } else {
      this.__favouriteStationIds = this.__favouriteStationIds.filter(
        id => id !== this.station.station_id,
      );
    }
    const e = new CustomEvent('favToggled', { bubbles: true, composed: true });
    this.dispatchEvent(e);
  }

  getImageShare() {
    return this.api
      ? `${window.location.origin}${window.location.pathname.replace(
          /[^/]*$/,
          '',
        )}graph.html?stationId=${
          this.station.station_id
        }&component=ki-wwp-graph-precip-ext&key=${encodeURIComponent(
          this.apppath?.path,
        )}&period=${this._selectedPeriod}`
      : '';
  }

  connectedCallback() {
    if (super.connectedCallback) super.connectedCallback();

    let resize;
    window.addEventListener('resize', () => {
      window.clearTimeout(resize);
      resize = setTimeout(() => {
        this.chart && this.chart.reflow();
      }, 220);
    });
  }

  get tablecolumns() {
    return [
      {
        field: 'timestamp',
        label: this.timestampLabel || this.i18n.t('timestamp'),
        sortable: true,
        hideAble: false,
      },
      {
        field: 'value',
        label: this.yAxisLabel || this.i18n.t('precipmm'),
        sortable: false,
      },
    ];
  }

  createChart() {
    if (hclocales[this.i18n.language.substring(0, 2)]) {
      this.highcharts.setOptions({
        lang: hclocales[this.i18n.language.substring(0, 2)],
      });
    }
    this.chart = this.highcharts.chart(
      this.renderRoot.querySelector('#chart-node'),
      this.defaultOptions(),
      this.chartCreated.bind(this),
    );
  }

  chartCreated(chart) {
    const e = new CustomEvent('load', {
      detail: chart,
    });
    this.dispatchEvent(e);
  }

  updated(changedProperties) {
    if (changedProperties.get('stationId')) {
      this.station = null;
      this.fetchData();
    }
    if (this.chart) {
      this.chart.reflow();
    }
  }

  disconnectedCallback() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }

  static get properties() {
    return {
      favButton: { type: Boolean },
      miniGraph: { type: Boolean },
      stationId: { type: String },
      options: { type: Object },
    };
  }

  drawSeries(period, min, max) {
    const cfg = find(this.ranges, { value: period });
    let _min = min;
    let _max = max;
    if (cfg.yearSelector) {
      _min = _min || dayjs().startOf('year').valueOf();
      _max = _max || dayjs(this.coverage.max).valueOf();
      this.renderRoot
        .querySelector('#yearselector')
        .classList.remove('hideButton');
    } else {
      this.renderRoot
        .querySelector('#yearselector')
        .classList.add('hideButton');
    }
    while (this.chart.series.length > 0) {
      this.chart.series[0].remove(false);
    }

    if (this.files[0][cfg.data]) {
      if (period === 'PoR') {
        const fileData = this.files[0][cfg.data][0]
          ? this.files[0][cfg.data][0]
          : this.files[0][cfg.data];
        _min = dayjs(fileData.data[0][0]).valueOf();
        _max = dayjs(last(fileData.data)[0]).valueOf();
      }
      const minDate =
        _min || dayjs(_max).subtract(dayjs.duration(period)).valueOf();
      const maxDate = _max || dayjs().valueOf();
      const xAxis = this.chart.get('mainXAxis');
      this.chart.zoomOut();

      /*  // Workaround for year view (avoid plotting of empty next month)
      if (cfg.value === 'P1Y')
        xAxis.setExtremes(
          minDate,
          max || dayjs().startOf('month'),
          false,
          false,
        ); 
      else */

      xAxis.setExtremes(minDate, maxDate, false, false);

      if (cfg.yAxisLabel) {
        this.yAxisLabel = cfg.yAxisLabel;
        this.chart.get('mainYAxis').setTitle({ text: cfg.yAxisLabel }, false);
      }

      this.chart.get('sumYAxis').update({ visible: !cfg.sumOnMainAxis }, false);

      const timeformat = cfg.dateFormat || '%d.%m.%Y %H:%M';
      let ts = find(this.files[0][cfg.data], { ts_shortname: cfg.ts });
      ts =
        ts || this.files[0][cfg.data][0]
          ? this.files[0][cfg.data][0]
          : this.files[0][cfg.data];

      if (
        this.filterAccuracy &&
        ts.columns.indexOf('Aggregation Accuracy %') > -1
      ) {
        const { columns } = ts;
        const valueIndex = columns.indexOf('Value');
        const accIndex = columns.indexOf('Aggregation Accuracy %');
        ts.data.forEach(item => {
          if (item[accIndex] < this.filterAccuracy) {
            item[valueIndex] = null;
          }
        });
      }
      const tsdata = this._formatGraphData(ts.data, minDate, maxDate);
      const accData = tsdata;
      const tableData = orderBy(tsdata, 'x', ['desc']).map(item => ({
        timestamp: dayjs(item.x)
          .tz()
          .format(cfg.dateFormatTable || 'L LT'),
        value: formatNumber(item.y, this.numberFormat || {}),
      }));
      const table = this.shadowRoot.getElementById('dataTable');
      table.columns = this.tablecolumns;
      // eslint-disable-next-line no-multi-assign
      this.orderedTableData = table.data = tableData;
      table.data = this.orderedTableData;

      // Show Accuracy in tooltip
      if (this.filterAccuracy) {
        this._addAccuracy(accData, timeformat);
      }

      // Add bars
      this.chart.addSeries(
        {
          data: tsdata,
          name: cfg.tslabel || this.parameter_label,
          id: 'data',
          color: '#0056a0',
          selected: true,
          marker: { enabled: false },
          tooltip: merge(
            {
              valueDecimals: 1,
              valueSuffix: ` ${ts.ts_unitsymbol}`,
              xDateFormat: timeformat,
            },
            this.tooltipBar,
          ),
        },
        false,
      );

      // Add sum line
      if (!cfg.hideSumLine) {
        this.chart.addSeries(
          {
            data: this._formatGraphDataSum(
              ts.data,
              minDate,
              maxDate,
              cfg.resetSumAt,
            ),
            name: cfg.sumLabel || this.sumLabel || this.i18n.t('Sum line'),
            yAxis: cfg.sumOnMainAxis ? 'mainYAxis' : 'sumYAxis',
            type: 'line',
            step: 'left',
            marker: { enabled: false },
            color: 'rgb(170,70,67)',
            selected: true,
            tooltip: merge(
              {
                valueDecimals: 1,
                valueSuffix: ` ${ts.ts_unitsymbol}`,
                xDateFormat: timeformat,
              },
              this.tooltipSum,
            ),
          },
          false,
        );
      }
      this.chart.redraw();
    }
  }

  getRangeOptions() {
    return this.ranges || [];
  }

  render() {
    const title =
      this.graphTitle && this.station
        ? template(this.graphTitle, this.station)
        : '';
    return html` <div id="nav-node" class="${this.miniGraph ? 'hidden' : ''}">
        <div class="label">${title}</div>
        <ki-wwp-switcher-button
          @changed="${e => {
            this.drawSeries(e.detail.value);
            this._selectedPeriod = e.detail.value;
          }}"
          .options="${this.getRangeOptions()}"
          .value=${this._selectedPeriod}
          class="buttons"
        >
        </ki-wwp-switcher-button>
        <div class="actions">
          <ki-icon-btn
            class="ripple clipboard-btn ${this.imageShare ? '' : 'hideButton'}"
            title="${this.i18n.t('shareLink')}"
            icon="ki ki-share"
            ,
            id="link-btn"
            @click=${() => {
              const popup = this.renderRoot.querySelector('#download-popup');
              this.renderRoot.querySelector('#embeddedgraph').value =
                `<iframe src="${this.getImageShare()}" style="width:80%;height:700px;border:0;"></iframe>`;
              if (this.imageShareAutoOpen) {
                window.open(this.getImageShare(), '_blank');
              }
              popup.visible ? popup.hide({}) : popup.show({});
            }}
          ></ki-icon-btn>
          <ki-icon-btn
            class="fav-btn ripple ${this.favButton ? '' : 'hidden'}"
            @click="${this._toggleFavourite}"
            title="${this.isFavorite()
              ? this.i18n.t('removeFav')
              : this.i18n.t('addFav')}"
            icon="ki ${this.isFavorite() ? 'ki-star' : 'ki-star-o'}"
          ></ki-icon-btn>
          <ki-popup
            for="link-btn"
            style="margin-right: 20px;"
            top="40px"
            left="-100"
            id="download-popup"
          >
            <div
              style="padding: 10px;font-size: 1.2em;border-bottom: 1px solid gray;display:flex;justify-content:space-between;align-items: center;"
            >
              ${this.i18n.t('embedGraph')}<ki-icon-btn
                title="schließen"
                @click="${() => {
                  this.renderRoot.querySelector('#download-popup').hide({});
                }}"
                style="margin:0;height: 20px;width: 20px;line-height: 20px;"
                icon="ki ki-times"
              ></ki-icon-btn>
            </div>
            <textarea
              disabled
              id="embeddedgraph"
              style="border: none;outline: none;resize:none;padding:10px;background:white;width: 90%;"
              rows="12"
            ></textarea>
            <ui5-button
              @click="${() => {
                window.open(this.getImageShare(), '_blank');
              }}"
              title="${this.i18n.t('previewTitle')}"
              >${this.i18n.t('preview')}</ui5-button
            >
            <ui5-button
              @click="${() => {
                copyTextToClipboard(
                  this.renderRoot.querySelector('#embeddedgraph').value,
                );
              }}"
              title="${this.i18n.t('copyToClipboard')}"
              >${this.i18n.t('copy')}</ui5-button
            >
          </ki-popup>
          <ki-icon-btn
            class="ripple"
            id="graph-download-button"
            title="${this.i18n.t('downloadGraph')}"
            icon="ki ki-download"
            @click="${this.downloadGraph}"
          ></ki-icon-btn>
          <ki-icon-btn
            class="ripple clipboard-btn ${this.tableOpen ? '' : 'hideButton'}"
            id="table-clipboard-btn"
            tooltip="${this.i18n.t('copyTableMessage')}"
            ,
            title="${this.i18n.t('copyTableTitle')}"
            icon="ki ki-clipboard"
            .value=${this.orderedTableData}
            @click=${() => {
              copyTextToClipboard(
                this.renderRoot
                  .querySelector('#dataTable')
                  .toCSV(getCsvDelimiter())
                  .replace(/(<([^>]+)>)/gi, ''),
              );
            }}
          ></ki-icon-btn>
          <ki-icon-btn
            toggle
            title="${this.i18n.t('tableTitle')}"
            icon="ki ki-table"
            .selected="${this.tableOpen}"
            @click="${this.showTable}"
          ></ki-icon-btn>
        </div>
      </div>
      <div class="contentnode">
        <div id="chart-wrapper">
          <ui5-select
            class="hideButton"
            id="yearselector"
            @change="${this._handleYearChange}"
          >
            ${this._years.map(
              year =>
                html`<ui5-option
                  ?selected=${year.value === 'YTD'}
                  value="${year.value}"
                  >${year.label}</ui5-option
                >`,
            )}
          </ui5-select>
          <div id="chart-node"></div>
        </div>
        <div id="table-wrapper" class="${this.tableOpen ? '' : 'hidden'}">
          <ki-wwp-ts-table
            id="dataTable"
            .columns="${this.tablecolumns}"
            sort='[{"field":"timestamp", "ascending":false}]'
            idproperty="timestamp"
          >
          </ki-wwp-ts-table>
        </div>
      </div>`;
  }

  // eslint-disable-next-line class-methods-use-this

  get _years() {
    if (this.coverage) {
      let max = dayjs(this.coverage.max).tz().get('year');
      const min = dayjs(this.coverage.min).tz().get('year');
      const years = [{ value: 'YTD', label: this.i18n.t('currentYear') }];
      while (max >= min) {
        years.push({ value: max, label: max.toString() });
        max -= 1;
      }
      return years;
    }
    return [{ value: 'YTD', label: this.i18n.t('currentYear') }];
  }

  _handleYearChange(e) {
    const { value } = e.target.selectedOption;
    const min =
      value && value !== 'YTD'
        ? dayjs(value, 'YYYY').tz().startOf('year')
        : dayjs().tz().startOf('year');
    const max =
      value && value !== 'YTD'
        ? dayjs(value, 'YYYY').tz().endOf('year')
        : dayjs().tz();
    this.drawSeries('P1Y', min.valueOf(), max.valueOf());
  }

  showTable() {
    this.renderRoot.querySelector('#table-wrapper').classList.toggle('hidden');
    this.renderRoot
      .querySelector('#table-clipboard-btn')
      .classList.toggle('hideButton');
    this.tableOpen = !this.tableOpen;
    this.chart && this.chart.reflow();
  }

  downloadGraph() {
    this.chart.update(
      {
        exporting: {
          chartOptions: {
            title: {
              text: this.graphTitle
                ? template(this.graphTitle, this.station)
                : '',
            },
            subtitle: {
              text: `${this.i18n.t('station_no')}: ${
                this.station.station_no
              } | ${this.i18n.t('request_at')} ${dayjs()
                .tz()
                .format('LLLL')} <br>
            ${dayjs(this.chart.xAxis[0].min).format('L')} - ${dayjs(
              this.chart.xAxis[0].max,
            )
              .tz()
              .format('L')}`,
            },
          },
          filename: `${this.parameter_label}_${this.station.station_name}_${
            this.station.station_no
          }_${dayjs().tz().format('YYYYMMDD')}`,
          fallbackToExportServer:
            this.config.allowHighchartsExportServer ?? false,
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
          sourceWidth: 1600,
          sourceHeight: 800,
          scale: 1,
        },
      },
      false,
    );
    this.chart.exportChartLocal();
  }

  async fetchData() {
    this.chart?.destroy();
    this.chart = null;
    const _files = {};
    this.station = this.station || (await this.api.getStation(this.stationId));
    await Promise.all(
      this.station.timeseries
        .filter(
          ts =>
            ts.station_parameter === this.station_parameter &&
            (!this.onlyFetchSpecifiedRanges ||
              PopupUtil.fileMatchesRange(ts.href, this.ranges, this.alarms)),
        )
        .map(async ts => {
          _files[last(ts.href.split('/'))] = await this.api.getTsData(ts.href);
        }),
    );
    this.files = DataTransformation.normalizeFiles(_files);
    const yearcfg = find(this.ranges, { value: 'P1Y' });
    if (yearcfg && this.files[0][yearcfg.data]) {
      const tsdata = this.files[0][yearcfg.data][0]
        ? this.files[0][yearcfg.data][0].data
        : this.files[0][yearcfg.data].data;
      if (tsdata && tsdata[0]) {
        this.coverage = {
          max: last(tsdata)[0],
          min: tsdata[0][0],
        };
      }
    }
    if (this.station) {
      this.createChart();
      let selected =
        find(this.ranges, { layerAlias: this.layerName }) ??
        find(this.ranges, { selected: true });
      selected =
        this._selectedPeriod || selected?.value || this.ranges[0].value;

      if (!find(this.ranges, { period: selected })) {
        selected = this.ranges[0].value;
      }

      if (!selected) {
        selected = this.ranges[0].value;
      }
      this._selectedPeriod = selected;
      this.drawSeries(selected);

      this.requestUpdate();
    }
    return this.files;
  }

  // eslint-disable-next-line class-methods-use-this
  _formatGraphData(data, minDate, maxDate) {
    const tsData = [];
    data.forEach(dp => {
      const ts = new Date(dp[0]).getTime();
      if (ts > minDate && ts < maxDate) {
        const _p = {
          x: ts,
          y: dp[1],
          color: '#0056a0',
          acc: undefined,
        };

        if (!this.ignoreAccuracy) {
          _p.acc = dp[2] ? Math.floor(dp[2]) : undefined;
          if (_p.acc < 100) {
            _p.color = {
              pattern: {
                path: 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2',
                color: '#0056a0',
                width: 4,
                height: 4,
              },
            };
            _p.borderColor = '#0056a0';
          }
        }
        tsData.push(_p);
      }
    });
    return tsData;
  }

  // eslint-disable-next-line class-methods-use-this
  _formatGraphDataSum(data, minDate, maxDate, resetSumAt) {
    let sum = 0;
    const tsData = [];
    data.forEach(dp => {
      const ts = dayjs(dp[0]);
      const tsms = ts.valueOf();
      if (tsms >= minDate && tsms <= maxDate) {
        if (resetSumAt && resetSumAt === ts.format('HH:mm')) {
          sum = 0;
        }
        tsData.push([tsms, dp[1] === null ? null : (sum += dp[1])]);
      }
    });
    return tsData;
  }

  createGraphOptions() {
    const self = this;
    const graphOptions = {
      chart: {
        animation: false,
        type: 'column',
        pinchType: undefined,
        zoomType: this.zoomType,
        panning: {
          enabled: false,
        },
      },

      series: [],
      plotOptions: {
        column: {
          groupPadding: 0,
          dataLabels: {
            enabled: true,
            formatter() {
              // Show data coverage
              const color = this.point.acc < 100 ? 'red' : 'rgba(29,29,29)';
              const val =
                // eslint-disable-next-line no-nested-ternary
                this.point.acc === 0.01
                  ? 'k.a.'
                  : this.point.acc
                    ? `${this.point.acc}%`
                    : '';
              return self.showAccuracy
                ? `<tspan style="color:${color}">${val}</tspan>`
                : '';
            },
          },
        },
        series: {
          animation: false,
          showCheckbox: ViewPort.size !== SM && !this.miniGraph,
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      yAxis: [
        merge(
          {
            softMax: 2,
            min: 0,
            allowDecimals: false,
            id: 'mainYAxis',
            minorTicks: !this.miniGraph,
            tickAmount: 8,
            title: {
              text: this.yAxisLabel || `${this.i18n.t('precip')} [mm]`,
            },
          },
          this.yAxisBar,
        ),
        merge(
          {
            softMax: 2,
            min: 0,
            allowDecimals: false,
            id: 'sumYAxis',
            minorTicks: !this.miniGraph,
            tickAmount: 8,
            opposite: true,
            title: {
              text: this.yAxisLabelSum || `${this.i18n.t('Sum line')} [mm]`,
            },
          },
          this.yAxisSum,
        ),
        {
          id: 'accAxis',
          labels: {
            enabled: false,
          },
          title: {
            enabled: false,
          },
          gridLineWidth: 0,
        },
      ],
      xAxis: [
        {
          id: 'mainXAxis',
          type: 'datetime',
          ordinal: false,
          labels: {
            formatter() {
              return this.dateTimeLabelFormat === '%e. %b' &&
                this.tickPositionInfo.unitName === 'hour'
                ? `<b>${this.axis.defaultLabelFormatter.call(this)}</b>`
                : this.axis.defaultLabelFormatter.call(this);
            },
          },
          max: new Date().getTime(),
        },
        {
          visible: false,
          id: 'alarmAxis',
        },
      ],
    };
    return graphOptions;
  }

  _addAccuracy(tsdata, timeformat) {
    // Transform Accuracy to Value
    const _data: Array<object> = [];
    tsdata.forEach(d => {
      const newPoint = {};
      newPoint.x = d.x;
      newPoint.y = d.acc;
      if (d.acc) _data.push(newPoint); // Catch periods without accuracy
    });

    // Add Accuracy as series to show accuracy in tooltip
    // Hide series in graph and legend
    if (_data.length > 0)
      this.chart.addSeries(
        {
          data: _data,
          yAxis: 'accAxis',
          name: this.i18n.t('accuracy'),
          id: 'acc',
          color: 'black',
          selected: true,
          marker: { enabled: false },
          tooltip: {
            valueDecimals: 0,
            valueSuffix: `%`,
            xDateFormat: timeformat,
          },
          showInLegend: false,
          opacity: 0,
          grouping: false,
        },
        false,
      );
  }

  get creditOptions() {
    const credits = this.credits ? { ...this.credits } : { enabled: false };
    if (credits?.text) {
      credits.text = template(credits.text, this.station);
    }
    if (this.credits && ViewPort.size === SM) {
      credits.position = credits.positionMobile || credits.position;
    }
    credits.enabled = !this.miniGraph;
    return credits;
  }

  defaultOptions() {
    const conf = merge(
      {
        title: {
          text: '',
        },
        subtitle: {
          text: this.subTitle || '',
          floating: true,
        },
        credits: this.creditOptions,
        exporting: {
          chartOptions: {
            title: {
              text: this.graphTitle
                ? template(this.graphTitle, this.station)
                : '',
            },
            subtitle: {
              text: `${this.i18n.t('station_no')}: ${
                this.station.station_no
              } | ${this.i18n.t('request_at')} ${dayjs().tz().format('LLLL')}`,
            },
          },
          filename: `${this.parameter_label}_${this.station.station_name}_${
            this.station.station_no
          }_${dayjs().tz().format('YYYYMMDD_kkmmss')}`,
          fallbackToExportServer:
            this.config.allowHighchartsExportServer ?? false,
          sourceWidth: 1600,
          sourceHeight: 800,
          scale: 1,
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
        },
        plotOptions: {
          series: {
            label: {
              enabled: false,
            },
            // Should enable to set from the config so that Highcharts render all points to avoid missing bars in large datasets.
            turboThreshold:
              this.turboThreshold !== undefined ? this.turboThreshold : 2000,
            showCheckbox: true,
            events: {
              checkboxClick(event) {
                if (event.checked) {
                  this.show();
                  // this.legendSymbol.hide();
                } else {
                  this.hide();
                  //  this.legendSymbol.hide();
                }
              },
              legendItemClick() {
                if (this.checkbox) {
                  this.checkbox.checked = !this.visible;
                }
              },
              hide() {
                this.yAxis.update({
                  visible: false,
                });
              },
              show() {
                this.yAxis.update({
                  visible: true,
                });
              },
            },
          },
        },
        legend: {
          enabled: this.forceLegend || !this.miniGraph,
          align: 'left',
          symbolPadding: 5,
          symbolRadius: 0,
          itemDistance: 40,
          squareSymbol: true,
          itemCheckboxStyle: {
            marginLeft: '-30px',
          },
        },
        tooltip: {
          shared: this.sharedTooltip,
          split: false,
        },
        time: {
          timezone: this.timeZone,
        },
        yAxis: {
          id: 'mainYAxis',
          minRange: 3,
          title: {
            text: this.yAxisLabel || this.i18n.t('precipmm'),
          },
        },
        series: [],
      },
      this.createGraphOptions(),
    );
    return conf;
  }
}
