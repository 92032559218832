// eslint-disable-next-line max-classes-per-file
import Control from 'ol/control/Control';
import DragPan from 'ol/interaction/DragPan';
import Select from 'ol/interaction/Select';
import { always } from 'ol/events/condition';
import DragBox from 'ol/interaction/DragBox';
import '../ki-switcher/ki-icon-switcher';

export default class MultiSelectionToggle extends Control {
  constructor(layers, options) {
    const element = document.createElement('ki-icon-switcher');
    element.options = options.options;
    element.value = 'mover';
    options.element = element;
    super(options);
    this.layers = layers;
    this.idProperty = options.idProperty;
    this.onSelection = options.onSelection;

    this.drawInteraction = new DragBox({
      className: 'dragbox',
      condition: always,
    });
  }

  changedEvent: any;

  setMap(map) {
    this.getMap()?.removeInteraction(this.drawInteraction);
    if (this.changedEvent) {
      this.element.removeEventListener('changed', this.changedEvent);
      this.changedEvent = null;
    }

    super.setMap(map);
    if (map) {
      this._initDrawingLayer();
      this.changedEvent = e => {
        this.switchMode(e.detail.value);
      };
      this.element.addEventListener('changed', this.changedEvent);
      this.switchMode(this.element.value);
    }
  }

  /*
   * @param {('mover','selector'))} mode
   * */
  switchMode(mode) {
    const dragpan = this.getMap()
      .getInteractions()
      .getArray()
      .filter(it => it instanceof DragPan)[0];
    dragpan.setActive(mode === 'mover');
    this.drawInteraction.setActive(mode === 'selector');
  }

  _initDrawingLayer() {
    this.__selectionInteraction = new (class extends Select {})();

    this.__selectionInteraction.setMap(this.getMap());
    const selectedFeatures = this.__selectionInteraction.getFeatures();

    this.getMap()?.addInteraction(this.drawInteraction);

    this.listenerKeys.push(
      this.drawInteraction.on('boxend', event => {
        selectedFeatures.clear();
        const selectionGeo = event.target.getGeometry();
        selectedFeatures.extend(this._getSelectFeatures(selectionGeo));
        this._fireSelection(selectedFeatures);
      }),
    );

    // TODO deselect?
  }

  _fireSelection(selectedFeatures) {
    this.onSelection(
      selectedFeatures.getArray().map(f => f.station[this.idProperty]),
    );
  }

  _getSelectFeatures(selectionGeo) {
    // TODO
    const { layers } = this;
    const features = [];

    layers.forEach(layer => {
      if (layer.getVisible()) {
        layer
          .getSource()
          ?.getFeatures?.()
          .forEach(feature => {
            if (
              selectionGeo.intersectsExtent(
                feature?.getGeometry?.()?.getExtent(),
              )
            ) {
              !feature.hide && features.push(feature);
            }
          });
      }
    });
    return features;
  }

  clearSelection() {
    // TODO
    this.__selectionInteraction.getFeatures().clear();
    this.__selectionInteraction.getFeatures().forEach(f => {
      this.__selectionInteraction.removeFeature_(f);
    });
  }

  set selection(val) {
    const list = this.__selectionInteraction.getFeatures();
    list.clear();
    this.layers.forEach(layer => {
      if (layer.getVisible()) {
        const features = layer
          .getSource()
          ?.getFeatures?.()
          .filter(feature => {
            const { station } = feature;
            return station && val.some(s => s === station[this.idProperty]);
          });
        list.extend(features);
      }
    });
  }
}
