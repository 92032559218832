import { JsonAPI } from './JsonAPI';
import { ZarrAPI } from './ZarrAPI';
import { ParquetAPI } from './ParquetAPI';

let currentApi: JsonAPI | ZarrAPI | ParquetAPI;

export const initAPI = (args: {
  basePath: any;
  dataPath: any;
  configSubPath: any;
  reportsPath: any;
  staticConfigPath: any;
  apiType: string;
}) => {
  if (!currentApi) {
    if (args.apiType === 'parquet') {
      currentApi = new ParquetAPI(args);
    } else if (args.apiType === 'zarr') {
      currentApi = new ZarrAPI(args);
    } else {
      currentApi = new JsonAPI(args);
    }
  }
  return currentApi;
};

export const initJsonApi = (args: {
  basePath: any;
  dataPath: any;
  configSubPath: any;
  reportsPath: any;
  staticConfigPath: any;
}) => {
  if (!currentApi) {
    currentApi = new JsonAPI(args);
  }
  return currentApi;
};

export const initZarrAPI = (args: {
  basePath: any;
  dataPath: any;
  configSubPath: any;
  reportsPath: any;
  staticConfigPath: any;
}) => {
  if (!currentApi) {
    currentApi = new ZarrAPI(args);
  }
  return currentApi;
};

export const initParquetApi = (args: {
  basePath: any;
  dataPath: any;
  configSubPath: any;
  reportsPath: any;
  staticConfigPath: any;
}) => {
  if (!currentApi) {
    currentApi = new ParquetAPI(args);
  }
  return currentApi;
};

export const getCurrentApi = (): JsonAPI | ZarrAPI | ParquetAPI => {
  if (!currentApi) {
    // eslint-disable-next-line no-throw-literal
    throw 'API not initialized';
  }
  return currentApi;
};

export const getCurrentApiType = (): string => {
  if (!currentApi) {
    // eslint-disable-next-line no-throw-literal
    throw 'API not initialized';
  }
  return currentApi.constructor.name;
};

/** Export types */
export { JsonAPI } from './JsonAPI';
export { ParquetAPI } from './ParquetAPI';
export { ZarrAPI } from './ZarrAPI';
