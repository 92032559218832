import Style from 'ol/style/Style';
import { get } from 'lodash-es';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import StationsLayer from './StationsLayer';
import FontIcon from './FontIcon';
import { getTrendArrow } from '../../common/base';

export default class StationsTrendLayer extends StationsLayer {
  // eslint-disable-next-line class-methods-use-this
  constructor(options) {
    options = {
      ...options,
    };
    super(options);
  }

  _createStationFeatures(stations) {
    const ret = [];
    stations.forEach(station => {
      if (station.point) {
        const f = new Feature({
          geometry: new Point(station.point),
          rot: get(station, this.trendkey),
        });

        const style = this._getStationStyle(f);
        style && f.setStyle(style);
        f.station = station;
        ret.push(f);
      }
    });
    return ret;
  }

  // eslint-disable-next-line class-methods-use-this
  _getStationStyle(feature) {
    return new Style({
      image: new FontIcon({
        backgroundColor: 'rgba(197, 213, 234, 1)',
        icon: 'ki ki-arrow-right',
        fontSize: 0.8,
        radius: 9,
        rotation: getTrendArrow(feature.get('rot'), true),
        shape: 'none',
        color: 'rgba(69,69,67, 1)',
        border: {
          color: 'rgba(29,29,27, 1)',
          width: 1,
        },
      }),
    });
  }

  set labelTemplate(val) {
    this.__labelTemplate = val;
    this.reload();
  }

  get labelTemplate() {
    /* eslint-disable no-template-curly-in-string */
    return this.__labelTemplate || this.isSiteLayer
      ? '${site_name}'
      : '${station_name}';
    /* eslint-enable no-template-curly-in-string */
  }
}
