import echarts from 'echarts';
import { Series } from '../../defs/GraphTypes';
import { ConfigLoader } from '..';
import { formatNumber } from '../../common/NumberFormatter';

export class SeriesFactory {
  configLoader: any;

  options: {
    showStationNamesInLegend: boolean;
    showParameterNamesInLegend: boolean;
    useDefaultColors: boolean;
  };

  constructor(options = {}) {
    this.configLoader = new ConfigLoader();
    this.options = options;
  }

  private _resolveColor(series: Series): string | undefined {
    const color =
      !this.options.useDefaultColors && series?.meta?.style?.color
        ? series.meta.style.color
        : undefined;
    return color;
  }

  private _resolveName(series: Series): string {
    if (
      !this.options.showStationNamesInLegend &&
      !this.options.showParameterNamesInLegend
    )
      return series.name;
    if (
      this.options.showStationNamesInLegend &&
      !this.options.showParameterNamesInLegend
    )
      return `${series.station.name} - ${series.name}`;
    if (
      !this.options.showStationNamesInLegend &&
      this.options.showParameterNamesInLegend
    )
      return `${series.name} (${series.parameter.name})`;
    return `${series.station.name} - ${series.name} (${series.parameter.name})`;
  }

  public getSeries(
    series: Series,
    numberFormat: Intl.NumberFormatOptions | undefined,
  ): echarts.ECharts.Series {
    const _type = series.meta.type;
    if (['timeseries', 'line'].includes(_type))
      return this.getLineSeries(series, numberFormat);
    if (_type === 'threshold') {
      return this.getThresholdSeries(series, numberFormat);
    }
    if (['min', 'max'].includes(_type))
      return this.getMinMaxSeries(series, numberFormat);
    if (['point'].includes(_type))
      return this.getPointSeries(series, numberFormat);
    if (_type === 'bar') return this.getBarSeries(series, numberFormat);

    console.warn(
      'Meta data not defined, fallback to line chart',
      series.id,
      series,
    );
    return this.getLineSeries(series, numberFormat);
  }

  /** Thresholds
   * e.g. Warning levels @ yAxis or t0 marking @ xAxis
   * @param vertical Markline @ xAxis
   * @returns Empty series with markLine
   */
  public getThresholdSeries(
    series: Series,
    numberFormat: Intl.NumberFormatOptions | undefined,
  ): echarts.ECharts.Series {
    const { style } = series.meta;
    return {
      type: 'line',
      name: this._resolveName(series),
      parameterName: series.parameter.name,
      data: series.data ? series.data : [],
      id: series.id,
      showSymbol: true,
      step: 'end',
      itemStyle: {
        color: this._resolveColor(series),
      },
      lineStyle: {
        color: this._resolveColor(series),
        width: style?.lineWidth || 1,
        type: 'dashed',
      },
      animation: false,
      tooltip: {
        valueFormatter: (value: number) =>
          `${formatNumber(value, numberFormat)} ${series.parameter.unit}`,
      },
    };
  }

  /** Line chart */
  public getLineSeries(
    series: Series,
    numberFormat: Intl.NumberFormatOptions | undefined,
  ): echarts.ECharts.Series {
    const { style } = series.meta;

    return {
      type: 'line',
      name: this._resolveName(series),
      parameterName: series.parameter.name,
      data: series.data ? series.data : [],
      id: series.id,
      showSymbol: false,
      connectNulls: false,
      itemStyle: {
        color: this._resolveColor(series),
      },
      lineStyle: {
        color: this._resolveColor(series),
        width: style?.lineWidth || 1,
      },
      animation: false,
      sampling: 'lttb',
      tooltip: {
        valueFormatter: (value: number) =>
          `${formatNumber(value, numberFormat)} ${series.parameter.unit}`,
      },
    };
  }

  /** Line chart, without connections
   *  @remarks Used for single values, e.g. "Handwerte", which were added manually
   */
  public getPointSeries(
    series: Series,
    numberFormat: Intl.NumberFormatOptions | undefined,
  ): echarts.ECharts.Series {
    const { style } = series.meta;

    return {
      type: 'line',
      name: this._resolveName(series),
      parameterName: series.parameter.name,
      data: series.data ? series.data : [],
      id: series.id,
      showSymbol: true,
      symbolSize: style?.symbolSize || 12,
      symbol: style?.symbol || 'circle',
      itemStyle: {
        color: this._resolveColor(series),
      },
      lineStyle: {
        width: 0,
      },
      animation: false,
      tooltip: {
        valueFormatter: (value: number) =>
          `${formatNumber(value, numberFormat)} ${series.parameter.unit}`,
      },
    };
  }

  /** Line chart */
  public getMinMaxSeries(
    series: Series,
    numberFormat: Intl.NumberFormatOptions | undefined,
  ): echarts.ECharts.Series {
    const { style } = series.meta;

    return {
      type: 'line',
      name: this._resolveName(series),
      parameterName: series.parameter.name,
      data: series.data ? series.data : [],
      id: series.id,
      color: this._resolveColor(series),
      symbol: style?.symbol || 'triangle',
      symbolRotate: style.symbolRotate ?? series.meta.type === 'min' ? 180 : 0,
      symbolSize: style?.symbolSize || 6,
      lineStyle: {
        width: 0,
      },
      animation: false,
      tooltip: {
        valueFormatter: (value: number) =>
          `${formatNumber(value, numberFormat)} ${series.parameter.unit}`,
      },
    };
  }

  /** Bar Chart
   * e.g. Precipitation
   */
  public getBarSeries(
    series: Series,
    numberFormat: Intl.NumberFormatOptions | undefined,
  ): echarts.ECharts.Series {
    // const { style } = series.meta;

    return {
      type: 'bar',
      name: this._resolveName(series),
      parameterName: series.parameter.name,
      data: series.data ? series.data : [],
      dataGroupId: 'bars',
      id: series.id,
      color: this._resolveColor(series),
      animation: false,
      barMaxWidth: 15,
      barMinWidth: 1,
      tooltip: {
        valueFormatter: (value: number) =>
          `${formatNumber(value, numberFormat)} ${series.parameter.unit}`,
      },
    };
  }

  /** Arrow
   * e.g. Wind direction
   * @example https://echarts.apache.org/examples/en/editor.html?c=wind-barb
   */
  // eslint-disable-next-line class-methods-use-this
  public getArrowSeries(series: Series): echarts.ECharts.Series {
    // const { style } = series.meta;
    console.debug(series);
    return {};
  }
}
