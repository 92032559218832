import { css, html, LitElement, TemplateResult } from 'lit';

import { customElement, property } from 'lit/decorators.js';

@customElement('ki-checkbox')
export default class KiCheckBox extends LitElement {
  // language=CSS
  static styles = css`
    :host {
      outline: none;
      display: inline-block;
      position: relative;
    }

    .ki-checkbox {
      display: block;
      cursor: pointer;
      user-select: none;
      color: rgba(128, 128, 128, 1);
    }

    .ki-checkbox input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .ki-checkbox input:checked ~ svg {
      font-size: inherit;
    }

    .ki-checkbox input:checked ~ svg {
      fill: var(--theme-color, #0056a0);
    }
    .ki-checkbox input:indeterminate ~ svg {
      fill: var(--theme-color, #0056a0);
    }

    .ki-checkbox input:disabled ~ svg {
      color: lightgray;
      cursor: not-allowed;
    }
    svg.icon {
      width: var(--ki-checkbox-box-width, 18px);
      height: var(--ki-checkbox-box-height);
      fill: var(--ki-checkbox-box-color, gray);
      cursor: pointer;
    }
  `;

  @property({ type: Boolean })
  checked = false;

  @property({ type: Boolean })
  disabled = false;

  @property({ type: Boolean })
  indeterminate = false;

  @property({ type: String })
  value = '';

  interediateImage: TemplateResult = html`<svg
    class="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      d="M108 284c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h232c6.6 0 12 5.4 12 12v32c0 6.6-5.4 12-12 12H108zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-48 346V86c0-3.3-2.7-6-6-6H54c-3.3 0-6 2.7-6 6v340c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"
    />
  </svg>`;

  checkedImage: TemplateResult = html`<svg
    class="icon"
    xmlns="http://www.w3.org/2000/svg"
    baseProfile="tiny"
    viewBox="0 0 18 18"
  >
    <path
      d="M0 0v18h18V0H0zm7.24 15L1 8.72l2.16-2.17 4.08 4.11L14.84 3 17 5.17 7.24 15z"
    />
  </svg>`;

  uncheckedImage: TemplateResult = html`<svg
    class="icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6z"
    />
  </svg>`;

  render() {
    let square = this.checked ? this.checkedImage : this.uncheckedImage;
    square = this.indeterminate ? this.interediateImage : square;

    return html` <label class="ki-checkbox">
      <input
        type="checkbox"
        ?disabled="${this.disabled}"
        .checked="${this.checked}"
        .indeterminate="${this.indeterminate}"
        .value="${this.value}"
        @click="${this._handleClick}"
        @change="${this._handleChange}"
      />
      ${square}
    </label>`;
  }

  _handleClick() {
    this.checked = !this.checked;
    this.indeterminate = false;
  }

  _handleChange() {
    this.dispatchEvent(
      new CustomEvent('change', {
        detail: {
          value: this.value,
          checked: this.checked,
        },
      }),
    );
  }
}
