/* eslint-disable camelcase */
export default class KiwisHelper {
  static _buildAbsPath(relPath, loc): string {
    /** Absolute path without specified protocol (http and https) */
    if (relPath.startsWith('//')) return `${loc.protocol}${relPath}`;
    /** Relative path handling */
    if (relPath.startsWith('/')) return `${loc.origin}${relPath}`;
    return `${loc.origin}/${relPath}`;
  }

  public static buildTsPath(station, ts_shortname) {
    if (ts_shortname)
      return `${station.site_no}/${station.station_no}/${station.stationparameter_no}/${ts_shortname}`;
    return `${station.site_no}/${station.station_no}/${station.stationparameter_no}/${station.ts_shortname}`;
  }
}
