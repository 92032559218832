import { css } from 'lit';
// language=CSS
export default css`
  ki-app-views {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  ki-menu {
    width: 100%;
  }
  .logo-left,
  .logo-right {
    display: flex;
  }
  .logo-right {
    margin-left: auto;
  }
  .logo-left img,
  .logo-right img {
    height: 75px;
    padding: 5px;
  }
  .header {
    height: 80px;
  }

  .sm-screen .logo-Demo {
    padding: 10px 0px;
  }

  :host(.sm-screen) .toggle-btn {
    display: flex;
    border-radius: 0;
    margin: 0 10px 0 0;
    min-width: 60px;
    width: 60px;
    justify-content: center;
    flex-direction: column;
    background-color: var(--theme-color-primary, white);
    color: white;
    fill: white;
    font-size: 1.5em;
    height: 100%;
  }
  .language-switch {
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 100;
    font-size: 15px;
  }

  .language-switch div {
    user-select: none;
    padding: 2px 15px;
    cursor: pointer;
    border-right: 1px solid #ccc;
  }

  .language-switch div:last-child {
    border-right: none;
  }

  .language-switch div:hover {
    color: white;
    text-decoration: underline;
  }

  .language-switch .activelang {
    color: white;
    cursor: auto;
  }
  .language-switch .activelang:hover {
    text-decoration: none;
  }
`;
