import { html, css, LitElement, unsafeCSS } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { getRouteOptionsAndParams } from '..';
import '@ui5/webcomponents/dist/DateRangePicker';
// eslint-disable-next-line import/extensions
import { i18nMixin } from '../../decorators';
import { Mix } from '../../common';
import { getCurrentApi } from '../../api';
import dayjs from '../../common/dayjsext';
import { formatNumber } from '../../common/NumberFormatter';
import nls from '../../locales/index';
import styles from './styles.css?inline';

@customElement('ww-editable-table')
export default class EditableTable extends Mix(LitElement, [
  i18nMixin,
  { nls },
]) {
  static styles = css`
    ${unsafeCSS(styles)}
  `;

  @property({ type: Array }) paramNames = [];

  @property() tsNames: string[] = [];

  @property({ type: String }) timestamp_from = '';

  @property({ type: String }) timestamp_until = '';

  @property({ type: Object }) ApiData;

  @property({ type: Map }) sortedDataMap = new Map();

  @property({ type: Object }) tsIdStationMap = {}; // to retrieve tsIds for ts that doesn't have any data i.e., 'Cmd.FFMC_INIT', 'Cmd.DMC_INIT', 'Cmd.DC_INIT'

  @property({ type: Boolean }) isEditable = false;

  @property({ type: Boolean }) toastVisible = false;

  @property({ type: Boolean }) station_name = '';

  @property({ type: Boolean }) station_no = '';

  @property({ type: Map }) updatedDataMap = new Map();

  @property({ type: Boolean }) isLoading = true;

  @property({ type: String }) toastMsg = '';

  @property({ type: Object }) api = getCurrentApi();

  @property({ type: String }) backendUrl = '';

  @property({ type: Array }) selectedDates: Date[] = [];

  firstUpdated() {
    this.api = getCurrentApi();
    const confirmButton = this.shadowRoot?.getElementById('confirm-button');
    if (confirmButton) {
      const minRange = new Date();
      minRange.setMonth(minRange.getMonth() - 24); // show last 24 months data - change as required
      confirmButton.addEventListener('click', () => {
        if (this.selectedDates.length > 0) {
          this.timestamp_from = this.selectedDates[0].toISOString();
          this.timestamp_until =
            this.selectedDates[this.selectedDates.length - 1].toISOString();
          this.fetchParameterValues();
        }
      });
    }
  }

  handleDateChange(evt) {
    this.selectedDates = [
      new Date(`${evt.detail.value.split(' - ')[0]}T00:00:00`),
      new Date(`${evt.detail.value.split(' - ')[1]}T23:59:59`),
    ];
  }

  /** fetch data */
  async connectedCallback() {
    if (super.connectedCallback) {
      super.connectedCallback();
    }

    const startDate = new Date();
    startDate.setHours(startDate.getHours() - 196); // 24
    const endDate = new Date();

    this.timestamp_from = startDate.toISOString();
    this.timestamp_until = endDate.toISOString();
  }

  async fetchParameterValues() {
    this.ApiData = [];
    this.isLoading = true;

    await Promise.allSettled(
      this.tsNames.map(async tsName => {
        const response = await fetch(
          `${
            this.backendUrl
          }/rest/${this.station_no}/${tsName}/${encodeURIComponent(
            this.timestamp_from,
          )}/${encodeURIComponent(this.timestamp_until)}`,
          {
            method: 'GET',
          },
        );

        if (response.ok) {
          const data = (await response.json()) ?? [];

          data.forEach(element => {
            this.ApiData?.push(element);
          });
        } else {
          // Error occurred while fetching parameter values
          console.error('Failed to fetch parameter values');
        }
        // Return a resolved promise to be included in the array of promises
        return Promise.resolve();
      }),
    );
    this.isLoading = false;
    this.requestUpdate();
  }
  /*----------------------------------------------------------------*/

  activateCalc() {
    fetch(`${this.backendUrl}/activate-calc`, {
      method: 'GET',
    })
      .then(response => response.text())
      .then(message => {
        console.log(message);
      })
      .catch(error => {
        console.error('Error activating calculation:', error);
      });
  }

  downloadCSV() {
    let csv = `${this.paramNames.map((col: any) => col.name).join(' , ')}\n`;

    Array.from(this.sortedDataMap.keys()).forEach(timestamp => {
      csv += `${this.station_name},${dayjs(timestamp).format('L LT')},`;

      this.paramNames.slice(2).forEach((param: any) => {
        const value =
          this.sortedDataMap.get(timestamp)[
            `${param.shortName}_${param.tsShortName}`
          ]?.value;
        csv += value !== undefined ? `${value},` : ',';
      });

      csv = csv.slice(0, -1); // Remove the trailing comma
      csv += '\n';
    });

    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'table_data.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  render() {
    const dataMap = new Map();
    // Iterate over the API data and populate the map
    if (this.ApiData) {
      for (const entry of this.ApiData) {
        const paramName = entry.parameter_shortname;
        const paramData = entry.data;
        const tsShortName = entry.ts_shortname;
        const { ts_id, station_name } = entry;

        // store ts_id and station_name
        const key = `${paramName}_${tsShortName}`;
        if (!this.tsIdStationMap[key]) {
          this.tsIdStationMap[key] = { ts_id, station_name };
        }

        // Iterate over the data array for the parameter
        for (const [timestamp, value] of paramData) {
          if (!dataMap.has(timestamp)) {
            // For unique timestamps
            dataMap.set(timestamp, {});
          }

          const timestampData = dataMap.get(timestamp);

          if (!timestampData[`${paramName}_${tsShortName}`]) {
            timestampData[`${paramName}_${tsShortName}`] = {};
          }

          timestampData[`${paramName}_${tsShortName}`].value = formatNumber(
            parseFloat(value),
            { maximumFractionDigits: 2 },
          );
        }
      }

      // Sort the dataMap based on timestamps
      this.sortedDataMap = new Map(
        Array.from(dataMap.entries()).sort(
          ([timestampA], [timestampB]) =>
            new Date(timestampB).getTime() - new Date(timestampA).getTime(),
        ),
      );
    }
    return html`
      <div class="${this.isLoading ? 'loader-container' : 'loader-hidden'} ">
        <div class="loader"></div>
      </div>
      <div class="toast ${this.toastVisible ? 'show' : ''}">
        ${this.toastMsg}
      </div>

      <div class="date-group">
        <div>
          <button class="togglebtn" @click=${this.toggleEditable}>
            Toggle Table Edit
            <span class="toggle-edit-icon">&#9998;</span>
          </button>
        </div>

        <div class="date-picker-container">
          <ui5-daterange-picker
            id="date-picker"
            format-pattern="yyyy-MM-dd"
            value="${`${this.timestamp_from.split('T')[0]} - ${this.timestamp_until.split('T')[0]}`}"
            hide-week-numbers="true"
            @change="${this.handleDateChange}"
          ></ui5-daterange-picker>
          <button id="confirm-button" class="date-ok-button">OK</button>
        </div>

        <div>
          <button class="date-ok-button" @click=${this.activateCalc}>
            Activate calculation
          </button>
        </div>
      </div>

      <div class="table-wrapper">
        <table>
          <thead>
            <tr class="fixed-header">
              ${this.paramNames.map((col: any) => {
                const isColEditable = col.editable;
                return html`
                  <th
                    class="${isColEditable && this.isEditable
                      ? 'editable-column'
                      : ''}"
                  >
                    ${col.name}
                    ${isColEditable && this.isEditable
                      ? html`<span class="edit-icon">&#9998;</span>`
                      : ''}
                  </th>
                `;
              })}
            </tr>
          </thead>
          <tbody>
            ${Array.from(this.sortedDataMap.keys()).map(
              timestamp => html`
                <tr>
                  <td>${this.station_name}</td>
                  <td>${dayjs(timestamp).format('L LT')}</td>
                  ${this.paramNames.slice(2).map((param: any) => {
                    const value =
                      this.sortedDataMap.get(timestamp)[
                        `${param.shortName}_${param.tsShortName}`
                      ]?.value;
                    const editableCol = param.editable;
                    return html`
                      <td
                        contenteditable="${editableCol
                          ? this.isEditable
                          : 'false'}"
                        class="${this.isEditable && editableCol
                          ? 'editable-column'
                          : ''}"
                        style=${this.isEditable && editableCol
                          ? 'cursor:text'
                          : 'cursor: pointer'}
                        @blur="${(e: FocusEvent) =>
                          this.handleBlur(
                            timestamp,
                            `${param.shortName}_${param.tsShortName}`,
                            e,
                          )}"
                        @keydown="${(e: KeyboardEvent) =>
                          this.validateInput(e)}"
                        onkeypress="if(event.keyCode==13 || event.which==13){event.preventDefault(); this.blur();}"
                      >
                        ${value}
                      </td>
                    `;
                  })}
                </tr>
              `,
            )}
          </tbody>
        </table>
        ${!this.sortedDataMap.size && !this.isLoading
          ? html`<div style="width: 100%; text-align: center; margin: 40px">
              No data for selected period
            </div>`
          : ''}
      </div>

      <div class="savebtn-group">
        <ki-icon-btn
          class="ripple clipboard-btn"
          id="table-download-csv-btn"
          tooltip="Download table as CSV"
          title="Download table as CSV"
          icon="ki ki-file"
          @click=${() => {
            this.downloadCSV();
          }}
        ></ki-icon-btn>
        <button id="savebtn" class="savebtn" @click=${this.saveData} disabled>
          Save
        </button>
        <button
          id="cancelbtn"
          class="cancelbtn"
          @click=${this.handleCancel}
          disabled
        >
          Cancel
        </button>
      </div>
    `;
  }

  // eslint-disable-next-line class-methods-use-this
  handleCancel() {
    // TODO: not necessary to reload the whole page
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }

  toggleEditable() {
    this.isEditable = !this.isEditable;
  }

  handleBlur(timestamp: any, param: string, event: FocusEvent) {
    const cell = event.target as HTMLElement;
    const trimmedValue = cell.textContent?.trim() || '';
    const sortedDataMapVal = this.sortedDataMap.get(timestamp)[param]?.value;
    if (sortedDataMapVal !== trimmedValue) {
      this.updatedDataMap.set(`${timestamp}%${param}`, cell);
      this.isBtnGroupEnabled(true);
    }
  }

  isBtnGroupEnabled(enabled: boolean) {
    const saveButton = this.shadowRoot.querySelector('#savebtn');
    const cancelButton = this.shadowRoot.querySelector('#cancelbtn');
    if (enabled) {
      saveButton.disabled = false;
      cancelButton.disabled = false;
    } else {
      saveButton.disabled = true;
      cancelButton.disabled = true;
    }
  }

  showToast(msg: string, cell: any) {
    this.toastVisible = true;
    this.toastMsg = msg;
    if (msg.includes('Updated')) cell.classList.add('updated');
    else cell.classList.add('not-updated');
    setTimeout(
      () => {
        this.toastVisible = false;
      },
      msg.includes('Updated') ? 3000 : 9000,
    );
  }

  async saveData() {
    this.isLoading = true;

    const promises: any[] = [];
    for (const [key, cell] of this.updatedDataMap) {
      const [timestamp, param] = key.split('%');
      promises.push(
        this.putData(param, timestamp, cell.textContent)
          .then(() => {
            this.showToast('Updated', cell);
          })
          .catch(err => {
            this.showToast(`${err} `, cell);
          }),
      );
    }

    try {
      await Promise.all(promises);
      this.isLoading = false;
      this.isBtnGroupEnabled(false);
    } catch (err) {
      console.error('Error while saving data:', err);
    }
  }

  async putData(param: string, timestamp: any, value: string | null) {
    const { ts_id } = this.tsIdStationMap[param];

    const backendPutUrl = `${
      this.backendUrl
    }/rest/${ts_id}/${encodeURIComponent(timestamp)}/${encodeURIComponent(timestamp)}`;

    const jsonBody = {
      record: [
        {
          value: [
            {
              quality: 200,
              interpolationType: 4,
              value,
            },
          ],
          ts: timestamp,
        },
      ],
    };

    try {
      const response = await fetch(backendPutUrl, {
        method: 'PUT',
        body: JSON.stringify(jsonBody),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const resData = await response.json();

      // backend will return empty object if data was edited successfully.
      if (Object.keys(resData).length !== 0) {
        // Object is not empty
        console.log('Error: ');
        throw new Error(`${resData.message}`);
      }

      return resData;
    } catch (error: any) {
      // Handle the error, log it, or rethrow it if needed
      console.error('Error:', error.message);
      throw error;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  validateInput(event: KeyboardEvent) {
    const allowedKeys = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '-',
      '.',
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'Enter',
    ];
    if (allowedKeys.indexOf(event.key) === -1) {
      event.preventDefault();
    }
  }

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, ['stationId']);
    Object.assign(this, params.options);
    const stationName = getRouteOptionsAndParams(location, ['stationLabel']);
    this.stationId = params.stationId;
    this.station = await this.api.getStation(this.stationId);
    this.station_name = stationName.stationLabel;
    // we have to pass station_no instead of stationID
    this.station_no = this.station.station_no;

    this.fetchParameterValues();

    if (this.tsData) {
      this.fetchTsData();
    }
    if (this.showImages) {
      this.fetchImages();
      let count = 0;
      this.interval = setInterval(() => {
        count += 1;
        this.gallery.navigateTo(count % this.images.length);
      }, 10000000);
    }
  }
}
