/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import { Series, Station, Group } from '../../defs/GraphTypes';
import { ConfigLoader, IService } from '../index';
import {
  getAllStations,
  getIntervalTimeseriesData,
  getStationMetadataById,
} from '../../api/ZarrAPIHelper';

export class ZarrGraphService implements IService {
  async data(
    seriesList: Series[],
    configLoader: ConfigLoader,
    from: Date,
    until: Date,
  ): Promise<Series[]> {
    const min = from?.getTime();
    const max = until?.getTime();

    return Promise.all(
      seriesList.map(async series => {
        const stationMeta = await getStationMetadataById(series.station.id);

        if (!stationMeta) {
          return series;
        }

        const timeseriesMetadata =
          stationMeta.parameter[series.parameter.id]?.timeseries;

        if (!timeseriesMetadata) {
          return series;
        }

        const timeseries = Object.keys(timeseriesMetadata);

        if (timeseries.length === 0) {
          return series;
        }

        const data = await getIntervalTimeseriesData(
          stationMeta?.site_no,
          stationMeta?.station_no,
          series.parameter.id,
          series.timeseries ? series.timeseries : timeseries[0],
          min,
          max,
        );

        if (!data) {
          return series;
        }

        series.data = data.data
          .filter(
            dat =>
              (min === undefined || dat.timestamp >= min) &&
              (max === undefined || dat.timestamp <= max),
          )
          .map(x => [x.timestamp, x.value]);

        return series;
      }),
    );
  }

  timeseries(filter: object): Promise<Series[]> {
    throw new Error('Method not implemented.');
  }

  async stations(filter: object): Promise<Station[]> {
    return (await getAllStations()).map(stationMeta => ({
      name: stationMeta.station_name,
      shortName: stationMeta.station_name,
      id: stationMeta.station_id,
      lat: stationMeta.station_latitude,
      lon: stationMeta.station_longitude,
      site: {
        name: stationMeta.site_name,
        id: stationMeta.site_no,
        wkt: undefined,
      },
    }));
  }

  groups(filter: object): Promise<Group[]> {
    throw new Error('Method not implemented.');
  }
}
