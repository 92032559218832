import { css, html, LitElement } from 'lit';

import { customElement, property } from 'lit/decorators.js';

@customElement('ki-toast')
export default class KiToast extends LitElement {
  // language=CSS
  static styles = css`
    :host {
      display: flex;
      flex-direction: row;
      position: fixed;
      background-color: white;
      color: black;
      fill: black;
      border: 1px solid var(--ki-toast-color, black);
      min-height: 48px;
      max-width: 400px;
      /*max-width: 80%; !*for mobile*!*/
      min-width: 288px;
      padding: 12px 0;
      box-sizing: border-box;
      border-radius: 2px;
      font-size: 14px;
      cursor: default;
      opacity: 0;
      transform: translateX(200px);
      transition:
        transform linear 0.3s,
        opacity linear 0.3s;
      z-index: 10;
    }

    :host(.visible) {
      opacity: 1;
      transform: translateX(0px);
    }

    /*right-bottom*/
    :host {
      position: fixed;
      right: 20px;
      bottom: 20px;
    }

    .icon-container {
      color: var(--ki-toast-color, black);
      flex: 0 0 70px;
      text-align: center;
      font-size: 24px;
      margin: auto;
    }

    .content-container {
      flex: 1;
    }

    .action-container {
      flex: 0 40px;
      text-align: center;
    }

    .close-btn {
      fill: lightgray;
      cursor: pointer;
    }
    .close-btn:hover {
      fill: gray;
    }

    :host(.success) {
      --ki-toast-color: var(--ki-success-color, #1eaf1e);
    }

    :host(.error) {
      --ki-toast-color: var(--ki-error-color, #d30535);
    }

    :host(.warning) {
      --ki-toast-color: var(--ki-warning-color, #fe7402);
    }

    :host(.info) {
      --ki-toast-color: var(--ki-info-color, #3f86cd);
    }
  `;

  @property({ type: String })
  content: string | undefined;

  @property({ type: String })
  type: string = '';

  visible: boolean = false;

  static types = {
    success: {
      className: 'success',
      icon: 'ki ki-check',
    },
    error: {
      className: 'error',
      icon: 'ki ki-exclamation',
    },
    warning: {
      className: 'warning',
      icon: 'ki ki-question',
    },
    info: {
      className: 'info',
      icon: 'ki ki-info',
    },
  };

  static showToast({ type = 'success', content = 'msg content' } = {}) {
    const toast = document.createElement('ki-toast');
    // @ts-expect-error
    toast.type = type;
    // @ts-expect-error
    toast.content = content;
    document.body.appendChild(toast);
    setTimeout(() => {
      // @ts-expect-error
      toast.open();
    });
  }

  renderMsg() {
    return this.content ? html` ${this.content} ` : html` <slot></slot> `;
  }

  render() {
    // @ts-expect-error
    this.renderRoot.host.classList.toggle('visible', this.visible);
    // @ts-expect-error
    this.renderRoot.host.classList.remove(Object.keys(KiToast.types));

    const type = KiToast.types[this.type];
    if (type) {
      // @ts-expect-error
      this.renderRoot.host.classList.add(KiToast.types[this.type].className);
    }

    // language=html
    return html`
      <div class="icon-container">
        <ki-icon icon="${type.icon}"></ki-icon>
      </div>
      <div class="content-container">${this.renderMsg()}</div>
      <div class="action-container">
        <ki-icon
          class="close-btn"
          icon="ki ki-times"
          @click="${this.close}"
        ></ki-icon>
      </div>
    `;
  }

  open({ duration = 200000 } = {}) {
    this.visible = true;
    this.requestUpdate();
    setTimeout(() => {
      this.close();
    }, duration);
  }

  close() {
    this.visible = false;
    this.requestUpdate();

    // or maybe use msg stack , reuse the element.
    // wait after animation
    // setTimeout(() => {
    //   this.remove();
    // }, 1000)
  }
}

// TODO stack for multi toast
