import { html, css, ReactiveElement } from 'lit';

import { customElement } from 'lit/decorators.js';
import './ki-list-item';
import './ki-list-item-group';
import PropertyDefaultValue from '../../common/PropertyDefaultValue';
import Mix from '../../common/Mix';

// @ts-expect-error
@customElement('ki-list')
export default class KiList extends Mix(ReactiveElement, PropertyDefaultValue) {
  // language=CSS
  static styles = css`
    ki-list {
      display: block;
      --ki-list-indent: 12px;
    }
  `;

  createRenderRoot() {
    return this;
  }

  render() {
    return html` <slot></slot>`;
  }
}
