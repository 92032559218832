/* eslint-disable func-names */
import i18next from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { dayjs } from '../common';

if (!i18next.isInitialized) {
  i18next.init({
    fallbackLng: 'en',
  });
  i18next.on('languageChanged', lng => {
    dayjs.locale(lng);
  });
}

/**
 * Returns the application language
 * @remark Language should be set via e.g '~/apps/external/index.html'
 * @example sessionStorage.setItem('i18nextLng', 'de');
 */
export function getCurrentLang() {
  const lng = window.sessionStorage.getItem('i18nextLng') || 'en';
  return lng;
}

export function addNls(i18nInstance, nls) {
  Object.entries(nls).forEach(([language, rs]) => {
    // @ts-expect-error
    Object.entries(rs).forEach(([namespace, r]) => {
      i18nInstance.addResourceBundle(language, namespace, r, true, true);
    });
  });
}

function initFn() {
  // @ts-expect-error
  this.i18n = i18next.createInstance();
  // @ts-expect-error
  this.i18n.use(languageDetector);
  // @ts-expect-error
  this.i18n.init(
    {
      detection: {
        order: [
          'sessionStorage',
          'querystring',
          'cookie',
          'navigator',
          'htmlTag',
          'path',
          'subdomain',
          'localStorage',
        ],
      },
      fallbackLng: 'en',
      defaultNS: 'common',
      debug: false,
      resources: {},
    },
    err => {
      if (err) {
        // eslint-disable-next-line no-console
        console.debug('something went wrong loading', err);
      }
    },
  );
  // @ts-expect-error
  this.i18n.on('initialized', () => {
    // @ts-expect-error
    this.requestUpdate();
  });
  // @ts-expect-error
  this.i18n.on('languageChanged', () => {
    // @ts-expect-error
    this.requestUpdate();
  });
  // @ts-expect-error
  this.i18n.store.on('added', () => this.requestUpdate());
  // @ts-expect-error
  this.i18n.store.on('removed', () => this.requestUpdate());
}

const extendCls = function (superclass, nls) {
  return class extends superclass {
    constructor() {
      super();
      this.i18n || initFn.call(this);
      addNls(this.i18n, nls);
    }
  };
};

// TODO find better method to do aspect
export const i18n = function (nls) {
  return function decorator(superclass) {
    return extendCls(superclass, nls);
  };
};

export const i18nMixin = (superclass, { nls = {} }) =>
  extendCls(superclass, nls);
