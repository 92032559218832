/* eslint-disable wc/no-constructor-attributes */
/* eslint-disable wc/no-self-class */
import { LitElement, html, css } from 'lit';

import { customElement, property } from 'lit/decorators.js';
import '../ki-icon/ki-icon';
import { last } from 'lodash-es/array';
import { some } from 'lodash-es/collection';
import { addSwipeEvents } from '../../common/util';
// import style from './ki-modal.css?inline';

@customElement('ki-modal')
export default class KiModal extends LitElement {
  // language=CSS
  static styles = css`
    :host {
      background-color: #fefefe;
      width: 100%;
      height: auto;
      max-height: 100%;
      border-top: 1px solid lightgray;
      position: fixed;
      z-index: 100;
      overflow: auto;
      left: 0;
      bottom: -100%;
      transition: bottom 0.5s;
    }

    :host(.visible) {
      z-index: 101;
      bottom: 0;
      max-height: 100%;
      filter: drop-shadow(1px 4px 7px grey);
    }

    :host(.visible.full-screen) {
      bottom: 0;
    }
    :host(.draggable) .ki-modal-drag {
      display: block;
    }

    .ki-modal-drag {
      display: none;
      width: 20%;
      height: 3px;
      margin: 5px auto 0px;
      border-radius: 32px;
      border: 1px solid transparent;
      background: gray;
    }

    .ki-modal-header {
      display: flex;
      flex-direction: row;
      padding: 0px 10px;
      font-size: 1.2em;
      line-height: 1.8em;
    }
    .ki-modal-header.bottom-border {
      border-bottom: 1px solid lightgray;
    }
    .ki-modal-header.bottom-border .ki-modal-label {
      height: 1.8em;
    }
    .ki-modal-label {
      flex: 1;
      display: inline-block;
    }
    .close {
      fill: gray;
      line-height: 1.8em;
    }
    .close.hide {
      display: none;
    }
    .close:hover {
      cursor: pointer;
    }
    .close:focus {
      cursor: pointer;
    }
  `;

  static history: Array<any> = []; // use to track order of popup modals

  @property({ type: Boolean })
  visible = false;

  @property({ type: Boolean })
  draggable = false;

  @property({ type: Boolean })
  closeable = false;

  @property({ type: Boolean })
  closeOnSwipeDown = false;

  __fullscreen = false; // __fullscreen is one state of draggable

  @property({ type: Boolean })
  trackable = false;

  @property({ type: String })
  label: string = '';

  @property({ type: Number })
  minContentHeight: number | undefined;

  _originTop: number = 0;

  render() {
    this.classList.toggle('visible', this.visible);
    this.classList.toggle('full-screen', this.__fullscreen);
    this.classList.toggle('draggable', this.draggable);
    return html`
      <div class="modal-header-wrapper">
        <div
          class="ki-modal-drag"
          @click="${this.handleClick}"
          @touchmove="${this.handleTouchMove}"
          @touchstart="${this.handleTouchStart}"
        ></div>
        <div
          class="ki-modal-header ${this.closeable || this.label
            ? 'bottom-border'
            : ''}"
        >
          <span class="ki-modal-label">${this.label}</span>
          <ki-icon
            icon="ki ki-times"
            class="close ${this.closeable ? '' : 'hide'}"
            @click="${this.close}"
          ></ki-icon>
        </div>
      </div>
      <slot></slot>
    `;
  }

  handleTouchMove(e) {
    if (this.draggable) {
      const touch = e.targetTouches[0];
      const step = this._originTop - touch.clientY;
      this.__fullscreen = step > 0;
      this.requestUpdate();
      this._originTop = touch.clientY;
    }
  }

  handleTouchStart(e) {
    if (this.draggable) {
      const touch = e.targetTouches[0];
      this._originTop = touch.clientY;
    }
  }

  handleClick(e) {
    e.stopPropagation();
    if (this.draggable) {
      this.__fullscreen = !this.__fullscreen;
      this.dispatchEvent(
        new CustomEvent('togglefullscreen', {
          bubbles: true,
          composed: true,
          detail: {
            fullscreen: this.__fullscreen,
          },
        }),
      );
      this.requestUpdate();
    }
  }

  connectedCallback() {
    // @ts-expect-error
    if (super.connectedCallback) super.connectedCallback();
    // this.draggable && this._addEventListener();
    // @ts-expect-error
    addSwipeEvents(this.renderRoot, (...args) => this.handleGesure(...args));
  }

  updated() {
    if (this.visible && this.trackable) {
      !some(KiModal.history, this) && KiModal.history.push(this);
    }
    if (this.__fullscreen || !this.visible) {
      this.__height = null;
    } else {
      this.__height = this.minContentHeight;
    }
  }

  set __height(val) {
    if (val) {
      this.style.bottom = `calc(${val}px - 100%)`;
    } else {
      // @ts-expect-error
      this.style.bottom = null;
    }
  }

  show() {
    this.visible = true;
    if (this.trackable) {
      const previousEl = last(KiModal.history);
      if (previousEl) {
        previousEl.visible = false;
      }
    } else {
      // make sure only one modal popup, case sernario: legends&layers vs station-list
      /* eslint-disable no-return-assign */
      KiModal.history.forEach(modal => (modal.visible = false));
      /* eslint-enable no-return-assign */
    }
  }

  close() {
    this.visible = false;
    if (this.trackable) {
      KiModal.history.pop(); // popup last element
      const previous = last(KiModal.history);
      if (previous) {
        previous.visible = true;
      }
    } else {
      /* eslint-disable no-return-assign */
      KiModal.history.forEach(modal => (modal.visible = true));
      /* eslint-enable no-return-assign */
    }
  }

  handleGesure(touchstartX, touchendX, touchstartY, touchendY) {
    // Swiped left
    if (touchendX < touchstartX + 50) {
      console.debug('Swiped left');
    }
    // Swiped right
    if (touchendX > touchstartX + 50) {
      console.debug('Swiped right');
    }
    // Swiped up
    if (touchendY < touchstartY + 50) {
      console.debug('Swiped up');
    }
    // Swiped down
    if (touchendY > touchstartY + 50) {
      this.closeOnSwipeDown && this.close();
    }
    // tap
    if (touchendY === touchstartY + 50) {
      console.debug('Tap');
    }
  }
}
