/**
 * Converts all string values within an object to their appropriate non-string types.
 * This includes converting numeric strings to numbers, "true" and "false" strings to booleans,
 * comma-separated values to arrays, and valid JSON strings to objects.
 * Any value that cannot be converted remains as a string.
 *
 * @param {Record<string, any>} input - The object whose string values are to be converted.
 * @returns {Record<string, any>} - A new object with values converted to more appropriate types where possible.
 *
 * @example
 * // Returns { count: 10, active: true, tags: ["node", "js"], config: { debug: false } }
 * convertObjectValues({ count: "10", active: "true", tags: "node,js", config: '{"debug": "false"}' });
 */
export function convertObjectValues(
  input: Record<string, any>,
): Record<string, any> {
  const output = {};

  Object.keys(input).forEach(key => {
    const value = input[key];
    try {
      // Attempt to parse the JSON value
      const res = JSON.parse(value);
      // If the result is an object and not an array, recursively convert its values
      if (typeof res === 'object' && !Array.isArray(res)) {
        output[key] = convertObjectValues(res);
      } else {
        // If it's an array or a primitive, assign it directly
        output[key] = res;
      }
    } catch (e) {
      // If JSON parsing fails, keep the original value
      output[key] = value;
    }
  });
  return output;
}
