/* eslint-disable lit-a11y/click-events-have-key-events */
/* eslint-disable max-classes-per-file */
import { customElement, property } from 'lit/decorators.js';
import { i18nMixin, responsiveMixin } from '../../decorators';
import {
  BaseTable,
  PromiseData,
  SortAble,
  ColumnHider,
  ColumnFormatter,
  Export,
  Selection,
  LazyRendering,
} from '../ki-table';
import { Mix } from '../../common';
import nls from '../../locales/index';
import ListMode from './ListMode';

@customElement('ki-responsive-table')
export default class KiResponsiveTable extends Mix(
  BaseTable,
  [i18nMixin, { nls }],
  Selection,
  responsiveMixin,
  PromiseData,
  SortAble,
  ColumnFormatter,
  ColumnHider,
  LazyRendering,
  ListMode,
  Export,
) {
  static nls = nls;

  @property({ type: Boolean })
  selectionActive = false;

  multiselection = true;

  _timeout;

  popupContainer;

  popupOptions = {};

  popupTimeout: ReturnType<typeof setTimeout> | undefined;

  constructor() {
    super();
    this.noDataMessage = this.i18n.t('noDataMessage');
    this.normalizeSpecialCharacters = true;
  }

  // eslint-disable-next-line class-methods-use-this
  get localStorageId() {
    return `ww-station-table-columnHidder`;
  }

  showPopup(station, e) {
    this.popupElement = document.createElement(
      this.popupComponent || 'ww-map-popup-auto',
    );
    const pelement = this.popupElement;

    pelement.layerAlias = this.layerAlias;
    pelement.stations = [station];
    pelement.style.position = 'fixed';
    pelement.style.background = 'white';
    pelement.style.zIndex = '111';
    pelement.style.border = '1px solid #ddd';
    Object.assign(this.popupElement, this.popupOptions);
    this.popupContainer.appendChild(pelement);
    pelement.style.display = 'block';
    // const tablebody = this.renderRoot.querySelector('.body');
    // const {top} = tablebody.getBoundingClientRect();
    const top = 150;
    const obs = new ResizeObserver(() => {
      if (pelement.clientHeight > 0) {
        const topoffset = top + pelement.clientHeight;
        const maxLeft = window.document.body.clientWidth - pelement.clientWidth;
        const posY =
          e.clientY > topoffset ? e.clientY - pelement.clientHeight : e.clientY;
        const posX =
          e.clientX > maxLeft
            ? e.clientX - pelement.clientWidth - 30
            : e.clientX + 30;

        pelement.style.left = `${posX}px`;
        pelement.style.top = `${posY}px`;
      }
    });

    obs.observe(pelement);
  }

  hidePopup() {
    if (this.popupElement) {
      this.popupElement.remove();
    }
  }

  disconnectedCallback() {
    if (super.disconnectedCallback) super.disconnectedCallback();
    this.hidePopup();
  }

  showPopupForItem(item, e) {
    this.hidePopup();
    this.showPopup(item, e);
  }

  startPopupTimer(item, e) {
    if (!this.classList.contains('sm-screen') && this.tablePopup) {
      if (this.popupTimeout) {
        clearTimeout(this.popupTimeout);
      }
      this.popupTimeout = setTimeout(() => this.showPopupForItem(item, e), 200);
    }
  }

  endPopupTimer() {
    if (this.popupTimeout) {
      clearTimeout(this.popupTimeout);
      this.popupTimeout = undefined;
    }
    this.hidePopup();
  }

  mouseenter(e, item) {
    this.startPopupTimer(item, e);
  }

  mouseleave(e) {
    this.endPopupTimer(e);
  }
}
