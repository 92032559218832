/* eslint-disable class-methods-use-this */
import { LitElement, PropertyValues, html } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
// import './components'; // TODO: Why?
import { uniqBy } from 'lodash-es';
import { Subscription } from 'rxjs/internal/Subscription';
import { Series, ViewConfig, Station, Parameter } from '../../defs';
import { WiskiTsTransformation } from '../../util';
import './ts-graph-echarts';
import './ts-graph-list';
import './ts-value-table';
import { getLastParamString } from '../ki-app';
import { ConfigLoader, IService, SeriesFactory } from '../../services';
import { Mix, formatNumber, dayjs } from '../../common';
import { i18nMixin, responsiveMixin } from '../../decorators';
import { TsGraphHelper } from './util/TsGraphHelper';
import nls from '../../locales/index';
import { echartsConfigTemplate } from './EchartsConfigTemplate';
import '../ki-timeseries-explorer/ki-timeseries-explorer';
import { Loader } from './util/decorators';
import style from './ts-graph-viewer.css';
import { StationSelectionItem } from '../ki-timeseries-explorer/StationSelectionItem';
import {
  setSearchParam,
  hashChangedSubject,
  getSearchParam,
} from '../ki-app/routing/ki-router';

@customElement('ts-graph-viewer')
export class TsGraphViewer extends Mix(LitElement, responsiveMixin, [
  i18nMixin,
  { nls },
]) {
  static styles = style;

  configLoader: ConfigLoader = new ConfigLoader();

  @property({ type: Object })
  service: IService | null = null;

  @query('ki-time-range-picker')
  rangePicker: any | undefined;

  options: any = echartsConfigTemplate;

  /** Enable Close Button, e.g. when used in Dialog   */
  @property({ type: Boolean })
  closeable: Boolean = false;

  datePattern = 'DD.MM.YYYY';

  @property({ type: Boolean })
  _showTsList: Boolean = false;

  @property({ type: Boolean })
  _showValueTable: Boolean = false;

  /** False: station view; True: ts groups */
  @property({ type: Boolean })
  layers: Boolean = true;

  @property({ type: String })
  locale: String = 'en';

  @property({ type: Boolean })
  noactions: Boolean = true;

  @property({ type: Object })
  numberFormat: Intl.NumberFormatOptions | undefined;

  @property({ type: Boolean })
  preselectTimeseries: Boolean = false;

  @property({ type: Boolean })
  yAxisBold: Boolean = true;

  @property({ type: Object, attribute: true })
  parameterTranslations: Map<string, string> = new Map();

  @property({ type: Array, attribute: true })
  selectedIds: Array<String> = [];

  @property({ type: Boolean })
  showStationNamesInLegend: Boolean = false;

  @property({ type: Boolean })
  useDefaultColors: Boolean = false;

  @property({ type: Boolean })
  yStart0: Boolean = true;

  @property({ type: String })
  customFontFamily: String | undefined;

  @property({ type: Array, attribute: false })
  tsList: Array<Series> = [];

  /** Enable TimeseriesExplorer (add any available timeseries) */
  @property({ type: Boolean })
  tsExplorer: Boolean = false;

  subscription: Subscription | undefined;

  /** Custom filter is used for reducing the timeseries neduced by KIWIS
   * @remark If not defined, the received tsList will be returned without modifications
   * @example Simple filter based on property
   * ```
   * function (list) {
              return list.filter(ts =>
                ['LVN', 'LVN-Web', 'LVN-Web-App', 'LVN-Web-LHP-App'].includes(
                  ts.LANUV_PARA_PUBLIC,
                ),
              );

   * ```
   */
  @property({ type: Function, attribute: false })
  customFilter = list => list;

  tsInfoOptions: Object | undefined = undefined;

  selectedSeries: Map<String, Series> = new Map();

  showListAtStartup: Boolean = false;

  loaded: Array<Series> = [];

  from: Date = dayjs().subtract(1, 'month').toDate();

  until: Date = dayjs().toDate();

  periods: Array<Array<String>> = [
    [
      'last24hour',
      'last7day',
      'last1month',
      'last6month',
      'last1year',
      'last5year',
      'last10year',
    ],
  ];

  viewConfig: ViewConfig = { timeseries: [] };

  get graphSettings() {
    return {
      useDefaultColors: this.useDefaultColors,
      showStationNamesInLegend: this.showStationNamesInLegend,
    };
  }

  get station(): Station {
    return this.stations[0];
  }

  get stations(): Array<Station> {
    const uniqueStations = new Map<string, Station>();
    this.tsList.forEach(ts => uniqueStations.set(ts.station.id, ts.station));
    return Array.from(uniqueStations.values());
  }

  get parameters(): Array<Parameter> {
    const uniqueParameters = new Map<string, Parameter>();
    this.tsList.forEach(ts =>
      uniqueParameters.set(ts.parameter.shortName, ts.parameter),
    );
    return Array.from(uniqueParameters.values());
  }

  connectedCallback() {
    if (super.connectedCallback) super.connectedCallback();

    const ctx = this;
    window.addEventListener('hashchange', function handleChange() {
      this.removeEventListener('hashchange', handleChange); // Only once
      ctx._loadView(getLastParamString());
    });

    this.seriesFactory = new SeriesFactory(this.graphSettings);
  }

  protected async firstUpdated() {
    this.valueTable = this.shadowRoot.querySelector('#valueTable');
    this.seriesList = this.shadowRoot.querySelector('#seriesList');

    this._showTsList = this.showListAtStartup;

    this.rangePicker.value = `${dayjs(this.from).format(
      this.datePattern,
    )} - ${dayjs(this.until).format(this.datePattern)}`;

    this.tsList = TsGraphHelper.applyMetaData(this.tsList, this.configLoader);

    this._loadView(getLastParamString());

    // detect url change
    this.subscription = hashChangedSubject.subscribe(() => {
      const tsParam = getSearchParam('selectedTsList');
      const tableParam = getSearchParam('tableOpen');
      tsParam ? (this._showTsList = true) : (this._showTsList = false);
      tableParam === 'true'
        ? (this._showValueTable = true)
        : (this._showValueTable = false);
    });
  }

  updated(changed: PropertyValues<this>) {
    if (changed.has('_showValueTable')) {
      setSearchParam('tableOpen', this._showValueTable?.toString());
    }
  }

  disconnectedCallback() {
    this.subscription?.unsubscribe();
  }

  _loadView(view: string) {
    this.viewConfig = this.configLoader.getView(view) || { timeseries: [] };

    //  Toggle Preselected timeseries according to viewConfig
    if (this.viewConfig?.timeseries.length) {
      this.viewConfig.timeseries.forEach(vc => {
        if (Array.isArray(vc)) {
          const res = vc
            .map(i =>
              this.tsList.find(ts => ts.shortName === i.name && i.preselected),
            )
            .filter(x => x !== undefined)[0];
          this.selectedSeries.set(res.id, res);
          this.selectedIds.push(res.id);
        } else {
          const res = this.tsList.find(
            ts => ts.shortName === vc.name && vc.preselected,
          );
          if (res) {
            this.selectedSeries.set(res.id, res);
            this.selectedIds.push(res.id);
          }
        }
      });
    }

    this.requestUpdate();
  }

  /**
   * Resolve axes which should be rendered
   * @remark Applies yAxisIndex to each series
   * @returns All y-Axes; gets merged to defaultConfig
   */
  _generateYAxes() {
    const uniqueParameters = uniqBy(
      Array.from(this.selectedSeries.values()).map(
        (series: Series) => series.parameter,
      ),
      o => o.name,
    );

    const _yAxes = uniqueParameters.map((para, index) => ({
      type: 'value',
      // id: para.name,
      name: `${
        this.parameterTranslations?.has(para.shortName) ?? false
          ? this.parameterTranslations.get(para.shortName)
          : para.shortName
      } [${para.unit}]`,
      _id: `${para.name}`,
      nameRotate: 90,
      nameLocation: 'center',
      alignTicks: true,
      index,
      position: TsGraphHelper.getAxisPosition(index),
      offset: TsGraphHelper.calculateOffset(index),
      nameGap: 60,
      min: this.yStart0 ? 0 : 'dataMin',
      axisPointer: {
        label: {
          show: true,
          formatter: (args: { value: number }) =>
            `${formatNumber(args.value, this.numberFormat)} ${para.unit}`,
        },
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: this.useDefaultColors
            ? 'grey'
            : this.configLoader.getParameterBaseColor(para.shortName) || 'grey',
        },
      },
      axisLabel: {
        fontWeight: this.yAxisBold ? 'bold' : undefined,
        formatter: (value: number) => formatNumber(value, this.numberFormat),
      },
      nameTextStyle: {
        fontWeight: this.yAxisBold ? 900 : undefined,
      },
    }));

    // Assign series to yAxes by index
    this.options.series = this.options.series.map(s => {
      s.yAxisIndex = _yAxes.find(yaxis => yaxis._id === s.parameterName)?.index;
      return s;
    });
    this.options.yAxis = _yAxes.length ? _yAxes : [{}];

    // Resize Grid
    this.options.grid = {
      ...this.options.grid,
      left: TsGraphHelper.calculateOffset(_yAxes.length - 1) + 60,
      right: TsGraphHelper.calculateOffset(_yAxes.length - 2) + 60,
    };
  }

  /**
   * @returns name of station
   */
  renderHeaderTitle() {
    return html` <div
      style="display: flex; flex-direction: row; align-items: center"
    >
      ${this._renderHeaderStation()} ${this._renderHeaderParameters()}
    </div>`;
  }

  _renderHeaderStation() {
    return this.stations.length === 1
      ? html`<div style="margin-right: 15px"><b>${this.station.name}</b></div>`
      : html``;
  }

  _renderHeaderParameters() {
    return this.parameters.map(para => {
      const baseColor =
        this.configLoader.getParameterBaseColor(para.shortName) ?? 'grey';
      return html`<div
        style="background-color: ${baseColor}; color: white; font-size: 14px; border-radius: 12px;  padding: 5px 10px; margin-right: 5px"
      >
        ${para.name}
      </div>`;
    });
  }

  render() {
    return html` <header>
        <slot class="title">${this.renderHeaderTitle()}</slot>
        <div class="actions" part="actions">
          <ki-time-range-picker
            showperiodlist
            initMin="${dayjs(this.from).valueOf()}"
            initMax="${dayjs().valueOf()}"
            dateFormat="DD.MM.YYYY"
            @changedate="${this.dateChange}"
            .periods="${this.periods}"
          ></ki-time-range-picker>
          ${this.tsExplorer
            ? html`<ki-icon-btn
                part="search"
                id="timeseries-search-button"
                title="${this.i18n.t('timeseriesExplorer')}"
                icon="ki ki-search"
                @click="${e => {
                  const tsexp = this.shadowRoot.querySelector('#ts-explorer');
                  tsexp.getPopover().showAt(e.target);
                }}"
              ></ki-icon-btn>`
            : ``}
          <ki-icon-btn
            part="list"
            toggle
            ?active="${this.showListAtStartup}"
            id="timeseries-list-button"
            title="${this.i18n.t('timeseriesSelection')}"
            icon="ki ki-line-chart2"
            @click="${() => {
              this._showTsList = !this._showTsList;
            }}"
          ></ki-icon-btn>
          <ki-icon-btn
            part="download"
            id="download-button"
            title="${this.i18n.t('downloadGraph')}"
            icon="ki ki-download"
            @click="${() => {
              this.shadowRoot
                .querySelector('#chartWrapper')
                .downloadImage(this.stations, this.parameters);
            }}"
          ></ki-icon-btn>
          <ki-icon-btn
            part="valuetable"
            toggle
            id="value-table-button"
            title="${this.i18n.t('valueList')}"
            dateFormat="DD.MM.YYYY"
            icon="ki ki-table"
            @click="${() => {
              this._showValueTable = !this._showValueTable;
            }}"
          ></ki-icon-btn>
          ${this.closeable
            ? html` <ki-icon-btn
                part="close"
                id="close-button"
                title="${this.i18n.t('close')}"
                icon="ki ki-times"
                @click="${() => {
                  this.dispatchEvent(new CustomEvent('closeGraph', {}));
                }}"
              ></ki-icon-btn>`
            : ``}
        </div>
      </header>
      <main>
        <ts-graph-echarts
          id="chartWrapper"
          .options="${this.options}"
          .locale="${this.i18n.language}"
        ></ts-graph-echarts>
        ${this._showTsList || this._showValueTable
          ? html`<div id="separator" style="display: none"></div>`
          : html``}
        <ts-graph-list
          id="seriesList"
          style="display: ${this._showTsList ? 'flex' : 'none'}"
          .service=${this.service}
          .selectedSeries="${this.selectedSeries}"
          .selectedIds="${this.selectedIds}"
          ?noactions="${this.noactions}"
          ?layers="${this.layers}"
          .tsList="${this.tsList}"
          .useDefaultColors="${this.useDefaultColors}"
          @selected="${(e: CustomEvent) =>
            this.getTsData(e.detail.series, e.detail.remove)}"
          @removeTs="${(e: CustomEvent) => {
            this.removeTsFromGraph(e.detail);
          }}"
          ?preselectTimeseries=${this.preselectTimeseries}
          .viewConfig=${this.viewConfig}
        ></ts-graph-list>
        <ts-value-table
          id="valueTable"
          style="display: ${this._showValueTable ? 'flex' : 'none'};"
          .selectedSeries=${this.selectedSeries}
          .viewConfig=${this.viewConfig}
        ></ts-value-table>
        ${this.tsExplorer ? this.renderTsExplorer() : ``}
      </main>`;
  }

  dateChange(e: CustomEvent) {
    const { fromTimeUnix, toTimeUnix } = e.detail;
    if (fromTimeUnix < toTimeUnix) {
      this.selectedSeries.forEach((serie: Series) => {
        serie.data = undefined;
      });
      this.from = dayjs(fromTimeUnix).toDate();
      this.until = dayjs(toTimeUnix).toDate();

      this.getTsData();
    }
  }

  renderTsExplorer() {
    return html`<ki-timeseries-explorer
      id="ts-explorer"
      .selectedStation=${this.stations?.length === 1
        ? new StationSelectionItem(this.station)
        : new StationSelectionItem()}
      .options=${this.tsExplorerOptions}
      @addTimeseries="${this._handleAddTimeseries}"
      .viewConfig=${this.viewConfig}
      .customFilter="${this.customFilter
        ? this.customFilter
        : function (list) {
            // console.debug('Apply Default filter');
            return list;
          }}"
    ></ki-timeseries-explorer>`;
  }

  _handleAddTimeseries(e) {
    const arr = TsGraphHelper.applyMetaData(
      WiskiTsTransformation.normalizeTsArray(
        e.detail.value,
        this.tsInfoOptions,
      ),
      this.configLoader,
    );

    arr.forEach(i => {
      if (!this.tsList.map(ts => ts.id).includes(i.id)) {
        this.selectedIds.push(i.id);
        this.selectedSeries.set(i.id, i);
        this.tsList.push(i);
      } else console.warn('Series already in list', i.name);
    });

    this.seriesList.updateList();
  }

  @Loader()
  async getTsData(series?: Array<Series>, remove?: Boolean) {
    if (series) {
      if (remove) {
        series.forEach(s => this.selectedSeries.delete(s.id));
      } else {
        series.forEach(s => this.selectedSeries.set(s.id, s));
      }
    }

    this.options.graphic.invisible = this.selectedSeries.size > 0;

    // Font Handling
    if (this.customFontFamily && this.customFontFamily.length > 0) {
      if (this.options.textStyle) {
        this.options.textStyle.fontFamily = this.customFontFamily;
      } else {
        this.options.textStyle = {
          fontFamily: this.customFontFamily,
        };
      }
    }

    const dataRequest = Array.from(this.selectedSeries.values()).filter(
      serie => !serie.data || serie.data.length === 0,
    );

    if (dataRequest.length > 0 && this.service) {
      await this.service.data(
        dataRequest,
        this.configLoader,
        this.from,
        this.until,
      );
    } else if (!this.service) console.warn('No service defined');

    /**
     * Set naming mode for timeseries in legend
     * @remark Support for TsName (always), Station and Parameter (optional, if multiple selectected)
     * */
    this.seriesFactory.options.showStationNamesInLegend =
      this.stations.length > 1;
    this.seriesFactory.options.showParameterNamesInLegend =
      this.parameters.length > 1;

    this.options.series = Array.from(this.selectedSeries.values()).map(
      (series: Series) =>
        this.seriesFactory.getSeries(series, this.numberFormat),
    );

    this.options.xAxis.min = dayjs(this.from).valueOf();
    this.options.xAxis.max = dayjs(this.until).valueOf();

    this.options.graphic.style.text = this.i18n.t('pleaseSelectATimeseries');

    this._generateYAxes();

    this.options = { ...this.options };

    this.valueTable?.updateSelection();
    this.requestUpdate();
  }

  async removeTsFromGraph(id) {
    // console.debug('before', id, this.selectedIds, this.selectedSeries);
    this.selectedSeries.delete(id);
    this.selectedIds = Array.from(this.selectedSeries.values()).map(
      series => series.id,
    );

    // console.debug('after', id, this.selectedIds, this.selectedSeries);

    this.tsList = this.tsList.filter(ts => ts.id !== id);

    this.options.series = this.options.series.filter(s => s.id !== id);
    this._generateYAxes();

    this.options = { ...this.options };
    this.valueTable?.updateSelection();
    this.requestUpdate();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ts-graph-viewer': TsGraphViewer;
  }
}
