import './ki-loader';

const loaderElement = document.createElement('ki-loader');
export function Loader(): any {
  return (target: any, propertyKey: string, descriptor: any) => {
    // console.debug(target, propertyKey);
    const oldFunc = descriptor.value;

    descriptor.value = async function (...args) {
      this.renderRoot.appendChild(loaderElement);
      const result = await oldFunc.apply(this, args);
      try {
        this.renderRoot.removeChild(loaderElement);
      } catch (e) {
        console.warn(e);
      }
      return result;
    };
    return descriptor;
  };
}
