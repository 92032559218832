/* eslint-disable max-classes-per-file */
import { customElement, property } from 'lit/decorators.js';

import { unByKey } from 'ol/Observable';

import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import Fill from 'ol/style/Fill';
import { boundingExtent } from 'ol/extent';
import { i18nMixin } from '../../decorators';
import { Mix, template } from '../../common';

import _AddOn from './_AddOn';
import StationsMarkerLayer from './StationsMarkerLayer';
import StationsChartLayer from './StationsChartLayer';
import StationsTrendLayer from './StationsTrendLayer';
import MultiSelectionToggle from './MultiSelectionToggle';
import './ki-station-info';
import nls from '../../locales/index';
// TODO
@customElement('ki-stations-layer')
export default class KiStationsLayer extends Mix(_AddOn, [i18nMixin, { nls }]) {
  static get properties() {
    return {
      zIndex: { type: String, attribute: 'z-index' },

      stations: { type: Array },
      labelPositions: { type: String },
      labelTemplate: { type: String },
      tagMarkers: { type: Object },
      tagSelectedMarkers: { type: Object },
      config: { type: Object },
      currentStation: { type: Object },
      popupComponent: { type: String }, // popup component
      popupOptions: { type: Object }, // popup component
      selected: { type: Array },
      multiSelectable: { type: Boolean },
      timeActive: { type: Boolean },
      noAutoZoom: { type: Boolean },
      noDeclutter: { type: Boolean },
      displayStationLabelsOnStart: { type: Boolean },
      displayInLayerControl: { type: Boolean },
    };
  }

  @property({ type: Boolean, attribute: true })
  isSiteLayer = false;

  @property({ type: String, attribute: false })
  idProperty = this.isSiteLayer ? 'site_id' : 'station_id';

  constructor() {
    super();
    this.mapLayers = [];
    this.stations = [];
    this.selected = [];
    this.zIndex = 100;
    this.noAutoZoom = false;
    // eslint-disable-next-line no-template-curly-in-string
    this.labelTemplate = this.isSiteLayer ? '${site_name}' : '${station_name}';
  }

  updateSelection() {
    if (this.multiSelectionToggle) {
      this.stationsMarkerLayer.selection = this.selected;
      this.multiSelectionToggle.selection = this.selected;
    }
  }

  _afterMapCreated() {
    super._afterMapCreated();
    this._initPopup();
  }

  _create() {
    this._createMakerLayer();
    this._createLabelLayer();
    this.disableMouseOver = this.parentElement.classList.contains('sm-screen');
    if (this.trendKey) {
      this._createTrendLayer();
    }
    this.multiSelectable && this._createSelectionControl();
    this.map.getLayers().extend(this.mapLayers);
  }

  _createChartLayer() {
    this.stationsMarkerLayer = new StationsChartLayer({
      config: this.config,
      name: this.i18n.t('stations'),
      displayInLayerControl: this.displayInLayerControl,
      dynamicMarkerSize: this.dynamicMarkerSize,
      group: 'Layers',
      tagMarkers: this.tagMarkers,
      zIndex: this.zIndex + 1,
      currentStation: this.currentStation,
    });
    this.mapLayers.push(this.stationsMarkerLayer);
  }

  _createLabelLayer() {
    this.stationsLabelLayer = new VectorLayer({
      name: this.i18n.t('Labels'),
      displayInLayerControl: true,
      group: 'Information',
      zIndex: 112,
      labelPositions: this.labelPositions,
      visible: this.displayStationLabelsOnStart || false,
      declutter: !this.noDeclutter,
      source: new VectorSource({
        features: this.stations.map(station => {
          const f = new Feature(new Point(station.point));
          f.setProperties({ station });
          return f;
        }),
      }),
      style: feature => {
        const station = feature.get('station');
        const customPosition = this.labelPositions
          ? this.labelPositions[station?.station_id]
          : {} ?? {};
        const style = new Style({
          text: new Text({
            font: 'bold 12px "Calibri", "sans-serif"',
            text: this.labelTemplate
              ? template(this.labelTemplate, station)
              : station.station_name,
            textAlign: 'left',
            textBaseline: 'top',
            padding: [3, 3, 3, 3],
            offsetX: 12,
            offsetY: -17 / 2,
            fill: new Fill({ color: 'rgba(29,29,29,1)' }),
            backgroundFill: new Fill({ color: 'rgba(255,255,255,0.8)' }),
            ...customPosition,
          }),
        });
        return style;
      },
    });
    this.mapLayers.push(this.stationsLabelLayer);
  }

  _createTrendLayer() {
    this.stationsTrendLayer = new StationsTrendLayer({
      name: this.i18n.t('Trend'),
      displayInLayerControl: true,
      group: 'Information',
      visible: this.displayStationTrendOnStart || false,
      stations: this.stations,
      zIndex: this.zIndex + 2,
      trendKey: this.trendKey,
      timeActive: this.timeActive,
    });
    this.mapLayers.push(this.stationsTrendLayer);
  }

  _createMakerLayer() {
    if (this.config?.type === 'piechart') {
      this.stationsMarkerLayer = new StationsChartLayer({
        config: this.config,
        name: this.i18n.t('stations'),
        displayInLayerControl: this.displayInLayerControl,
        dynamicMarkerSize: this.dynamicMarkerSize,
        group: 'Layers',
        tagMarkers: this.tagMarkers,
        zIndex: this.zIndex + 1,
        currentStation: this.currentStation,
      });
    } else {
      this.stationsMarkerLayer = new StationsMarkerLayer({
        name: this.i18n.t('stations'),
        config: this.config,
        displayInLayerControl: this.displayInLayerControl,
        dynamicMarkerSize: this.dynamicMarkerSize,
        group: 'Layers',
        tagMarkers: this.tagMarkers,
        tagSelectedMarkers: this.tagSelectedMarkers,
        zIndex: this.zIndex + 1,
        currentStation: this.currentStation,
        isSiteLayer: this.isSiteLayer,
        timeActive: this.timeActive,
      });
    }

    this.stationsMarkerLayer.on('change:source', () => {
      let extent;
      if (this.config?.clusterStations?.enabled) {
        extent = this.stationsMarkerLayer.getSource().source.getExtent();
      } else {
        extent = this.stationsMarkerLayer.getSource().getExtent();
      }

      this.dispatchEvent(
        new CustomEvent('sourceChange', {
          detail: { extent },
        }),
      );
    });
    this.stationsMarkerLayer.stations = this.stations;
    this.mapLayers.push(this.stationsMarkerLayer);

    // eslint-disable-next-line consistent-return
    this.clickEvent = this.map.on('singleclick', evt => {
      const stations = [];
      if (this.config?.clusterStations?.enabled) {
        this.map.forEachFeatureAtPixel(
          evt.pixel,
          feature => {
            if (feature) {
              const _features = feature?.getProperties().features;
              if (_features.length > 1) {
                const extent = boundingExtent(
                  _features.map(r => r.getGeometry().getCoordinates()),
                );
                this.map.getView().fit(extent, {
                  duration: 1000,
                  padding: [
                    window.screen.height / 4,
                    window.screen.width / 4,
                    window.screen.height / 4,
                    window.screen.width / 4,
                  ],
                });
              }
              if (
                _features.length === 1 ||
                this.map.getView().getZoom() === this.map.getView().getMaxZoom()
              ) {
                const { station } = _features[0];
                if (station) {
                  stations.push(station);
                }
              }
            }
          },
          { layerFilter: layer => layer === this.stationsMarkerLayer },
        );
      } else {
        this.map.forEachFeatureAtPixel(
          evt.pixel,
          feature => {
            if (feature && feature.station) {
              stations.push(feature.station);
            }
          },
          { layerFilter: layer => layer === this.stationsMarkerLayer },
        );
      }

      if (stations.length > 0) {
        this.dispatchEvent(
          new CustomEvent('station-click', {
            detail: {
              value: `${stations[0].site_no}${
                this.isSiteLayer ? '' : `/${stations[0].station_no}`
              }`,
              data: stations[0],
              // TODO station
            },
          }),
        );
        return false; // skip rest listener;
      }
    });
  }

  _initPopup() {
    let activePopup = false;
    // eslint-disable-next-line consistent-return
    this.hoverEvent = this.map.on('pointermove', evt => {
      this.map.getTargetElement().style.cursor = '';
      if (!this.stationsMarkerLayer || this.disableMouseOver) {
        return false;
      }
      const stations = [];
      if (this.config?.clusterStations?.enabled) {
        this.map.forEachFeatureAtPixel(
          evt.pixel,
          /* eslint-disable-next-line consistent-return */
          feature => {
            const _features = feature?.getProperties().features;
            if (
              _features.length === 1 ||
              this.map.getView().getZoom() === this.map.getView().getMaxZoom()
            ) {
              this.map.getTargetElement().style.cursor = 'pointer';
              if (_features[0]._hoverStyle) {
                _features[0].setStyle(_features[0]._hoverStyle);
              }
              if (_features[0].station) {
                stations.push(_features[0].station);
              }
            }
          },
          { layerFilter: layer => layer === this.stationsMarkerLayer },
        );
      } else {
        this.map.forEachFeatureAtPixel(
          evt.pixel,
          /* eslint-disable-next-line consistent-return */
          feature => {
            this.map.getTargetElement().style.cursor = 'pointer';
            if (feature && feature._hoverStyle) {
              feature.setStyle(feature._hoverStyle);
            }
            if (feature && feature.station) {
              stations.push(feature.station);
            }
          },
          { layerFilter: layer => layer === this.stationsMarkerLayer },
        );
      }

      if (stations.length > 0) {
        activePopup = true;
        this.map.dispatchEvent({
          type: 'show-popup',
          detail: {
            coordinate: this.map.getCoordinateFromPixel(evt.pixel),
            element: this.element,
            stations,
            layerAlias: this.layerAlias,
          },
        });
        return false;
      }
      if (activePopup) {
        activePopup = false;
        this.map.dispatchEvent({
          type: 'close-popup',
          detail: {
            element: this.element,
          },
        });
      }
      // prevent other listener
    });
  }

  _createSelectionControl() {
    this.multiSelectionToggle = new MultiSelectionToggle(
      [this.stationsMarkerLayer],
      {
        idProperty: this.idProperty,
        displayInLayerControl: true,
        onSelection: selection => {
          this.dispatchEvent(
            new CustomEvent('selection', { detail: { selection } }),
          );
        },
        options: [
          {
            icon: 'ki ki-mouse-pointer',
            label: this.i18n.t('move_in_map'),
            value: 'mover',
          }, // TODO i18n ?
          {
            icon: 'ki ki-object-group',
            label: this.i18n.t('Select_stations'),
            value: 'selector',
          },
        ],
      },
    );
    this.map.addControl(this.multiSelectionToggle);
    this.multiSelectionToggle.selection = this.selected;
    this.stationsMarkerLayer.selection = this.selected;
  }

  _tear() {
    this.mapLayers.forEach(l => this.map.removeLayer(l));
    this.map.removeControl(this.multiSelectionToggle);

    this.multiSelectionToggle = null;
    this.stationsLabelLayer = null;
    this.stationsTrendLayer = null;
    this.stationsMarkerLayer = null;

    this.mapLayers = [];
    unByKey(this.clickEvent);
    unByKey(this.hoverEvent);
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);
    this.element = document.createElement(
      this.popupComponent || 'ww-map-popup-auto',
    );
    Object.assign(this.element, this.popupOptions ?? {});
    if (this.map) {
      // TODO how to update. dirty code

      if (_changedProperties.has('stations')) {
        if (this.stationsMarkerLayer) this.stationsMarkerLayer._legends = null;
        if (this.stationsLabelLayer) {
          this.stationsLabelLayer.setSource(
            new VectorSource({
              features: this.stations
                .filter(station => station.point !== undefined)
                .map(station => {
                  const f = new Feature(new Point(station.point));
                  f.setProperties({ station });
                  return f;
                }),
            }),
          );
        }
        this.mapLayers.forEach(l => {
          l.selection = this.selected;
          l.stations = this.stations;
        });
      }

      if (_changedProperties.has('tagMarkers')) {
        if (this.stationsMarkerLayer) {
          this.stationsMarkerLayer.tagMarkers = this.tagMarkers;
        }
      }

      if (_changedProperties.has('tagSelectedMarkers')) {
        if (this.multiSelectionToggle) {
          this.multiSelectionToggle.tagSelectedMarkers =
            this.tagSelectedMarkers;
        }
      }
    }
  }
}
