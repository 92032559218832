// eslint-disable-next-line max-classes-per-file
import { customElement, property } from 'lit/decorators.js';
import { find } from 'lodash-es';
import { initAPI, getCurrentApi } from '@kisters/wiski-web/api/API';
import {
  getConfig,
  getSearchParam,
  initConfig,
  setSearchParam,
} from '@kisters/wiski-web/components';
import KiWwpGraph from '@kisters/wiski-web/components/ki-wwp-graph/ki-wwp-graph';
import { KIWIS } from '@kisters/wiski-web/api';
import dayjs from '@kisters/wiski-web/common/dayjsext';

@customElement('ki-wwp-graph-hdoe-fcst')
export default class KiWwpGraphHdoeFcst extends KiWwpGraph {
  @property({ type: String })
  key;

  @property({ type: String })
  branch = '';

  @property({ type: String })
  trendAb = '';

  @property({ type: String })
  prognoseDuration = '';

  @property({ type: String })
  stationId;

  @property({ type: String })
  dataPath;

  @property({ type: Boolean })
  dynamicData = false;

  @property({ type: String })
  tsId;

  favButton = false;

  config = getConfig();

  options = {};

  api;

  firstUpdated() {
    this.numberFormatter = new Intl.NumberFormat(
      this.i18n.language,
      this.numberFormat || {},
    );
    this.initAppConfig();
    this.refreshInterval = setInterval(() => {
      this.fetchData();
    }, 300000);

    /** Open graph from mobile view; table always open in desktop (see app.json) */
    if (
      this.renderRoot.host.classList.contains('sm-screen') ||
      this.miniGraph
    ) {
      this.tableOpen = false;
    }
  }

  async initAppConfig() {
    /** Fallback fot graph_fcst.html */
    this.config = await initConfig('./config/app.json', []);
    initAPI({ dataPath: this.config.apiPath });
    this.api = getCurrentApi();
    this.kiwis = new KIWIS({
      ...this.config?.kiwis,
    });
    this.fetchData();
  }

  async fetchData() {
    this.urlPeriod = getSearchParam('period'); // Assuming 'period' is the query parameter key
    console.log(this.urlPeriod);
    dayjs.locale(this.i18n.language);
    const stationDetailComp = find(this.config.route[0].children, {
      component: 'wwp-station-detail',
    });
    const configItem = find(stationDetailComp?.children, { path: this.key });
    if (configItem) {
      Object.assign(this, configItem.options);
    } else if (this.options) {
      // e.g. Assign popup options (WW-483)
      Object.assign(this, this.options);
    }
    let selected = find(this.ranges, { selected: true });

    selected = this._selectedPeriod || selected?.value || this.ranges[0].value;
    if (!find(this.ranges, { value: selected })) {
      selected = this.ranges[0].value;
    }
    this._selectedPeriod = selected;
    setSearchParam('period', this.urlPeriod);
    super.fetchData();
  }

  getImageShare() {
    return this.api
      ? `${window.location.origin}${window.location.pathname.replace(
          /[^/]*$/,
          '',
        )}graph.html?stationId=${
          this.station.station_id
        }&component=ki-wwp-graph-hdoe-fcst-ext&key=${encodeURIComponent(
          this.apppath?.path,
        )}&period=${this._selectedPeriod}`
      : '';
  }

  /** fcst.json is generated by forecast-selection tool (Prognosesteuerung) */
  async drawForecast() {
    let ts = this.files[this.forecast];
    if (!ts) {
      return;
    }

    // Avoid API cache and Browser cache
    const fcstConfig = await this.api._requestJson(
      `/forecastSettings.json?ts=${new Date().getTime()}`,
      this.api.configPath,
      true,
    );
    console.debug('# ForecastSettings', fcstConfig);

    const stationConfig: ForecastStationConfig = fcstConfig.find(
      s => s.station_id === this.stationId,
    );
    console.debug(
      '# -> StationConfig for station_id',
      this.stationId,
      stationConfig,
    );

    if (this.dynamicData) {
      if (!this.timestamp || !this.tsId) {
        return;
      }
      ts = await this.kiwis.getTimeseriesValues({
        format: 'json',
        ts_id: this.tsId,
        from: this.timestamp,
        to: this.timestamp,
      });
    } else if (!stationConfig.auto || (stationConfig.auto && stationConfig.selectedForecast && stationConfig.intervall !=="")) {
      try {
        ts = await this.api.getTsData(
          `internet/stations/${stationConfig.ts_path
            .split('/')
            .slice(0, 3)
            .join('/')}/fcst.json`,
        );
        console.debug('# Found fcst.json', ts);
      } catch (e) {
        console.warn('# Custom forecast fcst.json file not found');
        return;
      }
    }

    this.chart.get('mainXAxis').setExtremes(null, null, false, false);

    const { data, columns } = ts[0];
    const colArray = columns.split(',');
    const firstDataPoint = data[0];
    if (!firstDataPoint) {
      return;
    }
    const progTs = dayjs(firstDataPoint[0]).tz().valueOf(); // this.station[this.progTs] ? dayjs(this.station[this.progTs]+"Z").valueOf() : dayjs().valueOf();

    const trendAb = stationConfig?.trendAb
      ? Number.parseInt(stationConfig?.trendAb, 10)
      : null;


      const progDur = stationConfig?.prognoseDuration
      ? Number(stationConfig?.prognoseDuration)
      : null;

    const prognoseBisTs = dayjs(progTs).tz().add(progDur, 'hours').valueOf();
    const trendAbTs = dayjs(progTs).tz().add(trendAb, 'hours').valueOf();

    const prognoseBranches = this.branch || stationConfig.branch;
    const indexMin = colArray.indexOf('Min');
    const indexMax = colArray.indexOf('Max');
    const indexMittel = colArray.indexOf('Mittel');
    const indexTUMin = colArray.indexOf('TUMin');
    const indexTUMax = colArray.indexOf('TUMax');
    const indexTUMittel = colArray.indexOf('TUMittel');

    const dataTrend = [];
    const dataTrendVB = [];
    const dataProg = [];
    const dataProgVB = [];

    let actualMin;
    let actualMax;
    let actualMean;

    if (prognoseBranches === 'Alle-Ext' || prognoseBranches === 'Min/Max-Ext') {
      actualMin = indexMin;
      actualMax = indexMax;
    } else if (
      prognoseBranches === 'Alle-Int' ||
      prognoseBranches === 'Min/Max-Int'
    ) {
      actualMin = indexTUMin;
      actualMax = indexTUMax;
    }
    if (
      prognoseBranches === 'Alle-Ext' ||
      prognoseBranches === 'Prognose-Ext'
    ) {
      actualMean = indexMittel;
    } else if (
      prognoseBranches === 'Alle-Int' ||
      prognoseBranches === 'Prognose-Int'
    ) {
      actualMean = indexTUMittel;
    }

    data.forEach(row => {
      const tsValue = dayjs(row[0]).tz().valueOf();
      if (tsValue < prognoseBisTs) {
        if (trendAb && tsValue >= trendAbTs) {
          dataTrend.push([tsValue, row[actualMean]]);
          dataTrendVB.push([tsValue, row[actualMin], row[actualMax]]);
        } else {
          dataProg.push([tsValue, row[actualMean]]);
          dataProgVB.push([tsValue, row[actualMin], row[actualMax]]);
        }
      }
    });

    const xAxis = this.chart.get('mainXAxis');
    if (stationConfig.publish) {
      xAxis.addPlotLine({
        id: 'pz',
        zIndex: 2,
        color: 'gray',
        width: 2,
        value: progTs,
        label: {
          //   rotation:270,
          x: 5,
          y: this.miniGraph ? 80 : 120,
          verticalAlign: 'top',
          text: this.miniGraph ? `VHS` : 'Prognosezeitpunkt',
        },
      });
    }

    if (trendAb && stationConfig.trend && stationConfig.publish) {
      xAxis.addPlotLine({
        id: 'trend',
        zIndex: 2,
        color: 'gray',
        width: 2,
        value: trendAbTs,
        label: {
          // rotation:270,
          x: 5,
          y: this.miniGraph ? 80 : 120,
          verticalAlign: 'top',
          text: this.miniGraph ? `Trend` : `ab >${trendAb}h = Trend`,
        },
      });
      this.chart.addSeries(
        {
          name: 'Trend',
          selected: true,
          color: 'green',
          id: 'data_trend',
          dashStyle: 'dot',
          data: dataTrend,
          events: {
            hide: () => {
              xAxis.removePlotLine('trend');
            },
            show: () => {
              xAxis.addPlotLine({
                id: 'trend',
                zIndex: 2,
                color: 'gray',
                width: 2,
                value: trendAbTs,
                label: {
                  // rotation:270,
                  x: 5,
                  y: this.miniGraph ? 80 : 120,
                  verticalAlign: 'top',
                  text: this.miniGraph ? `Trend` : `ab >${trendAb}h = Trend`,
                },
              });
            },
          },
        },
        false,
      );
      this.chart.addSeries(
        {
          name: 'Vertrauensbereich Trend',
          type: 'arearange',
          fillOpacity: 0.5,
          linkedTo: 'data_trend',
          id: 'data_trendarea',
          showInLegend: false,
          selected: true,
          dashStyle: 'dot',
          fillColor: 'rgba(124,181,236,0.3)',
          data: dataTrendVB,
        },
        false,
      );
    }

    if (stationConfig.publish) {
      this.chart.addSeries(
        {
          name: 'Vertrauensbereich',
          type: 'arearange',
          fillOpacity: 0.5,
          id: 'data_forecastarea',
          selected: true,
          color: 'rgba(124,181,236,1)',
          showInLegend: false,
          fillColor: 'rgba(124,181,236,0.5)',
          data: dataProgVB,
          linkedTo: 'data_forecast',
        },
        false,
      );

      this.chart.addSeries({
        name: 'Vorhersage',
        selected: true,
        color: 'green',
        id: 'data_forecast',
        data: dataProg,
        events: {
          hide: () => {
            xAxis.removePlotLine('pz');
          },
          show: () => {
            xAxis.addPlotLine({
              id: 'pz',
              zIndex: 2,
              color: 'gray',
              width: 2,
              value: progTs,
              label: {
                //   rotation:270,
                x: 5,
                y: this.miniGraph ? 80 : 120,
                verticalAlign: 'top',
                text: this.miniGraph ? `VHS` : 'Prognosezeitpunkt',
              },
            });
          },
        },
      });
    }
  }
}
