import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('ki-loader')
export class KiLoader extends LitElement {
  protected render() {
    return html`
      <div class="overlay">
        <div class="overlay__inner">
          <div class="overlay__content"><span class="spinner"></span></div>
        </div>
      </div>
    `;
  }

  static styles = css`
    :host {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .overlay {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgba(255, 255, 255, 0.2);
    }

    .overlay__inner {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .overlay__content {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .spinner {
      width: 75px;
      height: 75px;
      display: inline-block;
      border-width: 2px;
      border-color: rgba(128, 128, 128, 0.5);
      border-top-color: #fff;
      animation: spin 1s infinite linear;
      border-radius: 100%;
      border-style: solid;
    }

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  `;
}
declare global {
  interface HTMLElementTagNameMap {
    'ki-loader': KiLoader;
  }
}
