/* eslint-disable class-methods-use-this */
import { css, html, LitElement, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import Highcharts from 'highcharts/highstock';
import { orderBy, merge } from 'lodash-es';
import { until } from 'lit/directives/until.js';
import { Mix, dayjs, hclocales } from '../../common';
import { i18nMixin } from '../../decorators';
import { formatNumber } from '../../common/NumberFormatter';
import nls from '../../locales/index';
import { getCurrentApi } from '../../api/API';
import { getConfig } from '../ki-app/services/ki-app-config-service';
import { AppConfigDefinition } from '../../defs/AppConfigDefinition';

const valFormatter = (val: number): string =>
  val === 0 ? val : formatNumber(val, {});

@customElement('ww-map-popup-base')
export default class WwMapPopup extends Mix(LitElement, [i18nMixin, { nls }]) {
  static styles = css`
    :host {
      display: block;
      z-index: 100;
      max-width: 420px;
      background: white;
    }
    .station-name {
      padding-right: 5px;
      padding-left: 10px;
      padding-top: 10px;
      font-size: 1.2em;
    }

    hr {
      margin: 5px 0;
    }
    .table {
      display: table;
      line-height: 1.5em;
    }
    .row {
      display: table-row;
    }

    .hidden {
      display: none !important;
    }

    .highlight {
      font-weight: bold;
    }

    .cell {
      display: table-cell;
      padding: 0 10px;
      min-width: 50px;
      color: gray;
    }

    .label {
      color: #4a4a49;
    }

    .highcharts-label {
      display: none;
    }

    #chartcontainer {
      border-top: 1px dotted lightgray;
      height: 240px;
      min-width: 360px;
      width: 100%;
    }

    :host([flexSize]) #chartcontainer {
      width: auto;
      flex: 1;
      height: auto;
    }

    :host([flexSize]) {
      max-width: initial;
    }

    .remark {
      padding: 2px 10px;
      color: red;
      max-width: fit-content;
    }
  `;

  @property({ type: Boolean })
  flexSize = false;

  @property({ type: Array })
  stations: Array<object> = [];

  config: AppConfigDefinition = getConfig();

  /* Additional comment also displayed in left Menu at stationDetils
   * Config: app -> remarkAttr
   */
  @property({ type: String })
  stationRemark;

  constructor() {
    super();
    if (hclocales[this.i18n.language.substring(0, 2)]) {
      Highcharts.setOptions({
        lang: hclocales[this.i18n.language.substring(0, 2)],
      });
    }
    this.stations = [];
    this.api = getCurrentApi();
  }

  // TODO: get formatting options for table tooltip from the config based on the speccific parameter
  /* eslint-disable-next-line class-methods-use-this */
  valFormatter(val) {
    return val === 0
      ? val
      : formatNumber(val, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
  }

  /* eslint-disable-next-line class-methods-use-this */
  _getRow(station, mainParameter) {
    const isMain =
      (mainParameter && mainParameter === station.stationparameter_no) ||
      mainParameter === station.stationparameter_name;
    const parameter =
      station.stationparameter_longname || station.stationparameter_name;
    const value =
      station.ts_value != null
        ? `${valFormatter(station.ts_value)} ${station.ts_unitsymbol}`
        : '-';
    // @TODO: Adapt harvester to return only a unified parameter i.e., timestamp. ts_timestamp not allowed
    const ts = station?.ts_timestamp || station?.timestamp;
    const timestamp = ts ? dayjs(ts).tz().format('L LT') : '-';
    return html`<div class="row ${isMain ? 'highlight' : ''}">
      <div class="cell label">${parameter}</div>
      <div class="cell"><b>${value}</b> (${timestamp})</div>
    </div>`;
  }

  // eslint-disable-next-line class-methods-use-this
  getGraph(station): TemplateResult {
    // override for graph
    console.debug('Override for graph', station);
    return html``;
  }

  _stationTmpl(stations, graph = true) {
    const station = stations[0];
    const river = station.WTO_OBJECT ? `(${station.WTO_OBJECT})` : '';
    if (station && this.config?.remarkAttr && !this.config?.hideRemarkInPopup)
      this.stationRemark = station[this.config?.remarkAttr] || '';
    return html`<div class="station-name">
        ${station.station_no} | ${station.station_name} ${river}
      </div>
      <div class="remark">${this.stationRemark}</div>
      <hr />
      <div class="table">
        ${station.ts_values
          ? this._handleMultiTsValues(station.ts_values, station)
          : stations.map(st => this._getRow(st, station.mainParameter))}
      </div>
      ${graph
        ? until(
            this.getGraph(station),
            html`<span style="margin: 10px">...</span>`,
          )
        : ''}`;
  }

  /** ts_values can have different structures
   * @remark normal case: ts.values.[stationparameter_name].tsObject
   * @remark edgeCase (layercfg -> multiTs: true): ts_values.[stationparameter_name].[ts_shortname].tsObject
   * @returns
   */
  _handleMultiTsValues(tsValues, station) {
    // Detect case
    const first = Object.values(tsValues);
    if (first.ts_id) {
      const result = orderBy(Object.values(tsValues), [
        item => item.stationparameter_name.toLowerCase(),
      ]).map(ts => this._getRow(ts, station.mainParameter));
      return result;
    }
    const _paras = orderBy(Object.keys(tsValues));
    const _timeseries = _paras.map(p => tsValues[p]).flat();
    const result = _timeseries.map(ts =>
      /** Merge TS into station, to avoid information loss, e.g. for remarkAttr */
      this._getRow(merge(station, ts), ts.ts_shortname),
    );
    return result;
  }

  render() {
    return this?.stations?.length
      ? this._stationTmpl(this.stations)
      : html`<div></div>`;
  }
}
