import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { getCurrentApi } from '../../api';
import { getRouteOptionsAndParams } from '..';

@customElement('wwp-attribute-html')
export default class WwpAttributeHtml extends LitElement {
  static styles = css`
    :host {
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      padding: 20px;
    }
  `;

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, ['stationId']);
    this.api = getCurrentApi();
    this.stationId = params.stationId;
    this.station = await this.api.getStation(this.stationId);
    Object.assign(this, params.options);
  }

  static get properties() {
    return {
      content: { type: String },
      station: { type: Object },
    };
  }

  render() {
    // language=html
    return html`<div>
      ${this.station && this.station[this.content]
        ? unsafeHTML(this.station[this.content])
        : ''}
    </div>`;
  }
}
