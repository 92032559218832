/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import { flattenDeep, first, last } from 'lodash-es';
import { findTsByString, findFirstTsByString } from '../../common';
import { DataProviderHelper } from './DataProviderHelper';
import { KIWIS } from '../../api';

let KiWISInst;

export class DataProvider {
  parameterNo: string;

  stationId: number;

  tsOptions: object;

  constructor(stationId, parameterNo, kiwis, tsOptions) {
    if (kiwis) KiWISInst = new KIWIS(kiwis);
    else console.error('No KIWIS settings supplied');

    this.stationId = stationId;
    this.parameterNo = parameterNo;
    this.tsOptions = tsOptions;
  }

  async fetchTsList() {
    const list = await KiWISInst.getTimeseriesList(
      {
        metadata: true,
        station_id: this.stationId,
        stationparameter_no: this.parameterNo,
      },
      [
        'ts_id',
        'ts_name',
        'ts_shortname',
        'coverage',
        'ts_path',
        'ca_ts',
        'stationparameter_name',
        'stationparameter_longname',
        'stationparameter_no',
        'ts_unitsymbol',
        'ca_par', // Custom attribute
        'station_id',
        'station_name',
        'station_no',
        'site_id',
        'site_no',
      ],
    );

    try {
      this._resolveTsFromPatterns(list);

      // Further checks
      list.forEach(item => {
        // Detect threshold/alarmTs
        if (
          this.thresholdTsList &&
          Array.isArray(this.thresholdTsList) &&
          this.thresholdTsList
            .flatMap(t => t.ts_shortname)
            .includes(item.ts_shortname)
        )
          item.isThreshold = true;
        // Mark MinMax timeseries
        if (
          item.ts_shortname.toLowerCase().includes('min') ||
          item.ts_shortname.toLowerCase().includes('max')
        ) {
          item.isMinMax = true;
        }
        // Mark as preselected to keep ts-selector in sync with chart
        if (
          this.presetTsList
            .flatMap(p => p.ts_shortname)
            ?.includes(item.ts_shortname)
        )
          item.preselected = true;
      });
      console.debug('After filter: ', list.length, ' remaining: ', list);
      return list;
    } catch (e) {
      console.error('Data fetching error (Could not fetch tslist)', e);
      return [];
    }
  }

  // Convert provided patterns to available timeseries
  _resolveTsFromPatterns(l = this.tsList) {
    // Find TS
    this.mainTsObj =
      first(
        flattenDeep(this.tsOptions?.mainTs?.map(ts => findTsByString(ts, l))),
      ) ||
      first(l) ||
      {};
    this.thresholdTsList = DataProviderHelper.filterDuplicates(
      flattenDeep(this.tsOptions?.alarmTs?.map(ts => findTsByString(ts, l))),
    );
    this.presetTsList = DataProviderHelper.filterDuplicates(
      flattenDeep(this.tsOptions?.presetTs?.map(ts => findTsByString(ts, l))),
    );

    console.debug(
      'Filter Resolving Results (Main/Alarm/Preset): ',
      this.mainTsObj,
      this.thresholdTsList.length,
      this.presetTsList.length,
    );
  }

  /** ts provided as tsPath; range as unix-ms */
  async getTsData({ range, ts }) {
    // Keep selected timeframe
    const tsObj = findFirstTsByString(ts, this.tsList);
    this.graphRange = range;

    const ts_path = `${tsObj.ts_path};compress(2000)`;
    let data;
    // Get whole dataset if ts is Alarm
    // or just selected timeframe if timeseries is regular
    if (tsObj.isThreshold) {
      data = await KiWISInst.getTimeseriesValues(
        {
          ts_path,
          period: 'complete',
        },
        null,
        'json',
      );
      // Add pivot points for displaying alarmTs
      // Add left-side limit
      data[0].data.unshift([
        new Date('1950-1-1').toISOString(),
        data[0].data[0][1],
      ]);
      // Add right-side limit
      data[0].data.push([
        new Date(Date.now()).toISOString(),
        last(data[0].data)[1],
      ]);
    } else
      data = await KiWISInst.getTimeseriesValues(
        {
          ts_path,
          from: range.min,
          to: range.max,
        },
        null,
        'json',
      );
    this.dispatchEvent(
      new CustomEvent('changedate', {
        bubbles: true,
        detail: {
          fromTime: range.min,
          toTime: range.max,
        },
      }),
    );
    return data[0].data;
  }
}
