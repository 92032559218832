/* eslint-disable class-methods-use-this */
import { html, css, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Mix, dayjs } from '../../common';
import '@ui5/webcomponents/dist/Select';
import '@ui5/webcomponents/dist/Option';
import { queryParamMixin } from '../../decorators';
import type { FileGenDoc } from './FileGenDoc';
import { getCurrentApi } from '../../api';
import { getRouteOptionsAndParams } from '../ki-app/routing/ki-router';

/**
 * Document viewer for PDF files
 * @remark Can be used as a stand-alone component or inside station-details, e.g. to show station specific documents
 * @remark Will use the index-files created by the FileGen or Python Harvester
 */
@customElement('ww-documents-filegen')
export default class WwDocumentsHtml extends Mix(LitElement, [
  queryParamMixin,
  { targetProperty: 'selected', selector: 'selected', defaultValue: '' },
]) {
  // language=css
  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      height: calc(100% - 30px);
    }
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
    .left-panel {
      display: flex;
      justify-content: center;
    }
    .dropdown {
      margin: 10px 0 0;
    }
    .dropdown-button {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin: 10px 0 0;
      gap: 5px;
    }
    .right-panel {
      flex: 1;
    }

    ui5-select {
      width: 350px;
    }

    a {
      font-size: 2em;
      text-align: center;
      display: block;
      margin-top: 20%;
    }
  `;

  constructor() {
    super();
    this.api = getCurrentApi();
    this.data = [];
  }

  data: FileGenDoc[] = [];

  /** Will resolve paths from index.json file if true; */
  indexFile: string = 'index.json';

  /** Root */
  documentRootPath: string = 'documents';

  /** Lookup subfolders with station_name/stationLabel
   * @remark Root-level docs will be visisble for all stations
   * @remark Will only show root-level docs if false
   */
  showStationDocuments: boolean = true;

  stationId: string = '';

  stationName: string = '';

  _selected = '';

  get selected() {
    return this._selected;
  }

  set selected(value) {
    this._selected = value;
    this.requestUpdate();
  }

  // Vaadin Router Method that gets called after this component is called
  // eslint-disable-next-line no-unused-vars
  async onAfterEnter(location) {
    // reduce location to params
    const params = getRouteOptionsAndParams(location, [
      'content',
      'stationId',
      'stationLabel',
    ]);
    // get historyPath from options and fetch history
    this.useIndexFile = params.options.documentRootPath;
    this.documentRootPath = params.options.documentRootPath;
    this.showStationDocuments = params.options.showStationDocuments;
    this.stationId = params.stationId;
    this.stationName = params.stationLabel;
    this.dataPathPrefix = this.api.basePath;
    this.fetchData();
  }

  /** Adds a `path` property to the provided documents */
  _addPaths(docs: FileGenDoc[], path: string): FileGenDoc[] {
    return docs.map(doc => ({
      ...doc,
      path: this._cleanupUrl(`${this.api.dataPath}/${path}/${doc.name}`),
    }));
  }

  _cleanupUrl(url: string) {
    return url.replace('//', '/');
  }

  // Fetch history
  async fetchData() {
    /** Try to read index file */
    const rootObject = await this.api._requestJson(
      `${this.documentRootPath}/${this.indexFile}`,
    );
    // populate data object
    const { data, _links } = rootObject;
    this.data = this._addPaths(data, this.documentRootPath);
    if (this.showStationDocuments) {
      const stationDir = _links.filter(l =>
        l.path.endsWith(`/${this.stationName}`),
      );

      if (stationDir.length) {
        const stationDocs = await this.api._requestJson(
          `/${stationDir[0].path}/${this.indexFile}`,
        );
        // Merge all documents
        this.data = this.data.concat(
          this._addPaths(stationDocs.data, `/${stationDir[0].path}`),
        );
      }
    }
    console.debug('Loaded documents', this.data);
    // Newest to the top
    this.data.sort((a, b) => b.date - a.date);

    this._setDocument(this.data[0].path);
    this.requestUpdate();
  }

  getUrl(file) {
    return `${this.api.dataPath}/${this.directory}/${file}`;
  }

  _setDocument(path) {
    console.debug('Set new document', path);
    this.renderRoot.querySelector('#contentframe').src = path;
  }

  _getDocument() {
    return this.renderRoot.querySelector('#contentframe').src;
  }

  _handleChanged(e) {
    console.debug(e);
    this._setDocument(e.target.selectedOption.value);
  }

  _handleClick(e) {
    console.debug(e);
    console.debug(this._getDocument());
    window.open(this._getDocument(), '_blank');
  }

  render() {
    // language=html
    return this.data
      ? html`
          <div class="left-panel">
            <ui5-select @change="${this._handleChanged}" class="dropdown">
              ${this.data.map(
                item =>
                  html`<ui5-option
                    value="${item.path}"
                    additional-text="${dayjs(item.lastModified).format('L LT')}"
                    >${item.name}</ui5-option
                  >`,
              )}
            </ui5-select>
            <button
              type="button"
              @click="${this._handleClick}"
              class="dropdown-button"
            >
              <svg
                width="22px"
                height="22px"
                viewBox="0 0 512 512"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <title>open-external</title>
                <g
                  id="Page-1"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="icon"
                    fill="#000000"
                    transform="translate(85.333333, 64.000000)"
                  >
                    <path
                      d="M128,63.999444 L128,106.666444 L42.6666667,106.666667 L42.6666667,320 L256,320 L256,234.666444 L298.666,234.666444 L298.666667,362.666667 L4.26325641e-14,362.666667 L4.26325641e-14,64 L128,63.999444 Z M362.666667,1.42108547e-14 L362.666667,170.666667 L320,170.666667 L320,72.835 L143.084945,249.751611 L112.915055,219.581722 L289.83,42.666 L192,42.6666667 L192,1.42108547e-14 L362.666667,1.42108547e-14 Z"
                      id="Combined-Shape"
                    ></path>
                  </g>
                </g>
              </svg>
              <span> Open Report in new Tab </span>
            </button>
          </div>
          <div class="right-panel">
            <iframe title="content" id="contentframe"></iframe>
          </div>
        `
      : html`<div>Loading...</div>`;
  }
}
