/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-classes-per-file */
import { html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import {
  last,
  merge,
  find,
  head,
  groupBy,
  flatten,
  orderBy,
  compact,
  first,
  uniq,
  cloneDeep,
  filter,
} from 'lodash-es';
import './ki-wwp-switcher-button';

import './ki-wwp-ts-table';
import Highcharts from 'highcharts/highstock';
import nodata from 'highcharts/modules/no-data-to-display';
import exporting from 'highcharts/modules/exporting';
import boost from 'highcharts/modules/boost';
import more from 'highcharts/highcharts-more';
import offlineexporting from 'highcharts/modules/offline-exporting';
import serieslabel from 'highcharts/modules/series-label';
import {
  SM,
  ViewPort,
  responsiveMixin,
  i18nMixin,
  queryParamMixin,
  localStorageMixin,
} from '../../decorators';
import DataTransformation from '../../common/timeseries/DataTransformation';
import { getCurrentApi } from '../../api';
import nls from '../../locales/index';

import style from './ki-wwp-graph.css?inline';
import convertUnit from '../../common/convertUnit/index';
import '@ui5/webcomponents/dist/Button';
import '@ui5/webcomponents/dist/Select';
import '@ui5/webcomponents/dist/Option';
import {
  getFile,
  normStr,
  rangeObjectContains,
  transformForecast,
} from './GraphHelper';
import { AttributeThreshold } from './AttributeThreshold';
import {
  copyTextToClipboard,
  dayjs,
  downloadFile,
  hclocales,
  LoaderMixin,
  Mix,
  template,
} from '../../common';
import { formatNumber } from '../../common/NumberFormatter';
import {
  getRouteOptionsAndParams,
  getCsvDelimiter,
  getConfig,
  getAllSearchParamsObject,
  setSearchParam,
} from '../ki-app';
import { AppConfigDefinition } from '../../defs/AppConfigDefinition';

@customElement('ki-wwp-graph')
export default class KiWwpGraph extends Mix(
  LitElement,
  LoaderMixin,
  [i18nMixin, { nls }],
  [
    localStorageMixin,
    { targetProperty: '__favouriteStationIds', typeParam: Array },
  ],
  [
    queryParamMixin,
    {
      targetProperty: '_selectedPeriod',
      selector: 'period',
      defaultValue: '',
    },
  ],
  responsiveMixin,
) {
  // language=CSS

  @property({ type: Object })
  station;

  /** Graph options injected via Popup-Class or app.json */
  @property({ type: Object })
  options = {};

  /** True if graph is used in a popup */
  @property({ type: Boolean })
  miniGraph = false;

  /** Switch unit between Imperial and SI */
  @property({ type: Boolean })
  showUnitSystem = false;

  @property({ type: Array })
  unitSystem = [];

  @state()
  private _graphUnit = '';

  /** Reduce loading time for popups; only fetch specified ranges
   * @remark Usually just 'week.json'
   */
  @property({ type: Boolean })
  onlyFetchSpecifiedRanges = false;

  @property({ type: Boolean })
  favButton = false;

  @property({ type: Boolean })
  blockTableDownload = false;

  @property({ type: String })
  stationId;

  config: AppConfigDefinition = getConfig();

  timeZone: string = this.config.timeZone || dayjs.tz.guess();

  /** Show only the selected duration/range in graph */
  forcePeriodRange = true;

  /** Default color for graph */
  mainColor = '#0056a0';

  qCodeExclude = [];

  /** Always show complete days and don't skip at the end of data or range */
  showUntilEndOfDay = true;

  imageShareAutoOpen = false;

  /** Set axis to zoom along */
  zoomType: 'x' | 'y' | 'xy' = 'x';

  /** Use highcharts GPU acceleration */
  boost = true;

  __selectedPeriod;

  hydroYearStart = '01-01';

  hydroYearEnd = '01-01';

  /** Default series type in Highcharts, required vor Highcharts 11 */
  chartType = 'line';

  /** Static lines/thresholds in graph */
  attributeThresholds: Array<AttributeThreshold> = [];

  /** Color indicator using the ■ symbol next to the ts-name */
  showColorSquare: boolean = false;

  /** Base time fallback Highcharts format;
   * @remark The format for the date in the tooltip header if the X axis is a datetime axis. The default is a best guess based on the smallest distance between points in the chart.
   */
  dateFormat: string = '%d.%m.%Y %H:%M';

  // eslint-disable-next-line class-methods-use-this
  get localStorageId() {
    return `wwp-favorites-ids`;
  }

  ___favouriteStationIds = [];

  get __favouriteStationIds() {
    return this.___favouriteStationIds;
  }

  set __favouriteStationIds(value) {
    this.___favouriteStationIds = value;
    this.requestUpdate();
  }

  get _selectedPeriod() {
    const result = this.__selectedPeriod || this.searchParams.period;
    setSearchParam('period', result);
    return result;
  }

  set _selectedPeriod(value) {
    this.__selectedPeriod = value;
    this.requestUpdate();
  }

  static get styles() {
    return [style];
  }

  constructor() {
    super();
    serieslabel(Highcharts);
    exporting(Highcharts);
    offlineexporting(Highcharts);
    nodata(Highcharts);
    more(Highcharts);
    boost(Highcharts);
  }

  connectedCallback() {
    if (super.connectedCallback) super.connectedCallback();
    let resize;
    window.addEventListener('resize', () => {
      window.clearTimeout(resize);
      resize = setTimeout(() => {
        if (this.chart) {
          this.chart.update({
            legend: this.legendOptions,
            credits: this.creditOptions,
          });
          this.chart.reflow();
        }
      }, 220);
    });
  }

  firstUpdated() {
    if (!this.miniGraph) {
      this.refreshInterval = setInterval(() => {
        this.fetchData();
      }, 300000);
    }
    Object.assign(this, this.options);
    if (
      this.renderRoot.host.classList.contains('sm-screen') ||
      this.miniGraph
    ) {
      this.tableOpen = false;
    }
    this.api = getCurrentApi();
    if (this.api) {
      this.fetchData();
    }
  }

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, [
      'layerName',
      'stationId',
      'stationLabel',
    ]);
    Object.assign(this, JSON.parse(JSON.stringify(params.options)));
    this.layerName = params.layerName;
    this.apppath = location.route;
    this.stationId = params.stationId;

    this.searchParams = getAllSearchParamsObject();
  }

  isFavorite() {
    return this.__favouriteStationIds.indexOf(this.station?.station_id) >= 0;
  }

  _toggleFavourite() {
    if (this.__favouriteStationIds.indexOf(this.station.station_id) === -1) {
      this.__favouriteStationIds = [
        ...this.__favouriteStationIds,
        this.station.station_id,
      ];
    } else {
      this.__favouriteStationIds = this.__favouriteStationIds.filter(
        id => id !== this.station.station_id,
      );
    }
    const e = new CustomEvent('favToggled', { bubbles: true, composed: true });
    this.dispatchEvent(e);
  }

  createChart() {
    if (!this.chart) {
      if (hclocales[this.i18n.language.substring(0, 2)]) {
        Highcharts.setOptions({
          lang: hclocales[this.i18n.language.substring(0, 2)],
        });
      }
      this.chart = new Highcharts.StockChart(
        this.renderRoot.querySelector('#chart-node'),
        this.defaultOptions(),
        this.chartCreated.bind(this),
      );
    }
  }

  chartCreated(chart) {
    const e = new CustomEvent('load', {
      detail: chart,
    });
    this.dispatchEvent(e);
  }

  updated(changedProperties) {
    if (changedProperties.get('stationId')) {
      //   console.debug(this.stationId, changedProperties.get('stationId'))
      this.station = null;
      this.fetchData();
    }
    if (this.chart) {
      //   console.debug("resize bfeore", this.renderRoot.querySelector('#contentnode').clientHeight, this.chart.chartHeight)
      this.chart.reflow();
      //    console.debug("resize", this.renderRoot.querySelector('#contentnode').clientHeight, this.chart.chartHeight)
    }
  }

  disconnectedCallback() {
    if (super.disconnectedCallback) super.disconnectedCallback();
    window.removeEventListener('resize', this.handleResize);
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
    clearInterval(this.refreshInterval);
  }

  handleResize = (): void => {
    this.requestUpdate();
  };

  // eslint-disable-next-line class-methods-use-this
  get legendOptions() {
    let legendOptions = {
      layout: 'horizontal',
      align: 'left',
      verticalAlign: 'bottom',
      alignColumns: false,
      itemMarginBottom: 10,
      itemDistance: ViewPort.size === SM ? 15 : 35,
      width: '90%',
      symbolRadius: 0,
      squareSymbol: false,
      enabled: !this.miniGraph,
      itemStyle:{
        "color": "#333333", "cursor": "pointer", "fontSize": "0.8em", "fontWeight": "bold", "textOverflow": "ellipsis"
      },
      itemHiddenStyle: {
        color: 'gray',
        'text-decoration': 'none'
      }
    };

    if (ViewPort.size === SM && ViewPort.landscape) {
      legendOptions = {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        alignColumns: false,
        itemMarginBottom: 5,
        enabled: !this.miniGraph,
        symbolPadding: 5,
        itemDistance: ViewPort.size === SM ? 15 : 35,
        width: ViewPort.size === SM ? '20%' : '80%',
        symbolRadius: 0,
        squareSymbol: false,
        itemStyle:{
          "color": "#333333", "cursor": "pointer", "fontSize": "0.8em", "fontWeight": "bold", "textOverflow": "ellipsis"
        },
        itemHiddenStyle: {
          color: 'gray',
          'text-decoration': 'none'
        }
      };
    }
    return legendOptions;
  }

  get creditOptions() {
    const credits = this.credits ? { ...this.credits } : { enabled: false };
    if (credits?.text) {
      credits.text = template(credits.text, this.station);
    }
    if (this.credits && ViewPort.size === SM) {
      credits.position = credits.positionMobile || credits.position;
    }
    credits.enabled = !this.miniGraph;
    return credits;
  }

  // eslint-disable-next-line class-methods-use-this
  getTableValuesFromTemplate(cfg, data, minDate, maxDate = Infinity) {
    const tsIndex = {};
    cfg.tableTemplate.forEach(tsshortname => {
      const ts = find(data, { ts_shortname: tsshortname });
      const tsdata = groupBy(ts.data, head);
      tsIndex[ts.ts_shortname] = tsdata;
    });

    const firstts = find(data, { ts_shortname: cfg.tableTemplate[0] });
    const tsdata = [];
    firstts.data.forEach(dp => {
      const ts = dp[0];
      const tsms = new Date(ts).getTime();
      if (tsms >= minDate && tsms <= maxDate) {
        const row = [tsms];
        cfg.tableTemplate.forEach(item => {
          const tempts = tsIndex[item];
          const val = tempts[ts] ? tempts[ts][0][1] : null;
          row.push(val);
        });
        tsdata.push(row);
      }
    });
    return { data: tsdata };
  }

  // eslint-disable-next-line consistent-return
  _getSeries(cfg, data, minDate, maxDate = Infinity) {
    const self = this;
    const dCommentIndex = data[0].columns
      ? data[0]?.columns.split(',').indexOf('Data Comment')
      : null;
    this.dateFormat = cfg?.tooltip?.xDateFormat;
    if (cfg.timeseries) {
      const tslist: any[] = [];
      cfg.timeseries.forEach((tscfg, i) => {
        if (tscfg.ts_shortname_min && tscfg.ts_shortname_max) {
          tscfg.type = 'range';
          const minTs = find(data, {
            ts_shortname: tscfg.ts_shortname_min,
          });
          const maxTs = find(data, {
            ts_shortname: tscfg.ts_shortname_max,
          });
          const minIdx = groupBy(minTs.data, head);
          const tsdata = [];

          maxTs.data.forEach(dp => {
            const ts = dp[0];
            const tsms = new Date(ts).getTime();
            const max = dp[1];
            const min = minIdx[ts] ? minIdx[ts][0][1] : null;
            if (tsms >= minDate && tsms <= maxDate) {
              tsdata.push([tsms, min, max]);
            }
          });

          tslist.push(
            merge(
              {
                data: tsdata,
                id: `data_${i}`,
                name: this.parameter_label,
                color: 'rgba(0, 86, 160, 0.3)',
                type: 'arearange',
                fillOpacity: 0.3,
                xAxis: 'mainXAxis',
                station: {
                  ts_unitsymbol: minTs?.ts_unitsymbol,
                  stationparameter_name: minTs?.stationparameter_name,
                  stationparameter_shortname: minTs?.stationparameter_shortname,
                },

                //   gapSize: 2,
                selected: true,
                marker: {
                  enabled: false,
                },
                tooltip: {
                  valueSuffix: ` ${self.getGraphUnitLabel(minTs.ts_unitsymbol)}`,
                  xDateFormat: cfg?.tooltip?.xDateFormat ?? this.dateFormat,
                },
              },
              tscfg.options,
            ),
          );
        } else {
          /** Compare available data with requested data in ranges-option; e.g. CWC with multiple parameters */
          const ts = tscfg.stationparameter_name
            ? find(data, {
                ts_shortname: tscfg.ts_shortname,
                stationparameter_name: tscfg.stationparameter_name,
              })
            : find(data, { ts_shortname: tscfg.ts_shortname });
          if (ts) {
            if (tscfg.minDate) {
              minDate = dayjs()
                .subtract(dayjs.duration(tscfg.minDate))
                .valueOf();
            }
            let tsData = ts.data;
            if (
              tscfg.sampleDataFilter?.field &&
              tscfg.sampleDataFilter?.value
            ) {
              const columns = ts.columns?.split(',');
              const fieldIndex = columns
                ? columns.indexOf(tscfg.sampleDataFilter.field)
                : null;
              if (fieldIndex) {
                tsData = filter(
                  tsData,
                  d => d[fieldIndex] === tscfg.sampleDataFilter.value,
                );
              }
            }
            tslist.push(
              merge(
                {
                  columns: ts.columns,
                  data: this._formatGraphData(tsData, minDate, maxDate, ts),
                  id: `data_${i}`,
                  name: this.parameter_label,
                  color: this.mainColor,
                  xAxis: 'mainXAxis',
                  selected: true,
                  marker: {
                    radius: 1,
                    enabled: true,
                  },
                  station: {
                    ts_unitsymbol: ts?.ts_unitsymbol,
                    stationparameter_name: ts?.stationparameter_name,
                    stationparameter_shortname: ts?.stationparameter_shortname,
                  },
                  tooltip: {
                    pointFormatter() {
                      return `${this.series.name}: <b>${formatNumber(
                        this.y,
                        self.numberFormat || {},
                      )} ${self.getGraphUnitLabel(
                        ts.ts_unitsymbol,
                      )}</b><br /> ${
                        dCommentIndex && tsData[this.index][dCommentIndex]
                          ? `Data Comment: <b>${
                              tsData[this.index][dCommentIndex]
                            }</b>`
                          : ''
                      }`;
                    },
                    xDateFormat: cfg?.tooltip?.xDateFormat ?? this.dateFormat,
                  },
                },
                tscfg.options,
              ),
            );
          }
        }
      });
      return tslist;
    }
    if (data[0]) {
      const options = cfg.options || {};
      const ts = data[0];
      this.dateFormat = cfg?.tooltip?.xDateFormat;
      return [
        merge(
          {
            data: this._formatGraphData(ts.data, minDate, maxDate, ts),
            id: 'data',
            color: '#0056a0',
            name: this.parameter_label,
            xAxis: 'mainXAxis',
            selected: true,
            marker: {
              radius: 1,
              enabled: true,
            },
            station: {
              ts_unitsymbol: ts?.ts_unitsymbol,
              stationparameter_name: ts?.stationparameter_name,
              stationparameter_shortname: ts?.stationparameter_shortname,
            },
            tooltip: {
              pointFormatter() {
                return `${this.series.name}: <b>${formatNumber(
                  this.y,
                  self.numberFormat || {},
                )} ${self.getGraphUnitLabel(ts.ts_unitsymbol)}</b><br /> ${
                  dCommentIndex && ts.data[this.index][dCommentIndex]
                    ? `Data Comment: <b>${
                        ts.data[this.index][dCommentIndex]
                      }</b>`
                    : ''
                }`;
              },
              xDateFormat: cfg?.tooltip?.xDateFormat ?? this.dateFormat,
            },
          },
          options,
        ),
      ];
    }
  }

  // eslint-disable-next-line class-methods-use-this
  _formatGraphData(
    data = [],
    minDate = -Infinity,
    maxDate = Infinity,
    timeseries = {},
  ) {
    const columns = timeseries.columns?.split(',');
    const tsData = [];

    const qCodeIndex = columns ? columns.indexOf('Quality Code') : null;

    data.forEach(dp => {
      const ts = new Date(dp[0]).getTime();
      const value =
        dp[1] && this.convertGraphUnit(dp[1], timeseries.ts_unitsymbol);
      if (this.qCodeExclude.length) {
        if (
          ts >= minDate &&
          ts <= maxDate &&
          !this.qCodeExclude.includes(dp[qCodeIndex])
        ) {
          tsData.push([ts, value, ...dp.slice(2)]);
        }
      } else if (ts >= minDate && ts <= maxDate) {
        tsData.push([ts, value, ...dp.slice(2)]);
      }
    });

    return tsData;
    // return data.map(dp => [new Date(dp[0]).getTime(), dp[1]]);
  }

  // remove plotlines added by forecast Deterministic
  removeForecastPlotLine() {
    const xAxis = this.chart?.get('mainXAxis');
    const { length } = xAxis.plotLinesAndBands;
    for (let i = 0; i < length; i += 1) {
      xAxis.removePlotLine(`pz_${i}`);
    }
  }

  drawForecastDeterministic(ts_list, index) {
    // fallback some customer need green color as forecast color, set green as first one.
    const colorArray = [
      '#008000', // green
      '#2caffe',
      '#544fc5',
      '#00e272',
      '#fe6a35',
      '#6b8abc',
      '#d568fb',
      '#2ee0ca',
      '#fa4b42',
      '#91e8e1',
    ];
    if (!ts_list) {
      return;
    }

    const { data } = last(ts_list);
    const firstDataPoint = data[0];
    if (!firstDataPoint) {
      return;
    }
    const t0 = dayjs(firstDataPoint[0]).valueOf(); // this.station[this.progTs] ? dayjs(this.station[this.progTs]+"Z").valueOf() : dayjs().valueOf();

    const xAxis = this.chart.get('mainXAxis');
    const plotLine = {
      id: `pz_${index}`,
      zIndex: 2,
      color: 'gray',
      width: 2,
      value: t0,
      label: {
        //   rotation:270,
        x: 5,
        y: this.miniGraph ? 80 : 120,
        verticalAlign: 'top',
        text: 't0',
      },
    };
    xAxis.addPlotLine(plotLine);

    /** Add Vorhersage */
    this.chart.addSeries({
      name: this.i18n.t('FORECAST'),
      selected: true,
      color: colorArray[index % 10],
      id: `data_forecast_${index}`,
      data: transformForecast(data),
      events: {
        hide: () => {
          xAxis.removePlotLine(`pz_${index}`);
        },
        show: () => {
          xAxis.addPlotLine(plotLine);
        },
      },
      tooltip: {
        xDateFormat: this.dateFormat ?? '%d.%m.%Y %H:%M',
      },
    });
  }

  drawForecast(ts) {
    if (!ts) {
      return;
    }

    this.chart.get('mainXAxis').setExtremes(null, null, false, false);

    const { data, columns } = ts[0];
    const colArray = columns.split(',');
    const firstDataPoint = data[0];
    if (!firstDataPoint) {
      return;
    }
    const progTs = dayjs(firstDataPoint[0]).valueOf(); // this.station[this.progTs] ? dayjs(this.station[this.progTs]+"Z").valueOf() : dayjs().valueOf();

    const trendAb = this.station[this.trendAb]
      ? Number(this.station[this.trendAb])
      : null;
    const progDur = this.station[this.progDuration]
      ? Number(this.station[this.progDuration])
      : null;

    const prognoseBisTs = dayjs(progTs).add(progDur, 'minutes').valueOf();
    const trendAbTs = dayjs(progTs).add(trendAb, 'hours').valueOf();

    const prognoseBranches = this.station.web_prognose_branches;
    const indexMin = colArray.indexOf('Min');
    const indexMax = colArray.indexOf('Max');
    const indexMittel = colArray.indexOf('Mittel');
    const indexTUMin = colArray.indexOf('TUMin');
    const indexTUMax = colArray.indexOf('TUMax');
    const indexTUMittel = colArray.indexOf('TUMittel');

    const dataTrend = [];
    const dataTrendVB = [];
    const dataProg = [];
    const dataProgVB = [];

    let actualMin;
    let actualMax;
    let actualMean;

    if (prognoseBranches === 'Alle-Ext' || prognoseBranches === 'Min/Max-Ext') {
      actualMin = indexMin;
      actualMax = indexMax;
    } else if (
      prognoseBranches === 'Alle-Int' ||
      prognoseBranches === 'Min/Max-Int'
    ) {
      actualMin = indexTUMin;
      actualMax = indexTUMax;
    }
    if (
      prognoseBranches === 'Alle-Ext' ||
      prognoseBranches === 'Prognose-Ext'
    ) {
      actualMean = indexMittel;
    } else if (
      prognoseBranches === 'Alle-Int' ||
      prognoseBranches === 'Prognose-Int'
    ) {
      actualMean = indexTUMittel;
    }

    data.forEach(row => {
      const tsValue = dayjs(row[0]).valueOf();
      if (tsValue < prognoseBisTs) {
        if (trendAb && tsValue >= trendAbTs) {
          dataTrend.push([tsValue, row[actualMean]]);
          dataTrendVB.push([tsValue, row[actualMin], row[actualMax]]);
        } else {
          dataProg.push([tsValue, row[actualMean]]);
          dataProgVB.push([tsValue, row[actualMin], row[actualMax]]);
        }
      }
    });

    const xAxis = this.chart.get('mainXAxis');
    if (this.station.web_prognose_publish !== 'no') {
      xAxis.addPlotLine({
        id: 'pz',
        zIndex: 2,
        color: 'gray',
        width: 2,
        value: progTs,
        label: {
          //   rotation:270,
          x: 5,
          y: this.miniGraph ? 80 : 120,
          verticalAlign: 'top',
          text: this.miniGraph ? `VHS` : 'Prognosezeitpunkt',
        },
      });
    }

    if (
      trendAb &&
      this.station.web_prognose_trendanzeige !== 'no' &&
      this.station.web_prognose_publish !== 'no'
    ) {
      xAxis.addPlotLine({
        id: 'trend',
        zIndex: 2,
        color: 'gray',
        width: 2,
        value: trendAbTs,
        label: {
          // rotation:270,
          x: 5,
          y: this.miniGraph ? 80 : 120,
          verticalAlign: 'top',
          text: this.miniGraph ? `Trend` : `ab >${trendAb}h = Trend`,
        },
      });
      this.chart.addSeries(
        {
          name: 'Trend',
          selected: true,
          color: 'green',
          id: 'data_trend',
          dashStyle: 'dot',
          data: dataTrend,
          events: {
            hide: () => {
              xAxis.removePlotLine('trend');
            },
            show: () => {
              xAxis.addPlotLine({
                id: 'trend',
                zIndex: 2,
                color: 'gray',
                width: 2,
                value: trendAbTs,
                label: {
                  // rotation:270,
                  x: 5,
                  y: this.miniGraph ? 80 : 120,
                  verticalAlign: 'top',
                  text: this.miniGraph ? `Trend` : `ab >${trendAb}h = Trend`,
                },
              });
            },
          },
        },
        false,
      );
      this.chart.addSeries(
        {
          name: 'Vertrauensbereich Trend',
          type: 'arearange',
          fillOpacity: 0.5,
          linkedTo: 'data_trend',
          id: 'data_trendarea',
          showInLegend: false,
          selected: true,
          dashStyle: 'dot',
          fillColor: 'rgba(124,181,236,0.3)',
          data: dataTrendVB,
        },
        false,
      );
    }

    if (this.station.web_prognose_publish !== 'no') {
      this.chart.addSeries(
        {
          name: 'Vertrauensbereich',
          type: 'arearange',
          fillOpacity: 0.5,
          id: 'data_forecastarea',
          selected: true,
          color: 'rgba(124,181,236,1)',
          showInLegend: false,
          fillColor: 'rgba(124,181,236,0.5)',
          data: dataProgVB,
          linkedTo: 'data_forecast',
        },
        false,
      );

      this.chart.addSeries({
        name: 'Vorhersage',
        selected: true,
        color: 'green',
        id: 'data_forecast',
        data: dataProg,
        events: {
          hide: () => {
            xAxis.removePlotLine('pz');
          },
          show: () => {
            xAxis.addPlotLine({
              id: 'pz',
              zIndex: 2,
              color: 'gray',
              width: 2,
              value: progTs,
              label: {
                //   rotation:270,
                x: 5,
                y: this.miniGraph ? 80 : 120,
                verticalAlign: 'top',
                text: this.miniGraph ? `VHS` : 'Prognosezeitpunkt',
              },
            });
          },
        },
      });
    }
  }

  _getTimestampFromDuration(dur) {
    if (!dur) {
      return dayjs().valueOf();
    }
    if (dur === 'PoR') {
      return dayjs(this.coverage.min).valueOf();
    }
    if (dur.charAt(0) === '+') {
      return dayjs().add(dayjs.duration(dur)).valueOf();
    }
    if (dur === 'PT24H') {
      return dayjs().subtract(dayjs.duration(dur)).valueOf();
    }
    const until = this.showUntilEndOfDay ? dayjs().endOf('day') : dayjs();

    return until.valueOf() - dayjs.duration(dur).valueOf();
  }

  /**
   * @param period e.g. "PT24H", "P7D", ...
   * @param min
   * @param max
   */
  drawSeries(period: string, min, max) {
    const cfg = find(this.ranges, { value: period }) || this.ranges[0];
    if (cfg.yearSelector) {
      this.renderRoot
        .querySelector('#yearselector')
        .classList.remove('hideButton');
    } else {
      this.renderRoot
        .querySelector('#yearselector')
        .classList.add('hideButton');
    }
    if (
      this.station.BODY_RESPONSIBLE &&
      this.station.BODY_RESPONSIBLE === 'WSV' // TODO: Remove LANUV?! artifact
    ) {
      this.renderRoot
        .querySelector('#yearselector')
        .classList.add('hideButton');
    }

    this.chart.series
      .filter(ser => ser.userOptions.id && ser.userOptions.id.includes('data'))
      // .filter(ser => ser.options.data.length === 0)
      .forEach(ser => ser.remove(false));

    const minDate = min || this._getTimestampFromDuration(period);
    let maxDate = max;
    const xAxis = this.chart.get('mainXAxis');
    this.chart.zoomOut();
    if (cfg.yAxisLabel) {
      this.yAxisLabel = cfg.yAxisLabel;
      this.shadowRoot.getElementById('dataTable').columns = this.tablecolumns;
    }
    cfg.timeseries &&
      cfg.timeseries.forEach(ts => {
        if (ts.maxDate) {
          maxDate = this._getTimestampFromDuration(ts.maxDate);
        }
      });

    if (cfg.plotLines) {
      cfg.plotLines.forEach(pl => {
        const _pl = { ...pl };
        _pl.id = `dynamic_${pl.id}`;
        _pl.value = this._getTimestampFromDuration(pl.value);
        xAxis.removePlotBandOrLine(_pl.id);
        if (pl.filter) {
          cfg.timeseries.some(item => item.ts_shortname === pl.filter) &&
            xAxis.addPlotLine(_pl);
        } else {
          xAxis.addPlotLine(_pl);
        }
      });
    } else {
      xAxis.plotLinesAndBands.forEach(
        pl => pl.id.includes('dynamic_') && xAxis.removePlotLine(pl.id),
      );
    }

    this.removeForecastPlotLine(); // incase multiple exist
    xAxis.removePlotLine('pz');
    xAxis.removePlotLine('trend');
    const maxD = maxDate
      ? this.showUntilEndOfDay
        ? dayjs(maxDate).endOf('day')
        : dayjs(maxDate)
      : undefined;

    if (period === 'PT24H') {
      xAxis.setExtremes(minDate, dayjs(maxDate).valueOf(), false, false);
    } else if (this.forcePeriodRange) {
      xAxis.setExtremes(
        minDate,
        this.showUntilEndOfDay
          ? dayjs(maxDate).endOf('day').valueOf()
          : dayjs(maxDate).valueOf(),
        false,
        false,
      );
    } else {
      xAxis.setExtremes(minDate, maxD?.valueOf(), false, false);
    }

    if (this.forecast && typeof this.forecast === 'string') {
      this.forecast = [this.forecast];
    }

    /** Legacy variant with confidence interval + trend
     * (!) Keep the "typo" in config (forcast) for backward compatibility
     * TODO: Look through all projects and identify affected ones concerning the typo
     * TODO: Choose forecast visualization in config file; e.g. type: "deterministic", "confInterval"
     */

    if (cfg.showForecast || cfg.showforcast) {
      let data = null;
      if (cfg.showforcast)
        console.warn(
          '# DEPRECATED CONFIG OPTION - Please use `showForecast: true` instead of `showforcast: true` in `app.json`',
        );

      this.forecast?.forEach(f => {
        const ts_list = cloneDeep(this.files[f]);
        if (ts_list) {
          if (!data) {
            data = ts_list;
          } else {
            data[0].data = orderBy(
              [...data[0].data, ...ts_list[0].data],
              [0],
              ['ASC'],
            );
          }
        }
      });

      data && this.drawForecast(data);
    }

    /** Deterministic: Single line with t0 */
    if (cfg.showForecastDeterministic) {
      let forecastData;
      this.forecast?.forEach((f, index) => {
        const ts_list = cloneDeep(this.files[f]);
        if (ts_list) {
          if (!forecastData) {
            forecastData = ts_list;
          } else {
            forecastData[0].data = orderBy(
              [...forecastData[0].data, ...ts_list[0].data],
              [0],
              ['ASC'],
            );
          }
        }
        this.separateForecast && this.drawForecastDeterministic(ts_list, index); // draw forecast data separately
      });
      !this.separateForecast && this.drawForecastDeterministic(forecastData, 0); // combined all forecast data as one
    }

    this.chart.get('data') && this.chart.get('data').remove(false);

    if (rangeObjectContains(this.files, cfg.data)) {
      this.showUnitSystem && this._initGraphUnit(this.files[cfg.data]);
      const series = this._getSeries(
        cfg,
        getFile(this.files, cfg),
        minDate,
        maxDate,
      );
      this.tableColumns = cfg.tableColumns ?? this.tableColumns;
      this.separateCol = cfg.separateCol ?? this.separateCol;
      const tabledata = cfg.tableTemplate
        ? this.getTableValuesFromTemplate(
            cfg,
            getFile(this.files, cfg),
            minDate,
            maxDate,
          )
        : Array.isArray(series)
          ? series[0]
          : series;
      if (tabledata) {
        // Sort and format
        // console.time('start');
        this.orderedTableData = orderBy(tabledata.data, [0], ['desc']).map(
          item => {
            const vals = [];
            item.forEach((_val, i) => {
              if (i > 0 && typeof _val === 'number') {
                vals.push(formatNumber(_val, this.numberFormat || {}));
              }
            });
            // Use separate col for each value
            if (this.separateCol) {
              return {
                timestamp: dayjs(item[0])
                  .tz()
                  .format(cfg.dateformat || 'L LT'),
                value: vals, // Return array
              };
            }
            // Show in common value col with " - "
            if (vals.length === 3) {
              vals[1] = `<b>${vals[1]}</b>`;
            }
            const val = vals.join(' - ');

            if (tabledata.columns) {
              const retObj = {
                timestamp: dayjs(item[0])
                  .tz()
                  .format(cfg.dateformat || 'L LT'),
                value: val, // Return single value
              };
              tabledata.columns.split(',').forEach((col, i) => {
                if (i > 1 && item[i] !== undefined) {
                  retObj[col] = item[i];
                }
              });
              return retObj;
            }

            return {
              timestamp: dayjs(item[0])
                .tz()
                .format(cfg.dateformat || 'L LT'),
              value: val, // Return single value
            };
          },
        );
        // console.timeEnd('start');
        this.shadowRoot.getElementById('dataTable').data =
          this.orderedTableData;
      }
      series.forEach((ts, i) => {
        const columns = ts.columns?.split(',');
        if (columns?.length > 2) {
          ts.data = ts.data.map(dp => {
            const d = {};
            // Map to object
            ['x', 'y', ...columns.slice(2)].forEach((c, index) => {
              d[c] = dp[index]; // Separate the assignment from the return statement
            });
            d.station = ts.station;
            return d; // Return the object after all assignments are made
          });
        }

        this.chart.addSeries(ts, i === series.length - 1);
      });
    }
  }

  getRangeOptions() {
    return this.ranges || [];
  }

  showDescription() {
    this.descriptionVisible = !this.descriptionVisible;
    this.chart.series.forEach(ser => {
      if (ser.options.description) {
        ser.update(
          {
            name:
              this.descriptionVisible && ser.options.description
                ? `<b>${ser.options.shortName}</b>: ${ser.options.description}`
                : ser.options.shortName,
            selected: ser.options.visible, // Sync checkbox in legend
          },
          false,
        );
      }
    });
    this.chart.redraw();
  }

  getImageShare() {
    return this.api
      ? `${window.location.origin}${window.location.pathname.replace(
          /[^/]*$/,
          '',
        )}graph.html?stationId=${
          this.station.station_id
        }&component=ki-wwp-graph-ext&key=${encodeURIComponent(
          this.apppath?.path,
        )}&period=${this._selectedPeriod}`
      : '';
  }

  render() {
    // language=html
    const title =
      this.graphTitle && this.station
        ? template(this.graphTitle, this.station)
        : '';
    return html`<div id="nav-node" class="${this.miniGraph ? 'hidden' : ''}">
        <div class="label">${title}</div>
        <ki-wwp-switcher-button
          @changed="${e => {
            if (this.chart) {
              this.drawSeries(e.detail.value);
            }
            this._selectedPeriod = e.detail.value;
          }}"
          .options="${this.getRangeOptions()}"
          class="buttons"
          .value=${this._selectedPeriod}></ki-wwp-switcher-button>
        </ki-wwp-switcher-bottom>
        <div class="actions">
          ${
            this.showUnitSystem
              ? html` <ui5-select
                  id="unitSystemSelect"
                  @change="${this._handleUnitChange}"
                >
                  ${this.unitSystem.map(
                    unit =>
                      html` <ui5-option
                        ?selected="${unit.value === this._graphUnit}"
                        value="${unit.value}"
                        >${unit.label}
                      </ui5-option>`,
                  )}
                </ui5-select>`
              : ''
          }

          <ki-icon-btn
            class="ripple clipboard-btn ${
              this.imageShare && ViewPort.size !== SM ? '' : 'hideButton'
            }"
            title="${this.i18n.t('shareLink')}"
            icon="ki ki-share"
            id="link-btn"
            @click=${() => {
              this.renderRoot.querySelector('#embeddedgraph').value =
                `<iframe src="${this.getImageShare()}" style="width:80%;height:700px;border:0;"></iframe>"`;
              const popup = this.renderRoot.querySelector('#download-popup');
              if (this.imageShareAutoOpen) {
                window.open(this.getImageShare(), '_blank');
              }
              popup.visible ? popup.hide({}) : popup.show({});
            }}
          ></ki-icon-btn>
          <ki-popup
            for="link-btn"
            style="margin-right: 20px;"
            top="40px"
            left="-100"
            id="download-popup"
          >
            <div
              style="padding: 10px;font-size: 1.2em;border-bottom: 1px solid gray;display:flex;justify-content:space-between;align-items: center;"
            >
              ${this.i18n.t('embedGraph')}<ki-icon-btn
                title="schließen"
                @click="${() => {
                  this.renderRoot.querySelector('#download-popup').hide({});
                }}"
                style="margin:0;height: 20px;width: 20px;line-height: 20px;"
                icon="ki ki-times"
              ></ki-icon-btn>
            </div>
            <textarea
              disabled
              id="embeddedgraph"
              style="border: none;outline: none;resize:none;padding:10px;background:white;width: 90%;"
              rows="12"
            >
            </textarea>
            <ui5-button
              @click="${() => {
                window.open(this.getImageShare(), '_blank');
              }}"
              title="${this.i18n.t('previewTitle')}"
              >${this.i18n.t('preview')}</ui5-button
            >
            <ui5-button
              @click="${() => {
                copyTextToClipboard(
                  this.renderRoot.querySelector('#embeddedgraph').value,
                );
              }}"
              title="${this.i18n.t('copyToClipboard')}"
              >${this.i18n.t('copy')}</ui5-button
            >
          </ki-popup>
          <ki-icon-btn
            class="fav-btn ripple ${this.favButton ? '' : 'hidden'}"
            @click="${this._toggleFavourite}"
            title="${
              this.isFavorite()
                ? this.i18n.t('removeFav')
                : this.i18n.t('addFav')
            }"
            icon="ki ${this.isFavorite() ? 'ki-star' : 'ki-star-o'}"
          ></ki-icon-btn>
          <ki-icon-btn
            toggle
            id="descriptionButton"
            class="${
              this.hasAlarms && ViewPort.size !== SM ? '' : 'hidden'
            } ${this.descriptionVisible ? 'selected' : ''}"
            title="${this.i18n.t('descriptions')}"
            icon="ki ki-question"
            @click="${this.showDescription}"
          ></ki-icon-btn>
          <ki-icon-btn
            class="ripple"
            id="graph-download-button"
            title="${this.i18n.t('downloadGraph')}"
            icon="ki ki-download"
            @click="${this.downloadGraph}"
          ></ki-icon-btn>
          ${
            this.blockTableDownload
              ? ''
              : html`<ki-icon-btn
                  class="ripple clipboard-btn ${this.tableOpen
                    ? ''
                    : 'hideButton'}"
                  id="table-clipboard-btn"
                  tooltip="${this.i18n.t('copyTableMessage')}"
                  ,
                  title="${this.i18n.t('copyTableTitle')}"
                  icon="ki ki-clipboard"
                  .value=${this.orderedTableData}
                  @click=${() => {
                    copyTextToClipboard(
                      this.renderRoot
                        .querySelector('#dataTable')
                        .toCSV(getCsvDelimiter())
                        .replace(/(<([^>]+)>)/gi, ''),
                    );
                  }}
                ></ki-icon-btn>`
          }
          <ki-icon-btn
            class="ripple clipboard-btn ${this.tableOpen ? '' : 'hideButton'}"
            id="table-download-csv-btn"
            tooltip="${this.i18n.t('copyTableMessage')}"
            title="${this.i18n.t('downloadTableAsCSVTitle')}"
            icon="ki ki-file"
            .value=${this.orderedTableData}
            @click=${() => {
              downloadFile(
                this.renderRoot
                  .querySelector('#dataTable')
                  .toCSV(getCsvDelimiter())
                  .replace(/(<([^>]+)>)/gi, ''),
                `${this.station.station_no}_${this.station.station_name}_graph_table.csv`,
              );
            }}
          ></ki-icon-btn>
          <ki-icon-btn
            toggle
            title="${this.i18n.t('tableTitle')}"
            icon="ki ki-table"
            ?active="${this.tableOpen}"
            @click="${this.showTable}"
          ></ki-icon-btn>
        </div>
      </div>
      <div class="contentnode" id="contentnode">
        <div id="chart-wrapper">
          <ui5-select
            class="hideButton"
            id="yearselector"
            @change="${this._handleYearChange}"
          >
            ${this._years.map(
              year =>
                html`<ui5-option
                  ?selected=${year.value === 'YTD'}
                  value="${year.value}"
                  >${year.label}</ui5-option
                >`,
            )}
          </ui5-select>
          <div id="chart-node"></div>
        </div>
        <div id="table-wrapper" class="${this.tableOpen ? '' : 'hidden'}">
          <ki-wwp-ts-table
            id="dataTable"
            .columns="${this.tablecolumns}"
            sort='[{"field":"timestamp", "ascending":false}]'
            idproperty="timestamp"
          >
          </ki-wwp-ts-table>
        </div>
      </div>`;
  }

  get _yAxisLabel() {
    let label = template(this.yAxisLabel, this.station);
    if (this.showUnitSystem) {
      const unit =
        find(this.unitSystem, { value: this._graphUnit })?.label || '';
      label = unit ? `${label} [${unit}]` : label;
    }
    return label;
  }

  get _years() {
    if (this.coverage) {
      let max = dayjs(this.coverage.max).tz().get('year');
      const min = dayjs(this.coverage.min).tz().get('year');
      if (this.hydroYearStart !== '01-01') {
        max -= 1;
      }
      const years = [{ value: 'YTD', label: this.i18n.t('currentYear') }];
      while (max >= min) {
        years.push({ value: max, label: max.toString() });
        max -= 1;
      }
      return years;
    }
    return [{ value: 'YTD', label: this.i18n.t('currentYear') }];
  }

  get tablecolumns() {
    return (
      this.tableColumns ?? [
        {
          field: 'timestamp',
          label: this.timestampLabel || this.i18n.t('timestamp'),
          sortable: true,
          hideAble: false,
        },
        {
          field: 'value',
          label: this._yAxisLabel || this.i18n.t('waterlevelcm'),
          sortable: false,
          format: 'html',
          css: 'text-align:right;padding-right:10px;',
        },
      ]
    );
  }

  _initGraphUnit(series) {
    if (series && !this._graphUnit) {
      // execute only once.
      this._graphUnit = series[0]?.ts_unitsymbol || '';
      this.chart?.update(this.defaultOptions());
    }
  }

  getGraphUnitLabel(unitSymbol) {
    if (!unitSymbol) {
      return '';
    }
    let graphUnitLabel = unitSymbol !== '---' ? unitSymbol : '';
    if (this.showUnitSystem) {
      graphUnitLabel =
        find(this.unitSystem, { value: this._graphUnit })?.label || '';
    }
    return graphUnitLabel;
  }

  convertGraphUnit(val, fromUnit) {
    if (this._graphUnit) {
      return convertUnit(val).from(fromUnit).to(this._graphUnit);
    }
    return val;
  }

  _updateAttributeThresholds() {
    const _thresholds = [];

    // reference to the _addAttributeThresholds
    this.attributeThresholds.forEach(item => {
      // Find desired field in files
      const _file = this._findFile(item);
      if (
        _file &&
        _file[item.field] &&
        _file[item.field] !== '' &&
        !Number.isNaN(_file[item.field])
      ) {
        _thresholds.push({
          ts_shortname: item.field,
          data: [
            [
              new Date().toISOString(),
              this.convertGraphUnit(parseFloat(_file[item.field]), _file.units),
            ],
          ],
        });
      } else if (item.fixedValue && typeof item.fixedValue === 'number') {
        _thresholds.push({
          ts_shortname: item.description,
          data: [
            [
              new Date().toISOString(),
              this.convertGraphUnit(item.fixedValue, _file.units),
            ],
          ],
        });
      } else
        console.warn(
          'Attribute Threshold did not match any criteria. Station: ',
          _file,
          '| Config: ',
          item,
        );
    });
    if (_thresholds) {
      _thresholds.forEach((thres, i) => {
        const id = `thres_${i}`;
        const series = find(this.chart?.series, s => s.userOptions?.id === id);
        series &&
          series.setData(this._getLevelData(thres.data), true, true, true);
      });
    }
  }

  _handleUnitChange(e) {
    this._graphUnit = e.target.selectedOption.value;
    this.chart?.update({
      yAxis: {
        title: {
          text: this._yAxisLabel,
        },
      },
    });

    if (this.chart) {
      const seriesSelectedstatus = this.chart?.series?.map(s => ({
        id: s.userOptions.id,
        selected: s.visible,
      })); // remember the series selected status before redraw
      this._updateAttributeThresholds();
      this.drawSeries(this._selectedPeriod);
      this.chart?.series.forEach(s => {
        const sId = s.userOptions.id;
        const isSelected = seriesSelectedstatus.find(
          s => s.id === sId,
        ).selected;
        s.setVisible(isSelected);
        s.select(isSelected);
      });
    }
  }

  _handleYearChange(e) {
    const { value } = e.target.selectedOption;
    const prevYear = value;
    const nextYear = (parseInt(value, 10) + 1).toString();

    const min =
      value && value !== 'YTD'
        ? dayjs(`${prevYear}${this.hydroYearStart}`, `YYYYMM-DD`).tz()
        : dayjs().tz().startOf('year');

    const max =
      value && value !== 'YTD'
        ? dayjs(`${nextYear}${this.hydroYearEnd}`, `YYYYMM-DD`).tz()
        : dayjs().tz();
    this.drawSeries('P1Y', min.valueOf(), max.valueOf());
  }

  showTable() {
    this.renderRoot.querySelector('#table-wrapper').classList.toggle('hidden');
    this.renderRoot
      .querySelector('#table-clipboard-btn')
      ?.classList.toggle('hideButton');
    this.renderRoot
      .querySelector('#table-download-csv-btn')
      .classList.toggle('hideButton');
    this.tableOpen = !this.tableOpen;
    this.chart && this.chart.reflow();
  }

  downloadGraph() {
    this.chart.update(
      {
        exporting: {
          chartOptions: {
            title: {
              text: this.graphTitle
                ? template(this.graphTitle, this.station)
                : '',
            },
            subtitle: {
              text: `${this.i18n.t('station_no')}: ${
                this.station.station_no
              } | ${this.i18n.t('request_at')} ${dayjs().format('LLLL')} <br>
            ${dayjs(this.chart.xAxis[0].min + 10000)
              .tz()
              .format('L')} - ${dayjs(this.chart.xAxis[0].max + 10000)
              .tz()
              .format('L')}`,
            },
          },
          filename: `${this.parameter_label}_${this.station.station_name}_${
            this.station.station_no
          }_${dayjs().tz().format('YYYYMMDD')}`,
          fallbackToExportServer:
            this.config.allowHighchartsExportServer ?? false,
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
          sourceWidth: 1600,
          sourceHeight: 800,
          scale: 1,
        },
      },
      false,
    );
    this.chart.exportChartLocal();
  }

  async fetchData() {
    this.chart?.destroy();
    this.chart = null;
    const _files = {};
    this.station = this.station || (await this.api.getStation(this.stationId));
    const dataToFetch = [];

    this.station.timeseries.forEach(ts => {
      /**
       * Check if parameters match
       * In case of popup is requesting data: Check if @param href is specified in range */
      if (
        normStr(ts.station_parameter) === normStr(this.station_parameter) &&
        this.ranges.some(range => ts.href.includes(range.data))
      ) {
        dataToFetch.push(ts.href);
      }
      if (this.additionalTsFiles) {
        this.additionalTsFiles.forEach(addts => {
          if (ts.href.includes(addts)) {
            dataToFetch.push(ts.href);
          }
        });
      }
      if (this?.alarms?.file) {
        this.alarms.file.forEach(addts => {
          if (ts.href.includes(addts)) {
            dataToFetch.push(ts.href);
          }
        });
      }
    });

    await Promise.all(
      uniq(dataToFetch).map(async href => {
        const _path = href.split('/').reverse();
        const _id = `${_path[1]}/${_path[0]}`;
        _files[_id] = await this.api.getTsData(href);
      }),
    );

    this.files = DataTransformation.normalizeFilesObject(_files);

    let selected = find(this.ranges, { selected: true });
    const yearcfg = find(this.ranges, range =>
      ['P1Y', 'PoR'].includes(range.value),
    );
    if (yearcfg && rangeObjectContains(this.files, yearcfg.data)) {
      const files = getFile(this.files, yearcfg);
      let min: string | null = null;
      let max: string | null = null;
      files.forEach(f => {
        const firstTs = first(f.data)?.[0];
        const lastTs = last(f.data)?.[0];
        if (firstTs === undefined || lastTs === undefined) {
          console.error(`Data is missing in file: ${f}`);
        }
        if (!min || firstTs < min) min = firstTs;
        if (!max || lastTs > max) max = lastTs;
      });
      this.coverage = { min, max };
      if (!this.coverage.min || !this.coverage.max)
        console.warn('Coverage not set!');
    }
    selected = this._selectedPeriod || selected?.value || this.ranges[0].value;
    if (!find(this.ranges, { value: selected })) {
      selected = this.ranges[0].value;
    }
    this._selectedPeriod = selected;
    this._setMaxValue(selected);
    if (this.station) {
      this.createChart();
      this.ranges.forEach(range => {
        if (range.timeseries) {
          if (this.additionalTsFiles) {
            this.additionalTsFiles.forEach(addf => {
              if (this.files[range.data]) {
                if (this.files[addf]) {
                  this.files[range.data] = uniq(
                    this.files[range.data].concat(this.files[addf]),
                  );
                }
              }
            });
          }

          /** Filter unwanted timeseries */
          range.timeseries = range.timeseries.filter(
            ts =>
              !ts.ts_shortname ||
              getFile(this.files, range).some(
                tsfile => ts.ts_shortname === tsfile.ts_shortname,
              ),
          );
        }
      });

      this.drawSeries(selected);
      this.requestUpdate();
    }
    return this.files;
  }

  _setMaxValue(period) {
    const cfg = find(this.ranges, { value: period });
    if (rangeObjectContains(this.files, cfg.data)) {
      let currentMax = -Infinity;
      let currentMin = Infinity;
      getFile(this.files, cfg).forEach(ts => {
        ts.data.forEach(dp => {
          currentMax = Math.max(dp[1], currentMax);
          currentMin = Math.min(dp[1], currentMin);
        });
      });
      this.maxInitalValue = currentMax;
      this.minInitalValue = currentMin;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  _getLastValue(data) {
    return data && data[0] ? last(last(data)) : null;
  }

  // eslint-disable-next-line class-methods-use-this
  _splitEventValues(data) {
    const artificalTs = [];
    data.forEach(ts => {
      ts.data.forEach(dp =>
        artificalTs.push({
          ts_name: dayjs(dp[0]).tz().format('L'),
          data: [dp],
        }),
      );
    });
    return artificalTs;
  }

  _getThreshold(ts, i, key, ploptions) {
    return merge(
      merge(
        {
          id: `${key}_${i}PL`,
          value: this._getLastValue(ts.data),
          width: 2,
          color: 'rgba(19, 19, 19, 1)',
          dashStyle: 'dash',
          zIndex: 111,
          label: {
            text: ts.ts_shortname
              ? ts.ts_shortname.replace('Cmd.', '')
              : ts.ts_name,
            align: 'right',
            y: 12,
            x: 0,
          },
        },
        ploptions,
      ),
      (this.threshholdMappings && this.threshholdMappings[ts.ts_shortname]) ||
        {},
    );
  }

  // eslint-disable-next-line class-methods-use-this
  _getLevelData(data) {
    const lastValue = last(data);
    return data.length
      ? [
          [1, lastValue[1]],
          [2, lastValue[1]],
        ]
      : [];
  }

  _getThreshholds(
    graphOptions,
    list,
    key,
    label,
    ploptions = null,
    visible = true,
  ) {
    if (visible !== 'auto' && typeof visible === 'string') {
      visible = this.layerName === visible;
    }

    let color;
    list.forEach((ts, i) => {
      const al = this._getThreshold(ts, i, key, ploptions);
      color = al.color || 'rgba(255,255,255,0)';
      graphOptions.series.push({
        label: {
          enabled: true,
          connectorAllowed: false, // Popup HLNUG
          format: this.showColorSquare
            ? "<span style='color: {color}'>◼</span> {name}"
            : '{name}',
          style: {
            color: 'rgb(19,19,19)',
            fontWeight: 'bold',
          },
        },
        dashStyle: al.dashStyle,
        width: al.width,
        xAxis: 'alarmAxis',
        marker: { enabled: false },
        enableMouseTracking: false,
        linkedTo: key,
        showInLegend: false,
        data: this._getLevelData(ts.data),
        description: al.label.description,
        shortName: al.label.text,
        name:
          this.descriptionVisible && al.label.description
            ? al.label.description
            : al.label.text,
        color,
      });
    });
    graphOptions.series.push({
      name: label,
      color: list.length === 1 ? color : 'rgba(255,255,255,0)',
      id: key,
      visible,
      selected: visible,
    });
  }

  createGraphOptions() {
    const alarmFileName = this.alarms?.file;
    let alarmFile = alarmFileName
      ? compact(flatten(alarmFileName.map(file => this.files[file])))
      : null;
    const graphOptions = {
      chart: {
        //  zoomType: "x",
        animation: false,
        type: this.chartType,
        pinchType: undefined,
        zoomType: this.zoomType,
        panning: {
          enabled: false,
        },
      },
      boost: {
        enabled: this.boost,
        useGPUTranslations: false,
        allowForce: false,
      },
      series: [],
      plotOptions: {
        area: {
          threshold: -Infinity,
        },
        column: {
          groupPadding: 0,
        },
        series: {
          //  boostThreshold:0,
          //   turboThreshold:0,
          animation: false,
          gapSize: this.gapSize !== undefined ? this.gapSize : 1,
          gapUnit: this.gapUnit !== undefined ? this.gapUnit : 'relative', // if gapUnit is set to "value" gapSize should be defined in miliseconds
          label: {
            enabled: false,
          },
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      yAxis: merge(
        {
          id: 'mainYAxis',
          plotLines: [],
        },
        this.yAxis,
      ),
      xAxis: [
        {
          id: 'mainXAxis',
          type: 'datetime',
          ordinal: false,
          labels: {
            formatter() {
              return this.dateTimeLabelFormat === '%e. %b' &&
                this.tickPositionInfo.unitName === 'hour'
                ? `<b>${this.axis.defaultLabelFormatter.call(this)}</b>`
                : this.axis.defaultLabelFormatter.call(this);
            },
          },
        },
        {
          visible: false,
          min: 1,
          max: 2,
          id: 'alarmAxis',
        },
      ],
    };
    this.hasAlarms = false;

    // Read alarms/thresholds provided as station/timeseries attributes (e.g. Station, week.json, year.json)
    if (this.attributeThresholds.length) {
      this._addAttributeThresholds(graphOptions);
    }

    if (this.alarms?.stationAttributes) {
      alarmFile = [];
      this.alarms.items.forEach(item => {
        item.tsList.forEach(ts => {
          if (this.station[ts]) {
            alarmFile.push({
              ts_shortname: ts,
              data: [[new Date().getTime(), parseFloat(this.station[ts])]],
            });
          }
        });
      });
    }

    if (alarmFile && alarmFile.some(ts => ts.data.length > 0)) {
      let spare = true;
      this.alarms?.items?.forEach((item, i) => {
        const alarms = alarmFile.filter(ts =>
          item.tsList.includes(ts.ts_shortname),
        );
        if (alarms.some(ts => ts.data.length)) {
          let { active } = item;
          if (item.active === 'auto') {
            if (alarms[0].data[0][1] > this.maxInitalValue) {
              active = false;
            } else {
              active = true;
            }

            if (!active && spare) {
              active = true;
              spare = false;
            }
          } else if (item.active === 'automin') {
            if (alarms[0].data[0][1] < this.minInitalValue) {
              active = false;
            } else {
              active = true;
            }

            if (!active && spare) {
              active = true;
              spare = false;
            }
          }
          this._getThreshholds(
            graphOptions,
            alarms,
            `alerts${i}_`,
            item.label || this.i18n.t('alarmlevels'),
            null,
            active,
          );
          this.hasAlarms = true;
        }
      });
    }

    if (this.files[this.events]) {
      const defaultPlOptions = {
        width: 2,
        color: 'rgba(0, 86, 160, 1)',
        dashStyle: 'DashDot',
        label: {
          align: 'left',
          y: 12,
          x: 0,
        },
      };
      this._getThreshholds(
        graphOptions,
        this._splitEventValues(this.files[this.events]),
        'events_',
        'HW-Ereignisse',
        defaultPlOptions,
        false,
      );
    }
    return graphOptions;
  }

  // Searches through available data structures for attributes
  _findFile(fielditem) {
    // Check for station attributes (e.g. ww-me)
    if (this.station[fielditem.field]) return this.station;
    // Check for timeseries attributes (e.g. ww-brw))
    // Specify files to look at in app.json -> options -> thresholdFiles[]
    const res = this.thresholdFiles?.map(_file => {
      if (this.files && this.files[_file]) return _file;
      return null;
    });
    if (res) return first(this.files[first(res)]);
    // Nothing found, return undefined
    return res;
  }

  _addAttributeThresholds(graphOptions) {
    const _thresholds = [];

    this.attributeThresholds.forEach(item => {
      // Find desired field in files
      const _file = this._findFile(item);
      if (
        _file &&
        _file[item.field] &&
        _file[item.field] !== '' &&
        !Number.isNaN(_file[item.field])
      ) {
        _thresholds.push({
          ts_shortname: item.field,
          data: [
            [
              new Date().toISOString(),
              this.convertGraphUnit(parseFloat(_file[item.field]), _file.units),
            ],
          ],
          label: item.label?.text || item.label,
          color: item.color,
          visible: item.visible,
          selected: item.selected,
          description: item.template
            ? template(item.description, _file)
            : item.description,
          shortName: item.label?.text || item.label,
        });
      } else if (item.fixedValue && typeof item.fixedValue === 'number') {
        _thresholds.push({
          ts_shortname: item.description,
          data: [
            [
              new Date().toISOString(),
              this.convertGraphUnit(item.fixedValue, _file.units),
            ],
          ],
          label: item.label?.text || item.label,
          color: item.color,
          visible: item.visible,
          selected: item.selected,
          description: item.template
            ? template(item.description, _file)
            : item.description,
          shortName: item.label?.text || item.label,
        });
      } else
        console.warn(
          'Attribute Threshold did not match any criteria. Station: ',
          _file,
          '| Config: ',
          item,
        );
    });
    if (_thresholds) {
      _thresholds.forEach((thres, i) => {
        graphOptions.series.push({
          xAxis: 'alarmAxis',
          marker: { enabled: false },
          enableMouseTracking: false,
          name: thres.label,
          label: {
            text: thres.label,
            enabled: true,
          },
          color: thres.color || 'rgba(255,255,255,0)',
          dashStyle: 'dash',
          id: `thres_${i}`,
          visible: thres.selected ?? true,
          selected: thres.selected ?? true,
          data: this._getLevelData(thres.data),
          description: thres.description,
          shortName: thres.label,
        });
      });
      this.hasAlarms = true;
    }
  }

  _getExtremes(attr) {
    if (typeof this[attr] === 'number') {
      return this[attr];
    }
    if (this[attr] && this.station[this[attr]]) {
      return this.station[this[attr]] ? Number(this.station[this[attr]]) : null;
    }
    return null;
  }

  defaultOptions() {
    const conf = merge(
      {
        title: {
          text: '',
        },
        subtitle: {
          text: this.subTitle || '',
          floating: true,
        },
        credits: this.creditOptions,
        events: {},
        exporting: {
          chartOptions: {
            title: {
              text: this.graphTitle
                ? template(this.graphTitle, this.station)
                : '',
            },
            subtitle: {
              text: `${this.i18n.t('station_no')}: ${
                this.station.station_no
              } | ${this.i18n.t('request_at')} ${dayjs().tz().format('LLLL')}`,
            },
          },
          filename: `${this.parameter_label}_${this.station.station_name}_${
            this.station.station_no
          }_${dayjs().tz().format('YYYYMMDD_kkmmss')}`,
          fallbackToExportServer:
            this.config.allowHighchartsExportServer ?? false,
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
          sourceWidth: 1600,
          sourceHeight: 800,
          scale: 1,
        },
        navigator: {
          enabled: false,
        },
        rangeSelector: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        navigation: {
          enabled: false,
        },
        plotOptions: {
          series: {
            //  gapSize: 1,
            //  boostThreshold:0,
            //  turboThreshold:0,
            dataGrouping: {
              enabled: false,
            },
            showCheckbox: ViewPort.size !== SM,
            events: {
              checkboxClick(event) {
                event.preventDefault();
                if (event.checked) {
                  this.show();
                } else {
                  this.hide();
                }
              },
              legendItemClick(event) {
                event.preventDefault();
                if (this.visible) {
                  if (this.checkbox) {
                    this.checkbox.checked = false;
                  }
                  this.hide();
                } else {
                  if (this.checkbox) {
                    this.checkbox.checked = true;
                  }
                  this.show();
                }
              },
            },
          },
        },
        legend: this.legendOptions,
        tooltip: {
          shared: true,
          split: false,
          useHTML: true,
        },
        time: {
          timezone: this.timeZone,
        },
        yAxis: {
          allowDecimals: !this.yAxisHideDecimals,
          reversed: this.yAxisReverse || false,
          minRange: this._getExtremes('yAxisMinRange'),
          softMax: this._getExtremes('yAxisMax'),
          softMin: this._getExtremes('yAxisMin'),
          opposite: false,
          title: {
            text: this._yAxisLabel || this.i18n.t('waterlevelcm'),
          },
        },
        series: [],
      },
      this.createGraphOptions(),
    );
    return conf;
  }
}
