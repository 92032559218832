/* eslint-disable max-classes-per-file */
import { LitElement, html, css } from 'lit';
import { customElement, query } from 'lit/decorators.js';
import Highcharts from 'highcharts/highstock';
import { cloneDeep } from 'lodash-es';
import more from 'highcharts/highcharts-more';
import nodata from 'highcharts/modules/no-data-to-display';
import exporting from 'highcharts/modules/exporting';
import label from 'highcharts/modules/series-label';
import offlineexporting from 'highcharts/modules/offline-exporting';
import boost from 'highcharts/modules/boost';
import { i18nMixin } from '../../decorators';
import { Mix, hclocales } from '../../common';
import nls from '../../locales/index';

more(Highcharts);
label(Highcharts);
nodata(Highcharts);
exporting(Highcharts);
offlineexporting(Highcharts);
boost(Highcharts);

@customElement('ki-chart')
export default class KiChart extends Mix(LitElement, [i18nMixin, { nls }]) {
  @query('#chart')
  chartNode;

  highcharts = Highcharts;

  static get properties() {
    return {
      options: { type: Object },
    };
  }

  static styles = css`
    #chart {
      height: 100%;
      width: 100%;
    }
  `;

  updated(changedProperties) {
    super.update(changedProperties);
    if (this.chart) {
      if (changedProperties.has('options')) {
        this.options.then(opt => {
          this.chart.update(cloneDeep(opt), true, true);
        });
      }
    }
  }

  render() {
    return html`<div id="chart"></div>`;
  }

  firstUpdated() {
    super.firstUpdated();
    if (hclocales[this.i18n.language.substring(0, 2)]) {
      this.highcharts.setOptions({
        lang: hclocales[this.i18n.language.substring(0, 2)],
      });
    }

    const Chart = Highcharts.chart;
    if (Chart) {
      this.chart = Chart(
        this.chartNode,
        cloneDeep(this.options || {}),
        this.callback ? this.callback : null,
      );
    }
  }

  disconnectedCallback() {
    this.chart?.destroy();
    if (super.disconnectedCallback) super.disconnectedCallback();
  }
}
