/* eslint-disable max-classes-per-file */
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import merge from 'lodash-es/merge';
import { debounce, unzipWith, last } from 'lodash-es';
import { getQueryObject, i18nMixin } from '../../decorators';
import { Mix, PropertyDefaultValue } from '../../common';
import nls from '../../locales/index';
import style from './ki-ts-viewer.css?inline';
// import './components'; // TODO: Why?
import '../ki-chart/ki-ts-graph';
import '../ki-time-range-picker/ki-time-range-picker';
import './ki-ts-viewer-table';

@customElement('ki-ts-viewer')
export default class KiTsViewer extends Mix(LitElement, PropertyDefaultValue, [
  i18nMixin,
  { nls },
]) {
  constructor() {
    super();

    this.tableOptions = {
      columns: [
        {
          field: 'x',
          label: this.i18n.t('valueTableHeaderTimestamp'),
          format: 'dateTime',
          sortable: true,
        },
        {
          field: 'y',
          label: this.i18n.t('valueTableHeaderValue'),
          sortable: false,
        },
      ],
      sort: [
        {
          field: 'x',
          ascending: false,
        },
      ],
    };
  }

  static styles = style;

  @property({ type: Object })
  chartOptions = {};

  @property({ type: Object })
  customAttributes = {};

  @property({ type: Object })
  tableOptions;

  @property({ type: Array })
  periods = [
    ['last1month', 'last6month', 'last1year', 'last5year', 'last10year'],
  ];

  @property({ type: Function })
  getTsData;

  @property({ type: Function })
  getTableData = function () {
    const mainTs = this.graph.chart.series.find(s => s.options.mainTs);
    return mainTs
      ? unzipWith([mainTs.processedXData, mainTs.processedYData], (x, y) => ({
          x,
          y,
        }))
      : [];
  };

  @property({ type: Boolean })
  tableVisible = false;

  @property({ type: Object })
  range = {};

  @property({ type: Array })
  tableData = [];

  get graph() {
    const graph = getQueryObject(this, '#graph');
    return graph;
  }

  render() {
    // language=html
    return html`
      <div class="header">
        <div class="title"><slot name="title">Title</slot></div>
        <div class="header-center">
          <div class="customAttributes">
            ${this.customAttributes?.AdminStatus ||
            this.customAttributes?.AdminBemerkung
              ? html` <span class="adminStatus"
                    >${this.customAttributes.AdminStatus}</span
                  >
                  <span class="adminBemerkung"
                    >${this.customAttributes.AdminBemerkung}</span
                  >`
              : html``}
          </div>
        </div>
        <div class="actions">
          <ki-time-range-picker
            showperiodlist
            initMin="${this.range?.min || -Infinity}"
            initMax="${this.range?.max || Infinity}"
            dateFormat="DD.MM.YYYY"
            @changedate="${this._updateRange}"
            .periods="${this.periods}"
          ></ki-time-range-picker>

          <div class="separator">|</div>

          <ki-icon-btn
            class="ripple"
            id="graph-download-button"
            title=${this.i18n.t('downloadGraph')}
            icon="ki ki-download"
            @click="${this.downloadGraph}"
          ></ki-icon-btn>
          <!--<ki-icon-btn class="ripple" id="quality-btn" icon="ki ki-quality-bars" title="Show quality control"></ki-icon-btn>-->
          <ki-icon-btn
            title=${this.i18n.t('showValueTable')}
            icon="ki ki-table"
            class="ripple ${this.tableVisible ? 'selected' : ''}"
            @click="${() => {
              this.tableVisible = !this.tableVisible;
            }}"
          >
          </ki-icon-btn>
          <slot name="actions"></slot>
        </div>
      </div>
      <div class="containerNode">
        <div class="chart-wrapper">
          <ki-ts-graph
            id="graph"
            .options="${this.computedChartOptions}"
            .getTsData="${ts =>
              this.getTsData({
                range: this.range,
                ts,
              })}"
          ></ki-ts-graph>
        </div>
        <div class="table-wrapper ${this.tableVisible ? '' : 'hidden'}">
          <ki-ts-viewer-table
            .columns="${this.tableOptions.columns}"
            .sort="${this.tableOptions.sort || []}"
            .data="${this.tableData}"
          >
          </ki-ts-viewer-table>
        </div>
      </div>
    `;
  }

  toggleTableVisible() {
    this.graph.chart.reflow();
  }

  _updateRange(e) {
    this.graph.chart?.zoomOut();
    this.range = {
      min: e.detail.fromTime.valueOf(),
      max: e.detail.toTime.valueOf(),
    };
    this.dispatchEvent(
      new CustomEvent('rangeChange', {
        detail: {
          range: this.range,
        },
        bubbles: true,
        composed: true,
      }),
    );
  }

  // eslint-disable-next-line class-methods-use-this
  _transformAlarmTs(data) {
    // Add left-side limit
    data[0].data.unshift([
      new Date('1900-1-1').toISOString(),
      data[0].data[0][1],
    ]);
    // Add right-side limit
    data[0].data.push([
      new Date(Date.now()).toISOString(),
      last(data[0].data)[1],
    ]);
  }

  get computedChartOptions() {
    const options = merge(
      {
        xAxis: {
          events: {
            // todo debounce ?
            afterSetExtremes: debounce(e => {
              this.tableData = this.getTableData({ max: e.max, min: e.min });
            }, 100),
          },
        },
      },
      this.chartOptions,
    );

    merge(options, {
      xAxis: {
        ...this.range,
      },
    });
    return options;
  }

  downloadGraph() {
    this.graph.chart.exportChartLocal();
  }
}
