/* eslint-disable max-classes-per-file */
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { LG, ViewPort } from '../../decorators';
// import './components'; // TODO: Why?

import '@ui5/webcomponents/dist/MultiComboBox';
import '@ui5/webcomponents/dist/ComboBox';
import '@ui5/webcomponents/dist/DateRangePicker';
import '@ui5/webcomponents/dist/Dialog';
import '@ui5/webcomponents-icons/dist/filter.js';
import '@ui5/webcomponents/dist/Assets.js';

import KiWcpWwpFilter from './ki-wcp-wwp-filter';
import '@ui5/webcomponents/dist/Badge';

@customElement('ki-wcp-wwp-advance-filter')
export default class KiWcpWwpAdvanceFilter extends KiWcpWwpFilter {
  // language=CSS
  static styles = [
    KiWcpWwpFilter.styles,
    css`
      :host {
        --sapButton_TextColor: var(--theme-text-color);
        --sapButton_BorderColor: lightgrey;
        --sapButton_Hover_BorderColor: lightgrey;
        --sapButton_Hover_TextColor: var(--theme-text-color);
      }
      :host .filter-btn {
        height: 100%;
      }
      :host .filter-btn ui5-badge {
        vertical-align: baseline;
      }

      :host ki-wcp-wwp-advance-filter {
        z-index: 10;
      }

      .field-item {
        width: 97%;
        max-width: unset;
        margin: 0.2rem 0.4rem;
      }

      .reset-btn-container {
        text-align: right;
      }
      .reset-btn {
        margin: 16px;
        --sapButton_TextColor: var(--theme-text-color);
        --sapButton_BorderColor: lightgrey;
        --sapButton_Hover_BorderColor: lightgrey;
        --sapButton_Hover_TextColor: var(--theme-text-color);
      }
    `,
  ];

  // TODO support compact mode
  @property()
  mode: 'simple' | 'advanced' = 'simple'; //

  @state()
  collapse = true;

  _toggleFilterPanel() {
    this.collapse = !this.collapse;
  }

  _renderFavoriteBtn() {
    // no favor btn for advance filter
    return this._isAdvanced() ? html`` : super._renderFavoriteBtn();
  }

  render() {
    if (this._isAdvanced()) {
      // language=html
      return this.collapse
        ? html`<ui5-button
            class="filter-btn"
            icon="sap-icon://filter"
            design="Default"
            accessible-name="Button with Accessible Name"
            @click="${this._toggleFilterPanel}"
          >
            ${ViewPort.size === LG ? this.i18n.t('ADVANCEFILTER.FILTER') : ''}
            ${this.activeFilter === '{}'
              ? html``
              : html`<ui5-badge color-scheme="6"
                  >${this.stations.length}</ui5-badge
                >`}
          </ui5-button>`
        : html`
            <ki-drawer
              ?opened="${!this.collapse}"
              @close="${() => this._toggleFilterPanel()}"
              title="${this.i18n.t('ADVANCEFILTER.FILTER')}"
            >
              <div class="filter-content">${super.render()}</div>
              <div class="reset-btn-container">
                <ui5-button
                  class="reset-btn"
                  design="Default"
                  @click="${() => this._resetValue()}"
                >
                  ${this.i18n.t('ADVANCEFILTER.RESET')}
                </ui5-button>
              </div>
            </ki-drawer>
          `;
    }
    return super.render();
  }

  _isAdvanced() {
    return this.mode === 'advanced';
  }
}
