import { LitElement, css, html, PropertyValues } from 'lit';
import { customElement, query, property } from 'lit/decorators.js';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, SankeyChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { SVGRenderer } from 'echarts/renderers';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  GraphicComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
} from 'echarts/components';
import { ECBasicOption } from 'echarts/types/dist/shared';
import { Mix } from '../../common';
import { i18nMixin } from '../../decorators';
import nls from '../../locales/index';
/** TODO: [WW-374] Find a more generic way to handle localisation */
import 'echarts/lib/i18n/langEN';
import 'echarts/lib/i18n/langDE';
import 'echarts/lib/i18n/langFR';
import './echartsLocales/langIS';

import dayjs from '../../common/dayjsext';

@customElement('ts-graph-echarts')
export class EchartsGraph extends Mix(LitElement, [i18nMixin, { nls }]) {
  @query('#chart')
  private chartNode!: HTMLDivElement;

  /** Echarts locale settings */
  @property({ type: String })
  locale: 'en' | 'de' | 'fr' = 'en';

  @property({ type: Object })
  options: ECBasicOption = {};

  @property({ type: Number })
  _legendHeight: Number = 0;

  private chart!: echarts.ECharts;

  get legendBoundingBox() {
    const legendModel = this.chart.getModel()?.getComponent('legend');
    if (!legendModel) {
      return undefined;
    }
    const legendView = this.chart.getViewOfComponentModel(legendModel);
    return legendView.group.getBoundingRect();
  }

  set legendHeight(value) {
    const v = Number.parseInt(value + 50, 10);
    if (v !== this._legendHeight) {
      this._legendHeight = v;
      this.options.grid.bottom = v;
      this.options = { ...this.options };
    }
  }

  get legendHeight(): Number {
    return this._legendHeight;
  }

  constructor() {
    super();
    echarts.use([
      TitleComponent,
      ToolboxComponent,
      TooltipComponent,
      GridComponent,
      LegendComponent,
      LineChart,
      BarChart,
      SankeyChart,
      SVGRenderer,
      UniversalTransition,
      GraphicComponent,
      DataZoomComponent,
      DataZoomInsideComponent,
      DataZoomSliderComponent,
    ]);
  }

  async firstUpdated() {
    this.chart = echarts.init(this.chartNode, '', {
      locale: this.locale.toUpperCase(),
    });
    const resizeObserver = new ResizeObserver(async () => {
      this.chart.resize();
      await this.updateComplete;
      this.measureLegend();
    });

    resizeObserver.observe(this);
  }

  async updated(changed: PropertyValues<this>) {
    if (changed.has('options') && this.options) {
      this.chart.setOption(this.options, {
        replaceMerge: ['series', 'yAxis'],
      });
    }
    await this.updateComplete;
    // Responsive Legend - will be resized after rendering, therefor wait for updateComplete
    this.measureLegend();
    this.chart.resize();
  }

  // eslint-disable-next-line class-methods-use-this
  getText(stations, parameters) {
    if (stations.length === 1 && parameters.length === 1)
      return `${stations[0].name} - ${parameters[0].name}`;
    if (stations.length > 1) return `${stations.map(s => s.name).join(', ')}`;
    return `${stations[0].name} - ${parameters.map(p => p.name).join(', ')}`;
  }

  /**
   * Resize chart grid dependent on legendBox legend boundingBox
   * @param chartHeight and @param chartWidth define the original size of the container
   */
  measureLegend() {
    this.legendHeight = this.legendBoundingBox?.height;
  }

  downloadImage(
    stations = [],
    parameters = [],
    filename = `Export_${dayjs().format('DD-MM-YYYY_HHmmss')}`,
  ) {
    let _filename = '';
    if (stations.length === 1 && parameters.length === 1)
      _filename = `${stations[0].name}_${parameters[0].name}_${dayjs().format(
        'DD-MM-YYYY_HHmmss',
      )}`;
    else _filename = filename;
    const opts = this.chart.getOption();
    const exportOptions = {
      title: {
        show: true,
        text: this.getText(stations, parameters),
        subtext: `${this.i18n.t('request_at')}: ${dayjs().format(
          'L LT',
        )} |  ${this.i18n.t('uncheckedRawData')}`,
      },
      toolbox: { show: false },
    };
    this.chart.setOption(exportOptions);

    const img = document.createElement('img');
    img.src = this.chart.getDataURL({
      pixelRatio: 1, // image's ratio. default is 1
      type: 'svg',
    });

    if (img.complete) {
      this.downloadLoadedImage(img, opts, _filename);
    } else {
      img.onload = () => {
        this.downloadLoadedImage(img, opts, _filename);
      };
    }
  }

  downloadLoadedImage(
    img: HTMLImageElement,
    resetOptions: ECBasicOption,
    filename: string,
  ) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = '#fff';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = filename;
    link.click();
    this.chart.setOption(resetOptions);
  }

  render() {
    return html`<div id="chart"></div>`;
  }

  static styles = css`
    :host {
      display: flex;
      overflow: hidden;
      height: 100%;
    }

    #chart {
      flex: 1;
    }
  `;
}

declare global {
  interface HTMLElementTagNameMap {
    'ts-graph-echarts': EchartsGraph;
  }
}
