import { NestedArray, TypedArray, ZarrArray, slice } from 'zarr';
import { Slice } from 'zarr/types/core/types';
import {
  AllLayerMetadata,
  AllParameterMetadata,
  DataMetadata,
  LastValues,
  loadLastValues,
  loadLayerMetadata,
  loadMetadata,
  openZarrGroup,
} from './Zarr';

export interface StationWithSiteMetadata {
  site_no: string;
  site_name: string;
  station_id: string;
  station_no: string;
  station_name: string;
  station_latitude: number;
  station_longitude: number;
  owner: string;
  river: string;
  basin: string;
  subbasin: string;
  state: string;
  district: string;
  subsystem: number;
  fcst_file_prefix: string;
  division: string;
  type: string;
  wl: number;
  dl: number;
  hfl_value: number;
  hfl_date: string;
  parameter: AllParameterMetadata;
}

const layerStationCache: any = {};
let metadataCache: DataMetadata | undefined;
let metadataPromise: Promise<DataMetadata | undefined> | undefined;

let layerMetadataCache: AllLayerMetadata | undefined;
let layerMetadataPromise: Promise<AllLayerMetadata | undefined> | undefined;

let lastValuesCache: LastValues | undefined;
let lastValuesPromise: Promise<LastValues | undefined> | undefined;

export const getLastValues = async (): Promise<LastValues | undefined> => {
  if (lastValuesPromise) {
    return lastValuesPromise;
  }
  if (lastValuesCache) {
    return lastValuesCache;
  }
  lastValuesPromise = loadLastValues().then(lastValues => {
    if (!lastValues) {
      console.warn('error loading last values');
      return undefined;
    }
    lastValuesCache = lastValues;
    return lastValues;
  });

  return lastValuesPromise.then(lastValues => {
    lastValuesPromise = undefined;
    return lastValues;
  });
};

export const getMetadata = async (): Promise<DataMetadata | undefined> => {
  if (metadataPromise) {
    return metadataPromise;
  }
  if (metadataCache) {
    return metadataCache;
  }
  metadataPromise = loadMetadata().then(allMetadata => {
    if (!allMetadata) {
      console.warn('error loading metadata');
      return undefined;
    }
    metadataCache = allMetadata;
    return allMetadata;
  });

  return metadataPromise.then(metadata => {
    metadataPromise = undefined;
    return metadata;
  });
};

export const getLayerMetadata = async (): Promise<
  AllLayerMetadata | undefined
> => {
  if (layerMetadataPromise) {
    return layerMetadataPromise;
  }
  if (layerMetadataCache) {
    return layerMetadataCache;
  }
  layerMetadataPromise = loadLayerMetadata().then(allMetadata => {
    if (!allMetadata) {
      console.warn('error loading layer metadata');
      return undefined;
    }
    layerMetadataCache = allMetadata;
    return allMetadata;
  });

  return layerMetadataPromise.then(metadata => {
    layerMetadataPromise = undefined;
    return metadata;
  });
};

export const getLayerStations = async (
  layerId: string,
): Promise<Array<string>> => {
  if (layerStationCache[layerId]) {
    return layerStationCache[layerId];
  }
  const stationsFile = await fetch(
    `/internet/layer/${layerId}/ts_id_mapping.json`,
  );

  if (stationsFile.status >= 300) {
    console.warn(`stations file for layer ${layerId} not found!`);
    return [];
  }

  const stations = await stationsFile.json();
  if (!(stations.length >= 0)) {
    console.warn(
      `stations for layer ${layerId} in wrong format. Please check file`,
    );
    return [];
  }
  layerStationCache[layerId] = stations
    .map(station => {
      const split = station.split('/');
      // split[3] is ts
      return split.length > 2 ? split[1] : undefined;
    })
    .filter(stationNo => stationNo !== undefined);
  return layerStationCache[layerId];
};

export const getAllStations = async (): Promise<
  Array<StationWithSiteMetadata>
> => {
  const allMetadata = await getMetadata();
  if (!allMetadata?.sites) {
    return [];
  }

  const siteStations = Object.keys(allMetadata.sites).map(siteNo => {
    const site = allMetadata.sites[siteNo];
    const stations = Object.keys(site.stations);

    return stations.map(stationNo => {
      const station = site.stations[stationNo];

      return {
        ...station,
        ...station.attr,
        ...site.attr,
        attr: undefined,
      };
    });
  });

  const result: Array<StationWithSiteMetadata> = [];

  siteStations.forEach(_siteStations => {
    result.push(..._siteStations);
  });

  return result;
};

export const getStationMetadataById = async (
  stationId: string,
): Promise<StationWithSiteMetadata | undefined> => {
  const allMetadata = await getMetadata();
  if (!allMetadata?.sites) {
    return undefined;
  }
  for (const siteNo of Object.keys(allMetadata.sites)) {
    const site = allMetadata.sites[siteNo];
    const stations = Object.keys(site.stations);
    for (const stationNo of stations) {
      const station = site.stations[stationNo];
      if (station.attr.station_id === stationId) {
        return {
          ...station,
          ...station.attr,
          ...site.attr,
          // @ts-ignore-error
          attr: undefined,
        };
      }
    }
  }
  return undefined;
};

export const getStationMetadata = async (
  stationNo: string,
): Promise<StationWithSiteMetadata | undefined> => {
  const allMetadata = await getMetadata();
  if (!allMetadata?.sites) {
    return undefined;
  }
  for (const siteNo of Object.keys(allMetadata.sites)) {
    const site = allMetadata.sites[siteNo];
    const stations = Object.keys(site.stations);
    if (stations.indexOf(stationNo) >= 0) {
      const station = site.stations[stationNo];
      return {
        ...station,
        ...station.attr,
        ...site.attr,
        // @ts-ignore-error
        attr: undefined,
      };
    }
  }
  return undefined;
};

export const getParameterMetadata = async (
  paramId: string,
): Promise<
  | {
      param_name: string;
      param_shortname: string;
      units: string;
    }
  | undefined
> => {
  const allMetadata = await getMetadata();
  if (!allMetadata?.parameters) {
    return undefined;
  }
  return allMetadata.parameters.find(
    param => param.param_shortname === paramId,
  );
};

export const getTimeseriesMetadata = async (
  ts: string,
): Promise<
  | {
      ts_name: string;
      ts_shortname: string;
      interpolation_type: number;
      ts_decimals: number;
      ts_resolution: string;
      is_forecast: boolean;
    }
  | undefined
> => {
  const allMetadata = await getMetadata();
  if (!allMetadata?.timeseries) {
    return undefined;
  }
  return allMetadata.timeseries.find(param => param.ts_shortname === ts);
};

const getTimeseriesArrays = async (
  siteNo: string,
  stationNo: string,
  paramId: string,
  timeseries: string,
): Promise<{
  timeArray: ZarrArray | undefined;
  dataArray: ZarrArray | undefined;
}> => {
  const tsGroup = await openZarrGroup(
    `/data/${siteNo}/${stationNo}/${paramId}/${timeseries}`,
  );

  const timeArray = (await tsGroup.getItem('_time')) as ZarrArray;
  const dataArray = (await tsGroup.getItem('data')) as ZarrArray;

  if (!timeArray.get || !dataArray.get) {
    console.warn(
      `Something went wrong loading ts Data for site: ${siteNo}, station: ${stationNo}, parameter: ${paramId}, ts: ${timeseries}`,
    );
    return {
      timeArray: undefined,
      dataArray: undefined,
    };
  }

  return {
    timeArray,
    dataArray,
  };
};

export interface TimeseriesData {
  data: Array<{
    timestamp: number;
    value: number | undefined;
  }>;
  ts_name: string;
  ts_shortname: string;
  interpolation_type: number;
  ts_decimals: number;
  ts_resolution: string;
  is_forecast: boolean;
  param_name: string;
  param_shortname: string;
  units: string;
}

export const getEmptyValues = async (paramId: string, timeseries: string) => {
  const timeseriesMetadata = await getTimeseriesMetadata(timeseries);
  const paramMetadata = await getParameterMetadata(paramId);

  if (!timeseriesMetadata || !paramMetadata) {
    console.warn(
      `couldn't load metadata for ts: ${timeseries}, param: ${paramId}`,
    );
    return undefined;
  }
  const tsData: TimeseriesData = {
    ...timeseriesMetadata,
    ...paramMetadata,
    data: [],
  };

  return tsData;
};

const getValues = async (
  siteNo: string,
  stationNo: string,
  paramId: string,
  timeseries: string,
  zarrSlice: Array<number | null | Slice>,
): Promise<TimeseriesData | undefined> => {
  const { timeArray, dataArray } = await getTimeseriesArrays(
    siteNo,
    stationNo,
    paramId,
    timeseries,
  );

  const tsData: TimeseriesData = await getEmptyValues(paramId, timeseries);

  if (!timeArray || !dataArray || !tsData) {
    return tsData;
  }

  const timeSlice = await timeArray.get(zarrSlice);
  const dataSlice = await dataArray.get(zarrSlice);

  if (timeSlice.data && dataSlice.data) {
    const timestamps = timeSlice as NestedArray<TypedArray>;
    const data = dataSlice as NestedArray<TypedArray>;

    const timeseriesData: Array<{
      timestamp: number;
      value: number | undefined;
    }> = new Array(timestamps.data.length);

    for (let index = 0; index < timestamps.data.length; index += 1) {
      const timestamp = (timestamps.data[index] as number) * 1000;
      timeseriesData[index] = {
        timestamp,
        value: undefined,
      };
      if (data.data!.length <= index) {
        continue;
      }
      const newValue = data.data[index] as number;
      if (!Number.isNaN(newValue) && newValue !== null) {
        timeseriesData[index].value = newValue;
      }
    }

    tsData.data = timeseriesData;

    return tsData;
  }

  if (!Number.isNaN(timeSlice) && !Number.isNaN(dataSlice)) {
    tsData.data = [
      {
        timestamp: timeSlice as number,
        value: dataSlice as number,
      },
    ];

    return tsData;
  }

  console.warn(
    `Something went wrong loading ts Data for site: ${siteNo}, station: ${stationNo}, parameter: ${paramId}, ts: ${timeseries}`,
  );
  return tsData;
};

const calculateGapOffset = (
  first: number,
  second: number | undefined,
  secondLast: number | undefined,
  last: number,
  items: number,
): number => {
  let diff: number | undefined;
  if (secondLast !== undefined) {
    diff = last - secondLast;
  }

  if (diff === undefined && second === undefined) {
    return 0;
  }
  if (diff === undefined && second !== undefined) {
    diff = second - first;
  }

  if (diff !== undefined && diff <= 86400000) {
    // smaller than a day
    const expectedItems = (last - first + 1) / diff;

    const offset = expectedItems - items;

    return offset > 0 ? offset : 0;
  }

  return 0;
};

export const getIntervalTimeseriesData = async (
  siteNo: string,
  stationNo: string,
  paramId: string,
  timeseries: string,
  from: number,
  to: number,
): Promise<TimeseriesData | undefined> => {
  const { timeArray } = await getTimeseriesArrays(
    siteNo,
    stationNo,
    paramId,
    timeseries,
  );

  if (!timeArray) {
    return undefined;
  }

  const timestampMin = (await timeArray.get([
    slice(null, 2),
  ])) as NestedArray<TypedArray>;
  const timestampMax = (await timeArray.get([
    slice(-2, null),
  ])) as NestedArray<TypedArray>;

  const itemsM1 = timeArray.meta.chunks[0] - 1;

  let fromIndex: number | null = 0;
  let toIndex: number | null = null;

  if (
    (timestampMin.data?.length ?? 0) > 0 &&
    (timestampMax.data?.length ?? 0) > 0
  ) {
    // TODO: calculate how many entries to expect, then do fromIndex = fromIndex - (expectedItems - itemsM1+1)
    const fromData: number = (timestampMin.data[0] as number) * 1000;
    const toData: number =
      (timestampMax.data[timestampMax.data.length - 1] as number) * 1000;

    let second: number | undefined;
    let secondLast: number | undefined;
    if (timestampMin.data.length >= 2) {
      second = (timestampMin.data[1] as number) * 1000;
    }
    if (timestampMax.data.length >= 2) {
      secondLast = (timestampMax.data[0] as number) * 1000;
    }

    const posOffset = calculateGapOffset(
      fromData,
      second,
      secondLast,
      toData,
      itemsM1 + 1,
    );

    const posFactor = itemsM1 / (toData - fromData);

    if (from <= fromData) {
      fromIndex = null;
    } else if (from > toData) {
      return getEmptyValues(paramId, timeseries);
    } else {
      fromIndex = Math.floor((from - fromData) * posFactor - posOffset);
      if (fromIndex <= 0) {
        fromIndex = null;
      }
    }

    if (to < fromData) {
      return getEmptyValues(paramId, timeseries);
    }
    if (to >= toData) {
      toIndex = null;
    } else {
      toIndex = Math.ceil((to - fromData) * posFactor);
    }
  }

  const slic = slice(fromIndex, toIndex);

  return getValues(siteNo, stationNo, paramId, timeseries, [slic]);
};

export const getLastTimeseriesData = async (
  siteNo: string,
  stationNo: string,
  paramId: string,
  timeseries: string,
): Promise<TimeseriesData | undefined> => {
  const tsData: TimeseriesData | undefined = await getEmptyValues(
    paramId,
    timeseries,
  );

  if (!tsData) {
    return tsData;
  }

  const lastValues = await getLastValues();
  if (!lastValues) {
    return tsData;
  }

  const lastValue =
    lastValues[`${siteNo}/${stationNo}/${paramId}/${timeseries}`];

  if (!lastValue) {
    return tsData;
  }

  tsData.data = [
    {
      timestamp: lastValue.time * 1000,
      value: lastValue.data,
    },
  ];

  return tsData;
};

export const getTimeseriesData = async (
  siteNo: string,
  stationNo: string,
  paramId: string,
  timeseries: string,
): Promise<TimeseriesData | undefined> =>
  getValues(siteNo, stationNo, paramId, timeseries, [null]);
