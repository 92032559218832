import { css } from 'lit';

// language=CSS
export default css`
  ki-icon-btn {
    margin: 5px;
    box-shadow: none;
  }

  ki-icon-btn.selected {
    fill: white;
    background: var(--theme-color-secondary, #0056a0);
  }

  .actions {
    display: flex;
  }

  .full {
    width: 100%;
    height: 100%;
  }
`;
