/* eslint-disable max-classes-per-file */
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import './ki-chart';
import { Mix, PropertyDefaultValue } from '../../common';
import { i18nMixin, getQueryObject } from '../../decorators';
import nls from '../../locales/index';
import LinkedTo from './LinkedTo';
import TsData from './TsData';

@customElement('ki-ts-graph')
export default class KiTsGraph extends Mix(
  LitElement,
  PropertyDefaultValue,
  TsData,
  LinkedTo,
  [i18nMixin, { nls }],
) {
  // language=CSS
  static styles = css`
    :host {
      display: block;
    }

    ki-chart {
      display: block;
      height: 100%;
    }
  `;

  static get properties() {
    return {
      afterComputedOptions: Function,
    };
  }

  render() {
    // language=html
    return html`<ki-chart
      id="chartNode"
      .options="${this.computedOptions}"
    ></ki-chart>`;
  }

  get chartNode() {
    return getQueryObject(this, '#chartNode');
  }

  get chart() {
    return this.chartNode?.chart;
  }

  get computedOptions() {
    return this._addTsDataToOptions(this.options).then(async options => {
      options = await this._addLinkedTo(options);
      return this.afterComputedOptions?.(options) || options;
    });
  }
}
