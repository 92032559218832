import { css, html, LitElement } from 'lit';

import { customElement, property } from 'lit/decorators.js';
import debounce from 'lodash-es/debounce';
import Mix from '../../common/Mix';
import '@ui5/webcomponents/dist/Input.js';
import '../ki-icon/ki-icon';
import nls from '../../locales/index';
import { i18nMixin } from '../../decorators/i18n';

// @ts-expect-error
@customElement('ki-searchbox')
export default class KiSearchbox extends Mix(LitElement, [i18nMixin, { nls }]) {
  static styles = [
    css`
      :host {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
      }

      .hide {
        display: none;
      }
    `,
  ];

  @property({ type: String })
  value = '';

  @property({ type: String })
  placeholder = '';

  @property({ type: Array })
  fieldList = [];

  constructor() {
    super();
    this.emitSearch = debounce(() => {
      this.dispatchEvent(
        new CustomEvent('search', { detail: { value: this.value } }),
      );
    }, 200);
  }

  render() {
    // language=html
    return html`
      <ui5-icon></ui5-icon>
      <ui5-input
        style="width: 100%"
        show-clear-icon
        id="input"
        .value="${this.value}"
        @input="${this._handleChanged}"
        placeholder="${this.placeholder || this.i18n.t('search')}"
      ></ui5-input>

      <slot name="actions"> </slot>
    `;
  }

  _handleChanged() {
    const input = this.renderRoot.querySelector('#input');
    this.value = input.value;
    this.dispatchEvent(
      new CustomEvent('change', { detail: { value: this.value } }),
    );
    this.emitSearch(); // debounced
  }

  resetValue() {
    this.value = '';
    this.renderRoot.querySelector('#input').value = '';
    this._handleChanged();
  }
}
