/* eslint-disable max-classes-per-file */
import { css, html, LitElement } from 'lit';
/* eslint-disable import/extensions */
import { until } from 'lit/directives/until.js';
import { customElement, property } from 'lit/decorators.js';
import { unsafeSVG } from 'lit/directives/unsafe-svg.js';
import icons from '../../icons';
/* eslint-enable import/extensions */
import { getQueryObject } from '../../decorators/query';

const getIconPath = iconName => {
  const libName = iconName.split(' ')[0];

  return `./icons/${libName}/${iconName.substring(libName.length * 2 + 2)}.svg`;
};

@customElement('ki-icon')
export default class KiIcon extends LitElement {
  static styles = [
    css`
      :host {
        display: inline-block;
        width: 1em;
      }
      svg {
        vertical-align: middle;
        max-width: 1em;
        max-height: 1em;
      }
    `,
  ];

  static iconCache: Array<string> = [];

  @property({ type: String })
  icon: string = '';

  @property({ type: String })
  color: string = 'black';

  async fetchIcon() {
    if (this.icon) {
      if (this.icon.startsWith('<svg')) {
        return unsafeSVG(this.icon);
      }
      if (icons[this.icon]) {
        return unsafeSVG(icons[this.icon]);
      }
      if (KiIcon.iconCache[this.icon]) {
        return unsafeSVG(await KiIcon.iconCache[this.icon]);
      }
      KiIcon.iconCache[this.icon] = new Promise<string>(resolve => {
        const iconPath = this.icon.endsWith('.svg')
          ? this.icon
          : getIconPath(this.icon);
        fetch(iconPath).then(p => {
          p.text().then(ic => resolve(ic));
        });
      });

      return unsafeSVG(await KiIcon.iconCache[this.icon]);
    }
    return '';
  }

  get __svgElement() {
    return getQueryObject(this, 'svg');
  }

  render() {
    return html`${until(this.fetchIcon(), html`<span>...</span>`)}`;
  }
}
