import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../ki-icon/ki-icon';
import { SwitcherOption } from './SwitcherOption';

/** Legacy Switcher - better use KiSwitcher */
@customElement('ki-icon-switcher')
export default class KiIconSwitcher extends LitElement {
  // language=CSS
  static styles = css`
    :host {
      display: inline-block;
      box-shadow:
        0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2);
      color: gray;
      background: white;
      border-radius: 36px;
    }
    .tab {
      margin: 6px;
      width: 28px;
      height: 28px;
      text-align: center;
      line-height: 28px;
      border-radius: 28px;
      cursor: pointer;
      overflow: hidden;
      transition:
        background-color 0.4s ease-in-out,
        color 0.2s ease-in-out;
    }
    .tab:hover ki-icon::part(icon) {
      fill: #4a4a49;
    }

    .tab.selected ki-icon {
      fill: white;
    }

    .tab.selected {
      color: white;
      background: var(--theme-color-primary, #0056a0);
    }

    ki-icon {
      fill: gray;
    }
  `;

  @property({ type: Array })
  options: SwitcherOption[] = []; // icon, value, label ?

  @property({ type: String })
  value = '';

  render() {
    this.value = this.value || this.options[0].value;
    // language=html
    return html`
      ${this.options.map(
        (option, index) => html`
          <div
            id="tab${index}"
            title="${option.label}"
            class="tab ${option.value === this.value ? 'selected' : ''}"
            @click="${() => {
              this.switchTo(option.value);
            }}"
          >
            <ki-icon .icon=${option.icon}></ki-icon>
          </div>
        `,
      )}
    `;
  }

  switchTo(value) {
    if (this.value !== value) {
      this.value = value;
      this.dispatchEvent(new CustomEvent('changed', { detail: { value } }));
    }
  }
}
