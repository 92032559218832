/* eslint-disable no-nested-ternary */
/* eslint-disable max-classes-per-file */
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { uniqBy, orderBy, get, last } from 'lodash-es';
import { Mix } from '../../common';
import {
  i18nMixin,
  SM,
  ViewPort,
  responsiveMixin,
  getQueryObject,
} from '../../decorators';

import '@ui5/webcomponents/dist/ComboBox';
import '@ui5/webcomponents/dist/DateRangePicker';
import nls from '../../locales/index';
import { Filter } from './Filter';
import './ki-multi-select';
import { getDisplayValue } from './FilterHelper';

@customElement('ki-wcp-wwp-filter')
export default class KiWcpWwpFilter extends Mix(LitElement, responsiveMixin, [
  i18nMixin,
  { nls },
]) {
  // language=CSS
  static styles = css`
    :host {
      display: flex;
    }

    ki-multi-select,
    ui5-multi-combobox,
    ui5-combobox {
      --_ui5_input_icon_padding: 11px;
      --_ui5_input_height: 40px;
      height: var(--_ui5_input_height);
      margin-right: 5px;
      margin-top: 0px;
      margin-bottom: 0px;
      flex: 1;
      border: 0;
      border-bottom: 1px solid var(--sapField_BorderColor);
      max-width: 250px;
    }

    ki-icon-btn.active,
    ki-icon-btn.selected {
      background-color: var(--theme-color-primary, #1d1d1b);
      color: white;
    }

    .hide {
      display: none;
    }

    .list-item {
      padding: 10px 20px;
      color: rgb(74, 74, 73);
      font-size: 1.2em;
    }

    .clear-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .clear-icon {
      margin-right: 2px;
      cursor: pointer;
      fill: #7e7e7e;
      width: 12px;
    }

    ui5-combobox:not([value]) .clear-icon,
    ui5-combobox[value=''] .clear-icon,
    ki-multi-select:not([value]) .clear-icon,
    ki-multi-select[value=''] .clear-icon,
    ui5-multi-combobox:not([value]) .clear-icon,
    ui5-multi-combobox[value=''] .clear-icon {
      display: none;
    }

    :host(.sm-screen) ki-icon-btn {
      margin-right: 10px;
    }

    :host(.sm-screen) ui5-combobox,
    :host(.md-screen) ui5-combobox,
    :host(.sm-screen) ki-multi-select,
    :host(.md-screen) ki-multi-select,
    :host(.sm-screen) ui5-multi-combobox,
    :host(.md-screen) ui5-multi-combobox {
      margin-bottom: 5px;
    }
  `;

  @property({ type: Array })
  filters: any[] = [];

  @property({ type: Array })
  favorites: any[] = [];

  @property({ type: String })
  activeFilter: string = '{}';

  @property({ type: Array })
  stations: any[] = [];

  @property({ type: Array })
  filteredRawData: any[] = [];

  @property({ type: Array })
  allStations: any[] = [];

  /** Show only favorite nodes */
  @property({ type: Boolean })
  favsActive: boolean = false;

  @property({ type: Boolean })
  cascade: boolean = false;

  @property({ type: Boolean })
  hideFavorite: boolean = false;

  get favOn() {
    let activeFilter = {};
    try {
      activeFilter = JSON.parse(this.activeFilter);
    } catch (e) {
      console.warn(`broken filter ${this.activeFilter}`);
    }
    return activeFilter.fav || false;
  }

  render() {
    // language=html
    return html` ${repeat(this.filters, item => this._getFilter(item))}
    ${!this.hideFavorite && this.favorites.length
      ? this._renderFavoriteBtn()
      : ''}`;
  }

  _renderFavoriteBtn() {
    return html` <ki-icon-btn
      toggle
      class="${this.favOn ? 'selected' : ''}"
      @click="${event => {
        this.favsActive = !this.favsActive;
        this._handleChanged(event);
      }}"
      icon="ki ki-star"
    ></ki-icon-btn>`;
  }

  _renderBtn(filt, activeFilter) {
    return html`
      <ki-icon-btn
        class="${activeFilter[filt.field] ? 'active' : ''}"
        icon="${filt.icon}"
        @click="${() => {
          if (activeFilter[filt.field]) {
            this.dispatchEvent(
              new CustomEvent('change', {
                detail: { value: {} },
              }),
            );
          } else {
            this.renderRoot
              .querySelector(`#${filt.field.replaceAll('.', '\\.')}`)
              .show();
          }
        }}"
      >
      </ki-icon-btn>
    `;
  }

  // eslint-disable-next-line class-methods-use-this
  _getFilter(filt: Filter) {
    const activeFilter = JSON.parse(this.activeFilter);
    if (ViewPort.size === SM && filt.icon) {
      return html`
        ${this._renderBtn(filt, activeFilter)}
        <ki-modal id="${filt.field}" closeable .label="${filt.label}">
          <div class="list">
            ${this.filterOptions(filt).map(
              option =>
                html` <div
                  class="list-item"
                  @click="${() => {
                    const filterValue = {};
                    filterValue[filt.field] = option.value;
                    this.renderRoot
                      .querySelector(`#${filt.field.replaceAll('.', '\\.')}`)
                      .close();
                    this.dispatchEvent(
                      new CustomEvent('change', {
                        detail: { value: filterValue },
                      }),
                    );
                  }}"
                >
                  ${option.displayValue}
                </div>`,
            )}
          </div>
        </ki-modal>
      `;
    }

    const isSelected = item =>
      activeFilter[filt.field] && activeFilter[filt.field].includes(item)
        ? 'selected'
        : '';

    switch (filt.type) {
      case 'multiselect':
        return html` <ki-multi-select
          id="${filt.field}"
          placeholder="${filt.label}"
          .changeOnBlur="${this.cascade}"
          @val-change="${this._handleChangedMulti}"
          class="field-item"
          filter="StartsWith"
        >
          ${this.filterOptions(filt).map(item =>
            isSelected(item.value)
              ? html` <ui5-mcb-item
                  selected
                  .value="${item.value}"
                  text="${this.i18n.t(item.displayValue)}"
                  additional-text="${item.additionalText}"
                ></ui5-mcb-item>`
              : html` <ui5-mcb-item
                  text="${this.i18n.t(item.displayValue)}"
                  .value="${item.value}"
                ></ui5-mcb-item>`,
          )}
        </ki-multi-select>`;
      case 'date-range':
        return html` <ui5-daterange-picker
          id="${filt.field}"
          placeholder="${filt.label}"
          value="${activeFilter[filt.field]}"
          delimiter="~"
          format-pattern="${filt.formatPattern || 'dd/MM/YYYY'}"
          @change="${this._handleChanged}"
          class="field-item"
        ></ui5-daterange-picker>`;
      default:
        return html` <ui5-combobox
          clear-button-visible
          id="${filt.field}"
          placeholder="${filt.label}"
          value="${activeFilter[filt.field] || ''}"
          @change="${this._handleChanged}"
          class="field-item"
        >
          ${this.filterOptions(filt).map(
            item =>
              html` <ui5-cb-item
                .value="${item.value}"
                text="${this.i18n.t(item.displayValue)}"
                additional-text="${item.additionalText}"
              ></ui5-cb-item>`,
          )}
          <div class="clear-container" slot="icon">
            <ki-icon
              class="clear-icon"
              icon="ki ki-times"
              @click="${() => {
                const combobox = getQueryObject(
                  this,
                  `#${filt.field.replaceAll('.', '\\.')}`,
                );
                if (filt.linkedTo && typeof filt.linkedTo === 'string') {
                  console.warn(
                    'Filters should be provided as Array of strings',
                    filt,
                  );
                  filt.linkedTo = [filt.linkedTo]; // Backward compatibility (used to be string)
                }
                if (filt.linkedTo) {
                  filt.linkedTo.forEach(item => {
                    const node = this.renderRoot.querySelector(`#${item}`);
                    if (node.value) {
                      node.value = '';
                    }
                  });
                }

                combobox.value = '';
                this._handleChanged({ target: combobox });
              }}"
            ></ki-icon>
          </div>
        </ui5-combobox>`;
    }
  }

  filterOptions({
    field,
    type,
    sort,
    displayTemplate,
    additionalText,
    sortBy,
    sortDir,
    splitBy,
  }) {
    let _stations = uniqBy(
      !this.cascade && type === 'multiselect'
        ? this.allStations
        : this.filteredRawData,
      field,
    );

    if (sortBy) {
      _stations = orderBy(_stations, sortBy, sortDir);
    }

    let data: Array<{
      value: string;
      additionalText: string;
      displayValue: string;
    }> = [];

    _stations.forEach(item => {
      let value = get(item, field);

      if (!Array.isArray(value)) {
        value = [value];
      }
      value.forEach(val => {
        data.push({
          value: val,
          additionalText: item?.[additionalText] ?? '',
          displayValue: displayTemplate
            ? getDisplayValue(item, displayTemplate)
            : splitBy
              ? last(val.split(splitBy))
              : val,
        });
      });
    });

    data =
      data
        .filter(
          (t, index) =>
            data.findIndex(item => item.value === t.value) === index,
        )
        .filter(t => !!t.value) || [];

    if (sort === 'numeric' && !sortBy) {
      data.sort((a, b) => {
        const numberVala = parseFloat(a.value);
        const numberValb = parseFloat(b.value);
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(numberVala) && !isNaN(numberValb)) {
          if (numberVala > numberValb) return 1;
          if (numberVala < numberValb) return -1;
          return 0;
        }

        if (a.value > b.value) return 1;
        if (a.value < b.value) return -1;
        return 0;
      });
    } else if (!sortBy) {
      data.sort(
        (a, b) => a.value.localeCompare && a.value.localeCompare(b.value),
      );
    }
    return data;
  }

  _resetValue() {
    this.activeFilter = `{"fav":${this.favsActive}}`;
    this._updateActiveFilter({});
  }

  _handleChangedMulti({ target: node }) {
    this._updateActiveFilter({
      [node.id]:
        node.selectedValues.length === 0
          ? undefined
          : node.selectedValues.map(item => item.value),
    });
  }

  _handleChanged({ target: node }) {
    this._updateActiveFilter({ [node.id]: node.value });
  }

  _updateActiveFilter(value) {
    let activeFilter = JSON.parse(this.activeFilter);
    // Recreate/Merge filter
    activeFilter = value
      ? {
          ...activeFilter,
          ...value,
          fav: this.favsActive,
        }
      : { fav: this.favsActive };

    // Remove unused properties from filter
    Object.keys(value).forEach(k => {
      const val = value[k];
      if (val === '') {
        delete activeFilter[k];
      }
    });

    // console.debug('activeFilter', activeFilter);
    // Trigger filter change event
    this.dispatchEvent(
      new CustomEvent('change', {
        detail: {
          value: activeFilter,
        },
      }),
    );
  }
}
