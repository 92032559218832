export default function f(claz) {
  return class extends claz {
    constructor(args) {
      super(args);
      this._visible = true;
      this.setProperties({
        name: args.name,
        group: args.group,
        displayInLayerControl: args.displayInLayerControl,
        tabOrder: args.tabOrder,
      });

      this.__element = args.__element || this.element;
    }

    setVisible(flag) {
      this._visible = flag;
      this.__element.classList.toggle('hide', !flag);
    }

    getVisible() {
      return this._visible;
    }
  };
}
