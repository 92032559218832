import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import FontIcon from './FontIcon';

function descar(arys, fn) {
  let result = [null];

  arys.forEach(ary => {
    const newResult = [];
    [...ary, null].forEach(item => {
      result.forEach(reItem => {
        if (reItem && item) {
          newResult.push(fn(reItem, item));
        } else {
          newResult.push(reItem || item);
        }
      });
    });
    result = result.concat(newResult);
  });

  return result.filter(r => !!r);
}

export default class LegendFactory {
  constructor(classification = {}) {
    // TODO use image instead fontIcon, to reuse it and good for performance?

    this.label = classification.label;

    this.description = classification.description;
    this.hideEmptyClasses = classification.hideEmptyClasses;
    this.notInteractive = classification.notInteractive;
    this.tagProperty = classification.tagProperty || '__tag';

    this.selectedMarkers = {}; // TODO move style settings to station,
    this.pngs = {};
    this.labels = {};
    this.tagCount = {};
    this.defaultMarker = this._createIconStyle(classification.default?.marker);
    this.defaultMarkerStyle = classification.default?.marker;

    this.markers = { _default: this.defaultMarker };
    this.polygons = {
      _default: this._createPolygonStyle(classification.default?.marker),
    };
    this.markers_ = { _default: classification.default?.marker };

    this.pngs._default = this.defaultMarker.icon
      ? this.defaultMarker.icon.image_.getImagePNGLegend()
      : this.defaultMarker.image_.getImagePNGLegend();
    this.tagGroups = classification.tags || [];
    if (!Array.isArray(this.tagGroups[0])) {
      this.tagGroups = [this.tagGroups];
    }
    this.tagGroups.forEach((tg, j) => {
      tg.forEach((tag, i) => {
        tag.zIndex = tag.zIndex || i + j;
      });
    });

    const allTags = descar(this.tagGroups, (tag1, tag2) => ({
      name: `${tag1.name}|${tag2.name}`,
      hideInLegend: tag1.hideInLegend || tag2.hideInLegend,
      deactivate: tag1.deactivate || tag2.deactivate,
      label: `${tag1.label},${tag2.label}`,
      zIndex: tag1.zIndex + tag2.zIndex,
      marker: {
        ...tag1.marker,
        ...tag2.marker,
      },
    }));

    allTags.forEach(item => {
      const marker = this._createIconStyle(item.marker, item.zIndex);
      this.markers[item.name] = marker;
      this.polygons[item.name] = this._createPolygonStyle(
        item.marker,
        item.zIndex,
      );
      this.markers_[item.name] = item.marker;
      this.selectedMarkers[item.name] = this._selectedStyle(item.marker); // TODO svg ?
      this.pngs[item.name] =
        item.marker.icon?.svg || marker.image_.getImagePNGLegend();
      this.labels[item.name] = item.label;
      return {
        ...item,
        marker,
      };
    });

    this.legendOptions = {
      description: this.description,
      label: this.label,
      notInteractive: this.notInteractive,
      hideEmptyClasses: this.hideEmptyClasses,
      items: this.tagGroups.map(tgs =>
        tgs.map(item => ({
          icon: this.pngs[item.name],
          label: item.label,
          name: item.name,
          hidden: item.hideInLegend,
          deactivate: item.deactivate,
        })),
      ),
    };
  }

  getMarker(item) {
    return this.markers[item[this.tagProperty]] || this.defaultMarker;
  }

  // eslint-disable-next-line class-methods-use-this
  _createPolygonStyle(marker = {}, zIndex = 0) {
    return new Style({
      fill: new Fill({
        color: marker.backgroundColor,
      }),
      stroke: new Stroke(marker.border),
      zIndex,
    });
  }

  // can be override
  // eslint-disable-next-line class-methods-use-this
  _createIconStyle(marker = {}, zIndex = 0) {
    if (marker.icon?.svg) {
      return new Style({
        image: new Icon({
          src: marker.icon.svg,
        }),
      });
    }
    return new Style({
      image: new FontIcon({
        border: { color: 'white', width: 1 },
        ...this.defaultMarkerStyle,
        ...marker,
      }), // all style will mix on default
      zIndex,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  _selectedStyle(marker) {
    marker = {
      ...this.defaultMarkerStyle,
      ...marker,
    };
    return [
      new Style({
        image: new FontIcon({
          border: { color: '#3399FF', width: 1 },
          ...marker,
          icon: 'ki ki-arrow-alt-to-bottom',
          backgroundColor: '#3399FF',
        }),
      }),
    ];
  }

  getLegendOptions() {
    return this.legendOptions;
  }
}
