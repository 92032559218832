/* eslint-disable max-classes-per-file */
// @ts-nocheck
// view stack
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './ki-breadcrumbs';
import {
  getCurrentLocation,
  getAllSearchParamsObject,
} from './routing/ki-router';
import { getConfig } from './services/ki-app-config-service';
import { getCurrentApi } from '../../api/API';
import { AppConfigDefinition } from '../../defs/AppConfigDefinition';
import { calculateBreadCrumbs, getStationByLocation } from './BreadcrumbHelper';
import { BreadcrumbItem } from '../../defs/routing/BreadcrumbItem';

@customElement('ki-app-breadcrumbs')
export default class KiAppBreadcrumbs extends LitElement {
  // language=CSS
  static styles = css`
    :host {
      display: block;
    }
  `;

  @property({ type: Number })
  maxitems = Infinity;

  @property({ type: Array })
  breadCrumbs: BreadcrumbItem[] = [];

  api = getCurrentApi();

  config: AppConfigDefinition = getConfig();

  /** Use short breadcrumbs if width is below 800px; SM resolution is used */
  // eslint-disable-next-line class-methods-use-this
  get useShortCrumb(): Boolean {
    return window.innerWidth <= 800;
  }

  connectedCallback() {
    if (super.connectedCallback) super.connectedCallback();
    window.addEventListener(
      'vaadin-router-location-changed',
      this.handleVaadinRouterEvent,
    );

    /** Debounce resizing, as it takes some time */
    let resizeTimer;
    window.addEventListener(
      'resize',
      () => {
        clearTimeout(resizeTimer);
        // Get the final width after resizing has stopped
        this._setBreadCrumbs(getCurrentLocation(), this.useShortCrumb);
      },
      100,
    );

    this.searchParams = getAllSearchParamsObject();
  }

  disconnectedCallback() {
    if (super.disconnectedCallback) super.disconnectedCallback();
    window.removeEventListener(
      'vaadin-router-location-changed',
      this.handleVaadinRouterEvent,
    );
  }

  handleVaadinRouterEvent = event => {
    this._setBreadCrumbs(event.detail.location, this.useShortCrumb);
  };

  /**
   *
   * @param location - location width added station meta information
   * @param shortCrumb Use shortform for small screens (set by shortBreadcrumb in app.json)
   */
  _setBreadCrumbs(location, shortCrumb: Boolean) {
    getStationByLocation(location, this.api)
      .then(result => {
        this.breadCrumbs = calculateBreadCrumbs(result, shortCrumb).slice(
          0,
          this.maxitems,
        );
      })
      .catch(error => {
        console.error(error);
      });
  }

  render() {
    // language=html
    return this.searchParams?.headless
      ? html``
      : html`<ki-breadcrumbs .items="${this.breadCrumbs}"></ki-breadcrumbs>`;
  }

  firstUpdated() {
    this._setBreadCrumbs(getCurrentLocation(), this.useShortCrumb);
  }
}
