/* eslint-disable wc/no-self-class */
import { css, html } from 'lit';
import { until } from 'lit/directives/until.js';
import { customElement, property } from 'lit/decorators.js';
import KiIcon from './ki-icon';

@customElement('ki-icon-btn')
export default class KiIconBtn extends KiIcon {
  // language=CSS
  static styles = [
    ...KiIcon.styles,
    css`
      :host {
        display: block;
        color: gray;
        fill: gray;
        background: white;
        box-shadow:
          0 2px 2px 0 rgba(0, 0, 0, 0.14),
          0 1px 5px 0 rgba(0, 0, 0, 0.12),
          0 3px 1px -2px rgba(0, 0, 0, 0.2);
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 40px;
        cursor: pointer;
        position: relative;
      }
      :host(:hover) ki-icon::part(icon) {
        fill: #4a4a49;
      }

      :host([active]) {
        color: white;
        fill: white;
        background: var(--theme-color-secondary, #0056a0);
      }

      :host(.ripple) {
        background-position: center;
        transition: background 0.8s;
      }
      :host(.ripple:hover) {
        background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%)
          center/15000%;
      }
      :host(.ripple:active) {
        background-color: var(--theme-color-secondary, #0056a0);
        background-size: 100%;
        transition: background 0s;
      }

      :host([disabled]) {
        pointer-events: none;
        opacity: 0.5;
      }

      svg {
        max-width: 5em;
      }
    `,
  ];

  @property({ type: Boolean, reflect: true })
  active = false;

  @property({ type: String })
  tooltip: string = '';

  @property({ type: Boolean })
  disabled: boolean = false;

  selected: boolean = false;

  @property({ type: Boolean })
  toggle: boolean = false;

  @property({ type: Boolean })
  loading: boolean = false;

  clickHandler;

  _renderLoader() {
    return html`${this.loading
      ? html`
          <style>
            .spiner-wrapper {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 99;
              height: 100%;
              border-radius: 50%;
              background: rgba(255, 255, 255, 0.9);
            }
            @keyframes ldio {
              0% {
                transform: rotate(0deg);
              }
              50% {
                transform: rotate(180deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
            .ldio div {
              position: absolute;
              animation: ldio 1.01s linear infinite;
              width: 78px;
              height: 78px;
              top: 11px;
              left: 11px;
              border-radius: 50%;
              box-shadow: 0 3.8000000000000003px 0 0 #1477eb;
              transform-origin: 39px 40.9px;
            }
            .loadingio-spinner-eclipse {
              width: 34px;
              height: 34px;
              display: inline-block;
              overflow: hidden;
              background: none;
            }
            .ldio {
              width: 100%;
              height: 100%;
              position: relative;
              transform: translateZ(0) scale(0.34);
              backface-visibility: hidden;
              transform-origin: 0 0;
            }
            .ldio div {
              box-sizing: content-box;
            }
          </style>
          <div class="spiner-wrapper">
            <div class="loadingio-spinner-eclipse">
              <div class="ldio">
                <div></div>
              </div>
            </div>
          </div>
        `
      : ``}`;
  }

  render() {
    return html` ${this._renderLoader()}
    ${until(this.fetchIcon(), html`<span>...</span>`)}`;
  }

  connectedCallback() {
    // @ts-expect-error
    if (super.connectedCallback) super.connectedCallback();
    this.clickHandler = () => {
      if (this.toggle) this.active = !this.active;
    };
    this.addEventListener('click', this.clickHandler);
    if (this.selected) this.classList.add('selected');
  }

  disconnectedCallback() {
    // @ts-expect-error
    if (super.disconnectedCallback) super.disconnectedCallback();
    this.removeEventListener('click', this.clickHandler);
  }
}
