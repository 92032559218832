/* eslint-disable wc/no-self-class */
import { html, LitElement } from 'lit';

import { customElement } from 'lit/decorators.js';
import { Mix } from '../../common';
import '@ui5/webcomponents/dist/Button';

// import './components'; // TODO: WHY?

import { responsiveMixin, SM, ViewPort, i18nMixin } from '../../decorators';

import '@ui5/webcomponents/dist/Tree';
import '@ui5/webcomponents/dist/TreeItem';

import nls from '../../locales/index';
import style from './ki-layers-control-element.css?inline';

// TODO rework to read from map.
function toggleValue(
  array,
  value,
  flag,
  conflictList: Array<string> | undefined = undefined,
) {
  const i = array.indexOf(value);
  if (conflictList) {
    conflictList.forEach(
      v => array.indexOf(v) >= 0 && array.splice(array.indexOf(v), 1),
    );
  }
  if (flag && !(array.indexOf(value) >= 0)) {
    array.push(value);
  } else if (!flag && i >= 0) {
    array.splice(array.indexOf(value), 1);
  }
}

// @ts-expect-error
@customElement('ki-layers-control-element')
export default class KiLayersControlElement extends Mix(
  LitElement,
  [i18nMixin, { nls }],
  responsiveMixin,
) {
  // language=CSS
  static styles = style;

  constructor() {
    super();
    this.treeList = false;
    this.values = [];
    this.layers = [];
    this.__maxHeight = null;
    this.toggleAll = false;

    // fix for bug fire map dragging
    const stopEvent = e => {
      e.stopPropagation();
    };
    this.onpointerdown = stopEvent;
    this.onpointerup = stopEvent;
  }

  static get properties() {
    return {
      layers: {
        type: Array,
      },
      values: {
        type: Object,
      },
      toggleAll: {
        type: Boolean,
      },
      treeList: {
        type: Boolean,
      },
    };
  }

  _renderCheckBoxGroup(group: {
    label: string;
    type: string;
    options: Array<{
      value: string;
      label: string;
      legendColor: string;
    }>;
  }) {
    // @ts-expect-error
    const groupId = group.label.replaceAll(' ', '_');

    const groupChecked = (): boolean =>
      group.options.every(option => this.values.indexOf(option.value) >= 0);

    const groupIntermediate = (): boolean =>
      group.options.some(option => this.values.indexOf(option.value) >= 0);

    const toggleGroup = (selected: boolean): void => {
      group.options.forEach(option => {
        toggleValue(this.values, option.value, selected);
      });
      this._emitChange();
    };

    return html`
      <div class="field-group">
        <div class="layer-item">
          <ki-checkbox
            style="display:${this.toggleAll ? 'inital' : 'none'}"
            id="${groupId}"
            @change="${e => toggleGroup(e.target.checked)}"
            ?checked=${groupChecked()}
            ?indeterminate=${groupIntermediate()}
          ></ki-checkbox>
          <div
            class="label ${this.toggleAll ? 'title' : 'simpletitel'}"
            @click="${() => {
              const checkbox = this.renderRoot.querySelector(`#${groupId}`);
              checkbox.checked = !checkbox.checked;
              toggleGroup(checkbox.checked);
            }}"
          >
            ${this.i18n.t(group.label)}
          </div>
        </div>
        ${group.options.map(
          option => html`
            <div class="layer-item">
              <ki-checkbox
                id="${option.value}"
                @change="${e => {
                  toggleValue(this.values, option.value, e.target.checked);
                  this._emitChange();
                }}"
                ?checked="${this.values.indexOf(option.value) >= 0}"
              ></ki-checkbox>
              <div
                class="label"
                @click="${() => {
                  const checkbox = this.renderRoot.querySelector(
                    `#${option.value}`,
                  );
                  checkbox.checked = !checkbox.checked;
                  toggleValue(this.values, option.value, checkbox.checked);
                  this._emitChange();
                }}"
              >
                ${option.label}
                ${option.legendColor
                  ? html`<span
                      class="colorlegend"
                      style="background-color:${option.legendColor};"
                    ></span>`
                  : ''}
              </div>
            </div>
          `,
        )}
      </div>
    `;
  }

  _renderRadioGroup(group) {
    return html`
      <div class="field-group">
        <div class="layer-item">
          <div class="label ${this.toggleAll ? 'basetitle' : 'simpletitel'}">
            ${this.i18n.t(group.label)}
          </div>
        </div>
        ${group.options.map(
          option => html`
            <div class="layer-item radio">
              <input
                type="radio"
                class="radiobutton value-radio-button"
                @change="${e => {
                  toggleValue(
                    this.values,
                    option.value,
                    e.target.checked,
                    group.options.map(op => op.value),
                  );

                  this.renderRoot
                    .querySelectorAll('.radiobutton')
                    .forEach(node => {
                      // eslint-disable-next-line no-param-reassign
                      node.checked = node === e.target;
                    });
                  this._emitChange();
                }}"
                ?checked="${this.values.indexOf(option.value) >= 0}"
              />
              <div class="label">${option.label}</div>
            </div>
          `,
        )}
      </div>
    `;
  }

  _emitChange() {
    this.requestUpdate();
    this.dispatchEvent(
      new CustomEvent('changed', {
        detail: {
          values: this.values,
        },
      }),
    );
  }

  _renderLayerList() {
    return html`
      <div class="layer-list">
        ${this.layers.map((group, index) => {
          if (group.type === 'checkbox') {
            return [
              this._renderCheckBoxGroup(group),
              index === this.layers.length - 1 ? '' : html` <hr /> `,
            ];
          }
          if (group.type === 'radio') {
            return [
              this._renderRadioGroup(group),
              index === this.layers.length - 1 ? '' : html` <hr /> `,
            ];
          }
          return '';
        })}
      </div>
    `;
  }

  _renderLayerTree() {
    return html`
      <div class="layer-list">
        ${this.layers.map(
          group =>
            html`<ui5-tree
              @selection-change="${() => {
                this._updateValues();
                this._emitChange();
              }}"
              mode="${group.type === 'checkbox'
                ? 'MultiSelect'
                : 'SingleSelectBegin'}"
              ><ui5-tree-item
                text="${this.i18n.t(group.label)}"
                class="toplevel"
                ?selected="${this._isGroupSelected(group)}"
                ?indeterminate="${this._isGroupIndeterminate(group)}"
                @click="${e => this._toggleGroupSelection(e, group)}"
              >
                ${group.options.map(
                  opt =>
                    html`<ui5-tree-item
                      text="${opt.label}"
                      ?selected="${this.values.includes(opt.label)}"
                      @click="${() => this._toggleOptionSelection(opt)}"
                    ></ui5-tree-item>`,
                )}
              </ui5-tree-item></ui5-tree
            >`,
        )}
      </div>
    `;
  }

  _toggleGroupSelection(event, group) {
    const topLevelCheckbox = event.target;

    group.options.forEach(opt => {
      const childCheckbox = topLevelCheckbox.querySelector(
        `[text="${opt.label}"]`,
      );
      if (childCheckbox && group.type === 'checkbox') {
        childCheckbox.selected = topLevelCheckbox.selected;
      }
    });

    this._updateValues();
    this._emitChange();
  }

  _isGroupSelected(group) {
    return group.options.some(opt => this.values.includes(opt.label));
  }

  _isGroupIndeterminate(group) {
    const selectedOptions = group.options.filter(opt =>
      this.values.includes(opt.label),
    );
    return (
      selectedOptions.length > 0 &&
      selectedOptions.length < group.options.length
    );
  }

  _toggleOptionSelection(option) {
    const selected = this.values.includes(option.label);
    toggleValue(this.values, option.label, !selected);
    this._updateValues();
    this._emitChange();
    console.debug('Option selected: ', option);
  }

  _updateValues() {
    this.values = [
      ...this.renderRoot.querySelectorAll('ui5-tree-item[selected]'),
    ].map(item => item.text);
  }

  _hideToplevelSelect() {
    const trees = this.renderRoot.querySelectorAll('ui5-tree');
    if (trees) {
      [...trees].forEach(tree => {
        if (tree.shadowRoot?.children[0]?.children[1]?.shadowRoot) {
          const checkboxOrButtons =
            tree.shadowRoot.children[0].children[1].shadowRoot.querySelectorAll(
              'ui5-checkbox,ui5-radio-button',
            );
          if (checkboxOrButtons.length > 0) {
            checkboxOrButtons[0].style.display = 'none';
          }
        }
      });
    }
  }

  firstUpdated() {
    setTimeout(() => {
      this._hideToplevelSelect();
    }, 200);
  }

  updateDefaultSelection() {
    const selection: Array<string> = [];
    this.layers.forEach(layer =>
      layer.options.forEach(opt => {
        if (opt.visible) {
          selection.push(opt.label);
        }
      }),
    );
    this.values = selection;
  }

  updated(_changedProperties: any) {
    this._hideToplevelSelect();
    if (_changedProperties.has('layers')) {
      this.updateDefaultSelection();
    }
  }

  render() {
    let list;
    if (ViewPort.size === SM) {
      list = html`
        <ki-modal
          id="layers-options"
          label="${this.i18n.t('Layer_selection')}"
          @click="${() => {
            this._removeVisible();
          }}"
          closeable
          >${this._renderLayerList()}</ki-modal
        >
      `;
    } else if (this.treeList) {
      list = this._renderLayerTree();
    } else {
      list = this._renderLayerList();
    }
    // language=html
    return html`
      <ki-icon-btn
        title="${this.i18n.t('Layer_selection')}"
        id="layers-control-btn"
        class="toggle-btn"
        icon="ki ki-layers"
        @click="${this._toggleList}"
        alt="Map layers"
      ></ki-icon-btn>
      ${list}
    `;
  }

  _removeVisible() {
    const layerOptions = this.renderRoot.querySelector('#layers-options');
    if (layerOptions && !layerOptions.visible) {
      this.classList.remove('list-visible');
    }
  }

  _toggleList() {
    if (ViewPort.size === SM) {
      this.classList.toggle('list-visible');
      this.renderRoot.querySelector('#layers-options').show();
    } else {
      this.classList.toggle('list-visible');
      this._hideToplevelSelect();
    }
  }
}
