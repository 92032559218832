/* eslint-disable max-classes-per-file */
import { css, html, LitElement } from 'lit';

import { customElement } from 'lit/decorators.js';
import { i18nMixin, addNls } from '../../decorators/i18n';
import nls from '../../locales/index';
import '../ki-icon/ki-icon';
import '../ki-icon/ki-icon-btn';
import { responsiveMixin, SM, ViewPort } from '../../decorators/responsive';
import PropertyDefaultValue from '../../common/PropertyDefaultValue';
import Mix from '../../common/Mix';

import './ki-app-breadcrumbs';

// @ts-expect-error
@customElement('ki-app')
export default class KiApp extends Mix(
  LitElement,
  PropertyDefaultValue,
  [i18nMixin, { nls }],
  responsiveMixin,
) {
  // var headerHeight

  // language=CSS
  static styles = css`
    :host {
      height: 100%;
      width: 100%;
    }

    .ki-app-container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .header {
      display: flex;
      height: 60px;
      background: var(--theme-color-tertiary, 'white');
      overflow: visible;
    }

    ki-icon-btn {
      display: none;
    }

    :host .logo {
      flex: 0;
    }

    .spacer {
      flex: 1;
      height: 100%;
    }

    .nav {
      z-index: 99;
      width: 100%;
      overflow: visible;
    }

    .main {
      flex: 1;
      overflow: auto;
      display: flex;
      flex-direction: column;
    }

    .main-slot {
      flex: 1;
      overflow: auto;
    }

    .drawer-container {
      display: block;
      left: -100%;
      width: 100%;
      height: calc(100% - 80px);
      top: 60px;
      position: absolute;
      z-index: 9999;
      transition: left 0s 0.5s;
    }

    .drawer-container.visible {
      left: 0;
      transition: left 0s 0s;
    }

    .overlay {
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      display: none;
      position: fixed;
      opacity: 0;
      top: 0;
      transition: all 0.5s;
    }

    .drawer {
      display: block;
      max-width: 95%;
      position: absolute;
      background: var(--theme-text-color, white);
      z-index: 1;
      height: 100%;
      left: -100%;
      transition: left 0.5s;
    }

    .drawer-container.visible .drawer {
      left: 0;
    }

    .drawer-container.visible .overlay {
      display: block;
      opacity: 1;
    }

    :host(.sm-screen) [active] {
      display: block;
      background: transparent;
      box-shadow: none;
      fill: var(--theme-color-primary, white);
      margin: 10px;
    }

    :host(.sm-screen) .logo {
      flex: 1;
      text-align: center;
    }

    :host(.sm-screen) .spacer {
      flex: 0 0 50px;
    }

    :host(.sm-screen) .nav {
      display: none;
    }

    :host(.sm-screen) .drawer {
      background: transparent;
    }

    :host(.sm-screen.LANDSCAPE) .drawer-container {
      top: 0px;
      height: 100%;
    }
    :host(.sm-screen.LANDSCAPE) .drawer {
      left: 60px;
    }
  `;

  static get properties() {
    return {
      drawerWidth: {
        type: String,
        default: '95%',
      },
      _sideBarVisible: {
        type: Boolean,
        default: false,
      },
      nls: {
        type: String,
      },
    };
  }

  constructor() {
    // TODO
    super();

    // Close menu (e.g. Pressing "Start" while being in "Start")
    window.addEventListener('selected', () => {
      this._sideBarVisible = false;
    });

    window.addEventListener('hashchange', () => {
      this._sideBarVisible = false;
    });
  }

  _setNls() {
    this.i18n && this.nls && addNls(this.i18n, this.nls);
  }

  _renderDrawer() {
    return html` <div
      class="drawer-container ${this._sideBarVisible ? 'visible' : ''}"
      @click="${this.toggleSideBar}"
    >
      <div class="overlay"></div>
      <div class="drawer" style="max-width: ${this.drawerWidth}">
        <slot name="nav"></slot>
      </div>
    </div>`;
  }

  _renderHeader() {
    return html`
      <div class="header">
        <ki-icon-btn
          icon="ki ki-bars"
          toggle
          @click="${this.toggleSideBar}"
        ></ki-icon-btn>
        <div class="logo">
          <slot name="logo"></slot>
        </div>
        <div class="spacer"></div>
        <div class="nav">
          ${ViewPort.size === SM ? '' : html`<slot name="nav"></slot>`}
        </div>
      </div>
    `;
  }

  // eslint-disable-next-line class-methods-use-this
  _renderMain() {
    return html`<div class="main">
      <ki-app-breadcrumbs></ki-app-breadcrumbs>
      <slot class="main-slot"></slot>
    </div>`;
  }

  // eslint-disable-next-line class-methods-use-this
  _renderFooter() {
    return html`<slot name="footer"></slot>`;
  }

  render() {
    // language=html
    return html`
      <div class="ki-app-container">
        ${ViewPort.size === SM ? this._renderDrawer() : ''}
        ${!this.searchParams?.headless ? this._renderHeader() : html``}
        ${this._renderMain()}
        ${!this.searchParams?.headless ? this._renderFooter() : html``}
      </div>
    `;
  }

  toggleSideBar() {
    this._sideBarVisible = !this._sideBarVisible;
  }
}
