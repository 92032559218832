import Control from 'ol/control/Control';
// import './components'; // TODO: Why?
import dayjs from '../../common/dayjsext';
/*
 * map zoom in and zoom out layers.
 * */
export default class RasterControl extends Control {
  constructor(options = {}) {
    options = { visible: true, ...options };
    const div = document.createElement('ki-player');
    const start = dayjs().tz().subtract(24, 'hours').startOf('hour');
    div.labelFormatter = val =>
      `${dayjs(start).tz().add(val, 'hour').format('L LT')}`;
    options.element = div;
    options.element.map = options.map;
    super(options);
    this.label = options.label;
    this.style = options.style;
    this.setVisible(options.visible);
    this.setProperties({
      name: options.name,
      displayInLayerControl: options.displayInLayerControl,
      group: options.group,
    });
  }

  set label(label) {
    this.element.label = label;
  }

  set options(options) {
    this.element.options = options;
  }

  set style(style) {
    this.element.style = style;
  }

  setMap(map) {
    super.setMap(map);
  }

  // move a parent class
  setVisible(flag) {
    this._visible = flag;
    this.element.classList.toggle('hide', !flag);
  }

  getVisible() {
    return this._visible;
  }
}
