import { Station } from '../../defs';

export class StationSelectionItem {
  /** Station name */
  text: string = '';

  /** Station Number (station_no) to identify station as a user */
  additionalText: string = '';

  /** Station ID (station_id) for internal logic - user usually does not know about this id */
  station_id: string = '';

  /** Station No (station_no) - should be the same as additionalText */
  station_no: string = '';

  /** Station name */
  station_name: string = '';

  isEmpty: boolean = true;

  constructor(station?: Station) {
    if (station) {
      this.additionalText = station.shortName;
      this.text = station.name;
      this.station_id = station.id;
      this.station_no = station.shortName;
      this.station_name = station.name;
      this.isEmpty = false;
    }
  }
}
