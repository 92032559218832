export function translateLabels(obj, lang) {
  if (Array.isArray(obj)) {
    obj.forEach(item => translateLabels(item, lang));
  } else if (obj && typeof obj === 'object') {
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      if (key === 'label' && obj.translate && obj.translate[lang]) {
        obj.label = obj.translate[lang];
      } else if (typeof value === 'object') {
        translateLabels(value, lang);
      }
    });
  }
}
