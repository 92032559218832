/* eslint-disable class-methods-use-this */
import { customElement } from 'lit/decorators.js';
import { html } from 'lit';
import { getConfig } from '../ki-app';
import { KIWIS, KiwisHelper } from '../../api';
import dayjs from '../../common/dayjsext';
import WwMapPopupBase from './ww-map-popup-base';
import '../ww-popup/ww-graph-wrapper';

let currentData = [];
let currentId;

const threshholdMappings = {
  'Cmd.Sys.W.MNW': {
    width: 2,
    color: 'rgba(64,64,64, 1)',
    dashStyle: 'dash',
    label: {
      text: 'MNW',
      shortText: 'MNW',
      align: 'right',
      description: 'Mittleres Niedrigwasser*',
      y: 12,
      x: -5,
    },
  },
  'Cmd.Sys.W.MW': {
    width: 2,
    color: 'rgba(64,64,64, 1)',
    dashStyle: 'dash',
    label: {
      text: 'MW',
      shortText: 'MW',
      align: 'right',
      description: 'Mittelwasser*',
      y: 12,
      x: -5,
    },
  },
  'Cmd.Sys.W.MHW': {
    width: 2,
    color: 'rgba(64,64,64, 1)',
    dashStyle: 'dash',
    label: {
      text: 'MHW',
      shortText: 'MHW',
      align: 'right',
      description: 'Mittleres Hochwasser*',
      y: 12,
      x: -5,
    },
  },
  'Cmd.Sys.W.N7W': {
    width: 2,
    color: 'rgba(197,142,69, 1)',
    dashStyle: 'dash',
    label: {
      text: 'N7W',
      shortText: 'N7W',
      align: 'center',
      description: 'Minimum der niedrigsten jährl. 7-Tages-Mittel*',
      y: 12,
      x: 25,
    },
  },
  'Cmd.Sys.W.MN7W': {
    width: 2,
    color: 'rgba(255,221,128,1)',
    dashStyle: 'dash',
    label: {
      text: 'MN7W',
      shortText: 'MN7W',
      align: 'center',
      description: 'Mittel der niedrigsten jährl. 7-Tages-Mittel*',
      y: 12,
      x: -25,
    },
  },
  'Cmd.Sys.W.HN7W': {
    width: 2,
    color: 'rgba(99,195,199,1)',
    dashStyle: 'dash',
    label: {
      text: 'HN7W',
      shortText: 'HN7W',
      align: 'center',
      description: 'Maximum der niedrigsten jährl. 7-Tages-Mittel*',
      y: 12,
      x: -75,
    },
  },
  'Cmd.Sys.W.Info1': {
    width: 2,
    color: 'rgba(255, 231, 76, 1)',
    dashStyle: 'dash',
    label: {
      text: 'Informationswert 1',
      shortText: 'Info 1',
      align: 'left',
      description:
        'Ggfs. Ausuferung des Gewässers, land- und forstwirtschaftliche Flächen können überflutet werden; leichte Verkehrsbehinderungen auf Hauptverkehrs- und Gemeindestraßen sind möglich.',
      y: 12,
      x: -5,
    },
  },
  'Cmd.Sys.W.Info2': {
    width: 2,
    color: 'rgba(252, 186, 2, 1)',
    dashStyle: 'dash',
    label: {
      text: 'Informationswert 2',
      shortText: 'Info 2',
      align: 'left',
      description:
        'Gefahr der Überflutung einzelner bebauter Grundstücke oder Keller; Sperrung überörtlicher Verkehrsverbindungen oder vereinzelter Einsatz der Wasser- oder Dammwehr (Feuerwehr, Katastrophenschutz) möglich.',
      y: 12,
      x: -5,
    },
  },
  'Cmd.Sys.W.Info3': {
    width: 2,
    color: 'rgba(252, 81, 47, 1)',
    dashStyle: 'dash',
    label: {
      text: 'Informationswert 3',
      shortText: 'Info 3',
      align: 'left',
      description:
        'Bebaute Gebiete in größerem Umfang können überflutet werden; Einsatz der Wasser- oder Dammwehr (Feuerwehr, Katastrophenschutz) in großem Umfang möglich.',
      y: 12,
      x: -5,
    },
  },
};

@customElement('ww-map-popup-dynamic')
export default class WwMapPopupDynamic extends WwMapPopupBase {
  constructor() {
    super();

    this.config = getConfig();
    this.kiwis = new KIWIS({ ...this.config?.kiwis });
  }

  kiwisReq(paths = [], from = '', to = '') {
    return this.kiwis.getTimeseriesValues(
      {
        dateformat: 'UNIX',
        ts_path: paths.join(),
        metadata: true,
        md_returnfields: 'ts_shortname,ts_name',
        from,
        to,
      },
      null,
      'json',
    );
  }

  async getSeries(station) {
    if (this.layerAlias === 'Wasserstand') {
      const tsPath = station.ts_path || KiwisHelper.buildTsPath(station);
      const tsPathBase = tsPath.split('/').slice(0, 3).join('/');
      const alarms = [
        'Cmd.Sys.W.Info1',
        'Cmd.Sys.W.Info2',
        'Cmd.Sys.W.Info3',
      ].map(item => `${tsPathBase}/${item}`);
      try {
        const tsData = await Promise.all([
          this.kiwisReq(
            [tsPath],
            dayjs().subtract(1, 'months').valueOf(),
            dayjs().valueOf(),
          ),
          this.kiwisReq(alarms),
        ]);
        const mainTsData = tsData[0] ? tsData[0][0].data ?? [] : [];
        const maxValue = Math.max(...mainTsData.map(tsv => tsv[1]));
        return [
          {
            id: 'preview',
            type: 'line',
            data: mainTsData,
          },
          ...tsData[1]
            .sort((a, b) => a.ts_shortname.localeCompare(b.ts_shortname))
            .map((item, i) => ({
              ...threshholdMappings[item.ts_shortname],
              type: 'line',
              step: 'left',
              visible: i === 0 || (item.data[0] && item.data[0][1] < maxValue),
              name: item.ts_name,
              marker: { enabled: false },
              label: {
                enabled: true,
                style: {
                  color: '#111111',
                },
              },
              xAxis: 1,
              data: [
                [1, item.data[0] ? item.data[0][1] : null],
                [2, item.data[0] ? item.data[0][1] : null],
              ],
            })),
        ];
      } catch (e) {
        return [];
      }
    }

    if (this.layerAlias === 'Niedrigwasser') {
      const tsPath = station.ts_path || KiwisHelper.buildTsPath(station);
      const tsPathBase = tsPath.split('/').slice(0, 3).join('/');
      const alarms = ['Cmd.Sys.W.N7W', 'Cmd.Sys.W.MN7W', 'Cmd.Sys.W.HN7W'].map(
        item => `${tsPathBase}/${item}`,
      );
      try {
        const tsData = await Promise.all([
          this.kiwisReq(
            [tsPath],
            dayjs().subtract(1, 'months').valueOf(),
            dayjs().valueOf(),
          ),
          this.kiwisReq(alarms),
        ]);
        const mainTsData = tsData[0][0].data ?? [];
        return [
          {
            id: 'preview',
            type: 'line',
            data: mainTsData,
          },
          ...tsData[1]
            .sort((a, b) => a.ts_shortname.localeCompare(b.ts_shortname))
            .map(item => ({
              ...threshholdMappings[item.ts_shortname],
              type: 'line',
              step: 'left',

              name: item.ts_name,
              marker: { enabled: false },
              label: {
                enabled: true,
                style: {
                  color: '#111111',
                },
              },
              xAxis: 1,
              data: [
                [1, item.data[0] ? item.data[0][1] : null],
                [2, item.data[0] ? item.data[0][1] : null],
              ],
            })),
        ];
      } catch (e) {
        return [];
      }
    }

    const tsPath = station.ts_path || KiwisHelper.buildTsPath(station);
    try {
      const tsData = await Promise.all([
        this.kiwisReq(
          [tsPath],
          dayjs().subtract(1, 'months').valueOf(),
          dayjs().valueOf(),
        ),
      ]);
      const mainTsData = tsData[0][0].data ?? [];
      return [
        {
          id: 'preview',
          type: 'line',
          data: mainTsData,
        },
      ];
    } catch (e) {
      return [];
    }
  }

  async getGraph() {
    const station = this.stations[0];
    const newId = station.ts_path || KiwisHelper.buildTsPath(station);
    if (currentId !== newId) {
      currentData = await this.getSeries(station);
      currentId = newId;
    }
    return html` <ww-graph-wrapper
      .series="${currentData}"
    ></ww-graph-wrapper>`;
  }
}
