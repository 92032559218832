export default class DataTransformation {
  /**
   * Fetch errors resulting from different file formats and conventions (FileGenerator <=2024 vs. Harvester/Exporter >=2024)
   * @remark This function ensures, that all fileObjects/tsObjects for each file will be wrapped in an array
   * */
  public static normalizeFiles(files: any[] | any): any[] {
    return Array.isArray(files) ? files : [files];
  }

  public static normalizeFilesObject<T>(filesObject: {
    [key: string]: T[] | T;
  }): {
    [key: string]: T[];
  } {
    const result = {};
    Object.keys(filesObject).forEach((k: string) => {
      const entry = filesObject[k];
      result[k] = Array.isArray(entry) ? entry : [entry];
    });
    return result;
  }
}
