/* eslint-disable max-classes-per-file */
import { customElement } from 'lit/decorators.js';
import { css } from 'lit';
import { i18nMixin } from '../../decorators';
import {
  BaseTable,
  Export,
  ColumnFormatter,
  LazyRendering,
  ColumnHider,
} from '../ki-table';
import { Mix } from '../../common';
import nls from '../../locales/index';

@customElement('ki-wwp-ts-table')
export default class KiWwpTsTable extends Mix(
  BaseTable,
  ColumnHider,
  LazyRendering,
  ColumnFormatter,
  Export,
  [i18nMixin, { nls }],
) {
  static get styles() {
    return [
      super.styles,
      css`
        .col-value {
          text-align: right;
        }

        .header-cell-hider,
        .col-__options {
          display: none;
        }

        .header div.row {
          border-bottom: 1px solid darkgray;
        }

        .header .header-content {
          padding: 10px 5px;
          max-width: 140px;
          hyphens: auto;
          word-break: break-word;
          text-align: left;
          white-space: normal;
          border-bottom: none;
        }
        .table {
          font-size: 14px;
        }
        .table .header div.row {
          border-bottom: 2px solid darkgray;
        }
        .table .body .cell {
          padding: 5px 5px;
        }
        .row {
          cursor: default;
        }
      `,
    ];
  }
}
