/**
 * Template evaluation
 * @remark Iterates over provided string and replaces placeholder with property from item
 * @example "River ${river_name}" <- item[river_name]
 */
export const getDisplayValue = (item: any, template: string): string => {
  let returnString = '';
  let placeholderString = '';
  let capturingPlaceholder = false;
  let lastCharDollar = false;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < template.length; i++) {
    const currentChar = template.charAt(i);

    if (!capturingPlaceholder && !lastCharDollar && currentChar !== '$') {
      returnString = `${returnString}${currentChar}`;
    } else if (!capturingPlaceholder && lastCharDollar && currentChar !== '{') {
      returnString = `${returnString}$${currentChar}`;
    } else if (!capturingPlaceholder && lastCharDollar) {
      capturingPlaceholder = true;
    } else if (capturingPlaceholder && currentChar === '}') {
      returnString = `${returnString}${item[placeholderString]}`;
      capturingPlaceholder = false;
      placeholderString = '';
    } else if (capturingPlaceholder) {
      placeholderString = `${placeholderString}${currentChar}`;
    }

    lastCharDollar = currentChar === '$';
  }

  return returnString;
};
