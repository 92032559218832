/* eslint-disable no-undef */
/**  Inject variables via Vite config
 * @remark Boilerplate code is required to avoid errors in case global variable is missing
 * @remark Modify `global.d.ts` in case you want to add more variables
 */
export class WiskiWebInfoService {
  name: string;

  version: string;

  wiskiwebVersion: string;

  baseConfigVersion: string;

  buildNumber: string;

  buildDate: string;

  lang: string;

  i18n: object | undefined;

  constructor(i18n?) {
    this.name = typeof VITE_APP_NAME !== 'undefined' ? VITE_APP_NAME : '-';
    this.version =
      typeof VITE_APP_VERSION !== 'undefined' ? VITE_APP_VERSION : '-';
    this.wiskiwebVersion =
      typeof VITE_WISKIWEB_VERSION !== 'undefined'
        ? VITE_WISKIWEB_VERSION
        : '-';
    this.baseConfigVersion =
      typeof VITE_BASE_CONFIG !== 'undefined' ? VITE_BASE_CONFIG : '-';
    this.buildNumber =
      typeof VITE_BUILD_VERSION !== 'undefined' ? VITE_BUILD_VERSION : '-';
    this.buildDate =
      typeof VITE_BUILD_TIMESTAMP !== 'undefined' ? VITE_BUILD_TIMESTAMP : '-';
    this.i18n = i18n;
    this.lang = this.i18n?.language;
  }

  getInfoAsString(): string {
    return `App Name: ${this.name}\nApp Version: ${this.version}\nWiskiWeb Version: ${this.wiskiwebVersion}\nBaseConfig Version: ${this.baseConfigVersion}\nBuild time: ${this.buildDate}\nBuild Number: ${this.buildNumber}\nLanguage: ${this.lang}`;
  }

  getInfoAsObject(): Record<string, string> {
    return {
      name: this.name,
      version: this.version,
      wiskiwebVersion: this.wiskiwebVersion,
      baseConfigVersion: this.baseConfigVersion,
      buildNumber: this.buildNumber,
      buildDate: this.buildDate,
      language: this.lang,
    };
  }
}
