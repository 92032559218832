// eslint-disable-next-line max-classes-per-file
import { customElement, property } from 'lit/decorators.js';
import { find } from 'lodash-es';
import dayjs from '@kisters/wiski-web/common/dayjsext';
import KiWwpGraphHdoeFcst from './ki-wwp-graph-hdoe-fcst';
import { initAPI, getCurrentApi } from '@kisters/wiski-web/api/API';
import { initConfig } from '@kisters/wiski-web/components/ki-app/services/ki-app-config-service';

@customElement('ki-wwp-graph-hdoe-fcst-ext')
export class KiWwpGraphHdoeFcstExt extends KiWwpGraphHdoeFcst {
  @property({ type: String })
  key;

  @property({ type: String })
  stationId;

  @property({ type: String })
  dataPath;

  favButton = false;

  firstUpdated() {
    this.initAppConfig();
    this.refreshInterval = setInterval(() => {
      this.fetchData();
    }, 300000);
  }

  async initAppConfig() {
    this.appcfg = await initConfig('./config/app.json', []);
    initAPI({ dataPath: this.appcfg.apiPath, apiType: this.appcfg.apiType });
    this.api = getCurrentApi();
    this.fetchData();
  }

  async fetchData() {
    dayjs.locale(this.i18n.language);
    const stationDetailComp = find(this.appcfg.route[0].children, {
      component: 'wwp-station-detail',
    });
    this.config = this.appcfg;
    const configItem = find(stationDetailComp.children, { path: this.key });
    if (configItem) {
      Object.assign(this, configItem.options);
    }
    this._selectedPeriod = this.period;
    this.imageShare = false;
    this.tableOpen = false;
    super.fetchData();
  }
}
