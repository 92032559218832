import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';

import { Mix } from '../../common';

import { i18nMixin } from '../../decorators';
import nls from '../../locales/index';
import style from './ki-station-map.css?inline';

@customElement('ki-station-download-button')
export default class KiStationDownloadButton extends Mix(LitElement, [
  i18nMixin,
  { nls },
]) {
  // language=CSS
  static styles = style;

  static get properties() {
    return {
      selectedStations: { type: Array },
    };
  }

  render() {
    // language=html
    return html`
      <ki-icon-btn
        title="${this.i18n.t('Download_station_list')}"
        class="download-btn"
        icon="ki ki-arrow-alt-to-bottom"
        id="download-btn"
        @click="${this.downloadStations}"
      ></ki-icon-btn>
    `;
  }

  downloadStations() {
    this.dispatchEvent(
      new CustomEvent('downloadstationsmd', {
        bubbles: true,
      }),
    );
  }
}
