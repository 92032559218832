/* eslint-disable max-classes-per-file */
import { css, html, LitElement } from 'lit';
/* eslint-disable import/extensions */
import { customElement, property } from 'lit/decorators.js';
import Mix from '../../common/Mix';
import { i18nMixin } from '../../decorators/i18n';
import { responsiveMixin } from '../../decorators/responsive';
import '../ki-icon/ki-icon';
import nls from '../../locales/index';

@customElement('ki-drawer')
export default class KiDrawer extends Mix(LitElement, responsiveMixin, [
  i18nMixin,
  { nls },
]) {
  @property()
  title = 'Title';

  @property({ type: Boolean })
  opened;

  static get styles() {
    return css`
      :host .main-panel {
        display: block;
        position: fixed;
        top: 0;
        left: 0;

        right: 0;
        width: 25vw;
        min-width: 250px;
        height: 100vh;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        z-index: 100;
        background-color: white;
      }

      :host([opened]) .main-panel {
        transform: translateX(0);
      }

      :host(.sm-screen) .main-panel {
        width: 100vw;
        height: 70vh;
        bottom: 0;
        top: auto;
        transform: translateY(100%);
      }

      :host(.sm-screen[opened]) .main-panel {
        transform: translateY(0);
      }

      .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 99;
        display: none;
      }

      :host([opened]) .overlay {
        display: block;
      }

      :host .drawer-header {
        display: flex;
        flex-direction: row;
        padding: 16px 10px;
        border-bottom: 1px solid lightgray;
      }

      :host .drawer-header .header-title {
        flex: 1;
        color: var(--theme-text-color);
        font-size: 20px;
      }

      :host .drawer-header .close-btn {
        font-size: 14px;
      }
      :host .drawer-header .close-btn:hover {
        cursor: pointer;
      }
    `;
  }

  render() {
    return html`
      <div class="overlay" @click="${this.emitCloseEvent}"></div>
      <div class="main-panel">
        <div class="drawer-header">
          <div class="header-title">${this.title}</div>
          <ki-icon
            class="close-btn"
            icon="ki ki-times"
            @click="${this.emitCloseEvent}"
            title="close"
          ></ki-icon>
        </div>
        <slot class="content"></slot>
      </div>
    `;
  }

  emitCloseEvent() {
    this.dispatchEvent(new CustomEvent('close', { detail: { opened: false } }));
  }
}
