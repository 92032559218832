/* eslint-disable class-methods-use-this */
// eslint-disable-next-line max-classes-per-file
import { TemplateResult, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { getRoutes } from '../ki-app';
import WwMapPopupBase from './ww-map-popup-base';

@customElement('ww-map-popup-auto')
export default class WwMapPopupAuto extends WwMapPopupBase {
  getGraph(station): TemplateResult {
    // console.debug(station)
    /** Check if parameters match to find correct config for graph
     * @remark Configure Export (Filegenerator mor Harvester) if properties are missing
     */
    const conf = this.getPopupConfig(station);
    if (!conf) {
      return html``;
    }

    // Bar Chart / Precipitation chart
    if (conf.component === 'ki-wwp-graph-precip') {
      return html`<ki-wwp-graph-precip
        miniGraph
        .options="${conf}"
        stationId="${station.station_id}"
        style="width:400px;height:240px;"
      ></ki-wwp-graph-precip>`;
    }
    // Line Chart
    return html`<ki-wwp-graph
      miniGraph
      .options="${conf}"
      stationId="${station.station_id}"
      style="width:400px;height:240px;"
    ></ki-wwp-graph>`;
  }

  getPopupConfig(station): object | undefined {
    const routes = getRoutes();

    if (routes.length <= 0) {
      return undefined;
    }

    const stationDetailRoute = routes[0].children?.find
      ? routes[0].children.find(child =>
          child?.component?.includes('wwp-station-detail'),
        )
      : undefined;
    if (!stationDetailRoute) {
      return undefined;
    }
    const conf = stationDetailRoute.children.find(
      item =>
        item.component.includes('ki-wwp-graph') &&
        [
          station.stationparameter_no,
          station.stationparameter_name,
          station.station_parameter,
          station.stationparameter_shortname,
        ].includes(item.options.station_parameter),
    );

    if (!conf) {
      return undefined;
    }
    // Create deep clone
    const result = JSON.parse(JSON.stringify(conf.options));
    // Inject graph information to render correct graph
    result.component = conf.component as
      | 'ki-wwp-graph'
      | 'ki-wwp-graph-precip'
      | 'ki-wwp-graph-pagasa'
      | 'ki-wwp-graph-pagasa-precip';
    result.ranges = this._findRange(result);
    return result;
  }

  protected _findRange(result): object[] {
    return result.popupRange ? [result.popupRange] : [result.ranges[0]];
  }
}
