import { LitElement, html, css } from 'lit';

import { customElement, property } from 'lit/decorators.js';

@customElement('ki-stack')
export default class KiStack extends LitElement {
  // language=CSS
  static styles = css`
    :host {
      display: block;
      position: relative;
      overflow: hidden;
      height: 100%;
      width: 100%;
    }

    .stack-container {
      height: 100%;
      width: 100%;
      display: block;
      overflow: hidden;
    }

    .stack-container > ::slotted(*[key]) {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      height: 100%;
      width: 100%;
    }

    .stack-container > ::slotted(.selected) {
      display: block;
    }
  `;

  @property({ type: String })
  selected: string | null | undefined = this.children[0].getAttribute('key');

  history: Array<string> = [];

  update(changedProperties) {
    super.update(changedProperties);
    if (changedProperties.has('selected')) {
      this._select(this.selected);
    }
  }

  render() {
    return html`<div class="stack-container"><slot></slot></div>`;
  }

  /* @private
   * */
  _select(key) {
    let selectedChild;
    for (const c of this.children) {
      const s = c.getAttribute('key') === key;
      c.classList.toggle('selected', s);
      if (s) {
        selectedChild = c;
      }
    }
    if (!selectedChild) {
      return;
    }
    if (!selectedChild.getAttribute('skipHistory')) {
      this.history.push(key);
    }
    if (selectedChild.onShow) {
      selectedChild.onShow(); // TODO need consider
    }
  }

  back() {
    this.selected = this.history.pop();
  }
}
