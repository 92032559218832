import { JsonAPI, ZarrAPI, ParquetAPI } from '../../api/API';
import { ILocation, IRouteOptionsParams } from '../../defs';
import { BreadcrumbItem } from '../../defs/routing/BreadcrumbItem';
import { getNavItemLabel } from './services/ki-app-config-service';

/**
 * Returns the siblings of a given route that are considered "previous"
 * based on a comparison of their path lengths and prefix match.
 * @param route The current route object.
 * @returns An array of route objects that are previous siblings.
 */
export const getPreviousSiblings = (route: IRouteOptionsParams): any[] => {
  if (!route.parent || !route.parent.children) {
    return [];
  }
  const siblings = route.parent.children.filter(
    child =>
      child.path.length < route.path.length &&
      route.path.startsWith(child.path),
  );
  siblings.sort((a, b) => a.path.length > b.path.length);
  return siblings;
};

/**
 * Constructs the full path of a route by recursively concatenating its parent's path.
 * @param route The route object.
 * @returns The full path as a string.
 */
export const getFullPath = (route: IRouteOptionsParams): string => {
  if (!route || !route.path || route.path === '' || route.path === '/') {
    return '';
  }
  return `${getFullPath(route.parent)}${route.path}`;
};

export const resolveMeta = (label, key, value, station) => {
  let l = label;
  /** Replace all strings like ${station.river_name} in label by the station meta data */
  if (key === 'station') {
    const keys = Object.keys(station).map(stationKey => [
      `station.${stationKey}`,
      station[stationKey],
    ]);
    // eslint-disable-next-line no-return-assign
    keys.forEach(k => (l = l.replace(`\${${k[0]}}`, k[1])));
  }
  //
  return l.replace(`\${${key}}`, value);
};

/**
 * Constructs a breadcrumb object with path and label, applying URL parameters.
 * @param route The route object.
 * @param params URL parameters object.
 * @param search URL search string.
 * @returns An object representing a breadcrumb.
 */
export const getCrumb = (
  route: IRouteOptionsParams,
  params: any,
  search: string,
  shortCrumb: Boolean,
): BreadcrumbItem => {
  let fullPath = getFullPath(route);
  const { station } = params;
  let { label } = route;
  const { breadcrumbShort } = route;
  Object.entries(params).forEach(([key, value]) => {
    fullPath = fullPath.replace(`:${key}`, value);
    label =
      getNavItemLabel(fullPath, shortCrumb) ||
      resolveMeta(label, key, value, station);
  });
  return {
    path: `${fullPath}${search || ''}`,
    label,
    breadcrumbShort,
  };
};

/**
 * Recursively collects breadcrumbs from the current route upwards, including any relevant siblings.
 * @param route The starting route object.
 * @param params URL parameters object.
 * @param search URL search string.
 * @returns An array of breadcrumb objects.
 */
export const recursivelyGetCrumbs = (
  route: IRouteOptionsParams,
  params: any,
  search: string,
  shortCrumb: Boolean,
): any[] => {
  if (route.path === '') {
    return [];
  }
  const siblingsCrumbs = getPreviousSiblings(route)
    .filter(sibling => sibling.label)
    .map(sibling => getCrumb(sibling, params, search, shortCrumb));
  const parentCrumbs = recursivelyGetCrumbs(
    route.parent,
    params,
    search,
    shortCrumb,
  );

  if (route.label) {
    return [
      ...parentCrumbs,
      ...siblingsCrumbs,
      getCrumb(route, params, search, shortCrumb),
    ];
  }
  return [...parentCrumbs, ...siblingsCrumbs];
};

/**
 * Calculates the complete breadcrumb trail for the given location in an application.
 * @param location The location object which includes route, parameters, and search string.
 * @returns An array of breadcrumb objects for the entire path.
 */
export const calculateBreadCrumbs = (
  location: IRouteOptionsParams,
  shortCrumb: Boolean,
): any[] => {
  const { route, params, search } = location;
  return recursivelyGetCrumbs(route, params, search, shortCrumb);
};

/**
 * Resolves station meta information based on a station ID provided in the location object and enriches the location object with this data. If no station is found, or if no stationId is provided, the location object is returned unchanged.
 * @param location - The location object containing parameters including stationId.
 * @param api - The API object that provides the `getStation` method.
 * @returns A Promise resolving to the location object, possibly augmented with station meta information.
 * @throws When the API call fails due to reasons other than the station not being found or the stationId missing.
 */
export const getStationByLocation = async (
  location: ILocation,
  api: JsonAPI | ZarrAPI | ParquetAPI,
): Promise<ILocation> => {
  const { stationId } = location.params;

  // Proceed with the function even if stationId is not provided, returning the unchanged location.
  if (!stationId) {
    return location;
  }

  try {
    const station = await api.getStation(stationId);
    if (!station) {
      // If no station is found, return the original location object unchanged.
      return location;
    }
    // If a station is found, augment the location object with the station data.
    return {
      ...location,
      params: {
        ...location.params,
        station,
      },
    };
  } catch (error) {
    if (error instanceof Error && error.message === 'Station not found') {
      // If the error is specifically that the station is not found, proceed without throwing.
      return location;
    }
    // For all other errors, rethrow them.
    throw new Error(
      `Failed to retrieve station: ${error instanceof Error ? error.message : 'Unknown error'}`,
    );
  }
};
