import deCommon from './de/common.json';
import enCommon from './en/common.json';
import frCommon from './fr/common.json';
import isCommon from './is/common.json';
import nlCommon from './nl/common.json';
import zhCommon from './zh/common.json';

export default {
  de: {
    common: deCommon,
  },
  en: {
    common: enCommon,
  },
  fr: {
    common: frCommon,
  },
  is: {
    common: isCommon,
  },
  nl: {
    common: nlCommon,
  },
  zh: {
    common: zhCommon,
  },
};
