import Control from 'ol/control/Control';
import Overview from 'ol-ext/control/Overview';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import { inAndOut } from 'ol/easing';
import Circle from 'ol/style/Circle';
import { SM, ViewPort } from '../../decorators';
import VisibleMixin from './VisibleMixin';
import { getLayer } from '../../common/ki-ol-layer-generator';
/*
 * map overview layers.
 * */
export default class stationMapOverviewLayer extends Control {
  constructor(options = {}) {
    options = { visible: true, ...options };
    const se = options.map.getOverlayContainerStopEvent();
    const div = se.ownerDocument.createElement('div');
    options.element = div;
    div.id = 'overview-map';
    div.part = 'overview-map';
    ViewPort.size !== SM && se.appendChild(div);

    const _ovmap = new (VisibleMixin(Overview))({
      name: 'Overview',
      group: 'Information',
      displayInLayerControl: options.displayInLayerControl,
      layers: options.layerOptions.layer.map(layer => getLayer(layer)),
      panAnimation: inAndOut,
      maxZoom: options.layerOptions.maxZoom,
      minZoom: options.layerOptions.minZoom,
      projection: options.layerOptions.projection,
      target: div,
      __element: div,
      style: [
        new Style({
          image: new Circle({
            fill: new Fill({
              color: 'rgba(105,105,105,0.8)',
            }),
            stroke: new Stroke({
              width: 7,
              color: 'rgba(105,105,105,0.8)',
            }),
            radius: 5,
          }),
          stroke: new Stroke({
            width: 1,
            color: 'rgba(16,147,205,1)',
          }),
        }),
      ],
    });
    if (options.layerOptions.attributions) {
      const attrs = se.ownerDocument.createElement('div');
      attrs.innerHTML = options.layerOptions.attributions;
      attrs.style.fontSize = '0.8em';
      attrs.style.marginTop = '-2px';
      options.element.appendChild(attrs);
    }
    options.map.addControl(_ovmap);
    super(options);
    this.label = options.label;
    this._ovmap = _ovmap;
    this.setVisible(options.visible);
    this.setProperties({
      name: options.name,
      displayInLayerControl: options.displayInLayerControl,
      group: options.group,
    });
  }

  set label(label) {
    this.element.label = label;
  }

  set options(options) {
    this.element.options = options;
  }

  setMap(map) {
    super.setMap(map);
  }

  // move a parent class
  setVisible(flag) {
    this._visible = flag;
    this.element.classList.toggle('hide', !flag);
  }

  getVisible() {
    return this._visible;
  }
}
