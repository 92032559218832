import { html, css, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { filter, last, orderBy } from 'lodash-es';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Mix } from '../../common';
import { queryParamMixin } from '../../decorators';
import { getRouteOptionsAndParams } from '../ki-app';
import { getCurrentApi } from '../../api';
import '@ui5/webcomponents/dist/Select';
import '@ui5/webcomponents/dist/Option';

import dayjs from '../../common/dayjsext';

@customElement('ww-report')
export default class WwReport extends Mix(LitElement, [
  queryParamMixin,
  { targetProperty: 'selected', selector: 'selected', defaultValue: '' },
]) {
  // language=css
  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      height: calc(100% - 30px);
    }
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
    .left-panel {
      text-align: center;
      flex: 0;
    }
    .right-panel {
      flex: 1;
    }

    ui5-select {
      width: 350px;
    }

    a {
      font-size: 2em;
      text-align: center;
      display: block;
      margin-top: 20%;
    }
  `;

  constructor() {
    super();
    this.api = getCurrentApi();
    this.data = [];
  }

  static get properties() {
    return {
      data: { type: Array },
    };
  }

  _selected = '';

  get selected() {
    return this._selected;
  }

  set selected(value) {
    this._selected = value;
    this.requestUpdate();
  }

  // Vaadin Router Method that gets called after this component is called
  // eslint-disable-next-line no-unused-vars
  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, ['content']);
    this.content = params.content;
    // Usually "documents" or "reports"
    this.directory = params.options?.directory || 'documents';

    this.fetchData();
  }

  // Fetch report
  async fetchData() {
    const res = await this.api._requestJson(
      `/${this.directory}/index.json`,
      undefined,
      true,
    );

    // Filter reports; only allow visible PDF
    this.data = orderBy(
      filter(res._links, d => {
        if (
          d.mime_type === 'application/pdf' &&
          d.id === this.content &&
          d.visible
        ) {
          const pathArray = d.href.split('/');
          d.date = pathArray[pathArray.length - 2];
          d.filename = last(pathArray);
          d.label = dayjs(d.date, 'YYYYMMDDHHmm').format('L LT'); // `${d.filename} (${d.date})`;
          return true;
        }
        return false;
      }),
      'date',
      'desc',
    ); // Newest file on first position
    this.requestUpdate();
  }

  updated() {
    // eslint-disable-next-line no-unused-expressions
    this.data?.length > 0 &&
      this._handleChanged({
        target: {
          selectedOption: { value: this.data[0].date },
        },
      });
  }

  getUrl(file) {
    return `${this.api.dataPath}/${this.directory}/${this.content}/${file}`;
  }

  /**
   *     Handles selection changes, updating the iframe to display the selected PDF.
   * @param e The selection change event.
   * @example http://localhost:3000/data/internet/reports/daily-flood-guidance/202203111336/report.pdf
   * @example http://localhost:3000/data/internet/reports/daily-flood-guidance/202203171340/report.pdf
   * @example http://localhost:3000/data/internet/reports/flood-alert/202203111336/report.pdf
   */
  private _handleChanged(e: Event): void {
    const selectElement = e.target as HTMLSelectElement;
    const pdfurl = this.getUrl(selectElement.value);
    console.debug('PDF URL: ', pdfurl);
    this.querySelector('#contentframe').src = pdfurl;
  }

  render() {
    // language=html
    return this.data
      ? html`
          <div class="left-panel">
            <ui5-select @change="${this._handleChanged}">
              ${this.data.map(
                item =>
                  html`<ui5-option value="${item.date}"
                    >${item.label.replace('.pdf', '')}</ui5-option
                  >`,
              )}
            </ui5-select>
          </div>
          <div class="right-panel">
            <iframe title="PDF Content" id="contentframe"></iframe>
          </div>
        `
      : html`<div>Loading...</div>`;
  }
}
