// eslint-disable-next-line max-classes-per-file
import { customElement, property } from 'lit/decorators.js';
import { find } from 'lodash-es';
import { dayjs } from '../../common';
import KiWwpGraphPrecip from './ki-wwp-graph-precip';
import { getCurrentApi, initAPI } from '../../api/API';
import { initConfig } from '../ki-app/services/ki-app-config-service';

@customElement('ki-wwp-graph-ext-precip')
export class KiWwpGraphExtPrecip extends KiWwpGraphPrecip {
  @property({ type: String })
  key;

  @property({ type: String })
  stationId;

  @property({ type: String })
  dataPath;

  favButton = false;

  firstUpdated() {
    this.initAppConfig();
    this.refreshInterval = setInterval(() => {
      this.fetchData();
    }, 300000);
  }

  async initAppConfig() {
    this.appcfg = await initConfig('./config/app.json', []);
    initAPI({
      dataPath: this.appcfg.apiPath,
      apiType: this.appcfg.apiType,
    });
    this.api = getCurrentApi();
    this.fetchData();
  }

  async fetchData() {
    dayjs.locale(this.i18n.language);
    const stationDetailComp = find(this.appcfg.route[0].children, {
      component: 'wwp-station-detail',
    });
    const configItem = find(stationDetailComp.children, { path: this.key });
    if (configItem) {
      Object.assign(this, configItem.options);
    }
    this._selectedPeriod = this.period;
    this.imageShare = false;
    this.tableOpen = false;
    super.fetchData();
  }
}

@customElement('ki-wwp-graph-precip-ext')
export class KiWwpGraphPrecipExt extends KiWwpGraphPrecip {
  @property({ type: String })
  key;

  @property({ type: String })
  stationId;

  @property({ type: String })
  dataPath;

  favButton = false;

  firstUpdated() {
    this.initAppConfig();
    this.refreshInterval = setInterval(() => {
      this.fetchData();
    }, 300000);
  }

  async initAppConfig() {
    this.appcfg = await initConfig('./config/app.json', []);
    initAPI({ dataPath: this.appcfg.apiPath, apiType: this.appcfg.apiType });
    this.api = getCurrentApi();
    this.fetchData();
  }

  async fetchData() {
    dayjs.locale(this.i18n.language);
    const stationDetailComp = find(this.appcfg.route[0].children, {
      component: 'wwp-station-detail',
    });
    const configItem = find(stationDetailComp.children, { path: this.key });
    if (configItem) {
      Object.assign(this, configItem.options);
    }
    this._selectedPeriod = this.period;
    this.imageShare = false;
    this.tableOpen = false;
    super.fetchData();
  }
}
