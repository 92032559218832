// aspect fn with before
/*
example:

 const a = {
  name: 'q',
  say(){
    console.debug(1);
  }
 }

 aspect(a,'say',(origin)=>{
   return function(args){
     console.debug(this.name);
     origin.apply(this);
     console.debug(2);
   }
 })

 a.say();

* */
export default function aspect(target, fnName, wrapper) {
  const originFn = target[fnName];
  // eslint-disable-next-line no-param-reassign
  target[fnName] = wrapper(originFn);
}
