/* eslint-disable max-classes-per-file */
import { html, css, LitElement } from 'lit';

import { customElement, property } from 'lit/decorators.js';
import '../ki-icon/ki-icon';
import { getQueryObject } from '../../decorators/query';

@customElement('ki-tree-node')
export default class KiTreeNode extends LitElement {
  static styles = css`
    .tree-item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      line-height: 2.5em;
      color: grey;
      width: 100%;
    }

    :host(.hideRoot) .tree-item .tree-node-content {
      display: none;
    }
    :host(.hideRoot) .tree-item .tree-node-children {
      padding-left: 0;
    }

    .tree-node-content.leaf-content {
      background-color: white;
    }

    .tree-item.selected .tree-node-content {
      color: white;
      background: var(--theme-color, #0056a0);
    }

    .tree-node-content {
      display: flex;
      flex-direction: row;
      padding: 0px 20px;
      height: 40px;
      overflow: hidden;
    }

    .tree-node-label {
      flex: 1;
    }
    .tree-node-label:hover {
      color: var(--theme-color, #0056a0);
      cursor: pointer;
    }
    .tree-item.selected .tree-node-label:hover {
      color: white;
      opacity: 0.8;
    }
    .tree-node-label .node-content-label {
      white-space: nowrap;
      max-width: 300px;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .node-content-label {
      flex: 1;
      line-height: 40px;
    }

    .node-content-icon {
      vertical-align: top;
      padding: 10px;
      font-size: 18px;
      cursor: pointer;
    }

    span::selection {
      background: white;
    }

    .expand-icon {
      padding: 10px;
      width: 36px;
      text-align: center;
    }

    .expand-icon.hide {
      display: none;
    }
    .expand-icon:hover {
      fill: var(--theme-color, #0056a0);
      cursor: pointer;
      border-radius: 50%;
    }

    .tree-node-children.hide {
      display: none;
    }
  `;

  @property({ type: String })
  icon: string | undefined;

  @property({ type: String })
  label: string = '';

  @property({ type: Object })
  value;

  @property({ type: Boolean })
  isLeaf: boolean = false;

  @property({ type: Array })
  selectedItem: Array<any> | undefined;

  @property({ type: Boolean })
  expand: boolean = false;

  @property({ type: Number })
  paddingLeft: number = 0;

  get __labelElement() {
    return getQueryObject(this, '#label');
  }

  render() {
    return html`
      <div
        class="tree-item ${this.selectedItem &&
        this.selectedItem.indexOf(this.value) !== -1
          ? 'selected'
          : ''} "
      >
        <div
          class="tree-node-content ${this.isLeaf ? 'leaf-content' : ''}"
          style="padding-left:${this.paddingLeft}px;"
        >
          <div class="tree-node-label" @click="${this.handleContentClick}">
            ${this.icon
              ? html`<ki-icon
                  class="node-content-icon"
                  icon="${this.icon}"
                ></ki-icon>`
              : ''}
            <span class="node-content-label" id="label">${this.label}</span>
          </div>
          <ki-icon
            id="icon"
            title="Expand"
            class="expand-icon ${this.isLeaf ? 'hide' : ''}"
            icon="ki ${this.expand ? 'ki-chevron-up' : 'ki-chevron-down'}"
            @click="${this.handleClick}"
          ></ki-icon>
        </div>
        <div class="tree-node-children ${!this.expand ? 'hide' : ''}">
          <slot></slot>
        </div>
      </div>
    `;
  }

  _createPapertooltip() {
    if (this.__labelElement.offsetWidth < this.__labelElement.scrollWidth) {
      const node = document.createElement('paper-tooltip');
      // @ts-expect-error
      node.for = 'label';
      node.innerHTML = this.label;
      this.__labelElement.appendChild(node);
    }
  }

  handleContentClick() {
    const labelClickedEvent = new CustomEvent('labelClicked', {
      detail: { value: this.value },
      bubbles: true,
      composed: true,
    });
    if (!this.isLeaf) {
      this.dispatchEvent(labelClickedEvent);
      this.expand = !this.expand;
    } else {
      this.dispatchEvent(
        new CustomEvent('itemSelected', {
          detail: { value: this.value },
          bubbles: true,
          composed: true,
        }),
      );
    }
  }

  handleClick() {
    // this.value = this.value;
    const itemSelectedEvent = new CustomEvent('itemSelected', {
      detail: { value: this.value },
      bubbles: true,
      composed: true,
    });

    if (this.isLeaf) {
      this.expand = false;
      this.dispatchEvent(itemSelectedEvent);
    } else {
      this.expand = !this.expand;
    }

    // @ts-expect-error
    this.requestUpdate(this.expand, !this.expand);
  }
}
