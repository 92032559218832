/* eslint-disable max-classes-per-file */
import './dynMenu.js';
import './dynMenuLanuv.js';

import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
/* eslint-disable import/extensions */
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
/* eslint-enable import/extensions */
import { each, findIndex, find, concat, flatten, first } from 'lodash-es';
import {
  navigateTo,
  getCurrentPath,
  getSearchParamString,
  getRouteOptionsAndParams,
  getConfig,
} from '../ki-app';
import {
  responsiveMixin,
  queryParamMixin,
  localStorageMixin,
  i18nMixin,
  SM,
  ViewPort,
} from '../../decorators/index.js';
import { LoaderMixin, Mix, reproject, registerProjections } from '../../common';
import { DynMenuOption } from './DynMenuOption.js';

import { getCurrentApi, JsonAPI } from '../../api';
import style from './wwp-station-detail.css?inline';
import nls from '../../locales/index';
import FontIcon from '../ki-station-map/FontIcon';

/* eslint-enable import/extensions */

import './wwp-station-info.js';

/* eslint-disable import/extensions */
import '@ui5/webcomponents/dist/Select';
import '@ui5/webcomponents/dist/List';
import '@ui5/webcomponents/dist/Option';
import '@ui5/webcomponents/dist/StandardListItem.js';
import '@ui5/webcomponents/dist/CustomListItem.js';
import '@ui5/webcomponents/dist/GroupHeaderListItem.js';
import { AppConfigDefinition } from '../../defs/AppConfigDefinition.js';

const _getNextItem = (
  // eslint-disable-next-line camelcase
  collection: Array<{ station_id: number }>,
  stationId: number,
) => {
  let nextItem;
  const index = findIndex(collection, s => s.station_id === stationId);

  if (index >= 0 && index < collection.length - 1) {
    nextItem = collection[index + 1];
  } else {
    [nextItem] = collection;
  }
  return nextItem;
};

// eslint-disable-next-line camelcase
const _getPreviousItem = (
  collection: Array<{ station_id: number }>,
  stationId: number,
) => {
  let nextItem;
  const index = findIndex(collection, s => s.station_id === stationId);

  if (index > 0) {
    nextItem = collection[index - 1];
  } else {
    nextItem = collection[collection.length - 1];
  }
  return nextItem;
};

@customElement('wwp-station-detail')
export default class WwpStationDetail extends Mix(
  LitElement,
  LoaderMixin,
  responsiveMixin,
  [
    queryParamMixin,
    { targetProperty: 'viewSelection', selector: 'mode', defaultValue: 'map' },
  ],
  [
    localStorageMixin,
    { targetProperty: '__favouriteStationIds', typeParam: Array },
  ],
  [i18nMixin, { nls }],
) {
  static styles = style;

  @property({ type: String })
  stationId;

  @property({ type: Object })
  _station = {};

  @property({ type: String })
  detail;

  /** Option to hide favorite start in the top-left */
  hideFavorite = false;

  /** Option to hide minimap in top-left corner */
  showMiniMap = true;

  dynMenu: DynMenuOption | undefined;

  /** Path to selected Item
   * @remark Must start with "/", e.g. "/Waterlevel"
   */
  _selectedMenuItem: string | null = null;

  api = getCurrentApi() as JsonAPI;

  mobileAutoForward = false;

  config: AppConfigDefinition = getConfig();

  _currentSubPath;

  ___favouriteStationIds = [];

  get __favouriteStationIds() {
    return this.___favouriteStationIds;
  }

  set __favouriteStationIds(value) {
    this.___favouriteStationIds = value;
    this.requestUpdate();
  }

  _viewSelection: 'map' | 'table' | 'overview' = 'map';

  get viewSelection() {
    return this._viewSelection;
  }

  set viewSelection(value) {
    this._viewSelection = value;
    this.requestUpdate();
  }

  connectedCallback() {
    if (super.connectedCallback) super.connectedCallback();
    /** Listen to favorite actions in dynamically loaded components */
    this.addEventListener('favToggled', () => this.requestUpdate());
  }

  // Vaadin Router Method that gets called after this component is called
  // eslint-disable-next-line no-unused-vars
  onAfterEnter(location, options, router) {
    const params = getRouteOptionsAndParams(location, [
      'layerName',
      'stationId',
      'stationLabel',
    ]);
    Object.assign(this, params.options);

    this.remarkAttr = this.config.remarkAttr;
    const mainRouteLength = location.route.path.split('/').length;
    this._search = location.search;
    this.currentRoute = location.route;

    this.currentRoute.children = this.currentRoute.children.filter(
      item => !(item.isDynamic || item.path?.includes('dyn_')),
    );
    this.currentRoute.__children = this.currentRoute.__children.filter(
      item => !(item.isDynamic || item.path?.includes('dyn_')),
    );

    this._mainRoute = location.pathname
      .split('/')
      .slice(0, mainRouteLength)
      .join('/');
    this._menuListRaw =
      location.route.children?.map(item => ({
        ...item,
        detailSelection: item.detailSelection || item.path.slice(1),
      })) ?? [];
    if (this._mainRoute !== location.pathname) {
      this._selectedMenuItem = `/${location.pathname
        .split('/')
        .slice(mainRouteLength)
        .join('/')}`;
    } else {
      this._selectedMenuItem =
        location.pathname === router.context.resolver.location.redirectFrom ||
        !router.context.resolver.location.redirectFrom
          ? null
          : `/${router.context.resolver.location.redirectFrom
              .split('/')
              .slice(-1)}`;
    }

    this.router = router;

    // Switch back to main menu on navigation (mobile)
    this.router.navigationChange = (newRoute, oldRoute) => {
      /** TODO: @Marko Why absolute length? This section is also executed on desktop devices */
      if (newRoute.length < oldRoute.length) {
        this.__selectedItem = this._selectedMenuItem; // Workaround LANUV: Preserve last selected menu item (required for nested LANUV dynMenu)
        this._selectedMenuItem = '';
        this.requestUpdate();
      }
    };

    this.stationId = params.stationId;
    this.stationLabel = params.stationLabel;
    this.fetchData(params.layerName);
  }

  // eslint-disable-next-line class-methods-use-this
  get localStorageId() {
    return `wwp-favorites-ids`;
  }

  _renderLeftMenu() {
    if (this._station && this._menuList) {
      return html`<ui5-list
        mode="SingleSelect"
        @selection-change="${this._handleChange}"
        separators="Inner"
      >
        ${this._renderListItems()}
      </ui5-list>`;
    }
    return html`Loading...`;
  }

  _renderListItems() {
    return html`${this._menuList.map(i => {
      if (i.items?.length) {
        return this._renderGroupItem(i);
      }
      return this._renderListItem(i);
    })}`;
  }

  /** Render non-clickable group header
   * @remark Selected if item-path is selectedItem
   */
  _renderGroupItem(item) {
    // LANUV DynMenu Workaround
    if (this._selectedMenuItem === '')
      this._selectedMenuItem = this.__selectedItem;

    if (
      item.hide ||
      item.subitem ||
      (item.toplevel && item?.items.some(i => !i.hide))
    ) {
      return html``;
    }
    return html`<ui5-li-groupheader>${item.label}</ui5-li-groupheader
      >${item.items?.map(i => this._renderListItem(i))}`;
  }

  /** Render Menu
   * @remark Grouping of parameters
   */
  _renderListItem(item) {
    return !item.hide
      ? html`<ui5-li
          style="${item.customCss}"
          .view=${item.view}
          .path="${item.path}"
          .text="${item.label}"
          .title="${item?.tooltip ?? ''}"
          .selected="${this._selectedMenuItem === item.path}"
          >${item.label}</ui5-li
        >`
      : html``;
  }

  // eslint-disable-next-line class-methods-use-this
  _handleChange(evt) {
    /** Remove path and replace by searchParam */
    const item = first(evt.detail.selectedItems);
    const _path = item.path;
    if (_path) {
      this._selectedMenuItem = _path;
      navigateTo(`${this._mainRoute}${_path}`); //
      this._currentSubPath = _path;
      this.requestUpdate();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  switchStation(to) {
    const query = getSearchParamString();
    const toReplace = `${getCurrentPath().split('/station/')[0]}/station/${
      to.station_id
    }/${to.station_name || to.station_longname}${query}`;
    navigateTo(toReplace);
  }

  goToStation(event) {
    const station = find(this._stations, {
      station_id: event.target.selectedOption.value,
    });
    station && this.switchStation(station);
  }

  nextFavStation() {
    const favSta = this._stations.filter(s =>
      this.__favouriteStationIds.includes(s.station_id),
    );

    const nextItem = _getNextItem(favSta, this.stationId);

    nextItem && this.switchStation(nextItem);
  }

  prevFavStation() {
    const favSta = this._stations.filter(s =>
      this.__favouriteStationIds.includes(s.station_id),
    );

    const prevItem = _getPreviousItem(favSta, this.stationId);
    prevItem && this.switchStation(prevItem);
  }

  nextStation() {
    const nextItem = _getNextItem(this._sortedStations, this.stationId);
    nextItem && this.switchStation(nextItem);
  }

  prevStation() {
    const prevItem = _getPreviousItem(this._sortedStations, this.stationId);
    prevItem && this.switchStation(prevItem);
  }

  isFavorite() {
    return this.__favouriteStationIds.indexOf(this._station?.station_id) >= 0;
  }

  isSelected(station) {
    return this._station?.station_id === station?.station_id ?? '';
  }

  getFavoriteList() {
    const favoriteStations = [];
    const seenStationIds = new Set();

    for (const station of this._stations) {
      if (this.__favouriteStationIds.includes(station.station_id)) {
        if (!seenStationIds.has(station.station_id)) {
          favoriteStations.push(station);
          seenStationIds.add(station.station_id);
        }
      }
    }
    return favoriteStations;
  }

  getTitle() {
    return this._stations && this.isFavorite()
      ? html`<ui5-select
          @change="${this.goToStation}"
          class="stationselect"
          value="${this._station?.station_id}"
        >
          ${this.getFavoriteList().map(
            station =>
              html`<ui5-option
                value="${station?.station_id}"
                .selected="${this.isSelected(station)}"
                >${station?.station_longname ||
                station?.station_name}</ui5-option
              >`,
          )}
        </ui5-select>`
      : html`<span
          >${this._station?.station_longname ||
          this._station?.station_name ||
          ''}</span
        >`;
  }

  _goToParent() {
    navigateTo(this._mainRoute.split('/').slice(0, 3).join('/') + this._search);
  }

  _goToStationMenu() {
    this._selectedMenuItem = null;
    navigateTo(this._mainRoute + this._search);
  }

  render() {
    // language=html
    return html`
      ${this.searchParams?.headless
        ? html``
        : html`<div class="left">
            <div class="header">
              <ki-icon
                class="back-btn icon-btn"
                icon="ki ki-angle-left"
                title="${this.i18n.t('back')}"
                @click="${this._goToParent}"
              ></ki-icon>
              <div class="titleContainer">
                <ki-icon
                  class="icon-btn fav-navbtn ${this.isFavorite()
                    ? ''
                    : 'hidden'}"
                  title="${this.i18n.t('previousFavStation')}"
                  icon="ki ki-angle-left"
                  @click="${this.prevFavStation}"
                ></ki-icon>

                <div class="title">${this.getTitle()}</div>
                ${this.hideFavorite
                  ? ''
                  : html`<ki-icon
                      class="icon-btn fav-navbtn ${this.isFavorite()
                        ? ''
                        : 'hidden'}"
                      title="${this.i18n.t('nextFavStation')}"
                      icon="ki ki-angle-right"
                      @click="${this.nextFavStation}"
                    ></ki-icon>`}
              </div>
              <ki-icon
                class="icon-btn fav ${this.hideFavorite ? 'hidden' : ''}"
                @click="${this._toggleFavourite}"
                title="${this.isFavorite()
                  ? this.i18n.t('removeFav')
                  : this.i18n.t('addFav')}"
                icon="ki ${this.isFavorite() ? 'ki-star' : 'ki-star-o'}"
              ></ki-icon>
            </div>
            <div class="map-container">
              <ki-station-map
                hideAttributions
                .view="${this._mapConfig?.view}"
                persistancetimeout=""
                .layers="${this._mapConfig?.layers ||
                this._mapConfig?.stationDetailLayers ||
                []}"
              >
                <ol-vector-layer
                  z-index="111"
                  .features="${[this.stationPositionMarker]}"
                ></ol-vector-layer>
                ${this.showMiniMap
                  ? html`<ki-station-map-overview-control
                      label="overview"
                      .layerOptions="${this._mapConfig?.overViewLayer}"
                    ></ki-station-map-overview-control>`
                  : html``}
              </ki-station-map>
            </div>
            <div class="prevenxt-container">
              <div class="prevnext-button-container">
                <ki-icon
                  class="icon-btn"
                  icon="ki ki-angle-left"
                  title="${this.i18n.t('previousStation')}"
                  @click="${this.prevStation}"
                ></ki-icon>
              </div>
              <div class="prevnext-title-container">
                ${this._station?.station_longname ||
                this._station?.station_name}
              </div>
              <div class="prevnext-button-container">
                <ki-icon
                  class="icon-btn"
                  icon="ki ki-angle-right"
                  title="${this.i18n.t('nextStation')}"
                  @click="${this.nextStation}"
                ></ki-icon>
              </div>
            </div>
            ${this._renderStatusBar()}
            <div class="list-container">${this._renderLeftMenu()}</div>
          </div>`}

      <div class="main ${this._selectedMenuItem ? '' : 'hide'}">
        <div class="graph-title">
          <!--only for mobile-->
          <svg
            @click="${this._goToStationMenu}"
            class="toggle-graph-btn icon-btn back-btn-mobile"
            xmlns="http://www.w3.org/2000/svg"
            baseProfile="tiny"
            viewBox="0 0 18 18"
          >
            <path
              d="M18 9a9 9 0 1 0-18.001.001A9 9 0 0 0 18 9zm-13.51.02L10.09 3l1.38 1.46-4.22 4.55 4.25 4.53L10.13 15 4.49 9.02z"
            />
          </svg>
        </div>
        <slot></slot>
      </div>
    `;
  }

  _toggleFavourite() {
    if (this.__favouriteStationIds.indexOf(this._station?.station_id) === -1) {
      this.__favouriteStationIds = [
        ...this.__favouriteStationIds,
        this._station?.station_id,
      ];
    } else {
      this.__favouriteStationIds = this.__favouriteStationIds.filter(
        id => id !== this._station?.station_id,
      );
    }
  }

  /** Remark Attribute / Additional station info (red text above left menu)
   * @param remarkAttr is defined in app.json (appConfig)
   */
  _renderStatusBar() {
    if (this._station && this._station[this.remarkAttr]) {
      return html`<div class="status-bar">
        <span
          >${unsafeHTML(
            this._station[this.remarkAttr].replace(/<br\s*\/?>/gi, ''),
          )}</span
        >
      </div>`;
    }
    return '';
  }

  get stationPositionMarker() {
    const loc = this._station?.point || [0, 0];
    const marker = new Feature(new Point(loc));
    marker.setStyle(
      new Style({
        image: new FontIcon({
          backgroundColor: 'rgba(0,0,0,0)',
          color: 'red',
          icon: 'ki ki-map-marker',
        }),
      }),
    );
    return marker;
  }

  async _menuFilter(menu) {
    const station = this._station;
    const preFilteringResult = menu.filter(menuItem => {
      if (typeof menuItem.filter === 'string') {
        return station?.timeseries.some(ts =>
          menuItem.filter ? menuItem.filter === ts.station_parameter : true,
        );
      }

      // Filter array is OR - logic (one criterium must match to pass!)
      if (Array.isArray(menuItem.filter)) {
        let ret = true;
        for (const [key, value] of Object.entries(menuItem.filter)) {
          ret =
            (ret && station[key] === value) ||
            (ret && station[key] && value === '*');
        }
        return menuItem.filter.some(filter =>
          station?.timeseries.some(ts =>
            filter ? filter === ts.station_parameter : true,
          ),
        );
      }

      // Filter object is AND - logic (all criterias must match to pass!)
      if (typeof menuItem.filter === 'object' && menuItem.filter !== null) {
        let ret = true;
        each(menuItem.filter, (val, key) => {
          if (key === 'parameter') {
            ret =
              ret &&
              val.every(item =>
                station?._links.some(link => link.station_parameter === item),
              );
          } else if (key === 'anyparameter') {
            ret =
              ret &&
              val.some(item =>
                station?._links.some(link => link.station_parameter === item),
              );
          } else if (key === 'href') {
            ret =
              ret &&
              val.some(item =>
                station?._links.some(link => link.href.includes(item)),
              );
          } else if (Array.isArray(val)) {
            ret = ret && val.some(cond => station[key] === cond);
          } else if (val.charAt && val.charAt(0) === '!') {
            ret = ret && station[key] && station[key] !== val.substring(1);
          } else {
            ret =
              (ret && station[key] && val === '*') ||
              (ret && station[key] === val) ||
              station?._links.some(item => item[key] === val);
          }
        });
        return ret;
      }
      return true;
    });

    const finalFilterResult = await this._customerFilter(preFilteringResult);
    return finalFilterResult;
  }

  /** Overwrite by customer specific implementation
   * @example ww-hlnug -> `wwp-station-detail-hlnug` */
  // eslint-disable-next-line class-methods-use-this
  async _customerFilter(menu) {
    return menu;
  }

  async loadDynamicMenu() {
    if (!this.dynMenu) {
      return [];
    }
    try {
      let module;
      /** TODO: Generalized dynamic menu  */
      const className = this.dynMenu?.className || 'dynMenu';
      console.debug('Load DynMenu: ', className);
      if (className === 'dynMenu') module = await import(`./dynMenu.js`);
      else if (className === 'dynMenuLanuv')
        module = await import('./dynMenuLanuv.js');
      else if (className === 'dynMenuGeneral')
        // e.g. ERAMET
        module = await import('./dynMenuGeneral.js');
      else console.error('Dynamic menu not found:', className);
      const items = await module.getMenuItems(
        this._station,
        this.dynMenu,
        this.dynMenu?.menuTemplate,
      );
      return items;
    } catch (e) {
      console.error('Error loading dynamic menu', e);
      return [];
    }
  }

  async fetchData(layerName) {
    if (this.api && this.stationId) {
      this._stations = await this.api.getStations();
      if (!this._stations) console.warn('(!) No stations found');
      this._sortedStations = this._stations.sort((a, b) => {
        const aId = parseFloat(a.station_id);
        const bId = parseFloat(b.station_id);
        return aId > bId;
      });

      this._station = await this.api.getStation(this.stationId);

      if (!this._station)
        console.warn(
          '(!) Could not resolve station by stationId ',
          this.stationId,
          ' | Please check if you confuse station_id with station_no and if the station is available | Station: ',
          this._station,
          '| Station: null indicates, that there is an error in the data structure',
        );

      await this._fetchMapData();

      if (
        this.parameterFilterAttribute &&
        this._station[this.parameterFilterAttribute]
      ) {
        try {
          const filterList = JSON.parse(
            this._station[this.parameterFilterAttribute],
          );
          if (this._station && filterList.length) {
            this._station.timeseries = this._station.timeseries.filter(ts =>
              filterList.includes(ts.station_parameter),
            );
            this._station._links = this._station._links.filter(ts =>
              filterList.includes(ts.station_parameter),
            );
          }
        } catch (e) {
          console.error('invalid parameterFilterAttribute');
        }
      }
      const dynMenuItems = await this.loadDynamicMenu();
      const dynMenuSubPaths = [];
      dynMenuItems.forEach(item => {
        if (item.items) {
          item.items.forEach(i => dynMenuSubPaths.push(i));
        }
      });
      this.currentRoute.children = this.currentRoute.children.concat(
        dynMenuItems.concat(dynMenuSubPaths),
      );
      this.currentRoute.__children = this.currentRoute.__children.concat(
        dynMenuItems.concat(dynMenuSubPaths),
      );

      /** Append dynamically loaded items */
      this._menuList = (await this._menuFilter(this._menuListRaw)).concat(
        dynMenuItems,
      );
      if (!this._menuList.some(item => item.path === this._selectedMenuItem)) {
        this._selectedMenuItem = null;
      }

      /** If no item is specified via URL */
      if (!this._selectedMenuItem) {
        /** Create iterable array from menu-tree-structure */
        const _flattenedList: Array<object> = flatten(
          this._menuList.map(i => (i.items ? concat(i, i.items) : i)),
        );
        /**  */

        this._selectedMenuItem = this._findSelection(_flattenedList, layerName);
      }

      if (this.mobileAutoForward || ViewPort.size !== SM) {
        setTimeout(() => {
          navigateTo(`${this._mainRoute}${this._selectedMenuItem}`);
        }, 10);
      } else {
        setTimeout(() => {
          navigateTo(`${this._mainRoute}${this._selectedMenuItem}`);
        }, 10);
      }

      this.requestUpdate();
    }
  }

  async _fetchMapData() {
    const mapInfo = await this.api.getMapConfig();
    this._mapConfig = mapInfo;
    registerProjections(this._mapConfig);
    reproject([this._station], mapInfo); // check if we can do auto reproject in map.
    this._mapConfig.view.center = this._station?.point;
    this._mapConfig.view.zoom =
      this._mapConfig.view.stationDetailZoom || this._mapConfig.view.zoom;
  }

  /**
   * Find and return the path of the selection to select and load the desired component
   * @param list - The list of items to search through
   * @param layerName - The name of the selected layer
   * @returns The path of the selected (or assumed) menu item
   */
  _findSelection(
    list: {
      detailSelection?: string;
      aliasKeys?: string[];
      label?: string;
      path: string;
    }[],
    layerName: string,
  ): string {
    // Try to match layerName with path
    const resultItemPath =
      find(list, { detailSelection: `${layerName}` })?.path ||
      find(list, item => {
        const aliasKeys = item.aliasKeys || [];
        aliasKeys.push(item.label || '');
        aliasKeys.push(item.path.replace('/', ''));
        return aliasKeys.includes(layerName);
      })?.path;

    // Fallback to default selection which can be set via options or to the first item in the list, usually station-info
    if (!resultItemPath) {
      return this.defaultSelection || this._menuList[0]?.path || '';
    }

    return resultItemPath;
  }
}
