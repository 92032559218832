import MultiComboBox, {
  IMultiComboBoxItem,
} from '@ui5/webcomponents/dist/MultiComboBox';
import { customElement } from 'lit/decorators.js';
/*
 * work around to solve performance issue of ui5 combobox.
 * */
@customElement('ki-multi-select')
export default class KiMultiSelect extends MultiComboBox {
  originFilteredItems: Array<IMultiComboBoxItem> = [];

  changeOnBlur = false;

  _beforeOpen() {
    // workaround
    if (this.filterSelected) {
      this._filteredItems = this.items;
    }
    super._beforeOpen();
  }

  onBeforeRendering() {
    super.onBeforeRendering();
    this.originFilteredItems = this._filteredItems;
    if (!this.filterSelected) {
      this._filteredItems = this.originFilteredItems.slice(0, 100);
    }
  }

  onAfterRendering() {
    const re = super.onAfterRendering();
    if (this.allItemsPopover) {
      const allLoaded =
        this.originFilteredItems.length === this._filteredItems.length;
      let moreEle = this.allItemsPopover.querySelector('#more');
      if (!moreEle && !allLoaded) {
        moreEle = document.createElement('div');
        moreEle.setAttribute('id', 'more');
        moreEle.setAttribute('style', 'color: lightgrey;padding:10px;');
        moreEle.innerHTML = '--- Too Many Items --- ';
        this.allItemsPopover.appendChild(moreEle);
      } else if (moreEle && allLoaded) {
        moreEle.remove();
      }
    }

    return re;
  }

  async connectedCallback() {
    super.connectedCallback?.();
    if (this.changeOnBlur) {
      this.addEventListener('selection-change', () => {
        if (!this.open) {
          this.dispatchEvent(new CustomEvent('val-change', {}));
        }
      });
      this.addEventListener('open-change', () => {
        if (!this.open) {
          this.dispatchEvent(new CustomEvent('val-change', {}));
        }
      });
    } else {
      this.addEventListener('selection-change', () => {
        this.dispatchEvent(new CustomEvent('val-change', {}));
      });
    }
  }
}
