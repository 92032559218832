/* eslint-disable max-classes-per-file */
import {
  filter,
  countBy,
  pickBy,
  every,
  orderBy,
  groupBy,
  forEach,
  concat,
  isEmpty,
  findIndex,
  cloneDeep,
} from 'lodash-es';
import { Subscription } from 'rxjs/internal/Subscription';

import { html, LitElement } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { navigateTo } from '../ki-app/routing/ki-router';
import { translateLabels } from '../../services/Translate/translater';

// eslint-disable-next-line import/no-extraneous-dependencies
import {
  localStorageMixin,
  responsiveMixin,
  i18nMixin,
  getQueryObject,
  queryParamMixin,
  ViewPort,
  SM,
} from '../../decorators';
import {
  LoaderMixin,
  Mix,
  PropertyDefaultValue,
  downloadFile,
  dayjs,
  downloadCSV,
} from '../../common';

import LegendFactory from '../ki-station-map/LegendFactory';
import Classifier from '../ki-station-map/Classifier';
import { StatusIcon } from '../ki-status-icon/ki-status-icon';
import { getTrendArrow } from '../../common/base';
import { getRouteOptionsAndParams, getCsvDelimiter } from '../ki-app';

import '@ui5/webcomponents/dist/Toast';

import { getCurrentApi, getCurrentApiType } from '../../api';
import { WiskiWebInfoService } from '../../services';

import nls from '../../locales/index';
import style from './wwp-overview.css?inline';

import { reproject, registerProjections } from '../../common/maputils';
import '../ki-responsive-table/ki-responsive-table';

import '../ki-wcp-wwp-filter/ki-wcp-wwp-advance-filter';

import '../ww-popup/ww-map-popup';
import '../ww-popup/ww-map-popup-auto';
import '../ww-popup/ww-map-popup-base';
import '../ww-popup/ww-map-popup-precip';
import '../ww-popup/ww-map-popup-dynamic';

import '../ki-wwp-graph/ki-wwp-sparkline';
import { SortedBy } from './SortedBy';
import { Filter } from '../ki-wcp-wwp-filter/Filter';
import { goHome } from '../../services/HomingService';

@customElement('wwp-overview')
export default class WwpOverview extends Mix(
  LitElement,
  LoaderMixin,
  PropertyDefaultValue,
  [
    localStorageMixin,
    { targetProperty: '__favouriteStationIds', typeParam: Array },
  ],
  [
    queryParamMixin,
    { targetProperty: 'viewSelection', selector: 'mode', defaultValue: 'map' },
  ],
  [
    queryParamMixin,
    {
      targetProperty: 'filterContent',
      selector: 'filter',
      defaultValue: '{}',
    },
  ],
  [
    queryParamMixin,
    {
      targetProperty: 'searchContent',
      selector: 'search',
      defaultValue: '',
    },
  ],
  responsiveMixin,
  [i18nMixin, { nls }],
) {
  @query('#map')
  stationMap;

  static styles = style;

  @property({ type: Boolean })
  strictSearch: boolean = false;

  @property({ type: Boolean })
  layersToggleAll: boolean = false;

  @property({ type: Boolean })
  layersTreeList: boolean = false;

  @property({ type: String })
  layerName: string = 'Not defined';

  @property({ type: Boolean })
  showCsvExport: boolean = false;

  // download data table with underling sample data
  @property({ type: Boolean })
  includeSampleData: boolean = false;

  // configure sample Data columns to be downloaded.
  @property({ type: Array })
  sampleDataItems: any[] = [];

  // Disable the sample download button if station result greater than the defined number.
  @property({ type: Number })
  maximumStation: number = 5;

  @property({ type: Object })
  protected _dataLayer = {};

  @property({ type: Array })
  homeExtent: any[] = [];

  @property({ type: Array })
  positionProjections: any[] = [];

  @property({ type: Array })
  protected _legends: any[] = [];

  @property({ type: Boolean, attribute: false })
  displayStationsInLayerControl = true;

  @property({ type: Object, attribute: false })
  protected _view = {
    projection: 'EPSG:3857',
    zoom: 8,
  };

  @property({ type: Array, attribute: false })
  layerGroups: any[] = [
    'Layers',
    'Information',
    { name: 'Backgrounds', type: 'radio' },
  ];

  @query('#zoom-control-element')
  zoomControl;

  _firstWWVisit = 'yes';

  _enableAutoZoom = false;

  tzFormat = 'zzz';

  zoomReset = false;

  refreshTimer = true;

  _filterContent = '{}';

  _filteredRawData = [];

  currentStationExtent = null;

  mapsLoaded = false;

  @property({ type: Boolean })
  hideFavorite = false;

  @property({ type: Boolean })
  allowViewmodeOverview = false;

  subscription: Subscription | undefined;

  /** Unsafe HTML visualized in right-bottom side of map */
  @property({ type: String })
  mapRemark;

  @state()
  private _downloading: boolean = false;

  get filterContent() {
    return this._filterContent;
  }

  set filterContent(value) {
    this._filterContent = value;
    this.requestUpdate();
  }

  // eslint-disable-next-line no-undef
  cachePrefix = VITE_APP_NAME;

  versionInfo = new WiskiWebInfoService(this.i18n);

  _searchContent = '';

  /** Search via Searchbar will be performed on the selected station properties
   * @remark Modify via "Options" in `app.json`
   */
  searchbarProperties: string[] = [
    'station_longname',
    'station_name',
    'station_no',
  ];

  get searchContent() {
    return this._searchContent;
  }

  set searchContent(value) {
    this._searchContent = value;
    this.requestUpdate();
  }

  _viewSelection: 'map' | 'table' | 'overview' = 'map';

  api = getCurrentApi();

  get viewSelection() {
    return this._viewSelection;
  }

  set viewSelection(value) {
    this._viewSelection = value;
    this.requestUpdate();
  }

  ___favouriteStationIds = [];

  get __favouriteStationIds() {
    return this.___favouriteStationIds;
  }

  set __favouriteStationIds(value) {
    this.___favouriteStationIds = value;
    this.requestUpdate();
  }

  get searchBox() {
    return getQueryObject(this, '#searchbox');
  }

  overViewLayer = {
    layer: [],
  };

  connectedCallback() {
    if (super.connectedCallback) super.connectedCallback();
    this.addEventListener('toggledPopupColumnHider', this._toggleLegend);

    this._loader._$loaderCount = 1;
    this.refresh = setInterval(() => {
      if (this.refreshTimer) {
        this.getDataLayer(true);
      }
    }, 300000);
    this._firstWWVisit = sessionStorage.getItem('firstWWVisit');
    console.debug(getCurrentApiType());

    this.subscription = goHome.subscribe(() => this.zoomControl?.zoomToHome());
  }

  disconnectedCallback() {
    if (super.disconnectedCallback) super.disconnectedCallback();
    this.removeEventListener('toggledPopupColumnHider', this._toggleLegend);
    clearInterval(this.refresh);
    this.subscription?.unsubscribe();
  }

  _toggleLegend(e) {
    const l = this.shadowRoot.querySelector('#overview-legend');
    if (l && e.detail?.visible === true) l.collapsed = true;
  }

  exportTable() {
    const table = getQueryObject(this, 'ki-responsive-table');
    console.debug('table found?', table);

    if (table) {
      navigator.clipboard.writeText(table.toCSV(getCsvDelimiter())).then(
        () => {
          getQueryObject(this, '#DownloadSuccessToast').show();
        },
        () => {
          getQueryObject(this, '#DownloadFailureToast').show();
        },
      );
    } else {
      getQueryObject(this, '#DownloadNoTableToast').show();
    }
  }

  async downloadSampleData() {
    // const stations = uniqBy(this._filteredRawData, "station_id")
    this._downloading = true;
    const filteredStations = cloneDeep(this.filteredStations);

    const { stationparameter_name } = JSON.parse(this.filterContent);
    // get the parameter shortname from parameter name
    const stationparameter_shortname = stationparameter_name?.map(
      p =>
        this._filteredRawData.find(d => d.stationparameter_name === p)
          ?.stationparameter_shortname,
    );

    const stationData = await Promise.all(
      filteredStations.map(s => this.api.getStation(s.station_id)),
    );

    const sampleDataLink = [];
    forEach(stationData, s => {
      s?.timeseries &&
        forEach(s.timeseries, ts => {
          // filter parameter
          if (
            !stationparameter_shortname ||
            stationparameter_shortname.includes(ts.station_parameter)
          ) {
            ts.href && sampleDataLink.push(ts.href);
          }
        });
    });
    // console.debug( stations,stationData,sampleDataLink);
    const sampleData = await Promise.all(
      sampleDataLink.map(link => this.api.getTsData(link)),
    );

    const resultData = { metadata: null, data: [] };

    const additionalColumns = []; // not needed for now.
    forEach(sampleData, s => {
      let sampleDataColumns = s.columns
        .split(',')
        .map(c => ({ field: c, label: c }));
      if (!isEmpty(this.sampleDataItems)) {
        sampleDataColumns = this.sampleDataItems;
      }
      if (!resultData.metadata) {
        resultData.metadata = [
          [
            ...this.config.columns.map(c => c.label),
            ...additionalColumns.map(c => c.label),
            ...sampleDataColumns.map(c => c.label),
          ],
        ];
      }
      resultData.data = concat(
        resultData.data,
        s.data?.map(d => {
          const station = filteredStations.find(
            sta => sta.station_id === s.station_id,
          ); // last measurement date is not exist in sample data
          return [
            ...this.config.columns.map(c => station[c.field]),
            ...additionalColumns.map(c => s[c.field]),
            ...sampleDataColumns.map(
              c => d[findIndex(sampleDataColumns, s => s.field === c.field)],
            ),
          ];
        }),
      );
    });

    downloadCSV(resultData, { name: 'stations_sample_data' });
    this._downloading = false;
    // console.debug(resultData)
  }

  triggerCSVDownload() {
    const table = getQueryObject(this, 'ki-responsive-table');
    if (table) {
      downloadFile(
        table.toCSV(getCsvDelimiter()),
        `station_overview_table.csv`,
      );
    }
  }

  get _switcherOptions() {
    const options = [
      { label: 'Map', value: 'map', icon: 'ki ki-map' },
      { label: 'Table', value: 'table', icon: 'ki ki-table' },
    ];
    if (this.allowViewmodeOverview)
      options.push({
        label: 'Overview',
        value: 'overview',
        icon: 'ki ki-widgets2',
      });
    return options.map(tab => ({
      label: this.i18n.t(tab.label),
      value: tab.value,
      icon: tab.icon,
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  get localStorageId() {
    return `wwp-favorites-ids`;
  }

  /**
   * Create handle during rendering
   * Pass handle to table to avoid overlapping of menu elements over popup
   */
  initPopupContainer() {
    const elem = document.createElement('div');
    elem.id = 'popupContainer';
    this.popupContainerHandle = elem;
    return html`${elem}`;
  }

  // Vaadin Router Method that gets called after this component is called
  // eslint-disable-next-line no-unused-vars
  onAfterEnter(location) {
    this.filterContent = '{}';
    const params = getRouteOptionsAndParams(location, ['layerName']);
    this.layerName = params.layerName;
    Object.assign(this, params.options);
  }

  // eslint-disable-next-line class-methods-use-this
  additionalContent() {
    return html``;
  }

  // eslint-disable-next-line class-methods-use-this
  parseData(data) {
    return data;
  }

  enableAutoZoom() {
    this._enableAutoZoom = true;
    setTimeout(() => {
      this._enableAutoZoom = false;
    }, 50);
  }

  render() {
    // eslint-disable-next-line wc/no-self-class
    this.classList.toggle('table', this.viewSelection === 'table');
    // TODO rename ki-station-layer to ki-maker-layer or value layer ?
    // language=html
    if (this.currentLayer !== this.layerName) {
      this.getDataLayer();
    }
    return (
      this._renderLoader() ||
      html`
        ${this.additionalContent()} ${this.initPopupContainer()}
        ${this._renderSearchbox()}
        ${this.showCsvExport && this.viewSelection === 'table'
          ? this.renderExportButton()
          : html``}

        <ki-wcp-wwp-advance-filter
          .mode="${this._dataLayer.config?.filterMode}"
          .cascade="${this._dataLayer.config?.cascade}"
          .hideFavorite="${this.hideFavorite}"
          .favorites="${this.__favouriteStationIds}"
          .activeFilter="${this.filterContent}"
          .allStations="${this._dataLayer.data}"
          .stations="${this.filteredStations}"
          .filters="${this.quickFilters}"
          .filteredRawData="${this._filteredRawData}"
          @change="${e => {
            this.enableAutoZoom();
            this.filterContent = JSON.stringify(e.detail.value);
          }}"
        >
        </ki-wcp-wwp-advance-filter>

        ${this.searchParams?.headless
          ? html``
          : html`<ki-switcher
              id="map-table-switcher"
              .options="${this._switcherOptions}"
              .value="${this.viewSelection}"
              @changed="${e => {
                this._enableAutoZoom = true;
                this.viewSelection = e.detail.value;
              }}"
            ></ki-switcher>`}
        <ki-stack class="slide-left" .selected="${this.viewSelection}">
          <div class="station-list" key="table">
            ${this.viewSelection === 'table' ? this.renderTable() : html``}
          </div>
          <div class="map-container" key="map">
            ${this.viewSelection === 'map' ? this.renderMap() : html``}
          </div>
          <div class="overview-container" key="overview">
            ${this.viewSelection === 'overview' && this.allowViewmodeOverview
              ? this.renderOverview()
              : html``}
          </div>
        </ki-stack>
        <ki-legend
          closeable
          .options="${this.legendOptions}"
          @changed="${this.legendChanged}"
          id="overview-legend"
        ></ki-legend>
        <div class="lastUpdate" title="${this.versionInfo.getInfoAsString()}">
          ${this.mapLastSyncedLabel
            ? this.mapLastSyncedLabel
            : this.i18n.t('lastupdated')}:
          ${dayjs.tz(this._dataLayer.creationDateInMillis).format('L LT')}
          ${this.__getTimezone()}
        </div>
        <div class="mapRemark">${unsafeHTML(this.mapRemark)}</div>
      `
    );
  }

  __getTimezone() {
    if (this.tzFormat) {
      return this.i18n.exists(
        encodeURIComponent(dayjs().tz().format(this.tzFormat)),
      )
        ? this.i18n.t(encodeURIComponent(dayjs().tz().format(this.tzFormat)))
        : dayjs().tz().format(this.tzFormat);
    }
    return '';
  }

  _renderSearchbox() {
    return html` <ki-searchbox
      placeholder="${this.i18n.t('SEARCHBAR.stationsearch')}"
      id="overviewSearchBox"
      .value="${this.searchContent}"
      @search="${e => {
        this.enableAutoZoom();
        this.searchContent = e.detail.value;
      }}"
    >
    </ki-searchbox>`;
  }

  renderExportButton() {
    return html`
      <div class="export-btn-group">
        ${this.includeSampleData
          ? html`
              <div
                title="${this.filteredStations?.length > this.maximumStation
                  ? `Please use the filter to download sample data for a maximum of ${this.maximumStation} stations.`
                  : ''}"
              >
                <ki-icon-btn
                  class="downloadButton"
                  icon="ki ki-download"
                  ?disabled="${this.filteredStations?.length >
                    this.maximumStation || this._downloading}"
                  title="Download sample data"
                  @click="${this.downloadSampleData}"
                  ?loading="${this._downloading}"
                ></ki-icon-btn>
              </div>
            `
          : html`
              <ki-icon-btn
                class="downloadButton"
                id="downloadButton"
                icon="ki ki-download"
                title="Download table"
                @click="${this.triggerCSVDownload}"
              ></ki-icon-btn>
              <ki-icon-btn
                class="ripple"
                id="copyToClipboardButton"
                icon="ki ki-clipboard"
                title="Copy table to clipboard"
                @click="${this.exportTable}"
              ></ki-icon-btn>
            `}
      </div>
      <ui5-toast id="DownloadSuccessToast" class="success-toast"
        >${this.i18n.t('copyTableMessage')}
      </ui5-toast>
      <ui5-toast id="DownloadNoTableToast" class="failure-toast"
        >${this.i18n.t('copyTableNoTableMessage')}
      </ui5-toast>
      <ui5-toast id="DownloadFailureToast" class="failure-toast"
        >${this.i18n.t('copyTableFailureMessage')}
      </ui5-toast>
    `;
  }

  get filteredStations() {
    const regex = new RegExp(
      `${
        this.strictSearch
          ? `^${this.searchContent.replaceAll('*', '.')}`
          : this.searchContent.replaceAll('*', '.')
      }`,
      'i',
    );
    let filterOptions = JSON.parse(this.filterContent);

    if (filterOptions && this._dataLayer.config.filter) {
      filterOptions = pickBy(filterOptions, (value, key) =>
        this._dataLayer.config.filter.some(
          f => f.field === key || key === 'fav',
        ),
      );
    }

    // search on raw data instead grouped stations
    // work around for filtering by ts related fields
    // might have bug, filtered stations still contains value of params which should be filter out
    // better solution need rework the data workflow
    let filteredData = this._dataLayer.rawData || this._dataLayer.data || [];

    // filter with searchContent
    filteredData = filteredData.filter(s =>
      this.searchbarProperties.some(property => regex.test(s[property] || '')),
    );

    if (filterOptions.fav) {
      filteredData = filteredData.filter(s =>
        this.__favouriteStationIds.includes(s.station_id),
      );
    }
    delete filterOptions.fav;
    const filterConfig = this.config?.filter || [];

    // filter values by filter options
    filteredData = filter(filteredData, obj =>
      every(filterConfig, ({ type, field, formatPattern }: Filter) => {
        const condVal = filterOptions[field];
        const value = obj[field];
        if (!condVal) {
          return true;
        }
        switch (type) {
          case 'multiselect': {
            return condVal.includes(value?.toString()) || condVal.length === 0;
          }

          case 'date-range': {
            const [startDate, endDate] = condVal
              .split('~')
              .map(date => dayjs(date.trim(), formatPattern || 'dd/MM/YYYY'));
            const dateValue = dayjs(value);
            return (
              dateValue.isSameOrAfter(startDate) && dateValue.isBefore(endDate)
            );
          }

          default:
            return value === condVal;
        }
      }),
    );

    // rawdata don't have __tag filed when filtering . so use station data when toggle by legends
    // search on raw data instead grouped stations
    // work around for filtering by ts related fields
    // might have bug, filtered stations still contains value of params which should be filter out
    // better solution need rework the data workflow
    // filteredData = filteredData.filter(s => {
    //   const tag = s.__tag;
    //   if (tag && this._legends.length > 0) {
    //     return tag.split('|').every(t => this._legends.indexOf(t) !== -1);
    //   }
    //   return true;
    // });
    const activedStationIds = Object.keys(
      groupBy(
        this._dataLayer?.data?.filter(s => {
          const tag = s.__tag;
          if (tag && this._legends.length > 0) {
            return tag.split('|').every(t => this._legends.indexOf(t) !== -1);
          }
          return true;
        }) || [],
        'station_id',
      ),
    );

    this._filteredRawData = filteredData;

    // get filtered station ids and get the stations from the data (all stations)

    const ids = Object.keys(groupBy(filteredData, 'station_id'));

    const filteredStations = this._dataLayer.data.filter(
      s =>
        ids.includes(s.station_id) && activedStationIds.includes(s.station_id),
    );
    // FilteredStations', filterOptions, filteredStations);
    return filteredStations;
  }

  get quickFilters() {
    return this._dataLayer.config?.filter || [];
  }

  get popup() {
    return this._dataLayer.config?.popup || 'ww-map-popup-auto';
  }

  get popupOptions() {
    return this._dataLayer.config?.popupOptions || {};
  }

  get sortedBy(): Array<SortedBy> {
    return this._dataLayer.config?.sortedBy;
  }

  get config() {
    return this._dataLayer.config;
  }

  // Presort by
  renderTable() {
    return html` <ki-responsive-table
      .data="${this._initialOrder(this.filteredStations)}"
      idproperty="station_id"
      .layerAlias="${this.layerName}"
      .popupComponent="${this.popup}"
      .popupOptions=${this.popupOptions}
      sort="${this.sortedBy
        ? JSON.stringify(this.sortedBy)
        : '[{"field":"station_name", "ascending":true}]'}"
      .columns="${this._dataLayer.config.columns}"
      .tablePopup="${this._dataLayer.config.tablePopup}"
      .formatters="${this.formatters}"
      .popupContainer="${this.popupContainerHandle}"
      @row-click="${this.showStation}"
    ></ki-responsive-table>`;
  }

  /** Defines sorting on first view */
  _initialOrder(filteredStations: Array<object>) {
    // Transform provided dataset from layercfg
    const fields: Array<string> = []; // field name
    const orders: Array<'asc' | 'desc'> = [];
    if (this.sortedBy && Array.isArray(this.sortedBy)) {
      this.sortedBy.forEach(_sort => {
        _sort.ascending ? orders.push('asc') : orders.push('desc');
        _sort.sortBy ? fields.push(_sort.sortBy) : fields.push(_sort.field);
      });
      return orderBy(filteredStations, fields, orders);
    }
    return filteredStations;
  }

  renderOverview() {
    return html` <ww-simple-dashboard
      id="dashboardOverview"
      stations
      .layerAlias="${this.layerName}"
      .popup="${this.popup}"
      .stations="${this.filteredStations}"
    ></ww-simple-dashboard>`;
  }

  renderMap() {
    return html` <ki-station-map
      id="map"
      .view="${this._view}"
      .layers="${this.mapConfig?.layers || []}"
      style="${this.olBackgroundColor
        ? `background-color: ${this.olBackgroundColor};`
        : ''}"
      persistancetimeout="3600"
      cachePrefix="${this.cachePrefix}"
    >
      ${this._dataLayer.config.popupControl
        ? html` <ki-popup-control></ki-popup-control>`
        : html``}

      <ki-stations-layer
        .displayStationLabelsOnStart="${this.displayStationLabelsOnStart}"
        .displayInLayerControl="${this.displayStationsInLayerControl}"
        .popupComponent="${this.popup}"
        .popupOptions=${this.popupOptions}
        .tagMarkers="${this.legendFactory.markers}"
        .layerAlias="${this.layerName}"
        .labelTemplate="${this._dataLayer.config?.labelTemplate}"
        .trendKey=${this._dataLayer.config?.trendKey}
        .tagSelectedMarkers="${this.legendFactory.selectedMarkers}"
        .config="${this._dataLayer.config}"
        .stations="${this.filteredStations}"
        .dynamicMarkerSize="${this._dataLayer.config?.dynamicMarkerSize}"
        @station-click="${this.showStation}"
        @sourceChange="${e => {
          if (
            this.zoomReset ||
            this._enableAutoZoom ||
            this._firstWWVisit !== 'no'
          ) {
            this.stationMap.zoomTo(
              e.detail.extent,
              this._enableAutoZoom ? 500 : 0,
              this.extentBuffer,
            );
            this._firstWWVisit = 'no';
            sessionStorage.setItem('firstWWVisit', 'no');
          }
        }}"
      >
      </ki-stations-layer>
      <ki-layers-control
        .treeList="${this.layersTreeList}"
        .toggleAll="${this.layersToggleAll}"
        .context="${this.layerName}"
        .i18n=${this.i18n}
        .groups="${this.layerGroups}"
        cachePrefix="${this.cachePrefix}"
      ></ki-layers-control>
      <ki-station-map-zoom-control
        id="zoom-control-element"
        style="right:20px;left:auto;"
        label="map zoom"
        .homeExtent="${ViewPort.size === SM
          ? this.homeExtentMobile
          : this.homeExtent}"
      ></ki-station-map-zoom-control>
      <ki-station-map-position-scale-control
        .projections="${this.positionProjections}"
        label="position and scale"
      ></ki-station-map-position-scale-control>
      <ki-station-map-overview-control
        label="overview"
        .layerOptions="${this.mapConfig?.overViewLayer || { layer: [] }}"
      ></ki-station-map-overview-control>
      <!--     <ki-station-map-raster-control></ki-station-map-raster-control> -->
    </ki-station-map>`;
  }

  // eslint-disable-next-line class-methods-use-this
  showStation(e) {
    // TODO provide route-link ,  route.linkTo
    // TODO site_no ?
    const station = e.detail.data;
    const label = station.station_longname || station.station_name;
    if (station.external && station.projectName)
      /** Go to project detail page, see ww-worldmap
       *  station_id ~ project_id
       * */
      window.open(
        `#/overview/${this.layerName}/${station.station_id}`,
        '_self',
      );
    else if (station.external && station.url) {
      window.open(station.url, '_new');
    } else if (station.URLEXTERN) {
      window.open(station.URLEXTERN, '_new');
    } else {
      let navigationPath = `/overview/${this.layerName}/station/${
        station.station_id
      }/${encodeURIComponent(label)}`;

      let detailPaths =
        this.detailsPaths?.filter(path => path.layerName === this.layerName) ??
        [];

      const anyHits = detailPaths.filter(path => {
        if (!path.anyParameters) {
          return false;
        }

        for (const param of path.anyParameters) {
          if (station.ts_values[param]) {
            return true;
          }
        }
        return false;
      });

      const allHits = detailPaths.filter(path => {
        if (!path.parameters) {
          return false;
        }

        for (const param of path.parameters) {
          if (!station.ts_values[param]) {
            return false;
          }
        }
        return true;
      });

      detailPaths = detailPaths.filter(
        path => !path.anyParameters && !path.parameters,
      );

      let detailPath;

      /* eslint-disable prefer-destructuring */
      if (anyHits.length > 0) {
        detailPath = anyHits[0];
      } else if (allHits.length > 0) {
        detailPath = allHits[0];
      } else if (detailPaths.length > 0) {
        detailPath = detailPaths[0];
      }
      /* eslint-enable prefer-destructuring */

      if (detailPath) {
        navigationPath = `${navigationPath}/${detailPath.detailsPath}`;
      }

      navigateTo(`${navigationPath}?mode=${this.viewSelection}`);
    }
  }

  async getDataLayer(sameLayer: boolean = false) {
    if (!this.mapsLoaded) {
      const mapConfig = await this.api.getMapConfig();
      this.mapConfig = mapConfig;
    }
    if (this.layerName) {
      this.currentLayer = this.layerName;

      let resp;
      try {
        resp = await this.api.getLayer(this.layerName);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(
          `error loading layer "${this.layerName}". Going back to start...`,
        );
        navigateTo('/');
        return;
      }

      try {
        // translate config
        translateLabels(resp.config, this.i18n.language);
      } catch (e) {
        console.warn(`error translating config is not working`);
      }

      if (!resp?.config) {
        // eslint-disable-next-line no-console
        console.warn(
          `layer "${this.layerName}" not found or has no config. Going back to start`,
        );
        navigateTo('/');
        return;
      }

      if (this.zoomReset && sessionStorage) {
        sessionStorage.removeItem(`${this.cachePrefix}-ki-station-map-zoom`);
      }

      resp.data = reproject(resp.data, this.mapConfig);
      // console.time('clientFilter');

      if (this.parameterFilterAttribute) {
        try {
          resp.data.forEach(station => {
            if (station[this.parameterFilterAttribute]) {
              const parFilter = JSON.parse(
                station[this.parameterFilterAttribute],
              );
              if (Array.isArray(parFilter) && parFilter.length !== 0) {
                Object.keys(station.ts_values).forEach(par => {
                  if (!parFilter.includes(par)) {
                    delete station.ts_values[par];
                  }
                });
              }
            }
          });
          resp.data = resp.data.filter(
            item => Object.keys(item.ts_values).length,
          );
        } catch (e) {
          console.error('invalid parameterFilterAttribute');
        }
      }

      resp.data.forEach(item => {
        if (item.ts_value) item.ts_value = parseFloat(item.ts_value);
        if (item.ts_values?.temp?.ts_value)
          item.ts_values.temp.ts_value = parseFloat(
            item.ts_values.temp.ts_value,
          );
      });
      // console.timeEnd('clientFilter');
      const data = new Classifier(resp.config.classification).classify(
        this.parseData(resp.data),
      );
      const tagCount =
        resp.config.classification.tagCount !== false
          ? countBy(data, '__tag')
          : null;
      this._loader._$loaderCount = 0;

      this._dataLayer = {
        config: resp.config,
        data,
        rawData: resp.rawData,
        tagCount,
        creationDateInMillis: resp.creationDateInMillis,
      };
      if (!sameLayer) {
        this._legends = [];
      }
      if (!this.mapsLoaded) {
        registerProjections(this.mapConfig);
        this._view = this.mapConfig.view || this._view;
        this.homeExtent = this.mapConfig.homeExtent;
        this.homeExtentMobile =
          this.mapConfig.homeExtentMobile ?? this.mapConfig.homeExtent;
        this.positionProjections = this.mapConfig.positionProjections;
        this.displayStationsInLayerControl =
          this.mapConfig.displayStationsInLayerControl;
        this.mapsLoaded = true;
      }
      this.requestUpdate();
    }
  }

  get legendFactory() {
    return new LegendFactory(this._dataLayer.config.classification);
  }

  get legendOptions() {
    const opts = this.legendFactory.getLegendOptions();
    return [{ ...opts, tagCount: this._dataLayer.tagCount }];
  }

  get formatters() {
    const factory = this.legendFactory;
    return {
      status(val, locale, col) {
        return StatusIcon(factory.pngs[val], factory.labels[val], col);
      },
      info(val, locale, col) {
        // todo move into options.
        return val
          ? html` <div class="cell" style="text-align: center;">
              <ki-icon
                style="color:rgba(255,221,66,1);"
                title="${val}"
                icon="${col.icon || 'ki ki-info-circle'}"
              ></ki-icon>
            </div>`
          : html` <div></div> `;
      },
      trend(val) {
        const trend =
          val !== null && val !== undefined
            ? `transform: rotateZ(${getTrendArrow(val)}deg)`
            : 'display:none;';
        return html` <div class="cell">
          <ki-icon
            style="font-size:1.5em;${trend}"
            icon="ki ki-arrow-right"
          ></ki-icon>
        </div>`;
      },
      sparkline(val, opts, col, item) {
        const vals = opts.options.values
          .map(value => ({
            unit: item?.ts_values[item.mainParameter]?.ts_unitsymbol,
            name: item?.ts_values[item.mainParameter]
              ?.stationparameter_longname,
            value: item[value],
            date: item[value.replace('ts_value', 'timestamp')],
          }))
          .filter(f => f.value);
        return html` <ki-wwp-sparkline .data="${vals}"></ki-wwp-sparkline>`;
      },
    };
  }

  legendChanged(e) {
    this._legends = e.detail.value;
  }
}
