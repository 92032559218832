import { orderBy, get } from 'lodash-es';
import { isArray } from 'highcharts';
import { template, dayjs } from '../../common';

function getValueRule(
  key: string,
  rule: string | Object,
): (item: Object) => string | number | Array<string | number> | undefined {
  const _getValue = (item: Object) => get(item, key);
  let getValue = (item: Object) => _getValue(item);
  if (typeof rule === 'object') {
    if (Object.prototype.hasOwnProperty.call(rule, 'last')) {
      getValue = item => {
        const values = _getValue(item);
        if ((values?.length ?? 0) > 0) {
          return values[values.length - 1];
        }
        return undefined;
      };
    }

    if (Object.prototype.hasOwnProperty.call(rule, 'first')) {
      getValue = item => {
        const values = _getValue(item);
        if ((values?.length ?? 0) > 0) {
          return values[0];
        }
        return undefined;
      };
    }

    if (Object.prototype.hasOwnProperty.call(rule, 'resultIndex')) {
      const oldGetValue = getValue;
      getValue = item => {
        const values = oldGetValue(item);
        if ((values?.length ?? 0) > 0) {
          return values[rule.resultIndex];
        }
        return undefined;
      };
    }
  }
  return getValue;
}

function getExistsRule(
  propExists: string | Array<string>,
): (item: Object) => boolean {
  return (item: Object) => {
    let cond = propExists;
    if (!isArray(cond)) {
      cond = [cond as string];
    }
    return (cond as Array<string>).every((token: string) => {
      const value = get(item, token);
      return value !== undefined && value !== null && value !== '';
    });
  };
}

function getNegatedRule(
  rule: string | object,
  key = '',
): (item: Object) => boolean {
  // eslint-disable-next-line no-use-before-define
  const internalRule = createRule(key, rule);
  return (item: Object) => !internalRule(item);
}

function getInRule(
  key: string,
  rule: string | object,
): (item: object) => boolean {
  const getValue = getValueRule(key, rule);
  let inArray = rule.in;
  if ((inArray?.length ?? 0) === 0) {
    inArray = [];
  }
  return (item: object) => inArray.indexOf(getValue(item)) >= 0;
}

function getIncludesRule(
  key: string,
  rule: string | object,
): (item: object) => boolean {
  const getValue = getValueRule(key, rule);
  const mustInclude = rule.includes;
  return (item: object) => {
    const values = getValue(item);
    if (!Array.isArray(values) && !(typeof values === 'string')) {
      return false;
    }
    return values.includes(mustInclude);
  };
}

function getExactRule(
  key: string,
  rule: string | object,
): (item: object) => boolean {
  const getValue = getValueRule(key, rule);
  const mustBe = rule.exact;
  return (item: object) => {
    const values = getValue(item);
    if (!Array.isArray(values) || !Array.isArray(mustBe)) {
      return values === mustBe;
    }

    return (
      values.every(value => mustBe.includes(value)) &&
      mustBe.every(value => values.includes(value))
    );
  };
}

function getMinRule(
  key: string,
  rule: string | object,
): (item: object) => boolean {
  const getValue = getValueRule(key, rule);
  const multiplier = rule.multiplier || 1;
  const ruleMin = rule.min;
  const ruleMinString = typeof ruleMin === 'string';
  const ruleAbs = rule.absolute;

  return (item: object) => {
    const min = ruleMinString
      ? template(ruleMin, item)
          ?.replace(',', '.')
          .replace(/[^\d.-]/g, '')
      : ruleMin;

    const _val = getValue(item);
    const value = ruleAbs && typeof _val === 'number' ? Math.abs(_val) : _val;

    return min !== '' && value !== undefined && value >= min * multiplier;
  };
}

function getMaxRule(
  key: string,
  rule: string | object,
): (item: object) => boolean {
  const getValue = getValueRule(key, rule);
  const multiplier = rule.multiplier || 1;
  const ruleMax = rule.max;
  const ruleMaxString = typeof ruleMax === 'string';
  const ruleAbs = rule.absolute;

  return (item: object) => {
    const max = ruleMaxString
      ? template(ruleMax, item)
          ?.replace(',', '.')
          .replace(/[^\d.-]/g, '')
      : ruleMax;

    const _val = getValue(item);
    const value = ruleAbs && typeof _val === 'number' ? Math.abs(_val) : _val;

    return max !== '' && value !== undefined && value <= max * multiplier;
  };
}

function getDurationRule(
  key: string,
  rule: string | object,
): (item: object) => boolean {
  const getValue = getValueRule(key, rule);
  const last = dayjs().tz().subtract(dayjs.duration(rule.duration));
  return (item: object) => {
    if (item.station_no === '22016') {
      console.debug(
        item,
        key,
        getValue(item),
        dayjs(getValue(item)).tz() >= last,
      );
    }
    return dayjs(getValue(item)).tz() >= last;
  };
}

function createRule(key: string, rule: string | object) {
  if (key === 'not') {
    return getNegatedRule(rule);
  }
  if (key === 'exists') {
    return getExistsRule(rule);
  }
  if (rule && typeof rule === 'object') {
    if (Object.prototype.hasOwnProperty.call(rule, 'in')) {
      return getInRule(key, rule);
    }
    if (Object.prototype.hasOwnProperty.call(rule, 'includes')) {
      return getIncludesRule(key, rule);
    }
    if (Object.prototype.hasOwnProperty.call(rule, 'exact')) {
      return getExactRule(key, rule);
    }
    if (
      Object.prototype.hasOwnProperty.call(rule, 'max') &&
      Object.prototype.hasOwnProperty.call(rule, 'min')
    ) {
      const minRule = getMinRule(key, rule);
      const maxRule = getMaxRule(key, rule);
      return (item: object) => minRule(item) && maxRule(item);
    }
    if (Object.prototype.hasOwnProperty.call(rule, 'max')) {
      return getMaxRule(key, rule);
    }
    if (Object.prototype.hasOwnProperty.call(rule, 'min')) {
      return getMinRule(key, rule);
    }
    if (Object.prototype.hasOwnProperty.call(rule, 'not')) {
      return getNegatedRule(rule.not, key);
    }
    if (Object.prototype.hasOwnProperty.call(rule, 'duration')) {
      return getDurationRule(key, rule);
    }
    if (Object.prototype.hasOwnProperty.call(rule, 'exists')) {
      return getExistsRule(rule.exists);
    }
    // TODO no rule
    return () => true;
  }
  return item => get(item, key) === rule;
}

// TODO move out.
// TODO use symbol for _default
export default class Classifier {
  constructor({ label, tags = [], tagProperty = '__tag' } = {}) {
    this.label = label;
    this.valueProperty = 'ts_value';
    this.tagProperty = tagProperty;

    if (!Array.isArray(tags[0])) {
      tags = [tags];
    }

    this.tagGroups = tags.map(tgs =>
      orderBy(tgs, 'priority').map(tag => ({
        ...tag,
        filter: this._createFilterFn(tag.filter),
      })),
    );
  }

  _classify(dataItem) {
    dataItem = { ...dataItem, [this.tagProperty]: '_default' };
    this.tagGroups.forEach(tgs => {
      tgs.some((item, i) => {
        if (item.filter(dataItem)) {
          if (
            !dataItem[this.tagProperty] ||
            dataItem[this.tagProperty] === '_default'
          ) {
            dataItem[this.tagProperty] = item.name;
            if (item.invalidateValue) {
              dataItem.invalidatedValue = dataItem[this.valueProperty];
              dataItem[this.valueProperty] = null;
            }
          } else {
            dataItem[this.tagProperty] += `|${item.name}`;
          } /** Sorting - e.g. ignore "remark" in multi classification, see LANUV-204 (STationsinformationen) */
          if (!item.classificationIgnore) {
            dataItem.__tagpriority = item.sortPriority || i;
            dataItem.__tag_label = item.label || '';
          }
          return true;
        }
        return false;
      });
    });

    return dataItem;
  }

  classify(data) {
    return data.map(item => this._classify(item));
  }

  // able to override
  // eslint-disable-next-line class-methods-use-this
  _createFilterFn(filter) {
    if (typeof filter === 'function') {
      return filter;
    }

    // TODO use 3rd party standard filter syntax
    const rules = Object.keys(filter).map(key => createRule(key, filter[key]));
    return function filterFn(item) {
      return rules.reduce((prev, rule) => prev && rule(item), true);
    };
  }

  getTag(station) {
    return station[this.tagProperty];
  }
}
