import { css } from 'lit';
// language=CSS
export default css`
  :host {
    display: block;
    position: relative;
    overflow: auto;
  }

  .table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    display: table;
    table-layout: fixed;
  }

  br {
    display: none;
  }

  .header {
    display: table-header-group;
    text-align: left;
  }

  .body {
    display: table-row-group;
    height: 100%;
    overflow: auto;
  }

  .row {
    display: table-row;
    border-width: 1px 0;
    border-color: #ececec;
    border-style: solid;
    cursor: pointer;
  }

  .row:hover {
    background: lightgray !important;
  }

  .cell {
    padding: 5px 10px;
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cell.number,
  .cell.date {
    text-align: right;
  }

  .header .row {
    border-top-width: 0;
    background-color: white;
    border-bottom: 1px solid darkgray;
  }

  .header .cell {
    position: sticky;
    top: 0;
    background: inherit;
    z-index: 2;
    padding: 0;
  }

  .header-content {
    padding: 10px;
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .header-content .col-label {
  }

  .footer {
    position: sticky;
    bottom: 0;
    background-color: white;
    text-align: right;
    padding: 3px 5px;
    border-top: 2px solid darkgray;
    font-size: 12px;
  }
`;
