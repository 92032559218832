import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import { containsCoordinate } from 'ol/extent';
import { get as getProjection } from 'ol/proj';
import TileArcGISRest from 'ol/source/TileArcGISRest';
import { OSM, XYZ } from 'ol/source';
import { Attribution } from 'ol/control';
import { Tile as TileLayer } from 'ol/layer';

let registeredProjections = false;

const defaultLayers = [
  'topo',
  'natgeo',
  'imagery2d',
  'ocean',
  'physical',
  'terrain',
  'street',
];

export function getDefaultBaseLayers(
  layerList = defaultLayers,
  { defaultLayer, format = 'PNG' } = {},
) {
  defaultLayer = defaultLayer || layerList[0];
  const commonlayers = [
    new TileLayer({
      name: 'osm',
      source: new OSM(),
    }),
    new TileLayer({
      name: 'topo',
      source: new XYZ({
        attributions: [
          new Attribution({
            html: 'Esri, HERE, DeLorme, Intermap, GEBCO, USGS, FAO, NPS, NRCAN, GeoBase, IGN, Kadaster NL, Ordnance Survey',
          }),
        ],
        url: '//services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
        crossOrigin: 'Anonymous',
        params: { FORMAT: format },
      }),
    }),
    new TileLayer({
      name: 'natgeo',
      opacity: 1.0,
      source: new XYZ({
        attributions: [
          new Attribution({
            html: 'Content may not reflect National Geographic"s current map policy. Sources: National Geographic, Esri, DeLorme, HERE, UNEP-WCMC, USGS, NASA, ESA, METI, NRCAN, GEBCO, NOAA, increment P Corp. ',
          }),
        ],
        url: '//services.arcgisonline.com/arcgis/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}',
        crossOrigin: 'Anonymous',
        params: { FORMAT: format },
      }),
    }),
    new TileLayer({
      name: 'imagery2d',
      opacity: 1.0,
      source: new TileArcGISRest({
        attributions: [
          new Attribution({ html: 'Copyright:© 2013 ESRI, i-cubed, GeoEye' }),
        ],
        url: '//services.arcgisonline.com/arcgis/rest/services/ESRI_Imagery_World_2D/MapServer',
        crossOrigin: 'Anonymous',
        params: { FORMAT: format },
      }),
    }),
    new TileLayer({
      name: 'physical',
      maxZoom: 8,
      source: new XYZ({
        attributions: [new Attribution({ html: 'US National Park Service' })],
        url: '//services.arcgisonline.com/arcgis/rest/services/World_Physical_Map/MapServer/tile/{z}/{y}/{x}',
        crossOrigin: 'Anonymous',
        params: { FORMAT: format },
      }),
    }),
    new TileLayer({
      name: 'terrain',
      maxZoom: 13,
      source: new XYZ({
        attributions: [new Attribution({ html: 'Esri, USGS, NOAA' })],
        url: '//services.arcgisonline.com/arcgis/rest/services/World_Terrain_Base/MapServer/tile/{z}/{y}/{x}',
        crossOrigin: 'Anonymous',
        params: { FORMAT: format },
      }),
    }),
    new TileLayer({
      name: 'street',
      source: new XYZ({
        attributions: [
          new Attribution({
            html: ' Esri, HERE, DeLorme, USGS, Intermap, INCREMENT P, NRCan, Esri Japan, METI, Esri China (Hong Kong), Esri Korea, Esri (Thailand), MapmyIndia, NGCC, © OpenStreetMap contributors, and the GIS User Community',
          }),
        ],
        url: '//services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
        crossOrigin: 'Anonymous',
        params: { FORMAT: 'PNG' },
      }),
    }),
    new TileLayer({
      name: 'ocean',
      maxZoom: 16,
      source: new XYZ({
        attributions: [
          new Attribution({
            html: 'Esri, GEBCO, NOAA, National Geographic, DeLorme, HERE, Geonames.org, and other contributors',
          }),
        ],
        url: '//services.arcgisonline.com/arcgis/rest/services/Ocean_Basemap/MapServer/tile/{z}/{y}/{x}',
        crossOrigin: 'Anonymous',
        params: { FORMAT: format },
      }),
    }),
    new TileLayer({
      name: 'chinaStreetGray',
      source: new XYZ({
        attributions: [
          new Attribution({
            html: 'Esri, HERE, DeLorme, Intermap, GEBCO, USGS, FAO, NPS, NRCAN, GeoBase, IGN, Kadaster NL, Ordnance Survey',
          }),
        ],
        url: '//cache1.arcgisonline.cn/ArcGIS/rest/services/ChinaOnlineStreetGray/MapServer/tile/{z}/{y}/{x}',
        crossOrigin: 'Anonymous',
        params: { FORMAT: format },
      }),
    }),
    new TileLayer({
      name: 'TianDiTu',
      source: new XYZ({
        attributions: [new Attribution({ html: '' })],
        url: 'http://t{0-7}.tianditu.com/DataServer?T=cta_w&x={x}&y={y}&l={z}',
        crossOrigin: 'Anonymous',
        params: { FORMAT: format },
      }),
    }),
    new TileLayer({
      name: 'GaoDe',
      source: new XYZ({
        attributions: [new Attribution({ html: '' })],
        url: 'http://webrd0{1-4}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=7&x={x}&y={y}&z={z}',
        crossOrigin: 'Anonymous',
        params: { FORMAT: format },
      }),
    }),
  ];
  return commonlayers
    .filter(layer => {
      // TODO order ?
      layer.setVisible(layer.get('name') === defaultLayer);
      return layerList.includes(layer.get('name'));
    })
    .map(l => {
      l.setProperties({
        displayInLayerControl: true,
        group: 'Backgrounds',
      });
      return l;
    });
}

export function registerProjections(mapCfg) {
  if (mapCfg.projections && !registeredProjections) {
    mapCfg.projections.forEach(proj => {
      const extent = proj.length === 3 ? proj.pop() : null;
      proj4.defs(proj[0], proj[1]);
      const olProj = getProjection(proj[0]);
      if (olProj && extent) {
        olProj.setExtent(extent);
      }
    });
    registeredProjections = true;
    register(proj4);
  }
}

export function reproject(data, mapCfg) {
  try {
    registerProjections(mapCfg);
  } catch (e) {
    console.error('error registering projections', mapCfg);
  }

  const viewProjection = mapCfg?.view?.projection || 'EPSG:3857';
  const dataProjection = mapCfg?.dataProjection || 'EPSG:4326';
  let x = 'station_longitude';
  let y = 'station_latitude';
  if (mapCfg.crs === 'regional') {
    x = 'station_carteasting';
    y = 'station_cartnorthing';
  } else if (mapCfg.crs === 'local') {
    x = 'station_local_x';
    y = 'station_local_y';
  }

  const stations = [];

  data.forEach(station => {
    if (station && station[x] && station[y]) {
      const point =
        dataProjection !== viewProjection
          ? proj4(dataProjection, viewProjection, [
              parseFloat(station[x]),
              parseFloat(station[y]),
            ])
          : [parseFloat(station[x]), parseFloat(station[y])];
      if (station.Web_Offset) {
        try {
          const offset = JSON.parse(station.Web_Offset);
          point[0] += offset[0];
          point[1] += offset[1];
        } catch (e) {
          console.error('Could not parse station offset', station);
        }
      }
      if (mapCfg.view.extent) {
        if (containsCoordinate(mapCfg.view.extent, point)) {
          station.point = point;
          stations.push(station);
        } else {
          console.warn('Station outside extent', station);
        }
      } else {
        station.point = point;
        stations.push(station);
      }
    }
  });
  // console.debug("reproject: ", new Date().getTime()-start)
  return stations;
}
