import { html, css, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { filter } from 'lodash-es';
import { Mix } from '../../common';
import { queryParamMixin } from '../../decorators';
import { getRouteOptionsAndParams } from '../ki-app';
import { getCurrentApi } from '../../api';
import '@ui5/webcomponents/dist/Select';
import '@ui5/webcomponents/dist/Option';

@customElement('ww-documents')
export default class WwDocuments extends Mix(LitElement, [
  queryParamMixin,
  { targetProperty: 'selected', selector: 'selected', defaultValue: '' },
]) {
  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      height: calc(100% - 30px);
    }
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
    .left-panel {
      text-align: center;
      flex: 0;
    }
    .right-panel {
      flex: 1;
    }

    ui5-select {
      width: 350px;
    }

    a {
      font-size: 2em;
      text-align: center;
      display: block;
      margin-top: 20%;
    }
  `;

  constructor() {
    super();
    this.api = getCurrentApi();
    this.data = [];
  }

  _selected = '';

  basePath = '';

  get selected() {
    return this._selected;
  }

  set selected(value) {
    this._selected = value;
    this.requestUpdate();
  }

  async onAfterEnter(location) {
    const params = getRouteOptionsAndParams(location, ['content']);
    this.directory = `${params.options?.directory ?? 'documents'}/${params.content}`;
    this.basePath = params.options?.basePath ?? '';

    this.fetchData();
  }

  async fetchData() {
    const res = await this.api._requestJson(`/${this.directory}/index.json`);
    this.data = filter(
      res.data,
      d => d.mimeType === 'application/pdf',
    ).reverse(); // Newest file on first position
    this._setDocument(this.data[0].name);
    this.requestUpdate();
  }

  getUrl(file) {
    return `${this.api.dataPath}/${this.directory}/${file}`;
  }

  _setDocument(pdf) {
    const pdfUrl = `${this.basePath}${this.api.dataPath}/${this.directory}/${pdf}`;
    const fullUrl = new URL(pdfUrl, window.location.origin).href;
    this.renderRoot.querySelector('#contentframe').src = fullUrl;
  }

  _handleChanged(e) {
    this._setDocument(e.target.selectedOption.value);
  }

  render() {
    return this.data
      ? html`
          <div class="left-panel">
            <ui5-select @change="${this._handleChanged}">
              ${this.data.map(
                item =>
                  html`<ui5-option value="${item.name}"
                    >${item.name.replace('.pdf', '')}</ui5-option
                  >`,
              )}
            </ui5-select>
          </div>
          <div class="right-panel">
            <iframe title="content" id="contentframe"></iframe>
          </div>
        `
      : html`<div>Loading...</div>`;
  }
}
