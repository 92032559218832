/* eslint-disable lit-a11y/click-events-have-key-events */
/* eslint-disable max-classes-per-file */
import { html, LitElement } from 'lit';
/* eslint-disable import/extensions */
import { property } from 'lit/decorators.js';

import { repeat } from 'lit/directives/repeat.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
/* eslint-enable import/extensions */
import style from './base-table.css?inline';
import '../ki-icon/ki-icon';
import { template } from '../../common/util';
import Mix from '../../common/Mix';
import PropertyDefaultValue from '../../common/PropertyDefaultValue';
import { getFromItem } from './GetField';
import { getConfig } from '../ki-app';
import { AppConfigDefinition } from '../../defs/AppConfigDefinition';
import { dayjs } from '../../common';

export default class BaseTable extends Mix(LitElement, PropertyDefaultValue) {
  // language=CSS
  static styles = style;

  get renderingData() {
    return this.data;
  }

  get renderingColumns() {
    // for customize
    return (
      this.columns ||
      Object.keys(this.renderingData[0] || {}).map(key => ({
        field: key,
        label: key,
        sortable: true,
      }))
    );
  }

  /** Required for LazyRendering */
  @property({ type: Number })
  startIndex = 0;

  @property({ type: String })
  idproperty = '';

  @property({ type: Array })
  data = [];

  @property({ type: Array })
  /*
   *  @example [
   *   {
   *     field: String,
   *     label: String,
   *     renderHeaderCell: function.
   *     renderCell: function
   *   }
   * ]
   * */
  columns = [];

  config: AppConfigDefinition = getConfig();

  timeZone: string = this.config.timeZone || dayjs.tz.guess();

  // eslint-disable-next-line class-methods-use-this
  _renderRow() {
    return html``;
  }

  _renderHeader() {
    return html`
      <div class="header">
        <div class="row">
          ${repeat(
            this.renderingColumns,
            (col: { field: any }) => col.field,
            col => this._renderHeaderCell(col),
          )}
        </div>
      </div>
    `;
  }

  // The ignore is important because prettier inserts whitespace which the table renders
  // prettier-ignore
  // eslint-disable-next-line class-methods-use-this
  _renderHeaderCell(col) {
    return col.renderHeaderCell
      ? col.renderHeaderCell()
      : html`
          <div
            class="cell col-${Array.isArray(col.field) ? col.field[0] : col.field}"
            style=${col.width ? `width:${col.width - 15}px;` : ''}
          >
            <div
              style=${col.labelCss || col.css || ''}
              class="header-content"
              part="header-content"
              title="${col.title || col.label}"
            ><span class="col-label">${unsafeHTML(col.label)}</span>
            </div>
          </div>
        `;
  }

  _renderData() {
    // language=html
    const keyFn = this.idproperty && (item => item[this.idproperty]);
    return html`
      <div class="body">
        ${repeat(this.renderingData || [], keyFn, item => this.renderRow(item))}
      </div>
    `;
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  mouseenter(_e?, _item?) {
    // implement in parent class if needed
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  mouseleave(_e?, _item?) {
    // implement in parent class if needed
  }

  renderRow(item) {
    // can be override;
    // language=html
    return html`
      <div
        class="row"
        @mouseenter="${e => this.mouseenter(e, item)}"
        @mouseleave="${e => this.mouseleave(e, item)}"
        @click="${() => {
          this._onRowClick(item);
        }}"
      >
        ${this.renderingColumns.map(col => this._renderCell(col, item))}
      </div>
    `;
  }

  // eslint-disable-next-line class-methods-use-this
  _renderCell(col, item) {
    const val = getFromItem(item, col.field);
    const prefix = col.prefix && val ? template(col.prefix, item) : '';
    const suffix = col.suffix && val ? template(col.suffix, item) : '';
    return col.renderCell
      ? col.renderCell(item)
      : html`
          <div
            style=${col.css || ''}
            class="cell ${(Array.isArray(col.field) ? col.field : [col.field])
              .map(field => `col-${field}`)
              .join(' ')}"
          >
            ${prefix}${val}${suffix}
          </div>
        `;
  }

  render() {
    // language=html
    return html`
      <div class="table" part="table">
        ${this._renderHeader()} ${this._renderData()}
      </div>
      <div class="footer">
        ${this.i18n.t('TABLE.ALL_TS_TZ')}: ${this.timeZone}
      </div>
    `;
  }

  _onRowClick(item) {
    this.dispatchEvent(
      new CustomEvent('row-click', {
        detail: {
          data: item,
        },
      }),
    );
  }
}
